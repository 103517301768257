import React from 'react';
// @ts-expect-error TS(2307): Cannot find module '../../assets/scheduleMaintenan... Remove this comment to see the full error message
import { ReactComponent as ScheduleMaintenaceGraphic } from '../../assets/scheduleMaintenanceGraphic.svg';

const ScheduleMaintenance = () => (
  <div className="flex flex-col bg-b-200 h-screen">
    <div className="mt-40">
      <p className="text-red-500 text-center text-xl px-3 lg:text-3xl uppercase">Scheduled maintenance</p>
    </div>

    <div className="mt-3 px-3">
      <p className="text-purple-500 text-center lg:text-xl">
        We are making updates to our site and will be back up soon.
      </p>
    </div>

    <div className="mt-5 flex justify-center">
      <ScheduleMaintenaceGraphic />
    </div>
  </div>
);

export default ScheduleMaintenance;
