import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';

import { getCustomer, getMyTeam } from '../../graphql/queries';
import { deleteTeamMember } from '../../graphql/mutations';
import { useUser } from '../../context/UserContext';
import { errorToast, successToast } from '../../shared/toast';
import ScaleLoader from '../../components/loader/Loader';
import TeamMemberCard from '../../components/cards/TeamMemberCard';
import PageHeader from '../../components/header/PageHeader';
import { getBaseUrl } from '../../shared/helper';
import Tabs from '../../components/tabs/Tabs';
import { effectualGroups } from '../../shared/groupsHelper';

const MyTeam = () => {
  const [customer, setCustomer] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [showExtra, setShowExtra] = useState(false);

  const history = useHistory();
  // @ts-expect-error TS(2339): Property 'userInRoles' does not exist on type 'unknown'.
  const { userInRoles } = useUser();

  const allowedToEdit = () => {
    if (userInRoles([effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName])) {
      setShowExtra(true);

      return true;
    }

    return false;
  };

  const dataStates = {
    LOADING: 'loading',
    LOADED: 'loaded',
  };

  const [dataState, setDataState] = useState(dataStates.LOADING);
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();

  // @ts-expect-error TS(2339): Property 'isEffectualUser' does not exist on type 'unknown'.
  const { isEffectualUser } = useUser();

  const useMountEffect = (func: any) => useEffect(func, []);

  const initializeComponent = () => {
    (async () => {
      try {
        const res = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));
        setCustomer((res as any).data?.getCustomer);
        const resMyTeam = await API.graphql(graphqlOperation(getMyTeam, { customerId }));
        const myTeam = (resMyTeam as any)?.data?.getMyTeam?.myTeam || [];
        myTeam.sort((a: any, b: any) => b.isPrimary - a.isPrimary);
        setTeamMembers(myTeam);
        setDataState(dataStates.LOADED);
      } catch (error) {
        if ((error as any).errors[0].errorType !== '404') {
          console.error('Error fetching my team', error);
        }
        setDataState(dataStates.LOADED);
      }
    })();
  };

  useMountEffect(initializeComponent);

  const removeMember = async (teamMemberId: any) => {
    setDataState(dataStates.LOADING);
    try {
      await API.graphql(graphqlOperation(deleteTeamMember, { customerId, teamMemberId }));
      successToast('User removed');
      initializeComponent();
    } catch (error) {
      errorToast('Error deleting team member');
      setDataState(dataStates.LOADED);
    }
  };

  const addNew = () => {
    history.push(`/empath${getBaseUrl(customerId)}/my-team/team-member`);
  };

  const setActiveTab = (key: any) => {
    history.push(`/empath${getBaseUrl(customerId)}/${key}`);
  };

  const Header = () => {
    if (isEffectualUser && allowedToEdit()) {
      return (
        <PageHeader
          title={`${(customer as any)?.name} Admin`}
          containerClassName="sm:mb-6"
          buttonLabel="Add New Member"
          customAction={() => addNew()}
        />
      );
    }

    return <PageHeader title="My Team" containerClassName="sm:mb-6" />;
  };

  return (
    <>
      {dataState === dataStates.LOADING && (
        <div className="flex flex-col items-center pt-20">
          {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
          <ScaleLoader height="100" width="100" color="text-purple-700" />
        </div>
      )}
      {dataState === dataStates.LOADED && (
        <div className="page-container">
          <Header />
          {showExtra && (
            <Tabs
              tabs={[
                { title: 'Users', key: 'users' },
                { title: 'Customer Details', key: '' },
                { title: 'View Team', key: 'my-team', active: true },
              ]}
              tabSelected={setActiveTab}
            />
          )}
          <div className="grid grid-cols-1 xl:grid-cols-2 2.5xl:grid-cols-3 4xl:grid-cols-4 gap-4">
            {teamMembers.length > 0 &&
              teamMembers.map((memberInfo, index) => (
                <div key={index}>
                  <TeamMemberCard
                    classNames="p-2 w-full h-full max-w-lg"
                    memberInfo={memberInfo}
                    removeMember={removeMember}
                    allowedToEdit={allowedToEdit}
                    customerId={customerId}
                  />
                </div>
              ))}
            {teamMembers.length === 0 &&
              isEffectualUser &&
              userInRoles([effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName]) && (
                <p>Click the Add New Member button to start creating your team.</p>
              )}
            {teamMembers.length === 0 &&
              !userInRoles([effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName]) && (
                <p>Coming Soon. We’re assembling your team. </p>
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default MyTeam;
