// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';
// @ts-expect-error TS(2732): Cannot find module '../components/inputs/currentTi... Remove this comment to see the full error message
import currentTimeZones from '../components/inputs/currentTimeZones.json';

export const shiftToStartOfUtcDay = (date: any) => {
  const currentDate = DateTime.fromJSDate(date);
  const convertedDate = DateTime.utc(currentDate.year, currentDate.month, currentDate.day, 0, 0, 0, 0).toJSDate();

  return convertedDate;
};

export const shiftToUtcEndOfMonth = (date: any) => {
  const currentDate = DateTime.fromJSDate(date);

  const convertedDate = DateTime.utc(currentDate.year, currentDate.month, currentDate.day, 0, 0, 0, 0)
    .endOf('month')
    .toJSDate();

  return convertedDate;
};

export const roundTimeToNearestMinutes = (minutes = 10, date = new Date()) => {
  const milliseconds = minutes * 60 * 1000;

  return new Date(Math.round(date.getTime() / milliseconds) * milliseconds);
};

export const isValidTimeZone = (timezone: any) => currentTimeZones.includes(timezone);

export const formatTimeWithTimeZone = (date: any, tz: any) => {
  const timeZone = isValidTimeZone(tz) ? { zone: tz } : {};
  const shortDate = DateTime.fromISO(date, timeZone).toFormat('t ZZZZ');

  return shortDate; // 9:55 AM PDT
};

export const formatTime = (date: any, tz: any) => {
  const timeZone = isValidTimeZone(tz) ? { zone: tz } : {};
  const shortDate = DateTime.fromISO(date, timeZone).toFormat('t');

  return shortDate; // 9:55 AM
};

export const formatTimeWithDay = (date: any, tz: any) => {
  const timeZone = isValidTimeZone(tz) ? { zone: tz } : {};
  const shortDate = DateTime.fromISO(date, timeZone).toFormat('ccc t ZZZZ');

  return shortDate; // Wed 9:55 AM EST
};

export const formatDateShortAndTime = (date: any, tz: any) => {
  const timeZone = isValidTimeZone(tz) ? { zone: tz } : {};
  const shortDate = DateTime.fromISO(date, timeZone).toFormat('D, t ZZZZ');

  return shortDate; // 3/30/2021, 9:55 AM PDT
};

export const formatDateMedAndTime = (date: any, tz: any) => {
  const timeZone = isValidTimeZone(tz) ? { zone: tz } : {};
  const medDate = DateTime.fromISO(date, timeZone).toFormat('DD, t ZZZZ');

  return medDate; // Mar 30, 2021, 9:55 AM PDT
};

export const toUtcIso = (date: any, tz: any) => {
  const timeZone = isValidTimeZone(tz) ? { zone: tz } : {};
  const luxonTime = DateTime.fromObject(timeZone).set({
    year: date.getFullYear(),
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: 0,
    milliseconds: 0,
  });

  return luxonTime.toUTC().toISO();
};

export const formatMonthDate = (date: any, tz: any) => {
  const timeZone = isValidTimeZone(tz) ? { zone: tz } : {};
  const shortDate = DateTime.fromISO(date, timeZone).toFormat('MM/dd');

  return shortDate; // 03/30
};

export const formatMonthDateAndTime = (date: any, tz: any) => {
  const timeZone = isValidTimeZone(tz) ? { zone: tz } : {};
  const shortDate = DateTime.fromISO(date, timeZone).toFormat('MM/dd, t');

  return shortDate; // 03/30, 9:55 AM
};

export const getVerifiedTimeZone = (tz: any) => tz || Intl.DateTimeFormat().resolvedOptions().timeZone;

export const convertToAnotherTimeZone = (date: any, tz: any) => {
  const timeZone = getVerifiedTimeZone(tz);

  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone }));
};
