import React from 'react';
import Tippy from '@tippyjs/react';
import { twCascade } from '@mariusmarais/tailwind-cascade';

type Props = {
  children: React.ReactElement;
  disabled?: boolean;
  header?: string;
  content?: React.ReactNode;
  containerClassName?: string;
  maxWidth?: string;
  showOnClick?: boolean;
};

const Tooltip = ({
  children,
  disabled,
  header,
  content,
  containerClassName = 'border-orange',
  maxWidth,
  showOnClick = false,
}: Props) => (
  <Tippy
    maxWidth={maxWidth || '350px'}
    disabled={disabled}
    interactive
    interactiveBorder={0}
    delay={[400, null]}
    trigger={showOnClick === true ? 'click' : 'mouseenter'}
    content={
      <div
        className={twCascade('overflow-hidden', 'shadow', 'bg-white', 'border-solid', 'border-l-4', containerClassName)}
      >
        <div className="pt-2 pb-1 px-5">
          <h6 className="text-md font-bold text-purple-600">{header}</h6>
          {content}
        </div>
      </div>
    }
    appendTo={document.body}
  >
    <div>{children}</div>
  </Tippy>
);
export default Tooltip;
