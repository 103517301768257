import React, { forwardRef } from 'react';

import { twCascade } from '@mariusmarais/tailwind-cascade';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactDatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-light-svg-icons';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';

type TimePickerInputProps = {
  onClick?: (...args: any[]) => any;
  date?: any; // TODO: PropTypes.instanceOf(Date)
  disabled?: boolean;
  inputClassName?: string;
  error?: string;
};

const TimePickerInput = forwardRef<any, TimePickerInputProps>(
  ({ onClick, date, inputClassName, disabled, error }, ref) => {
    const displayTime = () => {
      if (date) {
        return DateTime.fromJSDate(date).toFormat('t');
      }

      return date;
    };

    return (
      <button
        ref={ref}
        type="button"
        className={twCascade(
          'inline-flex',
          'items-center',
          'justify-between',
          'px-2',
          'py-0.5',
          'bg-white',
          'border',
          'border-gray-300',
          'rounded',
          disabled ? 'cursor-not-allowed' : '',
          error ? 'border-red-700' : '',
          inputClassName
        )}
        onClick={onClick}
      >
        <div>{displayTime()}</div>
        <div>
          <FontAwesomeIcon icon={faClock} className="text-purple-500" />
        </div>
      </button>
    );
  }
);

TimePickerInput.displayName = 'DatePickerInput';

type TimePickerProps = {
  date?: any; // TODO: PropTypes.instanceOf(Date)
  onChange?: (...args: any[]) => any;
  disabled?: boolean;
  inputClassName?: string;
  error?: string;
};

const TimePicker = forwardRef<any, TimePickerProps>(({ disabled, date, onChange, error, inputClassName }, ref) => (
  <ReactDatePicker
    disabled={disabled}
    selected={date}
    onChange={onChange}
    showTimeSelect
    showTimeSelectOnly
    timeIntervals={15}
    timeCaption="Time"
    dateFormat="h:mm aa"
    customInput={
      <TimePickerInput disabled={disabled} date={date} ref={ref} error={error} inputClassName={inputClassName} />
    }
  />
));

TimePicker.displayName = 'DatePicker';

export default TimePicker;
