/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMonitoringFilters = /* GraphQL */ `
  query GetMonitoringFilters(
    $params: QueryGetMonitoringFiltersParamsInput!
    $query: QueryGetMonitoringFiltersQueryInput!
  ) {
    getMonitoringFilters(params: $params, query: $query) {
      items {
        workloads {
          id
          environments {
            id
            services
          }
          regions {
            id
            services
          }
        }
        regions {
          id
          workloads {
            id
            services
          }
          environments {
            id
            services
          }
        }
        environments {
          id
          workloads {
            id
            services
          }
          regions {
            id
            services
          }
        }
        services {
          id
          name
          metrics {
            ids
            name
          }
        }
      }
    }
  }
`;
export const getMonitoringMetrics = /* GraphQL */ `
  query GetMonitoringMetrics(
    $params: QueryGetMonitoringMetricsParamsInput!
    $query: QueryGetMonitoringMetricsQueryInput!
  ) {
    getMonitoringMetrics(params: $params, query: $query) {
      items {
        metricname
        workload
        arn
        id
        type
        state
        otherMetrics
        metrics {
          value
          timestamp
        }
        tags
        size
      }
    }
  }
`;
export const listAllTickets = /* GraphQL */ `
query ListAllTickets(
  $query: QueryQueryInput
) {
  listAllTickets(query: $query) {
    items {
      customerId
      id
      type
      number
      category
      subject
      customer
      state
      openedBy
      openedByEmail
      openedAt
      createdOn
      updatedOn
      startDate
      data
      attachments
      changeRequest {
        id
        number
      }
      assignedTo
      assignedToName
      linkedIncidents {
        id
        number
      }
    }
    token
  }
}
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($params: QueryGetTicketParamsInput!) {
    getTicket(params: $params) {
      customerId
      id
      type
      number
      category
      subject
      customer
      state
      openedBy
      openedByEmail
      openedAt
      createdOn
      updatedOn
      startDate
      data
      attachments
      changeRequest {
        id
        number
      }
      incident {
        id
        number
      }
      assignedTo
      assignedToName
      linkedIncidents {
        id
        number
      }
      linkedSupportRequest {
        id
        number
      }
    }
  }
`;
export const getTicketComments = /* GraphQL */ `
  query GetTicketComments(
    $params: QueryGetTicketCommentsParamsInput!
    $query: QueryGetTicketCommentsQueryInput
  ) {
    getTicketComments(params: $params, query: $query) {
      items {
        id
        ticketId
        comment
        createdOn
        createdBy
      }
      attachments {
        id
        key
        fileName
        hash
      }
      token
    }
  }
`;
export const getTicketAttachment = /* GraphQL */ `
  query GetTicketAttachment($params: QueryGetTicketAttachmentParamsInput!) {
    getTicketAttachment(params: $params) {
      id
      fileName
      downloadUrl
    }
  }
`;

export const getMonitorStatusSite24x7  = /* GraphQL */ `
  query GetMonitorStatusSite24x7($params: QueryGetMonitorStatusSite24x7ParamsInput!) {
    getMonitorStatusSite24x7(params: $params){
      downValue
      upValue
      troubleValue
      criticalValue
      data
    }
  }
`;
export const getMonitorDetailsSite24x7 = /* GraphQL */ `
query GetMonitorDetailsSite24x7(
    $params: QueryGetMonitorDetailsSite24x7ParamsInput!
    $query: QueryGetMonitorDetailsSite24x7QueryInput!
  ) {
  getMonitorDetailsSite24x7(params: $params, query: $query){
    availabilitySummary
    logReportByDate
  }
}
`;
export const listEmpathUsers = /* GraphQL */ `
  query ListEmpathUsers(
    $query: QueryListEmpathUsersQueryInput!
  ) {
    listEmpathUsers(query: $query) {
      id
      email
      sub
      customerId
      customerIds
      serviceNowId
      groups
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      onboarded
      canApproveChangeRequest
    }
  }
`;
export const getEmpathUser = /* GraphQL */ `
  query GetEmpathUser($params: QueryGetEmpathUserParamsInput!) {
    getEmpathUser(params: $params) {
      id
      email
      sub
      customerId
      customerIds
      serviceNowId
      groups
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      onboarded
      canApproveChangeRequest
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($id: ID!) {
    getInventory(id: $id) {
      id
      customerId
      account
      region
      service
      resource
      workload
      data
      createdAt
      updatedAt
    }
  }
`;
export const listInventorys = /* GraphQL */ `
  query ListInventorys(
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byAccount = /* GraphQL */ `
  query ByAccount(
    $account: String
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAccount(
      account: $account
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byService = /* GraphQL */ `
  query ByService(
    $service: String
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byService(
      service: $service
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byResource = /* GraphQL */ `
  query ByResource(
    $resource: String
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byResource(
      resource: $resource
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byAccountByRegion = /* GraphQL */ `
  query ByAccountByRegion(
    $account: String
    $region: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAccountByRegion(
      account: $account
      region: $region
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCustomerIdByWorkload = /* GraphQL */ `
  query ByCustomerIdByWorkload(
    $customerId: String
    $workload: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCustomerIdByWorkload(
      customerId: $customerId
      workload: $workload
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCustomerId = /* GraphQL */ `
  query ByCustomerId(
    $customerId: String
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCustomerIdByService = /* GraphQL */ `
  query ByCustomerIdByService(
    $customerId: String
    $service: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCustomerIdByService(
      customerId: $customerId
      service: $service
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        account
        region
        service
        resource
        workload
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      products
      phone
      createdAt
      updatedAt
      isActive
      identityProvider
      metadataUrl
      ssoUrn
      ssoUrl
      openTicketCount
      featureFlags {
        supportOnly
        resaleOnly
        mcm
        vmc
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        products
        phone
        createdAt
        updatedAt
        isActive
        identityProvider
        metadataUrl
        ssoUrn
        ssoUrl
        openTicketCount
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      createdAt
      updatedAt
      onboarded
      sub
      customerId
      customerIds
      serviceNowId
      firstName
      lastName
      timeZone
      jobTitle
      phoneNumber
      phoneNumber2
      officeLocation
      profilePhotoUrl
      isSsoUser
      groups
      identityId
      canApproveChangeRequest
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        createdAt
        updatedAt
        onboarded
        sub
        customerId
        customerIds
        serviceNowId
        firstName
        lastName
        timeZone
        jobTitle
        phoneNumber
        phoneNumber2
        officeLocation
        profilePhotoUrl
        isSsoUser
        groups
        identityId
        canApproveChangeRequest
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
        onboarded
        sub
        customerId
        customerIds
        serviceNowId
        firstName
        lastName
        timeZone
        jobTitle
        phoneNumber
        phoneNumber2
        officeLocation
        profilePhotoUrl
        isSsoUser
        groups
        identityId
        canApproveChangeRequest
      }
      nextToken
    }
  }
`;
export const userBySub = /* GraphQL */ `
  query UserBySub(
    $sub: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
        onboarded
        sub
        customerId
        customerIds
        serviceNowId
        firstName
        lastName
        timeZone
        jobTitle
        phoneNumber
        phoneNumber2
        officeLocation
        profilePhotoUrl
        isSsoUser
        groups
        identityId
        canApproveChangeRequest
        dashboardSettings
      }
      nextToken
    }
  }
`;
export const usersByCustomerId = /* GraphQL */ `
  query UsersByCustomerId(
    $customerId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        createdAt
        updatedAt
        onboarded
        sub
        customerId
        customerIds
        serviceNowId
        firstName
        lastName
        timeZone
        jobTitle
        phoneNumber
        phoneNumber2
        officeLocation
        profilePhotoUrl
        isSsoUser
        groups
        identityId
        canApproveChangeRequest
      }
      nextToken
    }
  }
`;

export const getMyTeam = /* GraphQL */ `
  query GetMyTeam($customerId: String!) {
    getMyTeam(customerId: $customerId) {
      myTeam {
        teamMemberId
        fullName
        email
        role
        isPrimary
        phoneNumber
        profilePhotoUrl
      }
      createdAt
      customerId
      updatedAt
    }
  }
`;

export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $sub: String
    $id: ModelIDKeyConditionInput
    $filter: NotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      sub: $sub
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sub
        id
        message
        when
        isRead
        customerId
        type
        key
        createdAt
      }
      nextToken
    }
  }
`;

export const getSiteSettings = /* GraphQL */ `
  query GetSiteSettings($serviceName: ID!) {
    getSiteSettings(serviceName: $serviceName) {
      serviceName
      isUnderMaintenance
    }
  }
`;

export const generateCSVReport = /* GraphQL */ `
  query GenerateCSVReport(
    $body: QueryGenerateCSVReportBodyInput!
    ) {
    generateCSVReport(body: $body
      ) {
        statusCode
        payload
      }
  }
`;
