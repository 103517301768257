import React from 'react';
import ReactDOM from 'react-dom';

import { Amplify } from 'aws-amplify';
import ReactGa from 'react-ga';

import App from './App';
import * as serviceWorker from './serviceWorker';
// @ts-expect-error TS(7016): implicitly has an 'any' type.
import config from './aws-exports';

Amplify.configure(config);

ReactGa.initialize('UA-232740798-1');
// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
ReactGa.pageview(window.location.pathname + window.location.search);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
