import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { TextInputNoControl } from '../../../components/inputs';
import { Button } from '../../../components/buttons';

type Props = {
  setUser: (...args: any[]) => any;
  user: any;
};

const MfaForm = ({ setUser, user }: Props) => {
  const [form, setForm] = useState({ code: '' });
  const [verifying, setVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState('');
  const onChange = (e: any) => {
    const newState = { ...form, [e.target.name]: e.target.value };

    setForm(newState);
  };

  const verifyToken = async () => {
    try {
      setVerifying(true);

      if (!form.code) {
        setVerificationError('Code is required');
        setVerifying(false);

        return;
      }

      setVerificationError('');
      const authedUser = await Auth.confirmSignIn(user, form.code, 'SOFTWARE_TOKEN_MFA');

      setUser(authedUser);
      setVerifying(false);

      window.location.href = '/';
    } catch (err) {
      console.error('ERROR: ', err);
      if ((err as any).code === 'CodeMismatchException') {
        setVerificationError('Invalid code');
      } else {
        setVerificationError('Could not validate code');
      }
      setVerifying(false);
    }
  };

  return (
    <div className="my-8 flex flex-col xl:w-3/4 xl:mx-auto">
      <TextInputNoControl
        label="Enter MFA Code from Authenticator App"
        field="code"
        errorMessage={verificationError}
        onChange={onChange}
        onSubmit={verifyToken}
        purple
        autoFocus
        containerClassName="mb-4"
      />

      <Button
        title="Log In"
        onClick={verifyToken}
        buttonStyle="solid"
        type="submit"
        classNames="h-8 text-purple-700 hover:bg-gray-200"
        loading={verifying}
        loadingTitle="Verifying"
        disabled={verifying}
      />
    </div>
  );
};

export default MfaForm;
