import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';

import { TextInput } from '../../../components/inputs';
import { Button } from '../../../components/buttons';
import { isValidEmail } from '../../../components/inputs/utils';

type Props = {
  onBackToLoginClicked: (...args: any[]) => any;
  onResetPasswordClicked: (...args: any[]) => any;
};

const RequestPasswordResetForm = ({ onBackToLoginClicked, onResetPasswordClicked }: Props) => {
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, getValues, errors, control } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const requestPasswordReset = async () => {
    try {
      setLoading(true);

      const emailCheckResult = isValidEmail(getValues('email'));

      if (!emailCheckResult.valid) {
        setLoading(false);

        return;
      }

      const response = await Auth.forgotPassword(getValues('email').trim());

      if (response.__type && response.__type !== 'UserNotFoundException') {
        throw new Error(response.message);
      }

      onResetPasswordClicked();

      setLoading(false);
    } catch (err) {
      console.error(err);

      setNotification({
        isError: true,
        message: (err && (err as any).message) || 'An unknown error occurred',
      });

      setLoading(false);
    }
  };

  const checkValidEmail = (email: any) => {
    const res = isValidEmail(email);

    return res.valid ? true : res.error;
  };

  return (
    <div className="my-8">
      <form onSubmit={handleSubmit(requestPasswordReset)}>
        <TextInput
          label="Email Address"
          field="email"
          formErrors={errors}
          purple
          autoFocus
          control={control}
          rules={{
            required: true,
            validate: {
              isValidEmail: (value: any) => checkValidEmail(value),
            },
          }}
        />

        {notification && (
          <div
            className={`text-sm font-bold ${
              (notification as any).isError ? 'text-red-700' : 'text-gray-200'
            } font-body align-center`}
          >
            {(notification as any).message}
          </div>
        )}

        <Button
          title="Send Reset Code"
          buttonStyle="solid"
          type="submit"
          classNames="my-4 h-8 text-purple-700 hover:bg-gray-200"
          loading={loading}
          loadingTitle="Sending Reset Code"
        />

        <Button
          title="BACK TO LOGIN"
          onClick={onBackToLoginClicked}
          type="button"
          buttonStyle="text"
          classNames="text-white text-left hover:text-gray-200"
        />
      </form>
    </div>
  );
};

export default RequestPasswordResetForm;
