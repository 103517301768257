import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import IntroTour from '../../components/tour/IntroTour';
import { updateUser } from '../../graphql/mutations';
import { useUser } from '../../context/UserContext';
import { useSite } from '../../context/SiteContext';
import PageHeader from '../../components/header/PageHeader';
import { IncidentTable, ChangeRequestTable, CaseTable } from '../../components/tables';
import Tabs from '../../components/tabs/Tabs';
import { servicesGroups } from '../../shared/groupsHelper';

const EffectualDashboard = () => {
  // @ts-expect-error TS(2339): Property 'setActiveUser' does not exist on type 'unknown'.
  const { activeUser, setActiveUser } = useUser();
  // @ts-expect-error TS(2339): Property 'servicesStatus' does not exist on type 'unknown'.
  const { servicesStatus } = useSite();
  const [showTour, setShowTour] = useState(!activeUser.onboarded);
  const [activeTab, setActiveTab] = useState('Support Requests');
  const [tableMessage, setTableMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    const { isUnderMaintenance, serviceName } = servicesStatus;

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (isUnderMaintenance && serviceName === servicesGroups[serviceName].name) {
      setTableMessage('Service is unavailable');
    }
  }, [servicesStatus]);

  const finishTour = async () => {
    const oldUser = { ...activeUser, onboarded: true };
    const input = {
      id: oldUser.id,
      email: oldUser.email,
      createdAt: oldUser.createdAt,
      updatedAt: oldUser.updatedAt,
      onboarded: oldUser.onboarded,
      sub: oldUser.sub,
      customerIds: oldUser.customerIds,
      serviceNowId: oldUser.serviceNowId,
      firstName: oldUser.firstName,
      lastName: oldUser.lastName,
      timeZone: oldUser.timeZone,
      jobTitle: oldUser.jobTitle,
      phoneNumber: oldUser.phoneNumber,
      officeLocation: oldUser.officeLocation,
    };

    try {
      const userRes = await API.graphql(graphqlOperation(updateUser, { input }));

      setActiveUser((userRes as any).data.updateUser);
      setShowTour(false);
    } catch (err) {
      console.error('Error updating user', err);
    }
  };

  const doCaseClick = useCallback(
    (data: any) => {
      history.push(`/customers/${data.customerId}/support`);
      history.push(`/customers/${data.customerId}/support/cases/${data.id}`);
    },
    [history]
  );
  const doIncidentClick = useCallback(
    (data: any) => {
      history.push(`/customers/${data.customerId}/support/incidents`);
      history.push(`/customers/${data.customerId}/support/incidents/${data.id}`);
    },
    [history]
  );

  const doChangeRequestClick = useCallback(
    (data: any) => {
      history.push(`/customers/${data.customerId}/support/change-requests`);
      history.push(`/customers/${data.customerId}/support/change-requests/${data.id}`);
    },
    [history]
  );

  if (!activeUser.id) {
    return <div />;
  }

  const updateActiveTab = (tab: any) => {
    setActiveTab(tab);
  };

  return (
    <div className="page-container">
      <PageHeader title="Welcome!" hideSupport />

      <IntroTour doShow={showTour} cancel={finishTour} action={finishTour} />

      <Tabs
        tabs={[{ title: 'Support Requests', active: true }, { title: 'Incidents' }, { title: 'Change Requests' }]}
        tabSelected={updateActiveTab}
      />
      {activeTab === 'Support Requests' && (
        <CaseTable viewPortHeight={30} onCaseClick={doCaseClick} tableMessage={tableMessage} />
      )}
      {activeTab === 'Incidents' && (
        <IncidentTable viewPortHeight={30} onIncidentClick={doIncidentClick} tableMessage={tableMessage} />
      )}
      {activeTab === 'Change Requests' && (
        <ChangeRequestTable
          viewPortHeight={30}
          onChangeRequestClick={doChangeRequestClick}
          tableMessage={tableMessage}
        />
      )}
    </div>
  );
};

export default EffectualDashboard;
