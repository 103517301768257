import React from 'react';

type Props = {
  color?: string;
};

export default function Loader({ color }: Props) {
  return (
    <div className="flex text-center transition ease-in-out duration-700">
      <div className={`loader concentric ${color} text-center m-auto flex-auto content-center`}>
        <svg className="stroke-current m-auto text-center" style={{ width: 100, height: 100 }}>
          <circle cx="50" cy="50" r="40" />
          <circle cx="50" cy="50" r="35" />
          <circle cx="50" cy="50" r="30" />
          <circle cx="50" cy="50" r="25" />
          <circle cx="50" cy="50" r="20" />
        </svg>
      </div>
    </div>
  );
}
