import React from 'react';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { useController } from 'react-hook-form';
import { getErrorMessage } from './utils';

type Props = {
  label: string;
  field: string;
  formErrors?: any;
  errorMessage?: string;
  onSubmit?: (...args: any[]) => any;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  purple?: boolean;
  tooltip?: React.ReactNode;
  defaultValue?: string;
  control: any;
  rules?: any;
};

const TextInput = ({
  label,
  field,
  formErrors,
  errorMessage,
  onSubmit,
  tooltip,
  containerClassName = '',
  inputClassName = '',
  labelClassName = '',
  type = 'text',
  disabled,
  readOnly,
  autoFocus,
  purple,
  control,
  defaultValue,
  rules,
}: Props) => {
  const {
    field: { ref, onChange, value },
  } = useController({
    name: field,
    control,
    rules,
    defaultValue: defaultValue || '',
  });

  const error = errorMessage || getErrorMessage(formErrors, field, label);

  const labelColor = purple ? 'text-gray-200' : 'text-blue-900';
  const textColor = purple ? 'text-gray-200' : 'text-purple-700';
  const backgroundColor = purple ? 'bg-purple-700' : 'text-white';
  const borderColor = purple ? 'border-gray-200' : 'border-b-300';
  const focusBorderColor = purple ? 'focus:border-white' : 'focus:border-purple-700';

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && onSubmit && !disabled) {
      onSubmit();
    }
  };

  return (
    <div className={containerClassName}>
      <div className="flex flex-row items-center">
        <label
          htmlFor={field}
          className={twCascade(
            'flex',
            'text-base',
            'mb-1',
            labelColor,
            error ? 'text-red-700' : 'labelColor',
            labelClassName
          )}
        >
          {label}
          {rules?.required && <span className="text-banner-red">*</span>}
        </label>
        {tooltip && <div className="mb-1">{tooltip}</div>}
      </div>
      <input
        value={value || ''}
        id={field}
        name={field}
        ref={ref}
        disabled={disabled}
        readOnly={readOnly}
        type={type}
        onChange={onChange || (() => null)}
        onKeyDown={onKeyDown}
        className={twCascade(
          'px-2',
          'w-full',
          'leading-tight',
          'border',
          'border-solid',
          'rounded',
          error ? '' : borderColor,
          backgroundColor,
          textColor,
          'focus:ring-0',
          focusBorderColor,
          error ? 'border-red-700' : '',
          readOnly ? 'bg-gray-100' : '',
          inputClassName
        )}
        style={{
          // @ts-expect-error TS(2322): Type '{ '--ring-color': string; }' is not assignab... Remove this comment to see the full error message
          '--ring-color': purple ? 'white' : 'transparent', // Not supported in this version of tailwind
        }}
        // eslint-disable-next-line
          autoFocus={autoFocus}
      />
      <div className={`${error ? '' : 'invisible'} min-h-5 text-xs text-red-700`}>{error}</div>
    </div>
  );
};

TextInput.displayName = 'TextInput';

export default TextInput;
