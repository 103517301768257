import React from 'react';
import { twCascade } from '@mariusmarais/tailwind-cascade';

type Props = {
  classNames?: string;
};

export default function ComponentError({ classNames }: Props) {
  return (
    <div className={twCascade('bg-offwhite', 'shadow', 'rounded-lg', classNames)}>
      <div className="p-5 text-error-red">Error Loading Data</div>
    </div>
  );
}
