import React, { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'qrco... Remove this comment to see the full error message
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from '../../../components/tooltip/Tooltip';
import { TextInputNoControl } from '../../../components/inputs';
import { Button } from '../../../components/buttons';

type Props = {
  setUser: (...args: any[]) => any;
  user: any;
};

const CompleteMfaSetupForm = ({ setUser, user }: Props) => {
  const useMountEffect = (func: any) => useEffect(func, []);
  const [tokenUri, setTokenUri] = useState(null);
  const [secretToken, setSecretToken] = useState(null);
  const [verificationError, setVerificationError] = useState('');
  const [form, setForm] = useState({ verification: '' });
  const [verifying, setVerifying] = useState(false);
  const [cantScan, setCantScan] = useState(false);
  const textRef = useRef(null);

  useMountEffect(() => {
    const setup = async () => {
      const secret = await Auth.setupTOTP(user);
      const { userDataKey } = user;

      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setSecretToken(secret);
      if (user.attributes?.email) {
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setTokenUri(`otpauth://totp/Empath.io:${user.attributes.email}?secret=${secret}&issuer=Empath.io`);
      } else {
        const parts = userDataKey.split('.');
        const emailParts = parts.slice(2, parts.length - 1);

        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setTokenUri(`otpauth://totp/Empath.io:${emailParts.join('.')}?secret=${secret}&issuer=Empath.io`);
      }
    };

    setup();
  });

  const verifyToken = async () => {
    try {
      setVerificationError('');
      setVerifying(true);

      await Auth.verifyTotpToken(user, form.verification);
      await Auth.setPreferredMFA(user, 'TOTP');

      const authedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

      setVerifying(false);
      setUser(authedUser);
      window.location.href = '/';
    } catch (err) {
      if (
        (err as any).code === 'EnableSoftwareTokenMFAException' ||
        (err as any).code === 'InvalidParameterException'
      ) {
        setVerificationError('Incorrect Code');
      } else if ((err as any).code === 'NotAuthorizedException') {
        setVerificationError('Code Expired. Please Refresh.');
      }
      setVerifying(false);
    }
  };

  const onChange = (e: any) => {
    const newState = { ...form, [e.target.name]: e.target.value };

    setForm(newState);
  };

  const toggleCantScan = () => {
    setCantScan(!cantScan);
  };

  const doCopy = (e: any) => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    textRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  };

  return (
    <div className="flex flex-col my-8">
      {!cantScan && (
        <div className="pb-8 text-center text-gray-200 font-body">
          Please scan the qr code into your authenticator app.
          <span className="text-orange">
            <Tooltip
              header="Download An Authenticator Application"
              maxWidth="500px"
              content={
                <div>
                  <p>
                    Install an Authenticator application on your smartphone. Search for “authenticator” in your App
                    Store. We recommend Microsoft and Google Authenticators
                  </p>
                </div>
              }
            >
              <span>
                <FontAwesomeIcon icon={faInfoCircle} className="mb-1 ml-2 text-sm" />
              </span>
            </Tooltip>
          </span>
        </div>
      )}

      <div className="xl:w-3/4 xl:mx-auto">
        {!cantScan && (
          <div className="flex justify-around pb-4">
            {tokenUri && <QRCode value={tokenUri} size={256} fgColor="#200D53" includeMargin />}
          </div>
        )}
        {cantScan && (
          <p className="pb-8 text-center text-gray-200 font-body">Please copy this code into your authenticator app.</p>
        )}
        {cantScan && (
          <div className="relative text-white fill-current">
            <input
              type="text"
              readOnly
              className="w-full mb-4 text-gray-200 bg-purple-700 border border-gray-200 border-solid rounded max-w-f focus:ring-0"
              style={{ paddingRight: '2rem' }} // tailwind padding not working on inputs
              // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
              value={secretToken}
              ref={textRef}
            />

            <FontAwesomeIcon icon={faCopy} className="absolute top-0 right-0 mt-2 mr-2 text-white" onClick={doCopy} />
          </div>
        )}

        <div className="flex justify-center">
          <button
            type="button"
            className="pb-4 font-semibold text-center text-gray-200 uppercase font-body focus:ring-0 text-orange"
            onClick={toggleCantScan}
          >
            {!cantScan ? "Can't" : 'Can'} Scan?
          </button>
        </div>

        <TextInputNoControl
          label="Code From App"
          field="verification"
          onChange={onChange}
          onSubmit={verifyToken}
          errorMessage={verificationError}
          purple
          autoFocus
        />

        <Button
          title="Log In"
          onClick={verifyToken}
          buttonStyle="solid"
          classNames="mt-5 h-8 text-purple-700 hover:bg-gray-200"
          loading={verifying}
          loadingTitle="Logging In"
        />
      </div>
    </div>
  );
};

export default CompleteMfaSetupForm;
