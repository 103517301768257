import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons';

import { searchDataSet, sortDataSet, executeAsync } from '../../shared/helper';
import { effectualGroups, customerIds, reports } from '../../shared/groupsHelper';
import { useUser } from '../../context/UserContext';
import { Button } from '../../components/buttons';
import PageHeader from '../../components/header/PageHeader';
import { EmpathTable, exportCSVReport } from '../../components/tables';
import { listCustomers } from '../../graphql/queries';
// @ts-expect-error TS(2307): Cannot find module '../../assets/gears.svg' or its... Remove this comment to see the full error message
import { ReactComponent as Gears } from '../../assets/gears.svg';
// @ts-expect-error TS(2307): Cannot find module '../../assets/homeUser.svg' or ... Remove this comment to see the full error message
import { ReactComponent as HomeUser } from '../../assets/homeUser.svg';

const Customers = () => {
  const [loading, setLoading] = useState(true);
  const [tableError, setTableError] = useState('');
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [listCustomersCSVParams, setListCustomersCSVParams] = useState({});
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const useMountEffect = (func: any) => useEffect(func, []);
  const history = useHistory();
  // @ts-expect-error TS(2339): Property 'userInRoles' does not exist on type 'unknown'.
  const { activeUser, userInRoles } = useUser();

  const initializeData = async () => {
    try {
      const listCustomersCallParams = { filter: { isActive: { eq: true } } };

      setListCustomersCSVParams(listCustomersCallParams);
      const listResponse = await API.graphql(graphqlOperation(listCustomers));
      const list = (listResponse as any).data.listCustomers.items;
      const formatted = list.map((x: any) => ({
        ...x,
        openTicketCount: x.openTicketCount ? x.openTicketCount : 0,
      }));

      setData(formatted);
      setLoading(false);
    } catch (error) {
      console.error('Error loading customer list: ', error);

      setLoading(false);
      setTableError('Error Loading Data');
    }

    window.addEventListener('resize', doResize);
    doResize();
  };

  const doResize = () => {
    const { innerWidth: width } = window;
    const columns = [
      {
        header: 'Company',
        accessor: 'name',
        cell: function CompanyCell(cellInfo: any) {
          if (
            userInRoles([
              effectualGroups.effectualAdmin.groupName,
              effectualGroups.effectualSDM.groupName,
              effectualGroups.effectualObserver.groupName,
            ]) &&
            cellInfo.cell.value !== null
          ) {
            return (
              <Button
                type="button"
                buttonStyle="text"
                title={`${cellInfo.cell.value}`}
                onClick={() => {
                  history.push(`/customers/${cellInfo.row.original.id}`);
                }}
              />
            );
          }

          return cellInfo.cell.value !== null ? cellInfo.cell.value : '';
        },
      },
      {
        header: 'CID',
        accessor: 'id',
        show: width > 750,
      },
      {
        header: 'Tickets',
        accessor: 'openTicketCount',
        show: width > 750,
      },
      {
        header: '',
        accessor: 'actions',
        cell: function ActionCell(cellInfo: any) {
          return (
            <div className="flex text-xl text-purple-500">
              <div className="mr-6 md:mr-12">
                <button
                  aria-label="View Customer Details"
                  type="button"
                  className="w-7"
                  onClick={() => {
                    history.push(`/customers/${cellInfo.row.original.id}`);
                  }}
                >
                  <HomeUser />
                </button>
              </div>
              {userInRoles([
                effectualGroups.effectualAdmin.groupName,
                effectualGroups.effectualSDM.groupName,
                effectualGroups.effectualObserver.groupName,
              ]) && (
                <div>
                  <button
                    aria-label="View Customer Users"
                    type="button"
                    className="w-8"
                    onClick={() => {
                      history.push(`/empath/customers/${cellInfo.row.original.id}/users`);
                    }}
                  >
                    <Gears />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ];

    // @ts-expect-error TS(2345): Argument of type '({ header: string; accessor: str... Remove this comment to see the full error message
    setCols(columns);
  };

  useMountEffect(() => {
    initializeData();
  });

  const loadData = useCallback(
    async (options: any) =>
      executeAsync(() => {
        const { filter, sort, search } = options;
        let items = data.map(m => m);

        if (filter) {
          items = items.filter(x => filter.equals.includes(x[filter.field]));
        } else {
          items = items.filter(x => (x as any).isActive === true);
        }

        if (sort) {
          items = sortDataSet(items, sort);
        }

        if (search) {
          const searchOptions = {
            searchString: search,
            keys: ['name', 'id', 'type', 'openTicketCount'],
          };

          items = searchDataSet(items, searchOptions);
        }

        return {
          items: items ?? [],
        };
      }),
    [data]
  );

  const optionsDropdown = [
    {
      text: 'Export CSV',
      action: async () => {
        const reportName = reports.customerListReport;

        setIsOptionsLoading(true);
        await exportCSVReport(activeUser, customerIds.empath, reportName, listCustomersCSVParams);
        setIsOptionsLoading(false);
      },
    },
  ];

  return (
    <div>
      {cols.length > 0 ? (
        <div className="page-container">
          <PageHeader title="Customers" />

          <EmpathTable
            title="Customer List"
            icon={<FontAwesomeIcon icon={faAddressCard} swapOpacity />}
            columns={cols}
            dropdownFilters={[
              {
                label: 'All Active Customers',
                filterExpression: {
                  field: 'isActive',
                  equals: [true],
                },
              },
              {
                label: 'My Customers',
                filterExpression: {
                  field: 'id',
                  equals: [...JSON.parse(activeUser.customerIds)],
                },
              },
              {
                label: 'Inactive Customers',
                filterExpression: {
                  field: 'isActive',
                  equals: [false],
                },
              },
            ]}
            loadData={loadData}
            initializing={loading}
            sort={[{ field: 'name', sort: 'asce' }]}
            errorMessage={tableError}
            optionsDropdown={optionsDropdown}
            isOptionsLoading={isOptionsLoading}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Customers;
