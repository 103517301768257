import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { motion } from 'framer-motion';

import { useUser } from '../../context/UserContext';

type Props = {
  disabled?: boolean;
  label: any;
  icon: any;
  iconClassName?: string;
  iconStyle?: any;
  active?: boolean;
  href: string;
  drawer?: boolean;
  mobileNav?: (...args: any[]) => any;
  external?: boolean;
  newTab?: boolean;
};

const NavItem = forwardRef<any, Props>((props, ref) => {
  const { disabled, label, icon, iconClassName, iconStyle, active, href, drawer, mobileNav, external, newTab } = props;
  // @ts-expect-error TS(2339): Property 'isImpersonating' does not exist on type 'unknown'.
  const { isImpersonating } = useUser();

  const activeClass = isImpersonating
    ? 'bg-banner-red border-orange hover:border-white'
    : 'bg-nav-hover border-orange hover:border-white';

  if (disabled) {
    return (
      <div
        ref={ref}
        className="flex flex-row items-center justify-start h-10 p-2 text-sm font-medium text-gray-400 border-transparent border-l-3"
      >
        <div className={twCascade('my-auto', 'mr-4', 'ml-2', 'text-gray-400', iconClassName)} style={iconStyle}>
          {icon}
        </div>
        <motion.div animate={{ opacity: drawer ? 1 : 0 }} transition={{ duration: 0.15, type: 'keyframes' }}>
          <div className="w-32">{label}</div>
        </motion.div>
      </div>
    );
  }

  const content = (
    <div className="flex flex-row">
      <div
        className={twCascade('flex', 'items-center', 'justify-center', 'mr-4', 'ml-2', 'text-orange', iconClassName)}
        style={iconStyle}
      >
        {icon}
      </div>
      <motion.div animate={{ opacity: drawer ? 1 : 0 }} transition={{ duration: 0.15, type: 'keyframes' }}>
        <div className="w-32">{label}</div>
      </motion.div>
    </div>
  );

  if (external) {
    return (
      <a
        href={`${href}`}
        // @ts-expect-error TS(2322): Type '"_blank" | null' is not assignable to type '... Remove this comment to see the full error message
        target={newTab ? '_blank' : null}
        className={twCascade(
          'flex',
          'flex-row',
          'items-center',
          'justify-start',
          'text-sm',
          'font-medium',
          'text-white',
          'border-solid',
          'border-l-3',
          'border-transparent',
          'p-2',
          'h-10',
          active ? activeClass : '',
          'hover:border-l-3',
          'hover:text-orange'
        )}
        onClick={mobileNav}
        rel="noreferrer"
      >
        {content}
      </a>
    );
  }

  return (
    <NavLink
      ref={ref}
      to={`${href}`}
      className={twCascade(
        'flex',
        'flex-row',
        'items-center',
        'justify-start',
        'text-sm',
        'font-medium',
        'text-white',
        'border-solid',
        'border-l-3',
        'border-transparent',
        'p-2',
        'h-10',
        active ? activeClass : '',
        'hover:bg-nav-hover',
        'hover:border-solid',
        'hover:border-l-3',
        'hover:border-orange'
      )}
      onClick={mobileNav}
    >
      {content}
    </NavLink>
  );
});

NavItem.displayName = 'Nav Item';

export default NavItem;
