import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { API, graphqlOperation } from 'aws-amplify';
import { getBaseUrl } from '../../shared/helper';

import PageHeader from '../../components/header/PageHeader';
import Tabs from '../../components/tabs/Tabs';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/buttons/Button';
import { setupSso, validateSso, updateCustomer, changeSso } from '../../graphql/mutations';
import { getCustomer } from '../../graphql/queries';
import { successToast, errorToast } from '../../shared/toast';
// @ts-expect-error TS(7016): implicitly has an 'any' type.
import config from '../../aws-exports';
import Modal from '../../components/modal/Modal';
import { SelectInput } from '../../components/inputs';
// @ts-expect-error TS(2307): Cannot find module '../../assets/sso/okta.png' or ... Remove this comment to see the full error message
import Okta from '../../assets/sso/okta.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/sso/auth01.png' o... Remove this comment to see the full error message
import Auth01 from '../../assets/sso/auth01.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/sso/auth02.png' o... Remove this comment to see the full error message
import Auth02 from '../../assets/sso/auth02.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/sso/auth03.png' o... Remove this comment to see the full error message
import Auth03 from '../../assets/sso/auth03.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/sso/adfs1.png' or... Remove this comment to see the full error message
import Adfs1 from '../../assets/sso/adfs1.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/sso/adfs2.png' or... Remove this comment to see the full error message
import Adfs2 from '../../assets/sso/adfs2.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/sso/adfs3.png' or... Remove this comment to see the full error message
import Adfs3 from '../../assets/sso/adfs3.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/sso/office365.png... Remove this comment to see the full error message
import Office365 from '../../assets/sso/office365.png';
import { useUser } from '../../context/UserContext';
import { effectualGroups } from '../../shared/groupsHelper';

const Sso = () => {
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, getValues, reset, setError, clearErrors, setValue, formState, watch, control } =
    useForm();
  const useMountEffect = (func: any) => useEffect(func, []);
  const [customer, setCustomer] = useState({});
  const [originalMetadataUrl, setOriginalMetadataUrl] = useState('');
  const [showChangeConfirmationModal, setShowChangeConfirmationModal] = useState(false);
  const watchProvider = watch('provider');
  const [currentInstructions, setCurrentInstructions] = useState(null);
  // @ts-expect-error TS(2339): Property 'userInRoles' does not exist on type 'unknown'.
  const { isEffectualUser, userInRoles } = useUser();

  const { isDirty } = formState;

  const initCustomer = () => {
    (async () => {
      try {
        const customerRes = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));
        setCustomer((customerRes as any).data.getCustomer);
        setValue('metadataUrl', (customerRes as any).data.getCustomer.metadataUrl);
        setOriginalMetadataUrl((customerRes as any).data.getCustomer.metadataUrl);
      } catch (err) {
        console.error(err);
      }
    })();
  };

  useMountEffect(initCustomer);

  const disableAction = () => {
    if (isEffectualUser && userInRoles([effectualGroups.effectualObserver.groupName])) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!watchProvider) {
      return;
    }

    setCurrentInstructions(watchProvider);
  }, [watchProvider]);

  const setActiveTab = () => {
    history.push(`${getBaseUrl(customerId)}/admin/users`);
  };

  const doChange = async (data: any) => {
    await doSubmit(data, true);
  };

  const doInit = async (data: any) => {
    await doSubmit(data, false);
  };

  const doSubmit = async (data: any, isChange: any) => {
    if (!isDirty || (!isChange && originalMetadataUrl === data.metadataUrl)) {
      return false;
    }

    let providerName = (customer as any).name.toLowerCase().replace(/[^a-z]+/g, '');

    if (data.metadataUrl.toLowerCase().indexOf('okta.com') > -1) {
      providerName = `Okta-${providerName}`;
    }

    const body = {
      customerId,
      providerName,
      providerType: 'SAML',
      metadataUrl: data.metadataUrl,
    };

    try {
      setSubmitting(true);

      const ssoRes = await API.graphql(graphqlOperation(isChange ? changeSso : setupSso, { body }));

      setSubmitting(false);

      successToast(`SSO ${isChange ? 'updated' : 'integrated'}`);

      setOriginalMetadataUrl(data.metadataUrl);

      let idpData;

      if (isChange) {
        idpData = JSON.parse((ssoRes as any).data.changeSso.data);
      } else {
        idpData = JSON.parse((ssoRes as any).data.setupSso.data);
      }

      await API.graphql(
        graphqlOperation(updateCustomer, {
          input: { id: customerId, metadataUrl: data.metadataUrl, ssoUrn: idpData.urn, ssoUrl: idpData.url },
        })
      );
    } catch (err) {
      console.error(err);

      errorToast((err as any).message);

      setSubmitting(false);
    }
  };

  const doValidateSso = async () => {
    const value = getValues('metadataUrl');
    const validFormat = handleValidateIsUrl(value);

    if (typeof validFormat !== 'boolean') {
      setError('metadataUrl', {
        type: 'manual',
        message: validFormat,
      });

      return;
    }

    const res = await handleValidateSso(value);

    if (typeof res !== 'boolean') {
      setError('metadataUrl', {
        type: 'manual',
        message: res,
      });

      return;
    }

    clearErrors('metadataUrl');

    successToast('URL Validated');
  };

  const handleValidateSso = async (value: any) => {
    try {
      const body = {
        metadataUrl: value,
      };

      await API.graphql(graphqlOperation(validateSso, { body }));

      return true;
    } catch (_) {
      return 'URL must be valid';
    }
  };

  const handleValidateIsUrl = (value: any) => {
    try {
      const url = new URL(value);

      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (_) {
      return 'URL must be a valid format';
    }
  };

  const doCancel = () => {
    reset();
  };

  const changeConfirmation = async (accept: any) => {
    setShowChangeConfirmationModal(false);

    if (accept) {
      await handleSubmit(doChange)();
    }
  };

  const doFormSubmit = async () => {
    const url = getValues('metadataUrl');

    if (originalMetadataUrl && url !== originalMetadataUrl) {
      setShowChangeConfirmationModal(true);
    } else {
      await handleSubmit(doInit)();
    }
  };

  const OktaInstructions = () => <img src={`${Okta}`} alt="" />;

  const Auth0Instructions = () => (
    <div>
      <div>
        <img src={`${Auth01}`} alt="" />
      </div>

      <div>
        <img src={`${Auth02}`} alt="" />
      </div>

      <div>
        <img src={`${Auth03}`} alt="" />
      </div>
    </div>
  );

  const AdfsInstructions = () => (
    <div>
      <div>
        <img src={`${Adfs1}`} alt="" />
      </div>

      <div>
        <img src={`${Adfs2}`} alt="" />
      </div>

      <div>
        <img src={`${Adfs3}`} alt="" />
      </div>
    </div>
  );

  const Office365Instructions = () => <img src={`${Office365}`} alt="" />;

  const instructions = {
    Okta: OktaInstructions,
    Auth0: Auth0Instructions,
    ADFS: AdfsInstructions,
    'Office 365': Office365Instructions,
  };

  return (
    <div className="page-container">
      <Modal
        doShow={showChangeConfirmationModal}
        title={
          <span>
            SSO <span className="text-orange">Information Change</span>
          </span>
        }
        cancel={() => changeConfirmation(false)}
        action={() => changeConfirmation(true)}
      >
        <div>
          <div className="flex flex-col">
            <p className="flex mb-1 text-base text-blue-900">
              Changing your SSO provider will affect every user for your organization. Be sure you really want to do
              this.
            </p>
          </div>
        </div>
      </Modal>

      <PageHeader title="Admin" containerClassName="sm:mb-6" />

      <div className="flex-1 my-3 overflow-auto focus:ring-0">
        <Tabs tabs={[{ title: 'User Admin' }, { title: 'SSO Settings', active: true }]} tabSelected={setActiveTab} />

        <div className="px-4 py-5 overflow-hidden rounded-lg shadow md:px-8 bg-offwhite">
          <div className="flex flex-col lg:divide-x lg:divide-gray-400 lg:flex-row">
            <div className="w-full lg:pr-6">
              <h4 className="mb-4 text-lg font-normal text-purple-700 md:text-2xl font-title">
                Single Sign-On Settings
              </h4>

              <div className="flex flex-col w-full pb-8 mx-2">
                <p className="mb-1 text-base text-blue-900">URN / Audience</p>

                <p className="w-full p-1 px-2 pb-8 leading-tight text-grey-900">{`urn:amazon:cognito:sp:${config.aws_user_pools_id}`}</p>

                <p className="mb-1 text-base text-blue-900">Sign On URL</p>

                <p className="w-full p-1 px-2 leading-tight text-grey-900">{`https://${config.oauth.domain}/saml2/idpresponse`}</p>
              </div>

              <form className="px-2">
                <div className="flex flex-col items-end w-full md:flex-row sm:flex-row">
                  <TextInput
                    containerClassName="w-full"
                    label="Metadata URL"
                    field="metadataUrl"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        isUrl: (value: any) => handleValidateIsUrl(value),
                        isValid: async (value: any) => handleValidateSso(value),
                      },
                    }}
                    formErrors={errors}
                    disabled={disableAction()}
                  />

                  <Button
                    title="Validate URL"
                    onClick={doValidateSso}
                    classNames="mb-5 ml-4 h-full w-72"
                    disabled={disableAction()}
                  />
                </div>

                <div className="flex justify-end w-full mt-5 sm:mt-4 sm:flex-row">
                  <Button
                    title="Cancel"
                    buttonStyle="text"
                    classNames="mr-6"
                    onClick={doCancel}
                    disabled={disableAction()}
                  />

                  <Button
                    title="Save"
                    loadingTitle="Saving"
                    loading={submitting}
                    type="button"
                    disabled={disableAction() && !formState.isDirty}
                    onClick={doFormSubmit}
                  />
                </div>
              </form>
            </div>

            <div className="w-full pt-8 lg:pl-6 md:pt-0">
              <h4 className="mb-4 text-lg font-normal text-purple-700 md:text-2xl font-title">
                Single Sign-On Instructions
              </h4>

              <div className="flex flex-col">
                <SelectInput
                  label=""
                  containerClassName="w-full"
                  field="provider"
                  placeholder="Select Provider"
                  options={[{ label: 'Okta' }, { label: 'Auth0' }, { label: 'ADFS' }, { label: 'Office 365' }]}
                  control={control}
                />

                {/* @ts-expect-error TS(2349): This expression is not callable. */}
                {currentInstructions && instructions[currentInstructions]()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sso;
