import React from 'react';
// import PropTypes from 'prop-types';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { useController } from 'react-hook-form';
import Select from './Select';
import { getErrorMessage } from './utils';

type Props = {
  label: string;
  field: string;
  formErrors?: any;
  errorMessage?: string;
  labelClassName?: string;
  purple?: boolean;
  tooltip?: React.ReactNode;
  control: any;
  options?: any[]; // TODO: PropTypes.shape(optionsShape)
  placeholder?: string;
  defaultValue?: any; // TODO: PropTypes.shape(optionsShape)
  disabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isRtl?: boolean;
  isMulti?: boolean;
  rules?: any;
  filterOption?: any | ((...args: any[]) => any);
};

const FormSelect = ({
  label,
  field,
  formErrors,
  options,
  purple,
  errorMessage,
  labelClassName = '',
  tooltip,
  control,
  defaultValue,
  placeholder,
  disabled,
  isLoading,
  isClearable,
  isSearchable,
  isRtl,
  isMulti,
  rules,
  filterOption,
}: Props) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: field,
    control,
    rules,
    defaultValue: defaultValue || '',
  });

  const error = errorMessage || getErrorMessage(formErrors, field, label);
  const labelColor = purple ? 'text-gray-200' : 'text-blue-900';
  const borderColorUnfocus = error ? '#c81e1e' : '#E6E6E6';
  const borderColorFocus = '#200D53'; // purple-700

  const customStyles = {
    // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
    control: (provided, state) => ({
      ...provided,
      borderWidth: '1px',
      boxShadow: 'none',
      borderColor: state.isFocused ? borderColorFocus : borderColorUnfocus,

      // Overwrites default hover styles
      '&:hover': state.isFocused ? borderColorFocus : borderColorUnfocus,
    }),
  };

  return (
    <div>
      <div className="flex flex-row items-center">
        <label
          htmlFor={field}
          className={twCascade(
            'flex',
            'text-base',
            'mb-1',
            labelColor,
            error ? 'text-red-700' : 'labelColor',
            labelClassName
          )}
        >
          {label}
          {rules?.required && <span className="text-banner-red">*</span>}
        </label>
        {tooltip && <div className="mb-1">{tooltip}</div>}
      </div>
      <Select
        {...inputProps}
        ref={ref}
        options={options}
        isClearable={isClearable}
        isLoading={isLoading}
        isRtl={isRtl}
        isSearchable={isSearchable}
        isMulti={isMulti}
        styles={customStyles}
        disabled={disabled}
        placeholder={placeholder}
        filterOption={filterOption}
      />
      <div className={`${error ? '' : 'invisible'} min-h-5 text-xs text-red-700`}>{error}</div>
    </div>
  );
};

export default FormSelect;

// const optionsShape = {
//   label: PropTypes.string.isRequired,
//   value: PropTypes.string.isRequired,
// };

// const filterOptionShape = {
//   ignoreCase: PropTypes.bool,
//   ignoreAccents: PropTypes.bool,
//   trim: PropTypes.bool,
//   matchFromStart: PropTypes.bool,
// };
