import React, { useState } from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useAsyncDebounce } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/pro-light-svg-icons';

type Props = {
  globalFilter?: string;
  setGlobalFilter?: (...args: any[]) => any;
};

const GlobalFilter = ({ globalFilter, setGlobalFilter }: Props) => {
  const paddingLeft = '1.40rem';
  const paddingRight = '1.25rem';
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((v: any) => {
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setGlobalFilter(v || undefined);
  }, 500);

  return (
    <label className="relative block">
      <span className="absolute top-0 left-0 px-1 pt-1 text-b-500 hover:text-purple-700">
        <FontAwesomeIcon icon={faSearch} />
      </span>
      <input
        placeholder="Search"
        name="tableSearchBox"
        type="text"
        className="w-full sm:w-48 leading-tight text-purple-700 border border-gray-300 rounded focus:border-purple-700 focus:ring-0"
        style={{
          paddingLeft,
          paddingRight,
        }}
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          if (e.target.value.length > 2 || e.target.value === '') {
            onChange(e.target.value);
          }
        }}
      />
      <button
        aria-label="Clear Search Box"
        type="button"
        className="absolute cursor-pointer top-0 right-0 px-2 pt-1 text-b-500 hover:text-purple-700"
        onClick={() => {
          setValue('');
          onChange('');
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </label>
  );
};

export default GlobalFilter;
