import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import AvatarEditor from 'react-avatar-editor';
import Pica from 'pica';

import PageHeader from '../../components/header/PageHeader';
import Tabs from '../../components/tabs/Tabs';
import { TextInput, MaskInput, FormSelect } from '../../components/inputs';
import { timeZones } from '../../components/inputs/utils';
import Modal from '../../components/modal/Modal';
import { useUser } from '../../context/UserContext';
import { updateCognitoUser, sendTemplatedEmail, updateEmpathUser } from '../../graphql/mutations';
import { allGroups, customerRoles } from '../../shared/groupsHelper';
import { errorToast, successToast, infoToast } from '../../shared/toast';
import { Button } from '../../components/buttons';
import Checkbox from '../../components/inputs/Checkbox';
import Tooltip from '../../components/tooltip/Tooltip';
import SupportToolTip from '../support/SupportToolTip';

interface IForm {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
  phoneNumber2: string;
  profilePhotoUrl: string;
  officeLocation: string;
  timeZone: { value: string; label: string };
}

const Settings = () => {
  const [, setActiveTab] = useState('My Settings');
  const [saving, setSaving] = useState(false);
  const [savingPassword, setSavingPassword] = useState(false);
  const [savingEmail, setSavingEmail] = useState(false);
  const [previousEmail, setPreviousEmail] = useState<string>('');
  const [emailChangeModal, setEmailChangeModal] = useState(false);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, setValue, getValues, reset, control } = useForm<IForm>();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control: verControl, handleSubmit: verHandleSubmit, errors: verErrors, setError: verSetError } = useForm();

  const {
    control: passwordControl,
    handleSubmit: passwordHandleSubmit,
    errors: passwordErrors,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    watch: passwordWatch,
    reset: passwordReset,
  } = useForm();

  const {
    // @ts-expect-error TS(2339): does not exist on type 'unknown'.
    isImpersonating,
    // @ts-expect-error TS(2339): does not exist on type 'unknown'.
    activeCognitoUser,
    // @ts-expect-error TS(2339): does not exist on type 'unknown'.
    activeUserGroups,
    // @ts-expect-error TS(2339): does not exist on type 'unknown'.
    activeUser,
    // @ts-expect-error TS(2339): does not exist on type 'unknown'.
    setActiveUser,
    // @ts-expect-error TS(2339): does not exist on type 'unknown'.
    isEffectualUser,
    // @ts-expect-error TS(2339): does not exist on type 'unknown'.
    user: { isSsoUser },
    // @ts-expect-error TS(2339): does not exist on type 'unknown'.
    userNavigationHistoryStack,
  } = useUser();
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string | null>(null);
  const profilePhotoUpload = useRef(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [profilePhotoScale, setProfilePhotoScale] = useState(1);
  const profilePhotoEditor = useRef(null);

  const history = useHistory();
  const pica = Pica();

  const saveUser = async (data: any) => {
    data.timeZone = data.timeZone.value;

    if (activeUser.role !== data.role) {
      data.dashboardSettings = null;
    }

    let updatedUser = await API.graphql(
      graphqlOperation(updateEmpathUser, {
        params: { username: btoa(activeCognitoUser.username) },
        body: data,
      })
    );

    updatedUser = (updatedUser as any).data.updateEmpathUser;

    let photoUrl: string | null = profilePhotoUrl;

    if (profilePhoto !== null) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      const canvas = profilePhotoEditor.current.getImage();
      const offScreenCanvas = document.createElement('canvas');

      offScreenCanvas.width = 150;
      offScreenCanvas.height = 150;
      const picaCanvas = await pica.resize(canvas, offScreenCanvas);
      const image = await pica.toBlob(picaCanvas, 'image/jpg', 0.9);

      Storage.put(`${activeUser.id}.jpg`, image, { contentType: 'image/jpg' });
      photoUrl = picaCanvas.toDataURL('image/jpg', 0.9);

      setProfilePhotoUrl(photoUrl);
      setProfilePhoto(null);

      data.profilePhotoUrl = `${activeUser.id}.jpg`;

      updatedUser = await API.graphql(
        graphqlOperation(updateEmpathUser, { params: { username: btoa(activeCognitoUser.username) }, body: data })
      );
      updatedUser = updatedUser.data.updateEmpathUser;
    }
    if (activeUserGroups && activeUserGroups.length > 0) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      (updatedUser as any).role = allGroups[activeUserGroups[0]]?.description;
    }
    setActiveUser({
      ...activeUser,
      firstName: (updatedUser as any).firstName,
      lastName: (updatedUser as any).lastName,
      email: (updatedUser as any).email,
      timeZone: (updatedUser as any).timeZone,
      jobTitle: (updatedUser as any).jobTitle,
      phoneNumber: (updatedUser as any).phoneNumber,
      phoneNumber2: (updatedUser as any).phoneNumber2,
      officeLocation: (updatedUser as any).officeLocation,
      profilePhotoUrl: (updatedUser as any).profilePhotoUrl,
    });

    reset({
      ...updatedUser,
      timeZone: timeZones().find(timeZone => timeZone.value === (updatedUser as any).timeZone),
    });
  };

  const clearChanges = async () => {
    reset();
    setProfilePhotoScale(1);
    setProfilePhoto(null);
    if (activeUser.profilePhotoUrl) {
      setProfilePhotoUrl(await Storage.get(activeUser.profilePhotoUrl));
    } else {
      setProfilePhotoUrl(null);
    }
  };

  const undoEmailChange = async () => {
    try {
      const data = {
        email: previousEmail,
        email_verified: true,
      };

      await API.graphql(
        graphqlOperation(updateCognitoUser, { params: { username: btoa(activeCognitoUser.username) }, body: data })
      );
      const changes = getValues();

      changes.email = previousEmail;
      await saveUser(changes);
      infoToast(`Email has been set back to ${previousEmail}`);
    } catch (err) {
      errorToast(
        <div>
          <p>Error setting email to {activeUser.email}</p>

          <p>Please contact support.</p>
        </div>
      );
      console.error('Error undoing email change', err);
    }
    setEmailVerificationModal(!emailVerificationModal);
  };

  const verOnSubmit = async (data: any) => {
    activeUser.email = getValues('email');
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', data.verificationCode);
    } catch (err) {
      verSetError('verificationCode', { type: 'manual', message: (err as any).message });

      return;
    }
    try {
      await saveUser(getValues());
      successToast('Settings saved!');
    } catch (err) {
      errorToast('Error updating settings');
      console.error('Error updating settings', err);
    }

    setEmailVerificationModal(!emailVerificationModal);
  };

  const emailChangeConfirmation = async (accept: any) => {
    if (accept) {
      setSavingEmail(true);
      setPreviousEmail(activeUser.email);
      const email = getValues('email').toLowerCase();

      try {
        const data = {
          email,
        };

        await API.graphql(
          graphqlOperation(updateCognitoUser, { params: { username: btoa(activeCognitoUser.username) }, body: data })
        );

        const changes = getValues();

        changes.email = email;
        await saveUser(changes);
        setEmailVerificationModal(true);
        setSavingEmail(false);
      } catch (err) {
        errorToast(
          (err as any)?.errors[0]?.message
            ? `Error updating email: ${(err as any).errors[0].message}`
            : 'Error updating email: Please contact us'
        );
        setValue('email', activeUser.email);
        setSavingEmail(false);

        console.error('Error updating email', err);
      }
    } else {
      setValue('email', activeUser.email);
      infoToast(`Email has been set back to ${activeUser.email}`);
    }

    setEmailChangeModal(!emailChangeModal);
  };

  const onSubmit = async (data: any) => {
    if (!isImpersonating && data.email !== activeUser.email && !isSsoUser) {
      setEmailChangeModal(true);

      return;
    }

    try {
      setSaving(true);
      await saveUser(data);
      successToast('Settings saved!');
      setSaving(false);
    } catch (err) {
      setSaving(false);
      errorToast('Error updating settings');

      console.error('Error updating settings', err);
    }
  };

  const updatePassword = () => {
    if (isSsoUser) {
      return;
    }
    setShowPasswordModal(true);
  };

  const uploadClick = (e: any) => {
    setProfilePhoto(e.target.files[0]);
    setProfilePhotoUrl(null);
  };

  const cancelPassword = () => {
    passwordReset();
    setShowPasswordModal(false);
  };

  const passwordOnSubmit = async (data: any) => {
    try {
      setSavingPassword(true);

      const current = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(current, data.currentPassword, data.newPassword);

      await sendPasswordChangedEmail();

      successToast('Password updated');

      setSavingPassword(false);
      setShowPasswordModal(false);
      passwordReset();
    } catch (err) {
      console.error(err);
      if ((err as any).message) {
        errorToast((err as any).message);
      } else {
        errorToast('Unable to change password. Please try again.');
      }

      setSavingPassword(false);
    }
  };

  const sendPasswordChangedEmail = async () => {
    const body = {
      templateName: 'password-changed',
    };

    await API.graphql(graphqlOperation(sendTemplatedEmail, { body }));
  };

  useEffect(() => {
    (async () => {
      if (activeUserGroups && activeUserGroups.length > 0) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        activeUser.role = allGroups[activeUserGroups[0]]?.description;
      }

      if (activeUser.profilePhotoUrl) {
        const url = await Storage.get(activeUser.profilePhotoUrl);

        setProfilePhotoUrl(url);
      }

      reset({ ...activeUser, timeZone: timeZones().find(timeZone => timeZone.value === activeUser.timeZone) });
    })();
  }, [activeUser, activeUserGroups, reset]);

  const handleScale = (e: any) => {
    setProfilePhotoScale(parseFloat(e.target.value));
  };

  const matchPartOfLabel = (candidate: any, input: any) => {
    if (input) {
      const re = new RegExp(input, 'gi');

      return candidate.label.match(re);
    }

    return true;
  };

  const rolesToolTip = (
    <div>
      {customerRoles.map(x => (
        <div key={x.description}>
          <p className="font-bold">{x.role}: </p>
          {x.description}
        </div>
      ))}
    </div>
  );

  const handleCancelNavigation = () => {
    if (userNavigationHistoryStack.lastVisitedPage === '/settings') {
      history.push('/');
    } else {
      history.push(userNavigationHistoryStack.lastVisitedPage);
    }
  };

  return (
    <div className="page-container">
      <Modal
        doShow={emailVerificationModal}
        title={
          <span>
            Please <span className="text-orange">verify</span>
          </span>
        }
        cancelText="Undo"
        cancel={async () => {
          await undoEmailChange();
        }}
        actionText="Verify"
        action={() => verHandleSubmit(verOnSubmit)()}
        canExit={false}
      >
        <div className="p-1">
          <p>An email was sent to {getValues('email')}</p>

          <p>Please enter the code from the email or click undo if you have made a mistake.</p>

          <form>
            <TextInput
              control={verControl}
              label="Verification Code"
              field="verificationCode"
              rules={{ required: true }}
              formErrors={verErrors}
            />
          </form>
        </div>
      </Modal>

      <Modal
        doShow={emailChangeModal}
        title={
          <span>
            Please <span className="text-orange">confirm</span>
          </span>
        }
        cancelText="No"
        cancel={async () => {
          await emailChangeConfirmation(false);
        }}
        actionLoading={savingEmail}
        actionText="Yes"
        action={async () => {
          await emailChangeConfirmation(true);
        }}
      >
        <p>Changing your email will change your login.</p>

        <p>An email will be sent to {getValues('email')} with a code to verify.</p>

        <p>Are you sure?</p>
      </Modal>

      <Modal
        doShow={showPasswordModal}
        title={
          <span>
            Update <span className="text-orange">password</span>
          </span>
        }
        cancelText="Cancel"
        cancel={cancelPassword}
        actionLoading={savingPassword}
        actionText="Save"
        action={() => passwordHandleSubmit(passwordOnSubmit)()}
      >
        <form>
          <TextInput
            control={passwordControl}
            type="password"
            label="Current Password"
            field="currentPassword"
            rules={{ required: true }}
            formErrors={passwordErrors}
          />

          <TextInput
            control={passwordControl}
            type="password"
            label="New Password"
            field="newPassword"
            rules={{ required: true, minLength: 8 }}
            formErrors={passwordErrors}
          />

          <TextInput
            control={passwordControl}
            type="password"
            label="Confirm Password"
            field="confirmPassword"
            rules={{
              required: true,
              minLength: 8,
              validate: (value: any) => value === passwordWatch('newPassword') || 'Passwords do not match',
            }}
            formErrors={passwordErrors}
          />
        </form>
      </Modal>

      <PageHeader title="Settings" containerClassName="sm:mb-6" />

      <div className="flex-1 my-3 focus:ring-0">
        <Tabs tabs={[{ title: 'My Settings', active: true }]} tabSelected={setActiveTab} />

        <div className="px-4 py-4 mt-4 rounded-lg shadow bg-offwhite sm:py-5 sm:px-8">
          <h4 className="mb-4 text-2xl font-normal text-purple-700 font-title">Personal Information</h4>

          <form onSubmit={handleSubmit(onSubmit)} className="px-2">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5">
              <div className="grid grid-cols-1 col-span-2 md:grid-cols-2 gap-x-8 xl:gap-x-16">
                <TextInput
                  control={control}
                  label="First Name"
                  field="firstName"
                  rules={{ required: true }}
                  formErrors={errors}
                />

                <TextInput
                  label="Last Name"
                  field="lastName"
                  control={control}
                  rules={{ required: true }}
                  formErrors={errors}
                />

                <Tooltip
                  containerClassName={isImpersonating ? 'border-orange' : 'hidden'}
                  content="You cannot edit an email address while impersonating"
                >
                  <TextInput
                    control={control}
                    type="email"
                    label="Email Address"
                    field="email"
                    disabled={isImpersonating || isSsoUser}
                    rules={{
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Invalid Email',
                      },
                    }}
                    formErrors={errors}
                  />
                </Tooltip>

                <FormSelect
                  label="Time Zone"
                  field="timeZone"
                  control={control}
                  formErrors={errors}
                  options={timeZones()}
                  defaultValue={{ label: 'Select...', value: '' }}
                  filterOption={matchPartOfLabel}
                />

                <TextInput label="Job Title" field="jobTitle" control={control} formErrors={errors} />

                <TextInput label="Office Location" field="officeLocation" control={control} formErrors={errors} />

                <TextInput
                  label="Role"
                  field="role"
                  disabled
                  control={control}
                  formErrors={errors}
                  tooltip={!isEffectualUser ? <SupportToolTip>{rolesToolTip}</SupportToolTip> : null}
                />

                <MaskInput<IForm>
                  label="Phone Number"
                  field="phoneNumber"
                  rules={{ minLength: 10, maxLength: 10, required: true }}
                  formErrors={errors}
                  control={control}
                  options={{ phone: true, phoneRegionCode: 'US' }}
                />

                <Checkbox
                  label="Approve Change Requests"
                  text="User can approve change requests"
                  field="canApproveChangeRequest"
                  control={control}
                  disabled
                />

                <MaskInput<IForm>
                  label="Additional Phone Number"
                  field="phoneNumber2"
                  rules={{ minLength: 10, maxLength: 10 }}
                  formErrors={errors}
                  control={control}
                  options={{ phone: true, phoneRegionCode: 'US' }}
                />

                {!isImpersonating && (
                  <Button
                    title="Update Password"
                    onClick={updatePassword}
                    buttonStyle="text"
                    classNames={`mt-4 justify-start ${isSsoUser ? 'cursor-not-allowed no-underline' : ''}`}
                    disabled={isSsoUser}
                  />
                )}
              </div>

              <div className="lg:ml-3 xl:ml-10">
                <div className="mb-1 text-blue-900">Profile Photo</div>

                <input hidden type="file" accept="image/*" onChange={e => uploadClick(e)} ref={profilePhotoUpload} />
                {profilePhoto && (
                  <div className="flex flex-col items-center w-48 ml-4">
                    <AvatarEditor
                      image={profilePhoto}
                      width={175}
                      height={175}
                      scale={profilePhotoScale}
                      ref={profilePhotoEditor}
                    />

                    <input
                      className="w-full mt-2"
                      name="scale"
                      type="range"
                      onChange={handleScale}
                      min={1}
                      max={2}
                      step={0.01}
                      defaultValue={1}
                    />
                  </div>
                )}

                {profilePhotoUrl && <img className="object-fill w-48 h-48" src={profilePhotoUrl} alt="Profile" />}

                <Button
                  title="Upload New Photo"
                  // @ts-expect-error TS(2531): Object is possibly 'null'.
                  onClick={() => profilePhotoUpload.current.click()}
                  buttonStyle="text"
                />
              </div>
            </div>
            <div className="flex flex-col-reverse justify-end w-full mt-5 sm:flex-row sm:mt-4">
              <Button
                title="Cancel"
                onClick={async () => {
                  await clearChanges();
                  infoToast('Change Cancelled');
                  handleCancelNavigation();
                }}
                buttonStyle="text"
                classNames="mr-0 mt-6 sm:mt-0 sm:mr-6"
              />

              <Button title="Save" loadingTitle="Saving" loading={saving} type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
