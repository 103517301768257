import React from 'react';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { useController } from 'react-hook-form';
import { getErrorMessage } from './utils';

type Props = {
  label: string | any;
  field: string;
  formErrors?: any;
  errorMessage?: string;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  rows?: string;
  control: any;
  rules?: any;
  defaultValue?: string;
};

const TextAreaInput = ({
  label,
  field,
  formErrors,
  errorMessage,
  labelClassName,
  containerClassName,
  inputClassName,
  disabled,
  tooltip,
  // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'string'.
  rows = 2,
  control,
  rules,
  defaultValue,
}: Props) => {
  const {
    field: { ref, onChange, value },
  } = useController({
    name: field,
    control,
    rules,
    defaultValue: defaultValue || '',
  });

  const error = errorMessage || getErrorMessage(formErrors, field, label);

  return (
    <div className={containerClassName}>
      <div className="flex flex-row items-center">
        <label htmlFor={field} className={twCascade('mb-1', error ? 'text-red-700' : 'text-blue-900', labelClassName)}>
          {label}
          {rules?.required && <span className="text-banner-red">*</span>}
        </label>
        {tooltip && <div className="mb-1">{tooltip}</div>}
      </div>

      <textarea
        id={field}
        name={field}
        ref={ref}
        onChange={onChange}
        disabled={disabled}
        value={value}
        className={twCascade(
          'w-full',
          'resize-y',
          'leading-tight',
          'text-purple-700',
          'border',
          'border-solid',
          'rounded',
          error ? 'border-red-700' : '',
          inputClassName
        )}
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
        rows={rows}
      />
      <div className={`${error ? '' : 'invisible'} min-h-5 text-xs text-red-700`}>{error}</div>
    </div>
  );
};

TextAreaInput.displayName = 'TextAreaInput';

export default TextAreaInput;
