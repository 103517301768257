import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useUser } from '../context/UserContext';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import UnableToLoadPage from '../components/unableToLoadPage/UnableToLoadPage';

import Unauthorized from '../pages/errors/Unauthorized';

type Props = {
  children?: React.ReactElement;
  requiredRoles?: string[];
  requiredFeatureFlags?: string[];
  animate?: boolean;
  noUnauthorized?: boolean;
};

const PrivateRoute = ({ children, requiredRoles, animate, noUnauthorized, requiredFeatureFlags, ...rest }: Props) => {
  // @ts-expect-error TS(2339): does not exist on type 'unknown'.
  const { activeUser, userInRoles, userHasCustomer, userBelongsToCustomer, isEffectualUser, customerHasFeature } =
    useUser();

  const isAuthenticated = Boolean(activeUser && activeUser.email);

  const isAuthorizedByRole = Boolean(!requiredRoles || requiredRoles.length === 0 || userInRoles(requiredRoles));
  const isAuthorizedByFeatureFlag = Boolean(
    !requiredFeatureFlags || requiredFeatureFlags.length === 0 || customerHasFeature(requiredFeatureFlags)
  );
  const isAuthorizedToResource = isAuthorizedByRole && isAuthorizedByFeatureFlag;

  const cId = (rest as any).computedMatch.params.customerId;

  const isAuthorizedToCustomer = Boolean(userHasCustomer(cId)) || Boolean(userBelongsToCustomer(cId));

  const renderChildren = () => {
    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }

    if (!noUnauthorized && (!isAuthorizedToResource || (!isEffectualUser && !isAuthorizedToCustomer))) {
      return <Unauthorized />;
    }

    return children;
  };

  const location = useLocation();

  return (
    <Route {...rest}>
      <ErrorBoundary location={location} fallbackComponent={UnableToLoadPage}>
        {animate ? (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={{
              initial: {
                opacity: 0,
              },
              in: {
                opacity: 1,
                scale: 1,
                y: 0,
                x: 0,
              },
              out: {
                opacity: 0,
              },
            }}
            transition={{ duration: 0.25, type: 'tween' }}
          >
            {renderChildren()}
          </motion.div>
        ) : (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={{
              initial: {
                opacity: 1,
              },
              in: {
                opacity: 1,
                scale: 1,
                y: 0,
                x: 0,
              },
              out: {
                opacity: 1,
              },
            }}
          >
            {renderChildren()}
          </motion.div>
        )}
      </ErrorBoundary>
    </Route>
  );
};

export default PrivateRoute;
