import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';
import { getMonitorDetailsSite24x7 } from '../../graphql/queries';
import { EmpathTable } from '../../components/tables';
import { Spinner } from '../../components/animations';
import { sortDataSet, executeAsync, searchDataSet } from '../../shared/helper';
import { formatTimeWithDay, formatTime } from '../../shared/dateHelpers';
import LineChart, { yAxisFormat } from '../../components/charts/LineChart';
import Card from '../../components/cards/Card';
import { useUser } from '../../context/UserContext';

const MonitorDetail = (params: any) => {
  const { monitorId, statusValue, downReason } = params;
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [monitorDetails, setMonitorDetails] = useState([]);
  const [availabilityReport, setAvailabilityReport] = useState([]);
  const [recentPolls, setRecentPolls] = useState([]);
  const [columns, setCols] = useState([]);
  const [CPUUtilLinks, setCPUUtilLinks] = useState([]);
  const [CPUUtilLineChartOptions, setCPUUtilLineChartOptions] = useState([]);
  const [thresoldProfile, setThresoldProfile] = useState('');
  // @ts-expect-error TS(2339): Property 'activeUser' does not exist on type 'unknown'.
  const { activeUser } = useUser();
  const ZERO = '0';
  const TWO = '2';
  const THREE = '3';

  const replacementMapping = {
    EC2INSTANCE: ['cpu_utilization'],
    RDSINSTANCE: ['cpu_utilization', 'database_connections'],
    'APPLN-LOADBALANCER': ['request_count_avg', 'avg_latency', 'elb_4xx', 'elb_5xx'],
    EC2AUTOSCALING: ['cpu_utilization', 'bytes_received', 'bytes_sent'],
    URL: ['response_time'],
    EFS: ['burst_balance', 'permitted_throughput'],
    'NETWORK-LOADBALANCER': ['processed_bytes'],
    'VPC-VPN': ['tunnel_data_sent', 'tunnel_data_received'],
    DYNAMODB: ['read_throttle_events', 'write_throttle_events'],
    LOADBALANCER: ['avg_latency', 'backend_errors'],
    CLOUDFRONT: ['requests', 'total_error_rate'],
    EBSENVIRONMENT: ['instances_severe'],
    EKSCLUSTER: ['node_cpu_utilized'],
    'REDSHIFT-CLUSTER': ['cpu_utilization', 'database_connections'],
    'EC-MEM-NODE': ['cpu_utilization', 'ec_redis_bytes_used_for_cache'],
    SNSTOPIC: ['number_of_notifications_delivered', 'number_of_notifications_failed'],
    S3BUCKET: ['bucket_size', 'num_of_objects'],
    EKSNAMESPACE: ['cpu_utilization'],
    EKSNODE: ['cpu_utilization'],
  };

  const useMountEffect = (func: any) => useEffect(func, []);

  const initializeComponent = async () => {
    try {
      setLoading(true);
      const currentViewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const todayDate = DateTime.local().toFormat('yyyy-MM-dd');
      const monitorDetailsCallParams = {
        params: { customerId },
        query: { monitorId, todayDate },
      };

      const MonitorDetails = await API.graphql(graphqlOperation(getMonitorDetailsSite24x7, monitorDetailsCallParams));

      const availabilitySummary = JSON.parse(
        (MonitorDetails as any)?.data?.getMonitorDetailsSite24x7?.availabilitySummary
      );
      const log = JSON.parse((MonitorDetails as any)?.data?.getMonitorDetailsSite24x7?.logReportByDate);
      const { info, summary_details: summaryDetails, profile_details: profileDetails } = availabilitySummary.data;
      const logData: any = [];
      let cols: any = [];

      log.data.report.forEach((element: any) => {
        logData.push({
          cpu_utilization: element.cpu_utilization || element.cpu_utilized_per_node || element.cpu_utilized,
          availability: element.availability,
          // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
          collection_time: formatTimeWithDay(element.collection_time),
          graphTime: element.collection_time,
          bytes_received: element.networkin || element.network_tx,
          bytes_sent: element.network_out || element.network_rx,
          free_storage: element.free_storage,
          database_connections: element.database_connections_sum || element.redshift_database_connections,
          unhealthy_host_count: element.unhealthy_host_count,
          request_count_avg: element.request_count_avg,
          avg_latency: element.latency,
          elb_4xx: element.ELB_4XX,
          elb_5xx: element.ELB_5XX,
          processed_bytes: element.processed_bytes,
          tunnel_state: element.total_state,
          tunnel_data_sent: element.total_data_out,
          tunnel_data_received: element.total_data_in,
          consumed_read_capacity_units: element.consumed_read_capacity_units,
          consumed_write_capacity_units: element.consumed_write_capacity_units,
          read_throttle_events: element.read_throttle_events,
          write_throttle_events: element.write_throttle_events,
          backend_errors: element.backend_errors,
          requests: element.requests,
          total_error_rate: element.total_error_rate,
          environment_health: element.environment_health,
          warn_count: element.warn_count,
          instances_severe: element.instances_severe,
          ec_redis_bytes_used_for_cache: element.ec_redis_bytes_used_for_cache,
          ec_redis_cache_hits: element.ec_redis_cache_hits,
          ec_redis_cache_misses: element.ec_redis_cache_misses,
          number_of_messages_published: element.number_of_messages_published,
          number_of_notifications_delivered: element.number_of_notifications_delivered,
          number_of_notifications_failed: element.number_of_notifications_failed,
          bucket_size: element.bucket_size,
          bytes_downloaded: element.bytes_downloaded,
          bytes_uploaded: element.bytes_uploaded,
          num_of_objects: element.num_of_objects,
          burst_balance: element.burst_balance,
          client_connection: element.client_connection,
          permitted_throughput: element.permitted_throughput,
          total_io_bytes: element.total_io_bytes,
          location_id: element.location_id,
          response_code: element.response_code,
          response_time: element.response_time,
          reason: element.reason,
          memory_utilized: element.memory_utilized_per_node || element.memory_utilized,
          cpu_reserved: element.cpu_reserved_per_node,
          memory_reserved: element.memory_reserved_per_node,
          node_cpu_utilized: element.node_cpu_utilized,
          node_memory_utilized: element.node_memory_utilized,
          pod_cpu_utilized: element.pod_cpu_utilized,
          pod_memory_utilized: element.pod_memory_utilized,
        });
      });

      if (logData.length > 0) {
        cols = generateHeaders(logData);
      }

      setRecentPolls(logData);

      const status = {
        0: 'Monitor is Down',
        1: 'Monitor is Up',
        2: 'Monitor is in Trouble',
        3: 'Monitor is Critical',
      };

      setMonitorDetails({
        // @ts-expect-error TS(2345): Argument of type '{ monitor_type: any; resource_na... Remove this comment to see the full error message
        monitor_type: info.monitor_type,
        resource_name: info.resource_name,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        statusDescription: status[statusValue],
        status: statusValue,
      });

      setAvailabilityReport({
        // @ts-expect-error TS(2345): Argument of type '{ availability_percentage: any; ... Remove this comment to see the full error message
        availability_percentage: summaryDetails.availability_percentage,
        down_count: summaryDetails.down_count,
        downtime_duration: summaryDetails.downtime_duration,
        maintenance_duration: summaryDetails.maintenance_duration,
        maintenance_percentage: summaryDetails.maintenance_percentage,
      });

      if (profileDetails.threshold_profiles.length > 0) setThresoldProfile(profileDetails.threshold_profiles[0].name);

      setCols(cols);

      const lineChartOptions: any = [];

      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const chartMappingData = replacementMapping[info.monitor_type];

      if (chartMappingData !== undefined) {
        chartMappingData.forEach((chartData: any) => {
          let yAxisLabel = 'percent';
          let yAxisMax = 0;
          let chartTitle = '';

          for (let index = 0; index < logData.length; index += 1) {
            const element = logData[index];
            let val = 0;

            switch (chartData) {
              case 'cpu_utilization':
                val = parseFloat(element.cpu_utilization);
                chartTitle = 'CPU Utilization';
                break;
              case 'database_connections':
                chartTitle = 'Database Connections';
                yAxisLabel = 'number';
                val = parseFloat(element.database_connections);
                break;
              case 'request_count_avg':
                chartTitle = 'Request Count Avg';
                yAxisLabel = 'number';
                val = parseFloat(element.request_count_avg);
                break;
              case 'avg_latency':
                chartTitle = 'Avg Latency';
                yAxisLabel = 'number';
                val = parseFloat(element.avg_latency);
                break;
              case 'elb_4xx':
                chartTitle = 'ELB 4xx Errors';
                yAxisLabel = 'number';
                val = parseFloat(element.elb_4xx);
                break;
              case 'elb_5xx':
                chartTitle = 'ELB 5xx Errors';
                yAxisLabel = 'number';
                val = parseFloat(element.elb_5xx);
                break;
              case 'bytes_received':
                chartTitle = 'Bytes Received(MB)';
                yAxisLabel = 'bytes';
                val = parseFloat(element.bytes_received);
                break;
              case 'bytes_sent':
                chartTitle = 'Bytes Sent(MB)';
                yAxisLabel = 'bytes';
                val = parseFloat(element.bytes_sent);
                break;
              case 'burst_balance':
                chartTitle = 'Burst Credit Balance';
                yAxisLabel = 'number';
                val = parseFloat(element.burst_balance);
                break;
              case 'permitted_throughput':
                chartTitle = 'Permitted Throughput';
                yAxisLabel = 'number';
                val = parseFloat(element.permitted_throughput);
                break;
              case 'processed_bytes':
                chartTitle = 'Processed Bytes';
                yAxisLabel = 'bytes';
                val = parseFloat(element.processed_bytes);
                break;
              case 'tunnel_data_sent':
                chartTitle = 'Tunnel Data Sent';
                yAxisLabel = 'number';
                val = parseFloat(element.tunnel_data_sent);
                break;
              case 'tunnel_data_received':
                chartTitle = 'Tunnel Data Received';
                yAxisLabel = 'number';
                val = parseFloat(element.tunnel_data_received);
                break;
              case 'read_throttle_events':
                chartTitle = 'Read Throttle events';
                yAxisLabel = 'number';
                val = parseFloat(element.read_throttle_events);
                break;
              case 'write_throttle_events':
                chartTitle = 'Write Throttle events';
                yAxisLabel = 'number';
                val = parseFloat(element.write_throttle_events);
                break;
              case 'backend_errors':
                chartTitle = 'Backend Errors';
                yAxisLabel = 'number';
                val = parseFloat(element.backend_errors);
                break;
              case 'requests':
                chartTitle = 'Requests';
                yAxisLabel = 'number';
                val = parseFloat(element.requests);
                break;
              case 'total_error_rate':
                chartTitle = 'Total Error Rate';
                yAxisLabel = 'number';
                val = parseFloat(element.total_error_rate);
                break;
              case 'instances_severe':
                chartTitle = 'Instances Severe';
                yAxisLabel = 'number';
                val = parseFloat(element.instances_severe);
                break;
              case 'node_cpu_utilized':
                chartTitle = 'CPU utilization by nodes';
                yAxisLabel = 'percent';
                val = parseFloat(element.node_cpu_utilized);
                break;
              case 'ec_redis_bytes_used_for_cache':
                chartTitle = 'Bytes Used For Cache';
                yAxisLabel = 'bytes';
                val = parseFloat(element.ec_redis_bytes_used_for_cache);
                break;
              case 'number_of_notifications_delivered':
                chartTitle = 'Notifications Delivered';
                yAxisLabel = 'number';
                val = parseFloat(element.number_of_notifications_delivered);
                break;
              case 'number_of_notifications_failed':
                chartTitle = 'Notifications Failed';
                yAxisLabel = 'number';
                val = parseFloat(element.number_of_notifications_failed);
                break;
              case 'bucket_size':
                chartTitle = 'Bucket Size';
                yAxisLabel = 'bytes';
                val = parseFloat(element.bucket_size);
                break;
              case 'num_of_objects':
                chartTitle = 'Number of Objects';
                yAxisLabel = 'number';
                val = parseFloat(element.num_of_objects);
                break;
              case 'response_time':
                chartTitle = 'Response Time(ms)';
                yAxisLabel = 'number';
                console.log(element.response_time);
                val = parseFloat(element.response_time);
                console.log(val);
                break;

              default:
                break;
            }

            if (val > yAxisMax) yAxisMax = val;
          }

          yAxisMax = Math.round(yAxisMax / 10) * 10 + 5;

          const data = {
            title: { text: chartTitle },
            data: {
              xAxis: {
                type: 'datetime',
                labels: {
                  format: '{value:%l:%M%p}',
                  formatter: (values: any) => {
                    const { value } = values;

                    return formatTime(DateTime.fromMillis(value), activeUser.timeZone);
                  },
                },
              },
              yAxis: {
                name: 'Percent',
                labels: {
                  formatter: (values: any) => {
                    const { value } = values;

                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ money: (value: any) => string | number; percent: (value: any)
                    return yAxisFormat[yAxisLabel](value);
                  },
                },
                max: yAxisMax,
              },
              series: [
                {
                  name: chartTitle,
                  // @ts-expect-error TS(7006): Parameter 'newMetric' implicitly has an 'any' type... Remove this comment to see the full error message
                  data: logData.map(newMetric => {
                    const date = new Date(newMetric.graphTime).getTime();
                    let yData = 0;

                    switch (chartData) {
                      case 'cpu_utilization':
                        yData = newMetric.cpu_utilization;
                        break;
                      case 'database_connections':
                        yData = newMetric.database_connections;
                        break;
                      case 'request_count_avg':
                        yData = newMetric.request_count_avg;
                        break;
                      case 'avg_latency':
                        yData = newMetric.avg_latency;
                        break;
                      case 'elb_4xx':
                        yData = newMetric.elb_4xx;
                        break;
                      case 'elb_5xx':
                        yData = newMetric.elb_5xx;
                        break;
                      case 'bytes_received':
                        yData = newMetric.bytes_received;
                        break;
                      case 'bytes_sent':
                        yData = newMetric.bytes_sent;
                        break;
                      case 'burst_balance':
                        yData = newMetric.burst_balance;
                        break;
                      case 'processed_bytes':
                        yData = newMetric.processed_bytes;
                        break;
                      case 'tunnel_data_sent':
                        yData = newMetric.tunnel_data_sent;
                        break;
                      case 'tunnel_data_received':
                        yData = newMetric.tunnel_data_received;
                        break;
                      case 'read_throttle_events':
                        yData = newMetric.read_throttle_events;
                        break;
                      case 'write_throttle_events':
                        yData = newMetric.write_throttle_events;
                        break;
                      case 'backend_errors':
                        yData = newMetric.backend_errors;
                        break;
                      case 'requests':
                        yData = newMetric.requests;
                        break;
                      case 'total_error_rate':
                        yData = newMetric.total_error_rate;
                        break;
                      case 'instances_severe':
                        yData = newMetric.instances_severe;
                        break;
                      case 'node_cpu_utilized':
                        yData = newMetric.node_cpu_utilized;
                        break;
                      case 'ec_redis_bytes_used_for_cache':
                        yData = newMetric.ec_redis_bytes_used_for_cache;
                        break;
                      case 'number_of_notifications_delivered':
                        yData = newMetric.number_of_notifications_delivered;
                        break;
                      case 'number_of_notifications_failed':
                        yData = newMetric.number_of_notifications_failed;
                        break;
                      case 'bucket_size':
                        yData = newMetric.bucket_size;
                        break;
                      case 'num_of_objects':
                        yData = newMetric.num_of_objects;
                        break;
                      case 'response_time':
                        yData = newMetric.response_time;
                        break;

                      default:
                        break;
                    }

                    return {
                      y: Number(yData),
                      x: date,
                    };
                  }),
                },
              ],
              tooltip: {
                valueDecimals: 2,
              },
              chart: {
                height: currentViewWidth >= 2560 ? 450 : 350,
              },
              legend: {
                aling: 'center',
                verticalAlign: 'bottom',
              },
              cSVFileName: `${chartTitle}.csv`,
            },
          };

          lineChartOptions.push(data);
        });
      }

      setCPUUtilLineChartOptions(lineChartOptions);
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      console.error('Error load monitors: ', error);
      setError('Error Loading Data');

      setLoading(false);
    }
  };

  const generateHeaders = (logData: any) => {
    const cols = [];

    if (logData[0].collection_time !== undefined) {
      cols.push({
        header: 'Time',
        accessor: 'collection_time',
      });
    }
    if (logData[0].availability !== undefined) {
      cols.push({
        header: 'Status',
        accessor: 'availability',
        cell: function CompanyCell(cellInfo: any) {
          if (cellInfo.cell.value === ZERO) {
            return (
              <FontAwesomeIcon
                icon={faCircle}
                className="mr-1 text-red-500"
                style={{
                  // @ts-expect-error TS(2322): Type '{ '--ring-color': string; }' is not assignab... Remove this comment to see the full error message
                  '--ring-color': 'transparent',
                }}
              />
            );
          }
          if (cellInfo.cell.value === THREE) {
            return (
              <FontAwesomeIcon
                icon={faCircle}
                className="mr-1 text-yellow-500"
                style={{
                  // @ts-expect-error TS(2322): Type '{ '--ring-color': string; }' is not assignab... Remove this comment to see the full error message
                  '--ring-color': 'transparent',
                }}
              />
            );
          }
          if (cellInfo.cell.value === TWO) {
            return (
              <FontAwesomeIcon
                icon={faCircle}
                className="mr-1 text-yellow-300"
                style={{
                  // @ts-expect-error TS(2322): Type '{ '--ring-color': string; }' is not assignab... Remove this comment to see the full error message
                  '--ring-color': 'transparent',
                }}
              />
            );
          }

          return (
            <FontAwesomeIcon
              icon={faCircle}
              className="mr-1 text-emerald-600"
              style={{
                // @ts-expect-error TS(2322): Type '{ '--ring-color': string; }' is not assignab... Remove this comment to see the full error message
                '--ring-color': 'transparent',
              }}
            />
          );
        },
      });
    }
    if (logData[0].cpu_utilization !== undefined) {
      cols.push({
        header: 'cpu (%)',
        accessor: 'cpu_utilization',
      });
    }
    if (logData[0].bytes_received !== undefined) {
      cols.push({
        header: 'Bytes Received',
        accessor: 'bytes_received',
      });
    }
    if (logData[0].bytes_sent !== undefined) {
      cols.push({
        header: 'Bytes Sent',
        accessor: 'bytes_sent',
      });
    }
    if (logData[0].free_storage !== undefined) {
      cols.push({
        header: 'Free Storage',
        accessor: 'free_storage',
      });
    }
    if (logData[0].database_connections !== undefined) {
      cols.push({
        header: 'Database Connections',
        accessor: 'database_connections',
      });
    }
    if (logData[0].unhealthy_host_count !== undefined) {
      cols.push({
        header: 'Unhealthy Host Count',
        accessor: 'unhealthy_host_count',
      });
    }
    if (logData[0].request_count_avg !== undefined) {
      cols.push({
        header: 'Request Count Avg',
        accessor: 'request_count_avg',
      });
    }
    if (logData[0].avg_latency !== undefined) {
      cols.push({
        header: 'Avg Latency',
        accessor: 'avg_latency',
      });
    }
    if (logData[0].elb_4xx !== undefined) {
      cols.push({
        header: 'ELB 4XX Errors',
        accessor: 'elb_4xx',
      });
    }
    if (logData[0].elb_5xx !== undefined) {
      cols.push({
        header: 'ELB 5XX Errors',
        accessor: 'elb_5xx',
      });
    }
    if (logData[0].processed_bytes !== undefined) {
      cols.push({
        header: 'Processed Bytes',
        accessor: 'processed_bytes',
      });
    }
    if (logData[0].tunnel_state !== undefined) {
      cols.push({
        header: 'Tunnel State',
        accessor: 'tunnel_state',
      });
    }
    if (logData[0].tunnel_data_sent !== undefined) {
      cols.push({
        header: 'Tunnel Data Sent',
        accessor: 'tunnel_data_sent',
      });
    }
    if (logData[0].tunnel_data_received !== undefined) {
      cols.push({
        header: 'Tunnel Data Received',
        accessor: 'tunnel_data_received',
      });
    }
    if (logData[0].consumed_read_capacity_units !== undefined) {
      cols.push({
        header: 'Consumed Read Capacity Units',
        accessor: 'consumed_read_capacity_units',
      });
    }
    if (logData[0].consumed_write_capacity_units !== undefined) {
      cols.push({
        header: 'Consumed Write Capacity Units',
        accessor: 'consumed_write_capacity_units',
      });
    }
    if (logData[0].read_throttle_events !== undefined) {
      cols.push({
        header: 'Read Throttle Events',
        accessor: 'read_throttle_events',
      });
    }
    if (logData[0].write_throttle_events !== undefined) {
      cols.push({
        header: 'Write Throttle Events',
        accessor: 'write_throttle_events',
      });
    }
    if (logData[0].backend_errors !== undefined) {
      cols.push({
        header: 'Backend Connection Errors',
        accessor: 'backend_errors',
      });
    }
    if (logData[0].requests !== undefined) {
      cols.push({
        header: 'Requests Count',
        accessor: 'requests',
      });
    }
    if (logData[0].total_error_rate !== undefined) {
      cols.push({
        header: 'Total Error Rate',
        accessor: 'total_error_rate',
      });
    }
    if (logData[0].environment_health !== undefined) {
      cols.push({
        header: 'Enviornment Health',
        accessor: 'environment_health',
      });
    }
    if (logData[0].warn_count !== undefined) {
      cols.push({
        header: 'Number of Warn events',
        accessor: 'warn_count',
      });
    }
    if (logData[0].instances_severe !== undefined) {
      cols.push({
        header: 'Instances With Status Severe (Cloudwatch)',
        accessor: 'instances_severe',
      });
    }
    if (logData[0].ec_redis_bytes_used_for_cache !== undefined) {
      cols.push({
        header: 'Bytes Used For Cache',
        accessor: 'ec_redis_bytes_used_for_cache',
      });
    }
    if (logData[0].ec_redis_cache_hits !== undefined) {
      cols.push({
        header: 'Hits',
        accessor: 'ec_redis_cache_hits',
      });
    }
    if (logData[0].ec_redis_cache_misses !== undefined) {
      cols.push({
        header: 'Misses',
        accessor: 'ec_redis_cache_misses',
      });
    }
    if (logData[0].number_of_messages_published !== undefined) {
      cols.push({
        header: 'Messages Published',
        accessor: 'number_of_messages_published',
      });
    }
    if (logData[0].number_of_notifications_delivered !== undefined) {
      cols.push({
        header: 'Notifications Delivered',
        accessor: 'number_of_notifications_delivered',
      });
    }
    if (logData[0].number_of_notifications_failed !== undefined) {
      cols.push({
        header: 'Notifications Failed',
        accessor: 'number_of_notifications_failed',
      });
    }
    if (logData[0].bucket_size !== undefined) {
      cols.push({
        header: 'Bucket Size',
        accessor: 'bucket_size',
      });
    }
    if (logData[0].bytes_downloaded !== undefined) {
      cols.push({
        header: 'Bytes Downloaded',
        accessor: 'bytes_downloaded',
      });
    }
    if (logData[0].bytes_uploaded !== undefined) {
      cols.push({
        header: 'Bytes Uploaded',
        accessor: 'bytes_uploaded',
      });
    }
    if (logData[0].num_of_objects !== undefined) {
      cols.push({
        header: 'Number of Objects',
        accessor: 'num_of_objects',
      });
    }
    if (logData[0].burst_balance !== undefined) {
      cols.push({
        header: 'Burst Credit Balance',
        accessor: 'burst_balance',
      });
    }
    if (logData[0].client_connection !== undefined) {
      cols.push({
        header: 'Client Connections',
        accessor: 'client_connection',
      });
    }
    if (logData[0].permitted_throughput !== undefined) {
      cols.push({
        header: 'Permitted Throughput',
        accessor: 'permitted_throughput',
      });
    }
    if (logData[0].total_io_bytes !== undefined) {
      cols.push({
        header: 'Total I/O Operation',
        accessor: 'total_io_bytes',
      });
    }
    if (logData[0].location_id !== undefined) {
      cols.push({
        header: 'Location',
        accessor: 'location_id',
      });
    }
    if (logData[0].response_code !== undefined) {
      cols.push({
        header: 'HTTP Status Code',
        accessor: 'response_code',
      });
    }
    if (logData[0].response_time !== undefined) {
      cols.push({
        header: 'Response Time (ms)',
        accessor: 'response_time',
      });
    }
    if (logData[0].reason !== undefined) {
      cols.push({
        header: 'Reason',
        accessor: 'reason',
      });
    }
    if (logData[0].memory_utilized !== undefined) {
      cols.push({
        header: 'Memory Utilized',
        accessor: 'memory_utilized',
      });
    }
    if (logData[0].cpu_reserved !== undefined) {
      cols.push({
        header: 'CPU Reserved',
        accessor: 'cpu_reserved',
      });
    }
    if (logData[0].memory_reserved !== undefined) {
      cols.push({
        header: 'Memory Reserved',
        accessor: 'memory_reserved',
      });
    }
    if (logData[0].node_cpu_utilized !== undefined) {
      cols.push({
        header: 'CPU utilization by nodes',
        accessor: 'node_cpu_utilized',
      });
    }
    if (logData[0].node_memory_utilized !== undefined) {
      cols.push({
        header: 'Memory utilized by nodes',
        accessor: 'node_memory_utilized',
      });
    }
    if (logData[0].pod_cpu_utilized !== undefined) {
      cols.push({
        header: 'CPU utilized by pods',
        accessor: 'pod_cpu_utilized',
      });
    }
    if (logData[0].pod_memory_utilized !== undefined) {
      cols.push({
        header: 'Memory utilized by pods',
        accessor: 'pod_memory_utilized',
      });
    }

    return cols;
  };

  useMountEffect(() => {
    initializeComponent();
  });

  const loadData = useCallback(
    async (options: any) =>
      executeAsync(() => {
        const { filter, sort, search } = options;
        let items = recentPolls.map(m => m);

        if (filter) {
          items = items.filter(x => filter.equals.includes(x[filter.field]));
        }

        if (sort) {
          items = sortDataSet(items, sort);
        }

        if (search) {
          const keys = Object.keys(items[0]);
          const searchOptions = {
            searchString: search,
            keys,
          };

          items = searchDataSet(items, searchOptions);
        }

        return {
          items: items ?? [],
        };
      }),
    [recentPolls]
  );

  const initializeCPUUtilOptionsDropdown = (generateCSVFunction: any) => {
    // @ts-expect-error TS(2322): Type '{ text: string; downloadCSV: any; }' is not assignable to type 'never'.
    setCPUUtilLinks([{ text: 'Download CSV', downloadCSV: generateCSVFunction }]);
  };

  return (
    <div className="page-container">
      {loading && (
        <div className="absolute right-0">
          <Spinner className="h-4 w-4 mr-2" />
        </div>
      )}

      <div className="text-white justify-start mt-2">
        {((monitorDetails as any).status === 0 && (
          <div className="justify-start grid grid-cols-1 lg:grid-cols-2 2.5xl:grid-cols-3 gap-4 bg-red-500 p-2">
            <div>{(monitorDetails as any).monitor_type}</div>

            <div>{(monitorDetails as any).resource_name}</div>

            <div>{(monitorDetails as any).statusDescription}</div>
          </div>
        )) ||
          ((monitorDetails as any).status === 3 && (
            <div className="grid grid-cols-1 lg:grid-cols-3 2.5xl:grid-cols-3 gap-4 bg-yellow-300 p-2">
              <div>{(monitorDetails as any).monitor_type}</div>

              <div>{(monitorDetails as any).resource_name}</div>

              <div>{(monitorDetails as any).statusDescription}</div>
            </div>
          )) ||
          ((monitorDetails as any).status === 2 && (
            <div className="grid grid-cols-1 lg:grid-cols-3 2.5xl:grid-cols-3 gap-4 bg-yellow-500 p-2">
              <div>{(monitorDetails as any).monitor_type}</div>

              <div>{(monitorDetails as any).resource_name}</div>

              <div>{(monitorDetails as any).statusDescription}</div>
            </div>
          )) ||
          ((monitorDetails as any).status === 1 && (
            <div className="grid grid-cols-1 lg:grid-cols-3 2.5xl:grid-cols-3 gap-4 bg-emerald-600 p-2">
              <div>{(monitorDetails as any).monitor_type}</div>

              <div>{(monitorDetails as any).resource_name}</div>

              <div>{(monitorDetails as any).statusDescription}</div>
            </div>
          ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-1 2.5xl:grid-cols-3 gap-4 my-3">
        <Card
          title="Details"
          loading={loading}
          errorMessage={error}
          component={
            <div className="lg:space-y-4 2.5lg:space-y-4 text-purple-700">
              <span className="block">
                <span className="font-bold">Server monitored: </span>
                {(monitorDetails as any).resource_name}
              </span>

              <span className="block">
                <span className="font-bold">Attribute(s) changed:</span>

                <p>{downReason}</p>
              </span>

              <span className="block">
                <span className="font-bold">Threshold Profile: </span>
                {thresoldProfile}
              </span>
            </div>
          }
        />

        <div className="lg:col-span-2 2.5xl:col-span-2">
          <EmpathTable
            title="Recent Polls"
            columns={columns}
            loadData={loadData}
            viewPortHeight={20}
            initializing={loading}
            errorMessage={error}
          />
        </div>
      </div>

      <div className="flex-1 my-3 focus:ring-0">
        <div className="px-4 py-4 rounded-lg shadow bg-offwhite sm:py-5 sm:px-8">
          <h4 className="mb-4 text-2xl font-normal text-purple-700 font-title">Availability Report</h4>

          <div className="flex flex-wrap text-purple-800">
            <div className="m-3">
              <p className="font-bold">Availability</p>

              <p>{(availabilityReport as any).availability_percentage} %</p>
            </div>

            <div className="m-3">
              <p className="font-bold">No. of Downtime</p>

              <p>{(availabilityReport as any).down_count}</p>
            </div>

            <div className="m-3">
              <p className="font-bold">Downtime</p>

              <p>{(availabilityReport as any).downtime_duration}</p>
            </div>

            <div className="m-3">
              <p className="font-bold">Maintenance</p>

              <p>{(availabilityReport as any).maintenance_percentage} %</p>
            </div>

            <div className="m-3">
              <p className="font-bold">Maintenance Duration</p>

              <p>{(availabilityReport as any).maintenance_duration}</p>
            </div>
          </div>
        </div>
      </div>
      {CPUUtilLineChartOptions.map((lineChartData, i) => (
        <div key={i} className="my-2">
          <Card
            title={(lineChartData as any).title.text}
            optionsDropdown={CPUUtilLinks}
            classNames="w-full lg:col-span-2 2.5xl:col-span-1"
            loading={loading}
            errorMessage={error}
            component={
              <LineChart
                chartOptions={(lineChartData as any).data}
                // @ts-expect-error TS(2322):Type '{ chartOptions: any; getGenerateCSVFunction: (generateCSVFunction: any) => void; }'
                getGenerateCSVFunction={initializeCPUUtilOptionsDropdown}
              />
            }
          />
        </div>
      ))}
    </div>
  );
};

export default MonitorDetail;
