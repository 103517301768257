import { twCascade } from '@mariusmarais/tailwind-cascade';
import { Control, DeepMap, FieldError, FieldValues, RegisterOptions, useController } from 'react-hook-form';
import Cleave from 'cleave.js/react';
// eslint-disable-next-line import/no-unresolved
import { CleaveOptions } from 'cleave.js/options';
import { getErrorMessage } from './utils';

import 'cleave.js/dist/addons/cleave-phone.us';

interface Props<T> {
  label: string;
  field: Extract<keyof T, string>;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  formErrors?: DeepMap<T, FieldError>;
  control: Control<FieldValues>;
  options: CleaveOptions;
  disabled?: boolean;
}

function MaskInput<T>({ label, field, rules, formErrors, control, options, disabled }: Props<T>) {
  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name: field,
    control,
    rules,
    defaultValue: '',
  });

  const error = getErrorMessage(formErrors, field, label);

  const labelColor = 'text-blue-900';
  const textColor = 'text-purple-700';
  const backgroundColor = 'text-white';
  const focusBorderColor = 'focus:border-purple-700';

  // Cleave does not rerender to display error red border. Hence this function
  const getBorderColor = () => {
    if (error) return 'border-red-700';

    return 'border-b-300';
  };

  return (
    <div>
      <div className="flex flex-row items-center">
        <label
          htmlFor={field}
          className={twCascade('flex', 'text-base', 'mb-1', labelColor, error ? 'text-red-700' : 'labelColor')}
        >
          {label}
          {rules?.required && <span className="text-banner-red">*</span>}
        </label>
      </div>
      <Cleave
        {...inputProps}
        name={field}
        htmlRef={e => (ref.current = e)}
        disabled={disabled}
        onChange={e => onChange(e.target.rawValue)}
        options={options}
        className={twCascade(
          'px-2',
          'w-full',
          'leading-tight',
          'border',
          'border-solid',
          'rounded',
          getBorderColor(),
          backgroundColor,
          textColor,
          'focus:ring-0',
          focusBorderColor
        )}
      />
      <div className={`${error ? '' : 'invisible'} min-h-5 text-xs text-red-700`}>{error}</div>
    </div>
  );
}

MaskInput.displayName = 'MaskInput';

export default MaskInput;
