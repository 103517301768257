import React, { createContext, useContext, useMemo } from 'react';
import { customerIds as customerIdsList } from '../shared/groupsHelper';

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const UserContext = createContext();

type UserProviderProps = {
  children?: React.ReactElement;
  cognitoUser?: any;
  setCognitoUser?: (...args: any[]) => any;
  user?: any;
  setUser?: (...args: any[]) => any;
  groups?: any[];
  impersonation?: any;
  setImpersonation?: (...args: any[]) => any;
  setUserNavigationHistoryStack?: (...args: any[]) => any;
  userNavigationHistoryStack?: {
    currentPage?: string;
    lastVisitedPage?: string;
  };
};

const UserProvider = ({
  children,
  cognitoUser,
  setCognitoUser,
  groups,
  user,
  setUser,
  impersonation,
  setImpersonation,
  userNavigationHistoryStack,
  setUserNavigationHistoryStack,
}: UserProviderProps) => {
  const activeUser = impersonation?.user ? impersonation?.user : user;

  const activeUserGroups = impersonation?.user ? impersonation?.user.groups : groups;

  const activeCognitoUser = impersonation?.cognitoUser ? impersonation?.cognitoUser : cognitoUser;

  const isImpersonating = !!impersonation;

  const isEffectualUser = (() => {
    if (!cognitoUser || !cognitoUser.username) {
      return false;
    }

    // If we are impersonating, check if they are an effectual user
    if (impersonation) {
      return impersonation.groups.some((group: any) => group.indexOf('effectual') >= 0);
    }

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    return groups.some(group => group.indexOf('effectual') >= 0);
  })();

  const setActiveUser = (updatedUser: any) => {
    if (impersonation?.user) {
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setImpersonation({
        ...impersonation,
        user: updatedUser,
      });
    } else {
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setUser(updatedUser);
    }
  };
  const userInRoles = (roles: any) => {
    if (!cognitoUser || !cognitoUser.username) {
      return false;
    }

    // If we are impersonating, check the role against the impersonated user
    if (impersonation) {
      if (impersonation.groups) {
        return impersonation.groups.filter((element: any) => roles.includes(element)).length > 0;
      }

      return false;
    }

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    return groups.filter(element => roles.includes(element)).length > 0;
  };

  const customerHasFeature = (customerRoles: any) => {
    if (impersonation) {
      if (impersonation.user.featureFlags) {
        return impersonation.user.featureFlags.filter((feature: any) => customerRoles.includes(feature)).length > 0;
      }

      return false;
    }

    if (user.customerId.toLowerCase() === customerIdsList.empath) {
      return true;
    }

    return user.featureFlags.filter((feature: any) => customerRoles.includes(feature)).length > 0;
  };

  const userHasCustomer = (cid: any) => {
    if (!cognitoUser || !cognitoUser.username) {
      return false;
    }

    let customerIds = [];

    // If we are impersonating, check the cid against the impersonated customer
    if (impersonation?.user) {
      customerIds = JSON.parse(impersonation.user.customerIds);

      return customerIds.includes(cid);
    }

    if (impersonation) {
      return cid === impersonation.cid || cid === user.customerId;
    }

    customerIds = user?.customerIds ? JSON.parse(user.customerIds) : [];

    return customerIds.includes(cid);
  };

  const userBelongsToCustomer = (cid: any) => {
    if (!cognitoUser || !cognitoUser.username) {
      return false;
    }

    return user && user.customerId === cid;
  };

  const clearUserInfo = () => {
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setImpersonation(null);
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setUser(null);
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setCognitoUser(null);
  };

  const value = useMemo(
    () => ({
      isImpersonating,
      impersonation,
      setImpersonation,
      user,
      activeUser,
      activeUserGroups,
      activeCognitoUser,
      setCognitoUser,
      setActiveUser,
      userInRoles,
      isEffectualUser,
      userHasCustomer,
      userBelongsToCustomer,
      clearUserInfo,
      customerHasFeature,
      userNavigationHistoryStack,
      setUserNavigationHistoryStack,
    }),
    [user, impersonation]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => useContext(UserContext);

export default UserContext;

export { UserProvider, useUser };
