import React, { forwardRef } from 'react';

import { twCascade } from '@mariusmarais/tailwind-cascade';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactDatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';

type DatePickerInputProps = {
  onClick?: (...args: any[]) => any;
  date?: any; // TODO: PropTypes.instanceOf(Date)
  displayFormat?: string;
  disabled?: boolean;
  inputClassName?: string;
  error?: string;
};

const DatePickerInput = forwardRef<any, DatePickerInputProps>(
  ({ onClick, date, displayFormat, inputClassName, disabled, error }, ref) => {
    const displayDate = () => {
      if (!displayFormat && date) {
        return DateTime.fromJSDate(date).toFormat('D');
      }

      if (date) {
        return DateTime.fromJSDate(date).toFormat(displayFormat);
      }

      return date;
    };

    return (
      <button
        ref={ref}
        type="button"
        className={twCascade(
          'flex',
          'items-center',
          'justify-between',
          'px-2',
          'py-0.5',
          'bg-white',
          'border',
          'border-gray-300',
          'rounded',
          disabled ? 'cursor-not-allowed' : '',
          error ? 'border-red-700' : '',
          disabled && 'bg-bg-disabled text-disabled',
          inputClassName
        )}
        onClick={onClick}
      >
        <div>{displayDate()}</div>
        <div>
          <FontAwesomeIcon icon={faCalendarAlt} className="text-purple-500" />
        </div>
      </button>
    );
  }
);

DatePickerInput.displayName = 'DatePickerInput';

type DatePickerProps = {
  date?: any; // TODO: PropTypes.instanceOf(Date)
  minDate?: number;
  onChange?: (...args: any[]) => any;
  disabled?: boolean;
  displayFormat?: string;
  inputClassName?: string;
  error?: string;
  showTimeSelect?: boolean;
};

const DatePicker = forwardRef<any, DatePickerProps>(
  ({ disabled, date, onChange, displayFormat, inputClassName, error, minDate, showTimeSelect }, ref) => (
    <ReactDatePicker
      disabled={disabled}
      selected={date}
      onChange={onChange}
      minDate={minDate}
      showTimeSelect={showTimeSelect}
      customInput={
        <DatePickerInput
          ref={ref}
          inputClassName={inputClassName}
          disabled={disabled}
          date={date}
          displayFormat={displayFormat}
          error={error}
        />
      }
    />
  )
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
