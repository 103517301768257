import React, { useState, useEffect, useMemo, useRef } from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useTable, useSortBy, useGlobalFilter, defaultState } from 'react-table';

import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisHAlt } from '@fortawesome/pro-light-svg-icons';
import { Spinner } from '../animations';

import ComponentDataLoader from '../loader/ComponentDataLoader';
import GlobalFilter from './GlobalFilter';
import ErrorBoundary from '../errors/ErrorBoundary';
import ComponentError from '../errors/ComponentError';
import { Button } from '../buttons';

type Props = {
  icon?: any;
  title: string;
  subTitle?: string;
  columns: {
    header: string;
    accessor: string;
    cell?: (...args: any[]) => any;
    colSpan?: number;
  }[];
  loadData: (...args: any[]) => any;
  filter?: any; // TODO: PropTypes.shape(filterExpressionShape)
  sort?: {
    field?: string;
    direction?: string;
  }[];
  dropdownFilters?: {
    label: string;
    filterExpression?: any; // TODO: PropTypes.shape(filterExpressionShape)
  }[];
  callsToAction?: any[] | boolean;
  initializing?: boolean;
  viewPortHeight?: number;
  errorMessage?: string;
  containerClassName?: string;
  reloadInit?: boolean;
  optionsDropdown?: {
    label?: string;
    action?: (...args: any[]) => any;
  }[];
  isOptionsLoading?: boolean;
};

const EmpathTable = ({
  icon,
  title,
  subTitle,
  columns,
  loadData,
  filter,
  sort,
  dropdownFilters,
  callsToAction,
  viewPortHeight,
  initializing,
  errorMessage,
  containerClassName,
  reloadInit,
  optionsDropdown,
  isOptionsLoading,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [nextToken, setNextToken] = useState();
  const [data, setData] = useState([]);
  const [internalError, setInternalError] = useState('');
  const [filterState, setFilterState] = useState(filter);
  const tableHeight = viewPortHeight ? `${viewPortHeight}vh` : '50vh';
  const tableColSpan = columns
    .map(column => ((column as any).show !== undefined && !(column as any).show ? 0 : column.colSpan ?? 1))
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    .reduce((total, colSpan) => total + colSpan, 0);
  const scrollBarRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const handleOptions = () => setShowOptions(!showOptions);
  const limit = 50;

  // Initialize React-Table
  const { getTableProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, state, setGlobalFilter } = useTable(
    {
      columns: useMemo(
        () =>
          columns.map(m => {
            const col = {
              Header: m.header,
              accessor: m.accessor,
              // @ts-expect-error TS(2339): Property 'isSortable' does not exist on type '{ he... Remove this comment to see the full error message
              isSortable: m.isSortable ?? true,
              // @ts-expect-error TS(2339): Property 'show' does not exist on type '{ header: ... Remove this comment to see the full error message
              show: m.show === undefined || m.show === true,
            };
            if (m.colSpan) {
              // @ts-expect-error TS(2339): Property 'colSpan' does not exist on type '{ Heade... Remove this comment to see the full error message
              col.colSpan = m.colSpan;
            }
            if (m.cell) {
              // @ts-expect-error TS(2339): Property 'Cell' does not exist on type '{ Header: ... Remove this comment to see the full error message
              col.Cell = m.cell;
            }
            return col;
          }),
        [columns]
      ),
      data: useMemo(() => data, [data]),
      manualSortBy: true,
      manualGlobalFilter: true,
      initialState: {
        ...defaultState,
        sortBy: sort
          ? sort.map(sortStatement => ({
              id: sortStatement.field,
              desc: (sortStatement as any).sort === 'desc',
            }))
          : [],
      },
    },
    useGlobalFilter,
    useSortBy
  );

  const tableProperties = getTableProps();

  // @ts-expect-error TS(2345): Argument of type '() => () => boolean' is not assi... Remove this comment to see the full error message
  useEffect(() => {
    // This flag tells use effect whether or not another invocation has been canceled
    let canceled = false;
    const sortStatement = state.sortBy.map((sortBy: any) => ({
      field: sortBy.id,
      direction: sortBy.desc ? 'desc' : 'asc',
    }));
    if (scrollBarRef.current) {
      (scrollBarRef.current as any).getScrollElement().scrollTo(0, 0);
    }
    const fetchData = async () => {
      try {
        setInternalError('');
        setLoading(true);
        const fetchedData = await loadData({
          limit,
          filter: filterState,
          sort: sortStatement.length > 0 ? sortStatement : null,
          search: state.globalFilter ?? null,
        });
        if (!fetchedData || fetchedData.errors) {
          setData([]);
          // @ts-expect-error TS(2304): Cannot find name 'setInternalError'.
          setInternalError(true);
          setLoading(false);
        } else if (!canceled) {
          setData(fetchedData.items);
          setNextToken(fetchedData.token);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error refreshing table data: ', error);
        setInternalError('Error Loading Data');
        setLoading(false);
      }
    };
    if (!initializing && !errorMessage) {
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
      fetchData(sortStatement, canceled);
    }
    /*
     *  If another useEffect is started, this cleanup function will be called.
     *  This tells our function not to update the data in the UI because we need to wait on another loadData call to resolve
     */
    return () => (canceled = true);
  }, [state.sortBy, state.globalFilter, loadData, filterState, limit, initializing, errorMessage, reloadInit]);

  const handleTableScroll = async (scrollEvent: any) => {
    if (initializing || errorMessage || loadingMore) {
      return;
    }

    const contentHeight = scrollEvent.target.scrollHeight - scrollEvent.target.offsetHeight;

    if (contentHeight <= scrollEvent.target.scrollTop && nextToken) {
      const sortStatement = state.sortBy.map((sortBy: any) => ({
        field: sortBy.id,
        direction: sortBy.desc ? 'desc' : 'asc',
      }));

      setLoadingMore(true);

      const fetchedData = await loadData({
        limit,
        filter: filterState,
        sort: sortStatement.length > 0 ? sortStatement : null,
        search: state.globalFilter ?? null,
        token: nextToken,
      });

      setLoadingMore(false);

      setData(data.concat(fetchedData.items));
      setNextToken(fetchedData.token);
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const dropdownFilterChanged = async (event: any) => {
    setFilterState(
      // @ts-expect-error TS(2304): Cannot find name 'dropdownFilters'.
      dropdownFilters.find(dropDownfilter => dropDownfilter.label === event.target.value).filterExpression
    );
  };

  const renderColumnSortDirection = (column: any) => {
    if (column.isSorted && column.isSortedDesc) {
      return <FontAwesomeIcon icon={faChevronDown} className="inline w-3 h-3 ml-1" />;
    }
    if (column.isSorted) {
      return <FontAwesomeIcon icon={faChevronUp} className="inline w-3 h-3 ml-1" />;
    }
  };

  return (
    <div
      className={twCascade(
        'bg-offwhite',
        'overflow-hidden',
        'shadow',
        'rounded-lg',
        'min-h-full',
        'box-content',
        'relative',
        containerClassName
      )}
    >
      {/* @ts-expect-error TS(2322): Type '{ children: Element[]; fallbackClassNames: string; */}
      <ErrorBoundary
        fallbackClassNames="flex h-full justify-center items-center text-center"
        fallbackComponent={ComponentError}
      >
        <div className="flex px-5 pt-5 pb-1 lg:justify-center">
          <div className="flex flex-col w-full lg:flex-row lg:items-center">
            <div className="flex flex-row flex-1">
              {icon && <div className="flex-shrink-0 text-lg text-purple-500">{icon}</div>}
              <div className={twCascade('flex-1', 'w-0', icon && 'ml-5', 'text-purple-700')}>
                <h4 className="text-xl font-normal text-purple-700 font-title">{title}</h4>
                {subTitle && <p>{subTitle}</p>}
              </div>
            </div>
            {!initializing && !errorMessage && (
              <>
                {callsToAction &&
                  (callsToAction as any).map((callToAction: any) => (
                    <div key={callToAction.label} className="flex flex-col w-full mt-2 lg:mx-2 sm:w-auto lg:mt-0">
                      <Button type="button" onClick={callToAction.action} title={callToAction.label} />
                    </div>
                  ))}

                <div className="flex mt-4 lg:mt-0">
                  {dropdownFilters && (
                    <div className="w-full mt-2 lg:mx-2 sm:mt-0 sm:w-auto">
                      <select
                        onChange={dropdownFilterChanged}
                        id="tableDropdownFilter"
                        name="tableDropdownFilter"
                        className="w-full pr-8 leading-tight text-purple-700 border border-solid rounded sm:w-auto"
                      >
                        {dropdownFilters.map(dropdownFilter => (
                          <option key={dropdownFilter.label} value={dropdownFilter.label}>
                            {dropdownFilter.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="w-full mt-2 lg:mx-2 sm:mt-0 sm:w-auto">
                    <GlobalFilter
                      // @ts-expect-error TS(2322): Type '{ preGlobalFilteredRows: any; globalFilter: any;
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </div>
                  {optionsDropdown && !!optionsDropdown.length && (
                    <div className="flex flex-row justify-end w-6">
                      {showOptions && (
                        <div
                          className="absolute z-50 origin-top-right bg-white rounded-md shadow-lg md:right-0 md:top-0 md:mt-4 md:mr-4 w-36"
                          onMouseLeave={handleOptions}
                        >
                          <div className={`p-4 text-xs rounded-sm ring-1 ring-black ring-opacity-5 `}>
                            {optionsDropdown &&
                              optionsDropdown.map((m, index) => (
                                <button
                                  key={index}
                                  type="button"
                                  disabled={isOptionsLoading}
                                  onClick={() => {
                                    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefined'.
                                    m.action();
                                    setShowOptions(false);
                                  }}
                                >
                                  {(m as any).text}
                                </button>
                              ))}
                          </div>
                        </div>
                      )}

                      {!isOptionsLoading && (
                        <div className="text-purple-700">
                          <FontAwesomeIcon
                            icon={faEllipsisHAlt}
                            className="h-full text-2xl"
                            onMouseEnter={handleOptions}
                          />
                        </div>
                      )}
                      {isOptionsLoading && <Spinner className="h-4 w-4 mt-2" />}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="block m-4 bg-white rounded-sm sm:overflow-x-auto">
          {!errorMessage ? (
            <>
              <div className={`border-gray-200 shadow grid grid-cols-${tableColSpan}`} role={tableProperties.role}>
                {headerGroups.map((headerGroup: any) =>
                  headerGroup.headers.map((column: any) => {
                    const headerProperties = column.getHeaderProps(column.getSortByToggleProps());
                    if (column.isSortable) {
                      return (
                        column.show && (
                          <button
                            className={`col-span-${column.colSpan} focus:ring-0 px-2 py-4 font-semibold leading-4 tracking-wider text-left text-purple-500 bg-white`}
                            type="button"
                            key={headerProperties.key}
                            // @ts-expect-error TS(2322): Type '{ children: any[]; className: string; type: "button";
                            colSpan={headerProperties.colSpan}
                            role={headerProperties.role}
                            title={headerProperties.title}
                            onClick={headerProperties.onClick}
                            style={headerProperties.style}
                          >
                            {column.render('Header')}
                            {renderColumnSortDirection(column)}
                          </button>
                        )
                      );
                    }
                    return (
                      column.show ||
                      (column.show === undefined && (
                        <div
                          className={`col-span-${column.colSpan} focus:ring-0 px-2 py-4 font-semibold leading-4 tracking-wider text-left text-purple-500 bg-white`}
                          key={headerProperties.key}
                          // @ts-expect-error TS(2322): Type '{ children: any[]; className: string; type: "button";
                          colSpan={headerProperties.colSpan}
                          role={headerProperties.role}
                          title={headerProperties.title}
                        >
                          {column.render('Header')}
                        </div>
                      ))
                    );
                  })
                )}
              </div>
              {(initializing || loading) && rows.length !== 1 && (
                <div className="flex flex-col items-center h-full py-8 bg-gray-100 min-h-40">
                  <ComponentDataLoader className={`text-purple-700 ${rows.length > 0 ? 'absolute' : ''}`} />
                </div>
              )}

              {!initializing && (
                <SimpleBar
                  // @ts-expect-error TS(2769): No overload matches this call.
                  ref={scrollBarRef}
                  autoHide={false}
                  onScroll={x => {
                    // @ts-expect-error TS(2769): Expected 1 arguments, but got 2.
                    handleTableScroll(x, nextToken);
                  }}
                  style={{ maxHeight: tableHeight }}
                >
                  {!internalError && (
                    <div className={`border-gray-200 shadow grid grid-cols-${tableColSpan}`}>
                      {rows.map((row: any, rowIndex: any) => {
                        prepareRow(row);
                        return row.cells.map((cell: any) => {
                          const cellProperties = cell.getCellProps();
                          return (
                            cell.column.show && (
                              <div
                                className={twCascade(
                                  'px-2',
                                  'py-4',
                                  'whitespace-nowrap',
                                  'text-sm',
                                  'leading-5',
                                  'font-medium',
                                  'truncate',
                                  'text-gray-900',
                                  `col-span-${cell.column.colSpan}`,
                                  loading ? 'bg-gray-100 bg-opacity-25' : '',
                                  rowIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                                )}
                                role={cellProperties.role}
                                key={cellProperties.key}
                              >
                                {cell.render('Cell')}
                              </div>
                            )
                          );
                        });
                      })}
                    </div>
                  )}

                  {internalError && (
                    <div>
                      <div className="my-4 text-error-red">Error Loading Data</div>
                    </div>
                  )}

                  {!internalError && loadingMore && (
                    <div className="flex flex-col items-center pt-5">
                      <ComponentDataLoader className="text-purple-700" />
                    </div>
                  )}

                  {!internalError && !loading && rows.length === 0 && (
                    <div>
                      <div className="px-2 py-4 bg-gray-100 text-no-found-gray">No Data Found</div>
                    </div>
                  )}
                </SimpleBar>
              )}
            </>
          ) : (
            <p className="w-full my-16 text-center text-error-red bg-offwhite">{errorMessage}</p>
          )}
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default EmpathTable;

const filterExpressionShape = {
  field: PropTypes.string.isRequired,
  equals: PropTypes.arrayOf(PropTypes.string).isRequired,
  operator: PropTypes.string,
};

(filterExpressionShape as any).filter = PropTypes.shape(filterExpressionShape);

// const callToActionShape = {
//   label: PropTypes.string.isRequired,
//   action: PropTypes.func.isRequired,
// };
