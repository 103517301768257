import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// @ts-expect-error TS(2307): Cannot find module '../../assets/logo.svg' or its ... Remove this comment to see the full error message
import Logo from '../../assets/logo.svg';
// @ts-expect-error TS(2307): Cannot find module '../../assets/triangleOverlayCr... Remove this comment to see the full error message
import BG from '../../assets/triangleOverlayCropped.png';

import RequestPasswordResetForm from './forms/RequestPasswordResetForm';
import PasswordResetForm from './forms/PasswordResetForm';

export default function ResetPassword() {
  const useMountEffect = (func: any) => useEffect(func, []);
  const [passwordResetState, setPasswordResetState] = useState('');

  const passwordResetStates = {
    REQUESTING: 'requesting',
    VERIFYING_CODE: 'verifying_code',
  };

  useMountEffect(() => {
    if (window.location.hash === '#verifycode') {
      setPasswordResetState(passwordResetStates.VERIFYING_CODE);
    } else {
      setPasswordResetState(passwordResetStates.REQUESTING);
    }
  });

  const history = useHistory();

  const resetSent = () => {
    setPasswordResetState(passwordResetStates.VERIFYING_CODE);
  };

  const renderForm = () => {
    if (passwordResetState === passwordResetStates.REQUESTING) {
      return (
        <RequestPasswordResetForm
          onBackToLoginClicked={() => history.push('/login')}
          onResetPasswordClicked={resetSent}
        />
      );
    }

    if (passwordResetState === passwordResetStates.VERIFYING_CODE) {
      return <PasswordResetForm onBackToLoginClicked={() => history.push('/login')} />;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-800 via-purple-700 to-purple-500">
      <div
        className="w-screen min-h-screen justify-center content-center items-center flex flex-col"
        style={{ backgroundImage: `url(${BG})`, backgroundPosition: 'bottom center', backgroundRepeat: 'repeat-x' }}
      >
        <div className="md:w-3/12 w-4/5 flex flex-col">
          <img className="m-0 w-full" src={Logo} alt="Empath logo" />
          <div className="xl:w-3/4 xl:mx-auto">{renderForm()}</div>
        </div>
      </div>
    </div>
  );
}
