import React, { forwardRef } from 'react';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { Spinner } from '../animations';

type Props = {
  title: string;
  type?: 'button' | 'submit';
  buttonStyle?: 'solid' | 'outline' | 'text';
  onClick?: (...args: any[]) => any;
  loading?: boolean;
  loadingTitle?: string;
  disabled?: boolean;
  classNames?: string;
  textClassNames?: string;
  loaderSize?: number;
  gradient?: 'purple' | 'orange' | 'red' | 'none';
};

const Button = forwardRef<any, Props>(
  (
    {
      title,
      type = 'button',
      buttonStyle = 'outline',
      onClick,
      loading,
      loadingTitle,
      disabled,
      classNames,
      textClassNames,
      loaderSize,
      gradient = 'purple',
    },
    ref
  ) => {
    const getButtonGradientClass = () => {
      switch (gradient) {
        case 'purple':
          return 'btn-purple';
        case 'orange':
          return 'btn-orange';
        case 'red':
          return 'btn-red';
        default:
          return '';
      }
    };

    const getButtonStyle = () => {
      switch (buttonStyle) {
        case 'outline':
          return `btn-outline ${getButtonGradientClass()} text-base`;
        case 'solid':
          return 'rounded bg-white border-2 border-transparent w-full uppercase hover:bg-gray-200';
        case 'text':
          return 'border-2 border-transparent underline text-purple-500 hover:no-underline hover:text-purple-700';
        default:
          return '';
      }
    };

    return (
      <button
        ref={ref}
        className={twCascade(
          'h-6',
          'flex',
          'flex-row',
          'justify-center',
          'items-center',
          'focus:ring-0',
          'relative',
          getButtonStyle(),
          disabled || loading ? 'cursor-not-allowed' : 'cursor-pointer',
          classNames
        )}
        // rule disabled to allow dynamic button types
        // eslint-disable-next-line
      type={type}
        onClick={onClick}
        disabled={disabled || loading}
      >
        <span className={twCascade(buttonStyle !== 'text' ? 'mx-2' : '', textClassNames)}>
          {loading && loadingTitle ? loadingTitle : title}
        </span>

        {loading && (
          <div className="absolute right-0">
            <Spinner className={`h-${loaderSize || 4} w-${loaderSize || 4} mr-2`} />
          </div>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
