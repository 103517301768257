import React from 'react';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import './Loader.css';

type Props = {
  className?: string;
  spinnerClassName?: string;
};

export default function ComponentDataLoader({ className, spinnerClassName }: Props) {
  return (
    <div className={twCascade('loader', 'concentric', 'text-center', className)}>
      <svg className={twCascade('stroke-current', 'm-auto', 'w-24', 'h-24', spinnerClassName)}>
        <circle cx="50" cy="50" r="20" />
        <circle cx="50" cy="50" r="15" />
        <circle cx="50" cy="50" r="10" />
        <circle cx="50" cy="50" r="5" />
      </svg>
    </div>
  );
}
