import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { EmpathTable } from '../../components/tables';
import { searchDataSet, sortDataSet, executeAsync } from '../../shared/helper';
import { Button } from '../../components/buttons';
import { getMonitorStatusSite24x7 } from '../../graphql/queries';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import MonitorDetail from './MonitorDetail';

const Monitoring = (params: any) => {
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  const { modalMonitorId, modalStatusValue, modalDownReason } = params;

  const [loading, setLoading] = useState(true);
  const [tableError, setTableError] = useState('');
  const [columns, setCols] = useState([]);
  const [displayMonitorDetails, setDisplayMonitorDetails] = useState(false);
  const [monitorID, setMonitorID] = useState('');
  const [monitorStatus, setStatus] = useState('');
  const [downReason, setDownReason] = useState('');

  const [monitorStatusData, setmonitorStatusData] = useState([]);

  const useMountEffect = (func: any) => useEffect(func, []);

  const initializeComponent = async () => {
    try {
      setLoading(true);
      const monitorSatatusCallParams = { params: { customerId } };

      const monitorStatusAllData = await API.graphql(
        graphqlOperation(getMonitorStatusSite24x7, monitorSatatusCallParams)
      );

      if ((monitorStatusAllData as any)?.data?.getMonitorStatusSite24x7?.data) {
        const monitorData = JSON.parse((monitorStatusAllData as any)?.data?.getMonitorStatusSite24x7.data);

        setmonitorStatusData(monitorData.filter((x: any) => x.monitor_type !== 'AMAZON'));
      }

      if (modalMonitorId !== '') onMonitorClick(modalMonitorId, modalStatusValue, modalDownReason);

      setLoading(false);
    } catch (error) {
      console.error('Error load monitors: ', error);
      setLoading(false);
      setTableError('Error Loading Data');
    }

    doResize();
  };

  const onMonitorClick = (ID: any, statusValue: any, reason: any) => {
    setMonitorID(ID);
    setStatus(statusValue);
    setDisplayMonitorDetails(true);
    setDownReason(reason);
  };

  useMountEffect(() => {
    initializeComponent();
  });

  const loadData = useCallback(
    async (options: any) =>
      executeAsync(() => {
        const { filter, sort, search } = options;
        let items = monitorStatusData.map(m => m);

        if (filter) {
          items = items.filter(x => filter.equals.includes(x[filter.field]));
        }

        if (sort) {
          items = sortDataSet(items, sort);
        }

        if (search) {
          const searchOptions = {
            searchString: search,
            keys: ['name', 'monitor_type'],
          };

          items = searchDataSet(items, searchOptions);
        }

        return {
          items: items ?? [],
        };
      }),
    [monitorStatusData]
  );

  const doResize = () => {
    const { innerWidth: width } = window;
    const cols = [
      {
        header: 'Name',
        accessor: 'name',
        cell: function CompanyCell(cellInfo: any) {
          let statusColorClass = 'mr-1 text-emerald-600';

          if (cellInfo.row.original.status === 0) {
            statusColorClass = 'mr-1 text-red-500';
          } else if (cellInfo.row.original.status === 3) {
            statusColorClass = 'mr-1 text-yellow-300';
          } else if (cellInfo.row.original.status === 2) {
            statusColorClass = 'mr-1 text-yellow-500';
          }

          return (
            <div className="inline-flex space-x-2">
              <div className="flex-1">
                <FontAwesomeIcon
                  icon={faCircle}
                  className={statusColorClass}
                  style={{
                    // @ts-expect-error TS(2322): Type '{ '--ring-color': string; }' is not assignab... Remove this comment to see the full error message
                    '--ring-color': 'transparent',
                  }}
                />
              </div>

              <div className="flex-1">
                <Button
                  type="button"
                  buttonStyle="text"
                  title={`${cellInfo.cell.value}`}
                  onClick={() => {
                    onMonitorClick(
                      cellInfo.row.original.monitor_id,
                      cellInfo.row.original.status,
                      cellInfo.row.original.down_reason
                    );
                  }}
                />
              </div>
            </div>
          );
        },
      },
      {
        header: 'Type',
        accessor: 'monitor_type',
        show: width > 750,
      },
      {
        header: 'Status',
        accessor: 'status_description',
        cell: function CompanyCell(cellInfo: any) {
          if (cellInfo.cell.value === 'Monitor is Down') {
            return <div className="text-red-500 font-bold">Down</div>;
          }
          if (cellInfo.cell.value === 'Monitor is in Trouble') {
            return <div className="text-yellow-500 font-bold">Trouble</div>;
          }
          if (cellInfo.cell.value === 'Monitor is Critical') {
            return <div className="text-yellow-300 font-bold">Critical</div>;
          }

          return <div className="text-emerald-600 font-bold">Available</div>;
        },
      },
    ];

    // @ts-expect-error TS(2345): Argument of type '({ header: string; accessor: str... Remove this comment to see the full error message
    setCols(cols);
  };

  const onDetailsClick = () => {
    setDisplayMonitorDetails(false);
  };
  const Crumbs = () => {
    const crumbs = [
      {
        text: 'Resource Status',
        click: onDetailsClick,
        path: `/customers/${customerId}/mycloud`,
      },
      {
        text: `Details`,
      },
    ];

    return <Breadcrumbs crumbs={crumbs} />;
  };

  return (
    <div className="page-container">
      {!displayMonitorDetails && (
        <div>
          <EmpathTable
            title="Resource Monitoring Status"
            columns={columns}
            loadData={loadData}
            viewPortHeight={29}
            initializing={loading}
            errorMessage={tableError}
            containerClassName="mt-6"
          />
        </div>
      )}
      {displayMonitorDetails && (
        <>
          <Crumbs />

          <MonitorDetail monitorId={monitorID} statusValue={monitorStatus} downReason={downReason} />
        </>
      )}
    </div>
  );
};

export default Monitoring;
