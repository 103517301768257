import React, { useState } from 'react';

import { motion } from 'framer-motion';

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Nav from './Nav';

const LeftNav = () => {
  const [drawer, setDrawer] = useState(true);

  function handleSlidingDrawer() {
    setDrawer(!drawer);
  }

  return (
    <div className="hidden md:flex">
      <motion.div
        initial={{ width: '13rem' }}
        animate={{ width: drawer ? '13rem' : '3.5rem' }}
        transition={{ duration: 0.15, type: 'keyframes' }}
        className="flex flex-col flex-1 pb-4 overflow-y-auto bg-gradient-to-b from-purple-extra via purple-700 to-purple-500 overflow-x-hidden"
      >
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <Nav slidingDrawer={handleSlidingDrawer} drawer={drawer} />
      </motion.div>
    </div>
  );
};

export default LeftNav;
