// @ts-expect-error TS(7016): Could not find a declaration file for module 'is-n... Remove this comment to see the full error message
import isNumber from 'is-number';

export const ordinalSuffixOf = (num: any) => {
  const i = parseInt(num, 10);
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }

  return `${i}th`;
};

export const getBaseUrl = (customerId: any) => `/customers/${customerId}`;

export const executeAsync = async (func: any) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(func());
    });
  });

/**
 * Given and array of objects and an array of sorting descriptions, will return a sort array of objects.
 * Determines if we are working with numbers, strings, or null values.
 * @param {dataSet} the dataset that will be sorted
 * @param {sort} array of sorting settings [{item:'propertyName', direction:'desc'}]
 */
export const sortDataSet = (dataSet: any, sort: any) => {
  for (const item of sort) {
    dataSet.sort((a: any, b: any) => {
      if (item.direction === 'desc') {
        // First is null
        if (!a[item.field]) {
          return 1;
        }
        // Second is null
        if (!b[item.field]) {
          return -1;
        }
        // The field that is currently an array is only ever going to hold a single value, so this is OK for now
        if (Array.isArray(a[item.field]) && Array.isArray(b[item.field])) {
          // Both Numbers
          if (isNumber(a[item.field][0]) && isNumber(b[item.field][0])) {
            return parseFloat(a[item.field].join('')) > parseFloat(b[item.field].join('')) ? -1 : 1;
          }
          // Both Strings
          if (!isNumber(a[item.field][0]) && !isNumber(b[item.field][0])) {
            return a[item.field].join('').toLowerCase() > b[item.field].join('').toLowerCase() ? -1 : 1;
          }
        } else {
          // Both Numbers
          if (isNumber(a[item.field]) && isNumber(b[item.field])) {
            return parseFloat(a[item.field]) > parseFloat(b[item.field]) ? -1 : 1;
          }
          // Both Strings
          if (!isNumber(a[item.field]) && !isNumber(b[item.field])) {
            return a[item.field].toLowerCase() > b[item.field].toLowerCase() ? -1 : 1;
          }
        }

        if (!isNumber(a[item.field])) {
          return -1;
        }

        return 1;
      }

      // First is null
      if (!a[item.field]) {
        return -1;
      }
      // Second is null
      if (!b[item.field]) {
        return 1;
      }
      // The field that is currently an array is only ever going to hold a single value, so this is OK for now
      if (Array.isArray(a[item.field]) && Array.isArray(b[item.field])) {
        // Both Numbers
        if (isNumber(a[item.field][0]) && isNumber(b[item.field][0])) {
          return parseFloat(a[item.field].join('')) < parseFloat(b[item.field].join('')) ? -1 : 1;
        }
        // Both Strings
        if (!isNumber(a[item.field][0]) && !isNumber(b[item.field][0])) {
          return a[item.field].join('').toLowerCase() < b[item.field].join('').toLowerCase() ? -1 : 1;
        }
      } else {
        // Both Numbers
        if (isNumber(a[item.field]) && isNumber(b[item.field])) {
          return parseFloat(a[item.field]) < parseFloat(b[item.field]) ? -1 : 1;
        }
        // Both Strings
        if (!isNumber(a[item.field]) && !isNumber(b[item.field])) {
          return a[item.field].toLowerCase() < b[item.field].toLowerCase() ? -1 : 1;
        }
      }

      if (!isNumber(a[item.field])) {
        return 1;
      }

      return -1;
    });
  }

  return dataSet;
};

export const searchDataSet = (dataSet: any, search: any) =>
  dataSet.filter((item: any) =>
    Object.keys(item).some(
      key =>
        search.keys.includes(key) &&
        item[key] &&
        item[key].toString().toLowerCase().includes(search.searchString.toLowerCase())
    )
  );

// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
export const capitalizeFirstLetter = (string: any) => string.charAt(0).toUpperCase() + string.slice(1);
