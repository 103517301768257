import React, { Component } from 'react';

type Props = {
  fallbackComponent?: ({ classNames }: { classNames: string }) => JSX.Element;
  fallbackClassNames?: string;
  location?: any;
  children: JSX.Element;
};

type State = any;

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { location: propsLocation } = this.props;
    const { location: prevPropsLocation } = prevProps;

    if (prevPropsLocation && propsLocation) {
      if (prevPropsLocation.pathname !== propsLocation.pathname) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ hasError: false });
      }
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { fallbackComponent, children, fallbackClassNames } = this.props;

    const { hasError } = this.state;
    const FallbackComponent = fallbackComponent;

    if (hasError && !fallbackComponent) {
      return <h1>Error</h1>;
    }

    if (hasError) {
      // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
      return <FallbackComponent classNames={fallbackClassNames} />;
    }

    return children;
  }
}

export default ErrorBoundary;
