import React from 'react';

// @ts-expect-error TS(2307): Cannot find module '../../assets/logo.svg' or its ... Remove this comment to see the full error message
import Logo from '../../assets/logo.svg';
// @ts-expect-error TS(2307): Cannot find module '../../assets/triangleOverlayCr... Remove this comment to see the full error message
import BG from '../../assets/triangleOverlayCropped.png';

type Props = {
  errorMessage?: string;
};

const SplashLoader = ({ errorMessage }: Props) => (
  <div className="min-h-screen bg-gradient-to-b from-purple-800 via-purple-700 to-purple-500">
    <div
      className="w-screen min-h-screen justify-center content-center items-center flex flex-col"
      style={{ backgroundImage: `url(${BG})`, backgroundPosition: 'bottom center', backgroundRepeat: 'repeat-x' }}
    >
      <div className="md:w-3/12 w-4/5 flex flex-col">
        <img className="m-0 w-full" src={Logo} alt="Empath logo" />
      </div>

      {errorMessage ? <p className="text-red-700 mt-12">{errorMessage}</p> : null}
    </div>
  </div>
);

export default SplashLoader;
