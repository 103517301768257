import React, { forwardRef } from 'react';
// import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactSelect, { createFilter, components } from 'react-select';

type Props = {
  options?: any[]; // TODO: PropTypes.shape(optionsShape)
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isRtl?: boolean;
  isMulti?: boolean;
  styles?: any;
  size?: string;
  filterOption?: any | ((...args: any[]) => any);
};

// eslint-disable-next-line react/display-name
const Select = forwardRef<any, Props>(
  (
    {
      options,
      placeholder,
      disabled,
      isLoading,
      isClearable,
      isSearchable,
      isRtl,
      isMulti,
      filterOption,
      styles,
      size,
      ...inputProps
    },
    ref
  ) => {
    const b300 = '#E6E6E6';
    const purple700 = '#200D53';
    const optionTextPrimary = 'black';
    const optionBackgroundPrimary = 'white';
    const optionBackgroundSecondary = '#d2d6dc';
    const textSmFontSize = '0.875rem';
    const textSmLineHeight = '1.25rem';
    const smallContainerSize = 25;

    const DropdownIndicator = (props: any) =>
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props} className>
          <FontAwesomeIcon icon={faCaretDown} />
        </components.DropdownIndicator>
      );

    const customStyles = {
      // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
      control: (provided, state) => ({
        ...provided,
        borderWidth: '1px',
        boxShadow: 'none',
        borderColor: state.isFocused ? purple700 : b300,

        // Overwrites default hover styles
        // default border color
        border: '1px solid lightgray',

        height: size === 'small' ? smallContainerSize : null,
        minHeight: size === 'small' ? smallContainerSize : null,
        fontSize: size === 'small' ? textSmFontSize : null,
        lineHeight: textSmLineHeight,
        cursor: 'pointer',
      }),
      input: (provided: any) => ({
        ...provided,
        padding: '0px 0px',
        fontSize: textSmFontSize,
        lineHeight: textSmLineHeight,

        'input:focus': {
          boxShadow: 'none',
        },
      }),
      singleValue: (provided: any) => ({
        ...provided,
        padding: '0px 0px',
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        padding: '0px 6px',
      }),
      // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
      option: (provided, state) => ({
        ...provided,
        color: optionTextPrimary,
        backgroundColor: state.isSelected || state.isFocused ? optionBackgroundSecondary : optionBackgroundPrimary,
        cursor: 'pointer',
        fontSize: size === 'small' ? textSmFontSize : null,
      }),
      dropdownIndicator: (provided: any, state: any) =>
        disabled
          ? { display: 'none' }
          : {
              ...provided,
              color: purple700,
              '&:hover': state.isFocused ? purple700 : b300,
            },
      indicatorSeparator: (provided: any) =>
        disabled ? null : { ...provided, display: size === 'small' ? 'none' : null },
      clearIndicator: (provided: any) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
      }),
      menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 9999,
      }),
      ...styles,
    };

    const getFilter = () => {
      if (typeof filterOption === 'object') {
        const { ignoreCase = true, ignoreAccents = true, trim = true, matchFromStart = true } = filterOption || {};
        const filterConfig = {
          ignoreCase,
          ignoreAccents,
          trim,
          matchFrom: matchFromStart ? 'start' : 'any',
        };

        return createFilter(filterConfig);
      }
      if (typeof filterOption === 'function') {
        return filterOption;
      }

      return filterOption;
    };

    return (
      <ReactSelect
        ref={ref}
        {...inputProps}
        options={options}
        isClearable={isClearable}
        isLoading={isLoading}
        isRtl={isRtl}
        isSearchable={isSearchable}
        isMulti={isMulti}
        styles={customStyles}
        isDisabled={disabled}
        placeholder={placeholder}
        filterOption={getFilter()}
        menuPortalTarget={document.body}
        components={{ DropdownIndicator }}
        theme={(theme: any) => ({
          ...theme,
          borderRadius: 4,

          spacing: {
            ...theme.spacing,
            controlHeight: 30,
            baseUnit: 2,
          },

          colors: {
            ...theme.colors,
            primary: '#200D53', // Selected dropdown options, highlighter border
            neutral80: '#200D53', // Input Text Value #200D53
            neutral30: '#E6E6E6', // Onhover border
            neutral20: '#E6E6E6', // border, x, separator, and arrow
          },
        })}
      />
    );
  }
);

export default Select;

// const optionsShape = {
//   label: PropTypes.string.isRequired,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
// };

// const filterOptionShape = {
//   ignoreCase: PropTypes.bool,
//   ignoreAccents: PropTypes.bool,
//   trim: PropTypes.bool,
//   matchFromStart: PropTypes.bool,
// };
