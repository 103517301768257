import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import noData from 'highcharts/modules/no-data-to-display';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'byte... Remove this comment to see the full error message
import bytes from 'bytes';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';
import Boost from 'highcharts/modules/boost';
import * as moment from 'moment-timezone';
import { useUser } from '../../context/UserContext';
import { getVerifiedTimeZone, formatDateShortAndTime } from '../../shared/dateHelpers';

// @ts-expect-error TS(2322): Type 'typeof import("/Users/effectualuser/Document... Remove this comment to see the full error message
window.moment = moment;

Exporting(Highcharts);
Boost(Highcharts);

noData(Highcharts);

export const yAxisFormat = {
  money: (value: any) =>
    Math.abs(value) > 999
      ? // @ts-expect-error TS(2363): The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
        `${Math.sign(value) * (Math.abs(value) / 1000).toFixed(1)}k`
      : Math.sign(value) * Math.abs(value),
  percent: (value: any) => `${value}%`,
  bytes: (value: any) => bytes(value),
  number: (value: any) => value,
};

type Props = {
  chartOptions?: {
    title?: {
      text?: string;
      style?: {
        fontSize?: string;
        margin?: number;
      };
      align?: string;
      x?: number;
    };
    chart?: {
      type?: string;
      height?: string | number;
      marginLeft?: number;
      marginRight?: number;
    };
    yAxis?: {
      title?: {
        text?: string;
      };
      labels?: {
        formatter?: (...args: any[]) => any;
      };
    };
    xAxis?: {
      categories?: string[];
    };
    tooltip?: {
      formatter?: (...args: any[]) => any;
    };
    legend?: {
      align?: string;
      verticalAlign?: string;
      layout?: string;
      itemMarginTop?: number;
      itemMarginBottom?: number;
      x?: number;
    };
    plotOptions?: {
      pie?: {
        dataLabels?: {
          enabled?: boolean;
        };
      };
    };
    series?: {
      name?: string;
      data?: any[];
    }[];
    cSVFileName: string;
    errorLoadingData?: boolean;
  };
  startDate?: any;
  endDate?: any;
};

const LineChart = ({ chartOptions, startDate, endDate }: Props) => {
  const { series, errorLoadingData } = chartOptions || {};
  // @ts-expect-error TS(2339): Property 'activeUser' does not exist on type 'unknown'.
  const { activeUser } = useUser();

  const useMountEffect = (func: any) => useEffect(func, []);
  const [dateString, setDateString] = useState('');

  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
    time: {
      timezone: getVerifiedTimeZone(activeUser.timeZone),
    },
  });

  useMountEffect(() => {
    if (!startDate) {
      return;
    }

    if (errorLoadingData || (series && series.length === 0)) {
      setDateString('');

      return;
    }

    const start = DateTime.fromJSDate(startDate);
    const end = endDate ? DateTime.fromJSDate(endDate) : DateTime.local();

    if (start.hasSame(end, 'day')) {
      setDateString(start.toLocaleString());
    } else {
      setDateString(`${start.toLocaleString()} - ${end.toLocaleString()}`);
    }
  });

  const defaultOptions = {
    boost: {
      useGPUTranslations: true,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: dateString,
      style: {
        fontSize: '16px',
      },
      align: 'left',
      x: 20,
    },
    chart: {
      type: 'line',
      height: '100%',
      marginLeft: 80,
      marginRight: 50,
    },
    yAxis: {
      title: {
        text: '',
      },
      max: null,
    },
    lang: {
      noData: `${errorLoadingData ? 'Error Loading Data' : 'No Data Found'}`,
    },
    exporting: {
      filename: chartOptions?.cSVFileName?.substring(0, chartOptions.cSVFileName.length - 4),
      buttons: {
        contextButton: {
          symbol: 'download',
          symbolFill: '#463490',
          symbolStroke: '#463490',
          menuItems: [
            'downloadJPEG',
            'downloadPDF',
            {
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              text: Highcharts.getOptions().lang.downloadCSV || 'Download CSV',
              onclick() {
                const json: any = [];

                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                series.forEach(val => {
                  const value = val;

                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  value.data.forEach(metrics => {
                    const { name } = value;
                    const dateTime = formatDateShortAndTime(DateTime.fromMillis(metrics.x), activeUser.timeZone);
                    // @ts-expect-error TS(7006): Parameter 'x' implicitly has an 'any' type.
                    const isExist = json.filter(x => x.Date_Time === dateTime);

                    if (isExist.length > 0) {
                      for (let index = 0; index < json.length; index += 1) {
                        if (json[index].Date_Time === dateTime) {
                          // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
                          json[index][name] = metrics.y;
                          break;
                        }
                      }
                    } else {
                      const metricData = {
                        Date_Time: dateTime,
                        // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
                        [name]: metrics.y,
                      };

                      json.push(metricData);
                    }
                  });
                });
                let csv = '';

                if (json.length > 0) {
                  const fields = Object.keys(json[0]);
                  const replacer = function (key: any, value: any) {
                    return value === null ? '' : value;
                  };

                  // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
                  csv = json.map(row => fields.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

                  (csv as any).unshift(fields.join(',')); // add header column
                  csv = (csv as any).join('\r\n');
                }

                const element = document.createElement('a');
                const file = new Blob([csv], { type: 'text/plain' });

                element.href = URL.createObjectURL(file);
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                element.download = chartOptions.cSVFileName;
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
              },
            },
          ],
        },
      },
    },
    noData: {
      style: {
        fontFamily: 'Karla, sans-serif',
        fontWeight: 'normal',
        fontSize: '1rem',
        color: `${errorLoadingData ? '#D00505' : '#696279'}`,
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={{ ...defaultOptions, ...chartOptions }} />;
};

export default LineChart;
