import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

// @ts-expect-error TS(2307): Cannot find module '../../assets/placeholderUserIc... Remove this comment to see the full error message
import { ReactComponent as PlaceholderUserIcon } from '../../assets/placeholderUserIcon.svg';
import { Button } from '../buttons';
import Modal from '../modal/Modal';
import { useUser } from '../../context/UserContext';
import { getBaseUrl } from '../../shared/helper';
import { maskPhone } from '../inputs/utils';

type Props = {
  memberInfo: any;
  classNames?: string;
  removeMember?: (...args: any[]) => any;
  allowedToEdit?: (...args: any[]) => any;
  customerId: string;
};

const TeamMemberCard = ({ memberInfo, classNames, removeMember, allowedToEdit, customerId }: Props) => {
  const { fullName, role, email, phoneNumber, isPrimary, profilePhotoUrl, teamMemberId } = memberInfo;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  // @ts-expect-error TS(2339): Property 'isEffectualUser' does not exist on type 'unknown'.
  const { isEffectualUser } = useUser();

  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      if (profilePhotoUrl) {
        // Storage.get returns string even when arg is null
        const photoUrl = await Storage.get(profilePhotoUrl);

        // @ts-expect-error TS(2345): Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
        setProfilePhoto(<img className="w-full object-contain" src={photoUrl} alt="Profile" />);

        return;
      }

      // @ts-expect-error TS(2345): Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
      setProfilePhoto(<PlaceholderUserIcon />);
    };

    init();
  }, [profilePhotoUrl]);

  const cancel = () => {
    setShowDeleteModal(false);
  };

  const remove = () => {
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    removeMember(teamMemberId);
    setShowDeleteModal(false);
  };

  const goToEdit = () => {
    if (isEffectualUser) {
      history.push(`/empath${getBaseUrl(customerId)}/my-team/team-member/${teamMemberId}`);
    }
  };

  return (
    <div className={twCascade('flex', 'flex-col', 'bg-offwhite', 'shadow', 'rounded-lg', 'relative', classNames)}>
      <div>
        <div className={`flex flex-row-reverse ${isPrimary ? '' : 'invisible '}`}>
          <FontAwesomeIcon icon={faStar} className="text-xl text-orange" fixedWidth />
        </div>

        <div className="flex pb-6 pr-2">
          <div className="flex-none w-32 pl-4 mr-4 flex-1">{profilePhoto}</div>
          <div className="flex flex-col ml-4 relative min-w-0">
            <Button
              classNames={`${
                // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                isEffectualUser && allowedToEdit() ? '' : 'cursor-text'
              } pr-2 no-underline justify-start border-0 font-bold`}
              buttonStyle="text"
              textClassNames="text-sm sm:text-lg md:text-lg  text-left leading-none"
              title={fullName}
              onClick={goToEdit}
              // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              disabled={!allowedToEdit()}
            />
            <p className="text-sm text-b-900 mt-2">{role}</p>
            <div className="truncate max-w-full min-w-0">
              <a
                href={`mailto:${email}`}
                className="text-sm underline text-purple-500 hover:no-underline hover:text-purple-700"
              >
                {email}
              </a>
            </div>
            {phoneNumber ? (
              <p className="text-sm text-b-900">{maskPhone(phoneNumber)} </p>
            ) : (
              <p className="text-sm">&nbsp;</p>
            )}
          </div>
        </div>
        {/* @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
        {isEffectualUser && allowedToEdit() ? (
          <div className="flex flex-row-reverse mt-1 mr-1 absolute bottom-0 right-0 pb-2 pr-2">
            <Button
              title="Remove Team Member"
              classNames="border-1 border-banner-red text-banner-red p-0"
              textClassNames="text-xs"
              onClick={() => setShowDeleteModal(true)}
            />
          </div>
        ) : (
          <div className="pb-4" />
        )}
      </div>
      <Modal
        doShow={showDeleteModal}
        cancel={cancel}
        showXButton
        action={remove}
        title={
          <span>
            Remove <span className="text-orange">Team Member</span>
          </span>
        }
      >
        <p>{fullName} will be removed from My Team</p>
      </Modal>
    </div>
  );
};

export default TeamMemberCard;
