import React, { useRef, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { IncidentTable } from '../../components/tables';
import Tabs from '../../components/tabs/Tabs';
import PageHeader from '../../components/header/PageHeader';
import CloudSupportEscalationPlanLink from '../../components/cloudSupportEscalationPlanLink/CloudSupportEscalationPlanLink';
import { getBaseUrl } from '../../shared/helper';
import ScheduleMaintenance from '../errors/ScheduleMaintenance';
import { useSite } from '../../context/SiteContext';
import { servicesGroups } from '../../shared/groupsHelper';

const Incidents = () => {
  // @ts-expect-error TS(2339): Property 'servicesStatus' does not exist on type 'unknown'.
  const { servicesStatus } = useSite();
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  const history = useHistory();
  const table = useRef(null);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    const { isUnderMaintenance: serviceNowUnderMaintenance, serviceName } = servicesStatus;

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (serviceNowUnderMaintenance && serviceName === servicesGroups[serviceName].name) {
      setIsUnderMaintenance(true);
    }
  }, [servicesStatus]);

  const setActiveTab = (title: any) => {
    switch (title) {
      case 'Change Requests':
        history.push(`${getBaseUrl(customerId)}/support/change-requests`);
        break;
      case 'Support Requests':
        history.push(`${getBaseUrl(customerId)}/support/cases`);
        break;

      default:
        break;
    }
  };

  const doIncidentClick = (data: any) => {
    history.push(`/customers/${data.customerId}/support/incidents/${data.id}`);
  };

  if (isUnderMaintenance) {
    return <ScheduleMaintenance />;
  }

  return (
    <div className="page-container">
      {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
      <PageHeader title="Support" buttonLabel="New Support Request" onSubmitted={() => table.current.reloadData()} />

      <Tabs
        tabs={[{ title: 'Support Requests' }, { title: 'Incidents', active: true }, { title: 'Change Requests' }]}
        tabSelected={setActiveTab}
      />

      <IncidentTable ref={table} customerId={customerId} noLimit onIncidentClick={doIncidentClick} />

      <CloudSupportEscalationPlanLink />
    </div>
  );
};

export default Incidents;
