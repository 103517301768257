import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';

import { TextInput } from '../../../components/inputs';
import { Button } from '../../../components/buttons';

type Props = {
  setUser: (...args: any[]) => any;
  showPasswordReset: (...args: any[]) => any;
};

const LoginForm = ({ setUser, showPasswordReset }: Props) => {
  const [loggingIn, setLoggingIn] = useState(false);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, setError, formState, reset, clearErrors, control } = useForm({
    defaultValues: { email: '', password: '' },
  });
  const [usePassword, setUsePassword] = useState(false);
  const [samlError, setSamlError] = useState('');

  const { dirtyFields } = formState;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has('error_description')) {
      // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      setSamlError(params.get('error_description'));
    }
  }, []);

  useEffect(() => {
    if (usePassword) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      control.fieldsRef.current.password.ref.focus();
    } else {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      control.fieldsRef.current.email.ref.focus();
    }
  }, [control, usePassword]);

  const doSubmit = async (data: any) => {
    clearErrors('password');
    setLoggingIn(true);
    if (dirtyFields.email && data.email && !errors.email) {
      try {
        const res = await fetch(`https://${process.env.REACT_APP_SSO_CHECK_URL}/users/${data.email}/sso`);

        const json = await res.json();

        if (res.status !== 200) {
          throw new Error(json.message);
        }

        if (json.provider) {
          setUsePassword(false);
          Auth.federatedSignIn({ customProvider: json.provider });
        } else {
          reset({ email: data.email });
          setUsePassword(true);
          clearErrors('password');
        }
      } catch (err) {
        console.error(err);
      }

      setLoggingIn(false);
    }

    if (usePassword && !data.password) {
      setError('password', { type: 'manual', message: 'Password is required' });
      setLoggingIn(false);

      return;
    }

    if (usePassword) {
      doPasswordLogin(data);
    }
  };

  const doPasswordLogin = async (data: any) => {
    setLoggingIn(true);

    try {
      const user = await Auth.signIn(data.email.trim(), data.password);

      setLoggingIn(false);

      if (!user.attributes?.['custom:skip_MFA'] && user.preferredMFA === 'NOMFA') {
        user.challengeName = 'MFA_SETUP';
      }

      setUser(user);

      if (!user.challengeName) {
        window.location.href = '/';
      }
    } catch (err) {
      if ((err as any).code === 'NotAuthorizedException') {
        setError('password', { type: 'manual', message: 'Incorrect username or password' });
        setError('email', { type: 'manual', message: ' ' });
      }

      setLoggingIn(false);
    }
  };

  const getPasswordClassName = () => (usePassword ? 'block' : 'hidden');

  return (
    <div className="xl:w-3/4 xl:mx-auto">
      <div className="my-8">
        <form onSubmit={handleSubmit(doSubmit)}>
          <TextInput
            control={control}
            label="Email Address"
            field="email"
            rules={{ required: true }}
            purple
            autoFocus={!usePassword}
            formErrors={errors}
          />

          <TextInput
            control={control}
            containerClassName={getPasswordClassName()}
            label="Password"
            field="password"
            type="password"
            purple
            formErrors={errors}
            autoFocus={usePassword}
          />

          {samlError && <p className="mb-4 text-sm text-red-500 min-h-5">{samlError}</p>}

          <Button
            title="Log In"
            buttonStyle="solid"
            type="submit"
            classNames="mt-5 h-8 text-purple-700 hover:bg-gray-200"
            loading={loggingIn}
            loadingTitle="Logging In"
            disabled={loggingIn}
          />
        </form>

        <Button
          title="RESET PASSWORD"
          onClick={showPasswordReset}
          type="button"
          buttonStyle="text"
          classNames="mt-5 text-white text-left hover:text-gray-200"
        />
      </div>
    </div>
  );
};

export default LoginForm;
