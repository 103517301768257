import React from 'react';
// @ts-expect-error TS(2307): Cannot find module '../../assets/unavailableGraphi... Remove this comment to see the full error message
import { ReactComponent as UnavailableGraphic } from '../../assets/unavailableGraphic.svg';

const Unavailable = () => (
  <div className="flex flex-col bg-b-200 h-screen">
    <div className="mt-40">
      <p className="text-red-500 text-center text-xl px-3 lg:text-3xl uppercase">Empath is currently unavailable</p>
    </div>
    <div className="mt-3 px-3">
      <p className="text-purple-500 text-center lg:text-xl">
        We are aware of this issue and are working on a solution.
      </p>
    </div>
    <div className="mt-5 flex justify-center">
      <UnavailableGraphic />
    </div>
  </div>
);

export default Unavailable;
