import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { API, graphqlOperation } from 'aws-amplify';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';

import { searchDataSet, sortDataSet, executeAsync } from '../../shared/helper';
import { Button } from '../buttons';
import { useUser } from '../../context/UserContext';
import { listAllTickets } from '../../graphql/queries';
import EmpathTable from './EmpathTableWithErrorBoundry';
import { exportCSVReport } from './tableActions';
import Select from '../inputs/Select';
import { formatDateShortAndTime } from '../../shared/dateHelpers';
import Modal from '../modal/Modal';
import { createComment, generateApproveDenyComment } from '../../pages/support/helpers';
import { successToast } from '../../shared/toast';
import { customerIds, reports, effectualGroups } from '../../shared/groupsHelper';

type Props = {
  customerId?: string;
  viewPortHeight?: number;
  onDataLoaded?: (...args: any[]) => any;
  noLimit?: boolean;
  onChangeRequestClick: (...args: any[]) => any;
  showApproveColumn?: boolean;
  tableMessage?: string;
};

const ChangeRequestTable = forwardRef<any, Props>(
  (
    {
      customerId,
      viewPortHeight,
      onDataLoaded,
      noLimit = false,
      onChangeRequestClick,
      showApproveColumn = false,
      tableMessage = '',
    },
    ref
  ) => {
    // @ts-expect-error TS(2339): Property 'userInRoles' does not exist on type 'unknown'.
    const { user, impersonation, activeUser, isEffectualUser, userInRoles } = useUser();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [filters, setFilters] = useState();
    const [showFilters, setShowFilters] = useState(
      Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 640
    );
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [ticketToApprove, setTicketToApprove] = useState({});
    const [approvingTicket, setApprovingTicket] = useState(false);
    const [listAllTicketsCSVParams, setListAllTicketsCSVParams] = useState({});
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    const statusOptions = [
      { label: 'Open Change Requests', value: 'open' },
      { label: 'Closed Change Requests', value: 'closed' },
    ];
    const ownerOptions = [
      { label: 'All Change Requests', value: 'all' },
      { label: 'My Change Requests', value: 'my' },
    ];

    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
    const [selectedOwner, setSelectedOwner] = useState(ownerOptions[0]);

    const onFiltersClick = () => {
      updateFilters(selectedStatus.value, selectedOwner.value);
    };

    const updateFilters = useCallback(
      (status: any, owner: any) => {
        let filter;

        switch (status) {
          case 'open':
            filter = {
              // @ts-expect-error TS(2345): Argument of type '{ field: string; equals: string[... Remove this comment to see the full error message
              field: 'state',
              equals: ['-5', '-4', '-3', '-2', '-1', '0'],
            };
            break;
          case 'closed':
            filter = {
              field: 'state',
              equals: ['3', '4'],
            };
            break;
          default:
            break;
        }
        if (owner === 'my') {
          (filter as any).operator = 'AND';
          (filter as any).filter = {
            field: 'openedByEmail',
            equals: [(impersonation && impersonation.user && impersonation.user.email) || user.email],
            operator: 'OR',
            filter: {
              field: 'assignedTo',
              equals: [(impersonation && impersonation.user && impersonation.user.email) || user.email],
            },
          };
        }

        setFilters(filter);
      },
      [impersonation, user]
    );

    const loadData = useCallback(
      async (options: any) =>
        executeAsync(() => {
          const { sort, search } = options;

          if ((data as any).errors) {
            return data;
          }
          let items = data.map(m => m);

          if (sort) {
            items = sortDataSet(items, sort);
          }

          if (search) {
            const searchOptions = {
              searchString: search,
              keys: ['number', 'openedAt', 'updatedOn', 'state', 'openedBy', 'subject', 'startDate'],
            };

            items = searchDataSet(items, searchOptions);
          }

          setLoading(false);

          if (onDataLoaded) {
            onDataLoaded();
          }

          return {
            items: items ?? [],
          };
        }),
      [data, onDataLoaded]
    );

    const onCustomerClick = useCallback(
      (id: any) => {
        history.push(`/customers/${id}`);
      },
      [history]
    );

    useEffect(() => {
      const allowApproveClick = () => {
        if (
          isEffectualUser &&
          userInRoles([effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName]) &&
          activeUser.canApproveChangeRequest
        ) {
          return true;
        }
        if (!isEffectualUser && activeUser.canApproveChangeRequest) {
          return true;
        }
        return false;
      };
      const fetchData = async () => {
        try {
          if (tableMessage) {
            setError(tableMessage);
            setLoading(false);
            return;
          }
          setLoading(true);
          setError('');
          const formatDate = (cellInfo: any) =>
            cellInfo.cell && cellInfo.cell.value
              ? formatDateShortAndTime(cellInfo.cell.value, activeUser.timeZone)
              : '';
          const baseColumns = [
            {
              header: 'Number',
              accessor: 'number',
              cell: function NumberCell(cellInfo: any) {
                return (
                  <Button
                    type="button"
                    buttonStyle="text"
                    title={`${cellInfo.cell.value}`}
                    onClick={() => {
                      onChangeRequestClick({
                        id: cellInfo.row.original.id,
                        customerId: cellInfo.row.original.customerId,
                      });
                    }}
                  />
                );
              },
            },
            { header: 'Opened', accessor: 'openedAt', cell: formatDate },
            { header: 'Updated', accessor: 'updatedOn', cell: formatDate },
            {
              header: 'Status',
              accessor: 'state',
              cell: function StatusCell(cellInfo: any) {
                let className = '';
                switch (cellInfo.value) {
                  case 'Scheduled':
                  case 'Implement':
                  case 'Review':
                    className = 'text-emerald-600';
                    break;
                  case 'Authorize':
                    className = 'text-red-700';
                    break;
                  case 'New':
                  case 'Assess':
                    className = 'text-yellow-600';
                    break;
                  default:
                    className = '';
                    break;
                }
                return <div className={className}>{cellInfo.value}</div>;
              },
            },
            { header: 'Opened By', accessor: 'openedBy' },
            { header: 'Subject', accessor: 'subject', colSpan: 2 },
            { header: 'Scheduled Date', accessor: 'startDate', cell: formatDate },
          ];
          if (showApproveColumn && activeUser.canApproveChangeRequest) {
            baseColumns.push({
              header: 'Approve',
              accessor: 'id',
              cell: function ApproveCell(cellInfo) {
                return cellInfo.row.original.state === 'Authorize' ? (
                  <Button
                    type="button"
                    buttonStyle="text"
                    title="Approve?"
                    disabled={!allowApproveClick()}
                    onClick={() => initApproveModal(cellInfo.row.original)}
                  />
                ) : (
                  ''
                );
              },
            });
          }
          if (!customerId) {
            baseColumns.unshift({
              header: 'Customer',
              accessor: 'customer',
              cell: function NumberCell(cellInfo) {
                return (
                  <Button
                    type="button"
                    buttonStyle="text"
                    title={`${cellInfo.cell.value}`}
                    onClick={() => onCustomerClick(cellInfo.row.original.customerId)}
                  />
                );
              },
            });
          }
          // @ts-expect-error TS(2345): Argument of type '({ header: string; accessor: str... Remove this comment to see the full error message
          setColumns(baseColumns);
          const filter = filters || {
            field: 'state',
            equals: ['-5', '-4', '-3', '-2', '-1', '0'],
          };
          const query = {
            filter,
            sort: [{ field: 'updatedOn', direction: 'desc' }],
            type: 'ChangeRequest',
          };
          if (!noLimit) {
            (query as any).limit = 5;
          }
          const params = {
            query: {
              query: btoa(JSON.stringify(query)),
            },
          };
          if (customerId) {
            (params.query as any).customerId = customerId;
          }
          setListAllTicketsCSVParams(params);
          const result = await API.graphql(graphqlOperation(listAllTickets, params));
          if ((result as any).errors) {
            // @ts-expect-error TS(2345): Argument of type 'GraphQLResult<any> | Observable<... Remove this comment to see the full error message
            setData(result);
          } else {
            const resultItems = (result as any)?.data?.listAllTickets?.items;
            setData(resultItems ?? []);
          }
          setLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
          console.error('Error loading data: ', error);
          setError('Error Loading Data');
          setLoading(false);
        }
      };
      fetchData();
    }, [
      customerId,
      onCustomerClick,
      activeUser.timeZone,
      noLimit,
      filters,
      onChangeRequestClick,
      activeUser.canApproveChangeRequest,
      showApproveColumn,
      tableMessage,
      isEffectualUser,
      userInRoles,
    ]);

    useImperativeHandle(ref, () => ({
      reloadData() {
        onFiltersClick();
      },
    }));

    const initApproveModal = (ticket: any) => {
      const ticketdata = JSON.parse(ticket.data);

      ticket.description = ticketdata.description;
      ticket.test_plan = ticketdata.test_plan;
      ticket.risk_impact_analysis = ticketdata.risk_impact_analysis;
      ticket.justification = ticketdata.justification;
      ticket.implementation_plan = ticketdata.implementation_plan;
      ticket.backout_plan = ticketdata.backout_plan;
      ticket.u_expected_result = ticketdata.u_expected_result;
      setShowApproveModal(true);
      setTicketToApprove(ticket);
    };

    const approveActionClicked = async () => {
      setApprovingTicket(true);
      const comment = generateApproveDenyComment(impersonation, user, 'yes');

      await createComment((ticketToApprove as any).customerId, (ticketToApprove as any).id, comment);

      successToast('Change request approval submitted.');

      setShowApproveModal(false);
      setTicketToApprove({});
      setApprovingTicket(false);
    };

    const approveCancelClicked = () => {
      setShowApproveModal(false);
    };

    const optionsDropdown = [
      {
        text: 'Export CSV',
        action: async () => {
          const cId = isEffectualUser ? customerIds.empath : customerId;
          const reportName = reports.ticketsReport;

          setIsOptionsLoading(true);
          await exportCSVReport(activeUser, cId, reportName, listAllTicketsCSVParams);
          setIsOptionsLoading(false);
        },
      },
    ];

    return (
      <>
        <Modal
          title={
            <span>
              Approve <span className="text-orange">Change Request</span>
            </span>
          }
          cancelText="No"
          actionText="Yes"
          action={approveActionClicked}
          cancel={approveCancelClicked}
          doShow={showApproveModal}
          actionLoading={approvingTicket}
        >
          <div className="text-purple-700">
            <p className="pb-1">
              <span className="font-bold">Requested Date: </span>
              {DateTime.fromISO((ticketToApprove as any).startDate).toLocaleString(DateTime.DATETIME_FULL)}
            </p>
            <p className="pb-1">
              <span className="font-bold">{(ticketToApprove as any).subject}</span>
            </p>
            <p className="pb-1">
              <span className="font-bold">Change Description: </span>
              {(ticketToApprove as any).description}
            </p>
            <p>
              <span className="font-bold pb-2">Change Details: </span>
              <p className="text-sm text-black">
                <div className="pb-1 pl-2">
                  <span className="font-bold">Justification: </span>
                  {(ticketToApprove as any).justification}
                </div>
                <div className="pb-1 pl-2">
                  <span className="font-bold">Risk and impact: </span>
                  {(ticketToApprove as any).risk_impact_analysis}
                </div>
                <div className="pb-1 pl-2">
                  <span className="font-bold">Implementation Plan: </span>
                  {(ticketToApprove as any).implementation_plan}
                </div>
                <div className="pb-1 pl-2">
                  <span className="font-bold">Test Plan: </span>
                  {(ticketToApprove as any).test_plan}
                </div>
                <div className="pb-1 pl-2">
                  <span className="font-bold">Expected Result: </span>
                  {(ticketToApprove as any).u_expected_result}
                </div>
                <div className="pb-1 pl-2">
                  <span className="font-bold">Backout Plan: </span>
                  {(ticketToApprove as any).backout_plan}
                </div>
              </p>
            </p>
          </div>
        </Modal>
        <div className="flex flex-col justify-end mb-3 xl:flex-row">
          <div className="flex items-center justify-end w-full text-lg text-purple-500 sm:hidden">
            <button
              className="px-2 py-1 mb-2 focus:ring-0"
              type="button"
              onClick={() => setShowFilters(prevState => !prevState)}
            >
              <FontAwesomeIcon
                icon={faFilter}
                className="mr-1 text-purple-500"
                style={{
                  // @ts-expect-error TS(2322): Type '{ '--ring-color': string; }' is not assignab... Remove this comment to see the full error message
                  '--ring-color': 'transparent', // Not supported in this version of tailwind
                }}
              />{' '}
              Filters
            </button>
          </div>
          {/* @ts-expect-error TS(2559): Type '{ children: false | Element; }' has no properties in common with type 'IntrinsicAttributes & AnimatePresenceProps'. */}
          <AnimatePresence>
            {showFilters && (
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={{
                  initial: {
                    opacity: viewWidth >= 640 ? 1 : 0,
                    y: viewWidth >= 640 ? 0 : -100,
                    scaleY: viewWidth >= 640 ? 1 : 0,
                  },
                  in: {
                    opacity: 1,
                    scaleY: 1,
                    y: 0,
                    x: 0,
                  },
                  out: {
                    opacity: viewWidth >= 640 ? 1 : 0,
                    y: viewWidth >= 640 ? 0 : -100,
                    scaleY: viewWidth >= 640 ? 1 : 0,
                  },
                }}
                transition={{ duration: 0.25, type: 'tween' }}
                className="flex flex-col justify-end w-full lg:flex-row"
              >
                <div className="flex flex-col justify-end sm:flex-row sm:items-end">
                  <Select
                    // @ts-expect-error TS(2322): Type '{ controlClassName: string; className: strin... Remove this comment to see the full error message
                    controlClassName="truncate"
                    className="w-full mb-2 mr-4 sm:w-52 sm:mb-0"
                    options={statusOptions}
                    value={selectedStatus}
                    placeholder="Status"
                    onChange={(e: any) => setSelectedStatus(e)}
                    disabled={loading}
                  />
                  <Select
                    // @ts-expect-error TS(2322): Type '{ controlClassName: string; className: strin... Remove this comment to see the full error message
                    controlClassName="truncate"
                    className="w-full mb-2 mr-4 sm:w-52 sm:mb-0"
                    options={ownerOptions}
                    value={selectedOwner}
                    placeholder="All Regions"
                    onChange={(e: any) => setSelectedOwner(e)}
                    disabled={loading}
                  />
                </div>
                <Button onClick={onFiltersClick} title="Filter" classNames="mt-2 lg:mt-0 h-8" loading={loading} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <EmpathTable
          title="Recent Change Requests"
          icon={<FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />}
          columns={columns}
          sort={[{ field: 'updatedOn', sort: 'desc' }]}
          viewPortHeight={viewPortHeight}
          loadData={loadData}
          initializing={loading}
          errorMessage={error}
          optionsDropdown={optionsDropdown}
          isOptionsLoading={isOptionsLoading}
        />
      </>
    );
  }
);

ChangeRequestTable.displayName = 'ChangeRequestTable';

export default ChangeRequestTable;
