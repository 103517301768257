import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, NavLink, useParams, useHistory } from 'react-router-dom';
import { Storage, API, graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faUser, faBell } from '@fortawesome/pro-solid-svg-icons';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';
import gql from 'graphql-tag';
// @ts-expect-error TS(2307): Cannot find module '../../assets/logoEffectual.svg... Remove this comment to see the full error message
import { ReactComponent as IconEffectual } from '../../assets/logoEffectual.svg';

import { listNotifications } from '../../graphql/queries';
import { onCreateNotification, onUpdateNotification, onDeleteNotification } from '../../graphql/subscriptions';
import { useUser } from '../../context/UserContext';
import Nav from '../leftNav/Nav';
// @ts-expect-error TS(2307): Cannot find module '../../assets/logo.svg' or its ... Remove this comment to see the full error message
import Logo from '../../assets/logo.svg';
// @ts-expect-error TS(2307): Cannot find module '../../assets/headerBackground.... Remove this comment to see the full error message
import HeaderBG from '../../assets/headerBackground.png';
import { getBaseUrl, ordinalSuffixOf } from '../../shared/helper';
import { isValidTimeZone } from '../../shared/dateHelpers';

const Header = () => {
  const SIX_HOURS = 6;
  // @ts-expect-error TS(2339): Property 'isImpersonating' does not exist on type 'unknown'.
  const { user, activeUser, isEffectualUser, isImpersonating } = useUser();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showProfileNav, setShowProfileNav] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showNotificationList, setShowNotificationList] = useState(false);
  const [notificationGroups, setNotificationGroups] = useState({});
  const history = useHistory();

  const profileNavRef = useRef(null);
  const notificationRef = useRef(null);

  const handleMobileNav = () => setShowMobileNav(!showMobileNav);
  const handleProfileNavShow = () => setShowProfileNav(!showProfileNav);
  const handleShowNotificationList = () => setShowNotificationList(!showNotificationList);

  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type '{}'.
  const { customerId } = useParams();

  useEffect(() => {
    const getProfilePhoto = (url: any) => {
      if (url) {
        return <img className="w-8 h-8 m-auto rounded-full" src={url} alt="Profile" />;
      }
      if (isEffectualUser) {
        return <IconEffectual className="w-6 h-6 m-auto" />;
      }

      return <FontAwesomeIcon icon={faUser} className="text-xl text-white" fixedWidth />;
    };

    const init = async () => {
      if (activeUser.profilePhotoUrl) {
        // Storage.get returns string even when arg is null
        const photoUrl = await Storage.get(activeUser.profilePhotoUrl);

        // @ts-expect-error TS(2345): Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
        setProfilePhoto(getProfilePhoto(photoUrl));

        return;
      }

      // @ts-expect-error TS(2345): Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
      setProfilePhoto(getProfilePhoto());
    };

    init();
  }, [activeUser, isEffectualUser]);

  const handleNotification = useCallback((notification: any, doRemove = false) => {
    // @ts-expect-error TS(2345): Argument of type '(state: never[]) => any[]' is no... Remove this comment to see the full error message
    setNotifications(state => {
      const index = state.findIndex(x => (x as any).id === notification.id);
      let items;
      if (index === -1 && !doRemove) {
        if (!notification.isRead) {
          items = [...state, notification];
        } else {
          items = state;
        }
      } else if (!doRemove && !notification.isRead) {
        items = state.map((item, i) => {
          if (i === index) {
            return notification;
          }
          return item;
        });
      } else {
        items = state.filter((_, i) => i !== index);
      }
      return items;
    });
  }, []);

  useEffect(() => {
    let subbed = true;
    const fetch = async () => {
      let nextToken;
      let items: any = [];
      do {
        // @ts-expect-error TS(7022): 'results' implicitly has type 'any' because it doe... Remove this comment to see the full error message
        const results = await API.graphql(
          graphqlOperation(listNotifications, {
            sub: activeUser.sub,
            filter: { isRead: { eq: false } },
            nextToken,
          })
        );
        items = items.concat(results.data.listNotifications.items);
        ({ nextToken } = results.data.listNotifications);
      } while (nextToken);
      if (subbed) {
        // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
        items.sort((a, b) => a.when < b.when);
        setNotifications(items);
      }
      if (!isImpersonating) {
        const createSubscription = (
          API.graphql(graphqlOperation(onCreateNotification, { sub: user.sub })) as any
        ).subscribe({
          next: ({ value }: any) => {
            handleNotification(value.data.onCreateNotification);
          },
          error: (error: any) => console.warn(error),
        });
        const updateSubscription = (
          API.graphql(graphqlOperation(onUpdateNotification, { sub: user.sub })) as any
        ).subscribe({
          next: ({ value }: any) => {
            handleNotification(value.data.onUpdateNotification);
          },
          error: (error: any) => console.warn(error),
        });
        const deleteSubscription = (
          API.graphql(graphqlOperation(onDeleteNotification, { sub: user.sub })) as any
        ).subscribe({
          next: ({ value }: any) => {
            handleNotification(value.data.onDeleteNotification, true);
          },
          error: (error: any) => console.warn(error),
        });
        return () => {
          createSubscription.unsubscribe();
          updateSubscription.unsubscribe();
          deleteSubscription.unsubscribe();
          subbed = false;
        };
      }
      return () => {
        subbed = false;
      };
    };
    fetch();
  }, [activeUser.sub, user.sub, handleNotification, isImpersonating]);

  useEffect(() => {
    const pageClick = (e: any) => {
      if (profileNavRef.current && !(profileNavRef.current as any).contains(e.target)) {
        setShowProfileNav(false);
      }
    };
    if (showProfileNav) {
      window.addEventListener('click', pageClick);
    }
    return () => {
      window.removeEventListener('click', pageClick);
    };
  }, [showProfileNav]);

  useEffect(() => {
    const pageClick = (e: any) => {
      if (notificationRef.current && !(notificationRef.current as any).contains(e.target)) {
        setShowNotificationList(false);
      }
    };
    if (showNotificationList) {
      window.addEventListener('click', pageClick);
    }
    return () => {
      window.removeEventListener('click', pageClick);
    };
  }, [showNotificationList]);

  const handleNotificationNavigation = ({ type, key }: any) => {
    handleShowNotificationList();
    let ticketParamType = '';

    if (type.toLowerCase() === 'incident') {
      ticketParamType = 'incidents';
    } else if (type.toLowerCase() === 'changerequest') {
      ticketParamType = 'change-requests';
    } else if (type.toLowerCase() === 'support request') {
      ticketParamType = 'cases';
    }
    const url = `${getBaseUrl(customerId)}/support/${ticketParamType}/${key}`;

    history.push(url);
  };

  const handleCSVDownload = ({ key }: any) => {
    handleShowNotificationList();
    window.location = key;
  };

  const removeCSVNotification = async ({ id, sub }: any) => {
    const mutations = `notification: updateNotification(input: { isRead: true, id: "${id}", sub: "${sub}" }) { id, sub, message, when, isRead, customerId, type, key, createdAt, updatedAt }`;

    const notificationsMutation = gql`mutation { ${mutations} }`;

    try {
      await API.graphql(graphqlOperation(notificationsMutation));
      const updateNotifications = notifications.filter(notification => (notification as any).id !== id);

      setNotifications(updateNotifications);
    } catch (e) {
      console.error('Error updating notifications', e);
    }
  };

  const removeAllCSVNotification = async () => {
    try {
      const notificationsMutation: any = [];

      // eslint-disable-next-line @typescript-eslint/await-thenable, @typescript-eslint/require-await
      await notifications.map(async n => {
        const mutations = `notification: updateNotification(input: { isRead: true, id: "${(n as any).id}", sub: "${
          (n as any).sub
        }" }) { id, sub, message, when, isRead, customerId, type, key, createdAt, updatedAt }`;
        notificationsMutation.push(gql`mutation { ${mutations} }`);
      });

      // @ts-expect-error TS(7006): Parameter 'n' implicitly has an 'any' type.
      await Promise.all(notificationsMutation.map(async n => API.graphql(graphqlOperation(n))));
    } catch (error) {
      console.error('Error updating all notifications', error);
    }

    setNotifications([]);
  };

  useEffect(() => {
    const createMonthDayOrdinaled = (when: any) => {
      const timeZone = isValidTimeZone(activeUser.timeZone) ? { zone: activeUser.timeZone } : {};
      const timeString = DateTime.fromISO(when, timeZone).toFormat('MMMM d');
      const num = timeString.split(' ')[1];
      const month = timeString.split(' ')[0];
      const ordinaled = ordinalSuffixOf(num);
      return `${month} ${ordinaled}`;
    };
    const notificationRelativeGroups = notifications.reduce(
      (accu, cur) => {
        const timeZone = isValidTimeZone(activeUser.timeZone);
        const diff = DateTime.fromISO((cur as any).createdAt, timeZone).diffNow(['hours', 'days']);
        const diffDays = diff.values.days;
        if (diffDays < -30) {
          (cur as any).displayTime = createMonthDayOrdinaled((cur as any).createdAt);
          accu.older.push(cur);
        } else if (diffDays <= -1) {
          (cur as any).displayTime = createMonthDayOrdinaled((cur as any).createdAt);
          accu.withinThisMonth.push(cur);
        } else {
          const hoursAgo = Math.abs(Math.round(diff.values.hours));
          let displayTime = '';
          if (hoursAgo < 1) {
            displayTime = 'Less than an hour ago';
          } else {
            displayTime = `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
          }
          (cur as any).displayTime = displayTime;
          accu.withinToday.push(cur);
        }
        return accu;
      },
      { older: [], withinThisMonth: [], withinToday: [] }
    );
    notificationRelativeGroups.withinToday.sort((a, b) => {
      if (new Date((b as any).when) !== new Date((a as any).when)) {
        // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
        return new Date((b as any).when) - new Date((a as any).when);
      }
      // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      return new Date((b as any).createdAt) - new Date((a as any).createdAt);
    });
    notificationRelativeGroups.withinThisMonth.sort((a, b) => {
      if (new Date((b as any).when) !== new Date((a as any).when)) {
        // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
        return new Date((b as any).when) - new Date((a as any).when);
      }
      // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      return new Date((b as any).createdAt) - new Date((a as any).createdAt);
    });
    notificationRelativeGroups.older.sort((a, b) => {
      if (new Date((b as any).when) !== new Date((a as any).when)) {
        // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
        return new Date((b as any).when) - new Date((a as any).when);
      }
      // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      return new Date((b as any).createdAt) - new Date((a as any).createdAt);
    });
    setNotificationGroups(notificationRelativeGroups);
  }, [activeUser, notifications]);

  const notificationContainer = (notification: any) => {
    if (notification.type === 'Report') {
      const timeZone = isValidTimeZone(activeUser.timeZone);
      const diff = DateTime.fromISO(notification.when, timeZone).diffNow(['hours']);
      const hoursDiff = Math.abs(diff.values.hours);
      const olderThanSixHours = hoursDiff > SIX_HOURS;

      if (olderThanSixHours) {
        return (
          <button
            key={notification.id}
            className="w-full mb-4 text-left bg-b-200"
            type="button"
            onClick={() => removeCSVNotification(notification)}
          >
            <div className="mx-6">
              <div className="text-purple-700" style={{ whiteSpace: 'break-spaces' }}>
                Your Report Expired
              </div>
              <div className="text-purple-700">{notification.displayTime}</div>
            </div>
          </button>
        );
      }

      return (
        <div
          key={notification.id}
          className="w-full mb-4 text-left bg-b-200"
          tabIndex={notification.id}
          role="button"
          onClick={() => removeCSVNotification(notification)}
          onKeyDown={() => removeCSVNotification(notification)}
        >
          <div className="mx-6">
            <div className="text-purple-700" style={{ whiteSpace: 'break-spaces' }}>
              {notification.message}. Click{' '}
              <button
                type="button"
                onClick={event => {
                  event.stopPropagation();
                  handleCSVDownload(notification);
                }}
              >
                <span className="text-purple-500 underline focus:ring-0">HERE</span>
              </button>{' '}
              to download
            </div>
            <div className="text-purple-700">{notification.displayTime}</div>
          </div>
        </div>
      );
    }

    return (
      <button
        type="button"
        key={notification.id}
        className="w-full mb-4 text-left bg-b-200"
        onClick={() => handleNotificationNavigation(notification)}
      >
        <div className="mx-6">
          <div className="text-purple-700" style={{ whiteSpace: 'break-spaces' }}>
            {notification.message}
          </div>
          <div className="text-purple-700">{notification.displayTime}</div>
        </div>
      </button>
    );
  };

  return (
    <nav
      className="flex flex-col bg-gradient-to-r from-purple-800 via-purple-700 to-purple-400 md:py-3"
      style={{
        backgroundColor: '#0f1346',
        backgroundImage: `url(${HeaderBG})`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '953px' /* value pulled from mockups */,
      }}
    >
      <div className="flex justify-between flex-1">
        <div className="flex items-center w-32 md:hidden">
          <button
            aria-label="Toggle mobile navigation menu"
            type="button"
            className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-100 focus:ring-0"
            onClick={handleMobileNav}
          >
            <svg className="block w-6 h-6 foo" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        <div className="flex justify-center flex-grow py-2 md:justify-start md:ml-6">
          <Link to="/">
            <img className="" src={Logo} alt="Empath logo" />
          </Link>
        </div>

        <div className="w-32 md:hidden" />

        <div className="flex items-center justify-center pr-4 md:pr-0 sm:relative">
          <button type="button" onClick={handleShowNotificationList}>
            <span className="relative inline-block mt-1">
              <FontAwesomeIcon icon={faBell} className="w-8 h-8 text-xl text-white" fixedWidth />
              {notifications && notifications.length > 0 && (
                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                  {notifications.length}
                </span>
              )}
            </span>
          </button>
          {showNotificationList && (
            <div
              className="absolute top-0 right-0 z-10 w-full pt-2 overflow-y-scroll text-sm bg-white shadow-lg sm:w-64 sm:h-96 sm:mt-12 sm:mr-4 lg:mr-0"
              ref={notificationRef}
            >
              <div className="flex flex-row-reverse lg:hidden">
                <button
                  aria-label="Show Notification list"
                  type="button"
                  className="mr-2"
                  onClick={handleShowNotificationList}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              {!notifications.length && (
                <div className="">
                  <p className="ml-4 font-bold text-purple-700">No new notifications</p>
                </div>
              )}
              {!!notifications.length && (
                <>
                  <div className="flex flex-row-reverse">
                    <button
                      type="button"
                      className="mr-2 font-bold text-purple-700"
                      onClick={() => removeAllCSVNotification()}
                    >
                      <FontAwesomeIcon icon={faCheck} /> Mark all as read
                    </button>
                  </div>
                  {!!(notificationGroups as any).withinToday.length && (
                    <div>
                      <p className="mb-2 ml-4 font-bold text-purple-700">Today</p>
                      {(notificationGroups as any).withinToday.map((notification: any) =>
                        notificationContainer(notification)
                      )}
                    </div>
                  )}
                  {!!(notificationGroups as any).withinThisMonth.length && (
                    <div>
                      <p className="my-2 ml-4 font-bold text-purple-700">Last 30 days</p>
                      {!!(notificationGroups as any).withinThisMonth.length &&
                        (notificationGroups as any).withinThisMonth.map((notification: any) =>
                          notificationContainer(notification)
                        )}
                    </div>
                  )}
                  {!!(notificationGroups as any).older.length && (
                    <div>
                      <p className="my-2 ml-4 font-bold text-purple-700">Older</p>
                      {(notificationGroups as any).older.map((notification: any) =>
                        notificationContainer(notification)
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div
          className="hidden md:ml-4 md:flex md:items-center"
          onClick={handleProfileNavShow}
          onKeyUp={handleProfileNavShow}
          role="button"
          // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
          tabIndex="0"
          ref={profileNavRef}
        >
          <div className="relative ml-3 mr-6">
            <div className="flex items-center content-center">
              <button
                type="button"
                className="flex items-center text-sm transition duration-150 ease-in-out border-2 border-transparent rounded-full focus:ring-0"
                id="user-menu"
                aria-label="User menu"
                aria-haspopup="true"
              >
                <div className="flex items-center justify-center w-8 h-8 bg-purple-900 rounded-full">
                  {profilePhoto}
                </div>
                <div className="flex flex-row items-center">
                  <div className="pl-3 text-xl font-medium leading-6 tracking-wide text-white">
                    {activeUser.firstName} {activeUser.lastName}
                  </div>

                  <span className="text-orange">
                    <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                  </span>
                </div>
              </button>
            </div>

            {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
            <AnimatePresence>
              {showProfileNav && (
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={{
                    initial: {
                      opacity: 0,
                      scale: 0,
                    },
                    in: {
                      opacity: 1,
                      scale: 1,
                      y: 0,
                      x: 0,
                    },
                    out: {
                      opacity: 0,
                      scale: 0,
                    },
                  }}
                  transition={{ duration: 0.25, type: 'tween' }}
                  className="absolute right-0 z-50 w-48 origin-top-right rounded-md shadow-lg"
                >
                  <div className="p-4 bg-purple-700 rounded-sm ring-1 ring-black ring-opacity-5">
                    <ProfItem
                      href={customerId ? `/customers/${customerId}/settings` : '/settings'}
                      label="My Settings"
                    />

                    <ProfItem href="/logout" label="Sign Out" />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
      <AnimatePresence>
        {showMobileNav && (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={{
              initial: {
                opacity: 1,
                x: '-100vw',
              },
              in: {
                opacity: 1,
                scale: 1,
                y: 0,
                x: 0,
              },
              out: {
                opacity: 1,
                x: '-100vw',
              },
            }}
            transition={{ duration: 0.25, type: 'tween' }}
            className="absolute bottom-0 left-0 right-0 z-30 flex flex-col flex-1 overflow-y-auto top-14 xs:top-16 md:hidden bg-gradient-to-b from-purple-800 via-purple-700 to-purple-400"
          >
            <div className="pt-4 pb-3 border-b border-gray-200">
              <div className="flex items-center px-4 space-x-3">
                <div className="flex-shrink-0">{profilePhoto}</div>
                <div>
                  <div className="text-base font-medium leading-6 text-white">
                    {activeUser.firstName} {activeUser.lastName}
                  </div>
                  <div className="text-sm font-medium leading-5 text-white">{activeUser.email}</div>
                </div>
              </div>
              <div
                className="hidden mt-3 space-y-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <a
                  href="/settings"
                  className="block px-4 py-2 text-base font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-800 hover:bg-gray-100 focus:ring-0 focus:text-gray-800 focus:bg-gray-100"
                  role="menuitem"
                >
                  My Settings
                </a>

                <a
                  href="/logout"
                  className="block px-4 py-2 text-base font-medium text-gray-500 transition duration-150 ease-in-out hover:text-gray-800 hover:bg-gray-100 focus:ring-0 focus:text-gray-800 focus:bg-gray-100"
                  role="menuitem"
                >
                  Sign Out
                </a>
              </div>
            </div>

            <Nav drawer mobileNav={handleMobileNav} showMobileNav={showMobileNav} />
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

type ProfItemProps = {
  label: string;
  href?: string;
};

const ProfItem = ({ label, href }: ProfItemProps) => (
  <NavLink
    to={`${href}`}
    className="flex items-center justify-start px-2 py-1 text-xs font-medium leading-tight text-white transition duration-150 ease-in-out border-l-2 border-transparent border-solid group hover:border-solid hover:border-l-2 hover:border-orange focus:ring-0 focus:bg-nav-hover focus:border-orange"
  >
    {label}
  </NavLink>
);

export default Header;
