import React from 'react';
import Tippy from '@tippyjs/react';

type Props = {
  tags: any[];
};

export default function TagsToolTip({ tags }: Props) {
  return (
    <Tippy
      interactive
      interactiveBorder={0}
      delay={[400, null]}
      content={
        <div className="overflow-hidden shadow bg-white border-solid border-l-4">
          <div className="pt-2 pb-1 px-5">
            <h4 className="text-lg font-bold text-purple-900">Tags</h4>
            {tags.map(t => (
              <p className="text-xs py-1" key={t.Key}>
                <b className="pr-1">{t.Key}</b>
                {t.Value}
              </p>
            ))}
          </div>
        </div>
      }
      appendTo={document.body}
    >
      <div className="text-indigo-600 hover:text-indigo-900">Tags</div>
    </Tippy>
  );
}
