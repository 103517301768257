import React from 'react';
// @ts-expect-error TS(2307): Cannot find module '../../assets/unauthorizedGraph... Remove this comment to see the full error message
import { ReactComponent as UnauthorizedGraphic } from '../../assets/unauthorizedGraphic.svg';

const Unauthorized = () => (
  <div className="flex flex-col bg-b-200 h-screen">
    <div className="mt-40">
      <p className="text-red-500 text-center text-xl px-3 lg:text-3xl uppercase">
        You are not authorized to view this page…
      </p>
    </div>
    <div className="mt-3 px-3">
      <p className="text-purple-500 text-center lg:text-xl">Contact your admin for more information.</p>
    </div>
    <div className="mt-5 flex justify-center">
      <UnauthorizedGraphic className="mx-auto" />
    </div>
  </div>
);

export default Unauthorized;
