import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { API, graphqlOperation } from 'aws-amplify';
import { useForm } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import SupportTicketModal from '../modal/SupportTicketModal';
import { Button } from '../buttons';
import { useUser } from '../../context/UserContext';
import { useSite } from '../../context/SiteContext';
import { customerGroups, featureFlagGroups, groupsForImpersonation, servicesGroups } from '../../shared/groupsHelper';
import { listEmpathUsers, getCustomer } from '../../graphql/queries';
import Modal from '../modal/Modal';
import FormSelect from '../inputs/FormSelect';

type Props = {
  title: string;
  buttonLabel?: string;
  containerClassName?: string;
  onSubmitted?: (...args: any[]) => any;
  hideSupport?: boolean;
  customAction?: (...args: any[]) => any;
  optionsDropdown?: any[];
};

const PageHeader = ({
  title,
  buttonLabel = 'Need Help?',
  containerClassName,
  onSubmitted,
  hideSupport = false,
  customAction,
  optionsDropdown,
}: Props) => {
  const [showSupport, setShowSupport] = useState(false);
  const [showImpersonationModal, setShowImpersonationModal] = useState(false);
  const [userList, setUserList] = useState([]);
  // @ts-expect-error TS(2339): Property 'servicesStatus' does not exist on type 'unknown'.
  const { userInRoles, isEffectualUser, impersonation, setImpersonation, customerHasFeature } = useUser();
  // @ts-expect-error TS(2339): Property 'servicesStatus' does not exist on type 'unknown'.
  const { servicesStatus } = useSite();
  const [disableNeedHelpButton, setDisableNeedHelpButton] = useState(false);
  // @ts-expect-error TS(2339): Property 'userId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  const { handleSubmit, errors, control, reset } = useForm();
  const history = useHistory();
  const [showOptions, setShowOptions] = useState(false);
  const handleOptions = () => setShowOptions(!showOptions);

  const allowedToCreate = () => {
    if (
      userInRoles([
        customerGroups.customerAdmin.groupName,
        customerGroups.customerSupport.groupName,
        customerGroups.customerAccounting.groupName,
        customerGroups.customerTechnical.groupName,
        customerGroups.customerHelpDesk.groupName,
      ])
    ) {
      return true;
    }

    return false;
  };

  const impersonateUser = async (userIdToImpersonate: any) => {
    if (!userInRoles(groupsForImpersonation)) {
      return;
    }

    const customerRes = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));
    const { featureFlags } = (customerRes as any).data.getCustomer;
    const featureAccessList = featureFlags
      ? Object.keys(featureFlags).filter(feature => featureFlags[feature] === true)
      : [featureFlagGroups.supportOnly.name];
    // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
    const users = userList.map(user => ({ ...user, featureFlags: featureAccessList }));
    const userToImpersonate = users.find(item => item.id === userIdToImpersonate);

    setImpersonation({
      cid: (customerRes as any).data.getCustomer.id,
      company: (customerRes as any).data.getCustomer.name,
      groups: userToImpersonate.groups,
      users,
      user: userToImpersonate,
      cognitoUser: {
        sub: userToImpersonate.sub,
        username: userToImpersonate.sub,
      },
    });

    history.push('/');
  };

  useEffect(() => {
    if (!isEffectualUser || !customerId) {
      return;
    }
    (async () => {
      const res = await API.graphql(
        graphqlOperation(listEmpathUsers, { query: { isImpersonating: true, customerId } })
      );
      setUserList((res as any).data.listEmpathUsers);
      reset();
    })();
  }, [isEffectualUser, customerId, reset]);

  const clearImpersonation = () => {
    reset();
    setShowImpersonationModal(false);
  };

  const onSubmit = async (data: any) => {
    const userToImpersonate = data.impersonate.value;

    if (!userToImpersonate) {
      return false;
    }

    await impersonateUser(userToImpersonate);
  };

  useEffect(() => {
    const { isUnderMaintenance: serviceNowUnderMaintenance, serviceName } = servicesStatus;

    if (
      serviceNowUnderMaintenance &&
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      serviceName === servicesGroups[serviceName].name &&
      buttonLabel === 'Need Help?'
    ) {
      setDisableNeedHelpButton(true);
    }
  }, [servicesStatus, buttonLabel]);

  return (
    <div
      className={twCascade(
        'flex',
        'justify-between',
        'my-6',
        'sm:mt-8',
        'sm:mb-10 flex flex-col md:flex-row',
        containerClassName
      )}
    >
      <div className="flex flex-row justify-start">
        <h2 className="text-xl leading-6 text-purple-700 uppercase md:text-2xl font-title pr-5">{title}</h2>
        {optionsDropdown && !!optionsDropdown.length && (
          <div className="text-purple-700">
            {showOptions && (
              <div className="absolute z-50 bg-white rounded-md shadow-lg w-36" onMouseLeave={handleOptions}>
                <div className={`p-4 text-xs rounded-sm ring-1 ring-black ring-opacity-5 `}>
                  {optionsDropdown &&
                    optionsDropdown.map((m, index) => (
                      <button key={index} type="button" onClick={m.downloadCSV}>
                        {m.text}
                      </button>
                    ))}
                </div>
              </div>
            )}
            <FontAwesomeIcon className="text-xl" icon={faDownload} onMouseEnter={handleOptions} />
          </div>
        )}
      </div>
      {isEffectualUser && customerId && !impersonation && !customAction && userInRoles(groupsForImpersonation) && (
        <div className="flex">
          <Button
            title="Impersonate"
            buttonStyle="solid"
            classNames="bg-red-700 hover:bg-red-800 px-6 mt-4 lg:mt-0"
            textClassNames="text-white"
            onClick={() => {
              setShowImpersonationModal(true);
            }}
          />
        </div>
      )}

      {!hideSupport && allowedToCreate() && !customerHasFeature([featureFlagGroups.resaleOnly.name]) && (
        <Button
          title={buttonLabel}
          onClick={() => allowedToCreate() && setShowSupport(true)}
          classNames="mt-4 lg:mt-0"
          disabled={disableNeedHelpButton}
        />
      )}

      {customAction && <Button title={buttonLabel} onClick={customAction} classNames="mt-4 lg:mt-0" />}

      <SupportTicketModal
        doShow={showSupport}
        cancel={() => setShowSupport(false)}
        onSubmitted={() => {
          setShowSupport(false);
          if (onSubmitted) {
            onSubmitted();
          }
        }}
      />
      <Modal
        doShow={showImpersonationModal}
        title={
          <span>
            Select <span className="text-orange">user</span>
          </span>
        }
        cancelText="Cancel"
        cancel={() => clearImpersonation()}
        actionText="Impersonate"
        action={() => handleSubmit(onSubmit)()}
        // @ts-expect-error TS(2322): Type '{ children: Element; doShow: boolean; title:... Remove this comment to see the full error message
        actionColor="red"
        widthOverrides="max-w-11/12 sm:max-w-100 md:max-w-128"
      >
        <div className="p-1">
          <form>
            <FormSelect
              label="User to impersonate"
              field="impersonate"
              control={control}
              formErrors={errors}
              rules={{ required: true }}
              options={userList.map(item => ({
                label: `${(item as any).firstName} ${(item as any).lastName}`,
                value: (item as any).id,
              }))}
            />
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default PageHeader;
