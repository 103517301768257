import React, { useEffect } from 'react';
import { Switch, withRouter, useLocation, useHistory, generatePath } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';

import { useUser } from '../context/UserContext';
import PrivateRoute from './PrivateRoute';
import Layout from '../pages/Layout';
import EffectualDashboard from '../pages/home/EffectualDashboard';
import { effectualGroups, allGroups } from '../shared/groupsHelper';
import Customers from '../pages/customers/Customers';
import CustomerDetails from '../pages/customers/CustomerDetails';
import PageNotFound from '../pages/errors/PageNotFound';
import EmpathUserList from '../pages/empath/EmpathUserList';
import EmpathUserDetails from '../pages/empath/EmpathUserDetails';
import MyTeam from '../pages/myTeam/MyTeam';
import TeamMemberDetails from '../pages/myTeam/TeamMemberDetails';
import EmpathAdminUserList from '../pages/empath/EmpathAdminUserList';
import EmpathAdminUserDetails from '../pages/empath/EmpathAdminUserDetails';
import Settings from '../pages/settings/Settings';

type Props = {
  empathRoute: string;
  match: any;
};

const EmpathRoutes = ({ empathRoute, match }: Props) => {
  const location = useLocation();
  const history = useHistory();
  // @ts-expect-error TS(2339): Property 'impersonation' does not exist on type 'unknown'
  const { impersonation } = useUser();

  // Redirect if impersonating and customer id in path doesn't match the impersonated customer's customerId
  useEffect(() => {
    if (!match || !match.params) {
      return;
    }

    const pathCustomerId = match.params.customerId;

    if (pathCustomerId && impersonation && impersonation.cid && impersonation.cid !== pathCustomerId) {
      const redirectPath = generatePath(match.path, {
        ...match.params,
        customerId: impersonation.cid,
      });

      history.replace(redirectPath);
    }
  }, [impersonation, history, match]);

  return (
    <Layout>
      {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
          <PrivateRoute path="/settings" animate>
            <Settings />
          </PrivateRoute>

          {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
          <PrivateRoute path="empath/customers/:customerId/settings" animate>
            <Settings />
          </PrivateRoute>

          <PrivateRoute
            // @ts-expect-error TS(2322): Type '{ children: Element; path: string; exact: tr... Remove this comment to see the full error message
            path="/empath"
            exact
            animate
            // @ts-expect-error TS(7053): WILL FIX
            requiredRoles={Object.keys(allGroups).map(group => allGroups[group].groupName)}
          >
            <EffectualDashboard />
          </PrivateRoute>

          {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
          <PrivateRoute path="/empath/customers/" exact animate>
            <Customers />
          </PrivateRoute>

          <PrivateRoute
            // @ts-expect-error TS(2322): Type '{ children: Element; path: string; exact: tr... Remove this comment to see the full error message
            path="/empath/users"
            exact
            animate
            requiredRoles={[effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName]}
          >
            <EmpathAdminUserList />
          </PrivateRoute>

          <PrivateRoute
            // @ts-expect-error TS(2322): Type '{ children: Element; path: string; exact: tr... Remove this comment to see the full error message
            path="/empath/users/details/:userId?"
            exact
            animate
            requiredRoles={[effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName]}
          >
            <EmpathAdminUserDetails />
          </PrivateRoute>

          <PrivateRoute
            // @ts-expect-error TS(2322): Type '{ children: Element; path: string; exact: tr... Remove this comment to see the full error message
            path="/empath/customers/:customerId"
            exact
            animate
            requiredRoles={[
              effectualGroups.effectualAdmin.groupName,
              effectualGroups.effectualSDM.groupName,
              effectualGroups.effectualObserver.groupName,
            ]}
          >
            <CustomerDetails />
          </PrivateRoute>

          <PrivateRoute
            // @ts-expect-error TS(2322): Type '{ children: Element; path: string; exact: tr... Remove this comment to see the full error message
            path={`${empathRoute}/users`}
            exact
            animate
            requiredRoles={[
              effectualGroups.effectualAdmin.groupName,
              effectualGroups.effectualSDM.groupName,
              effectualGroups.effectualObserver.groupName,
            ]}
          >
            <EmpathUserList />
          </PrivateRoute>

          <PrivateRoute
            // @ts-expect-error TS(2322): Type '{ children: Element; path: string; exact: tr... Remove this comment to see the full error message
            path={`${empathRoute}/my-team`}
            exact
            animate
            requiredRoles={[
              effectualGroups.effectualAdmin.groupName,
              effectualGroups.effectualSDM.groupName,
              effectualGroups.effectualObserver.groupName,
            ]}
          >
            <MyTeam />
          </PrivateRoute>

          <PrivateRoute
            // @ts-expect-error TS(2322): Type '{ children: Element; path: string; exact: tr... Remove this comment to see the full error message
            path={`${empathRoute}/my-team/team-member/:teamMemberId?`}
            exact
            animate
            requiredRoles={[effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName]}
          >
            <TeamMemberDetails />
          </PrivateRoute>

          <PrivateRoute
            // @ts-expect-error TS(2322): Type '{ children: Element; path: string; exact: tr... Remove this comment to see the full error message
            path={`${empathRoute}/users/details/:userId?`}
            exact
            animate
            requiredRoles={[
              effectualGroups.effectualAdmin.groupName,
              effectualGroups.effectualSDM.groupName,
              effectualGroups.effectualObserver.groupName,
            ]}
          >
            <EmpathUserDetails />
          </PrivateRoute>

          {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
          <PrivateRoute path="*" animate>
            <PageNotFound />
          </PrivateRoute>
        </Switch>
      </AnimatePresence>
    </Layout>
  );
};
// @ts-expect-error TS(2345): Argument of type '({ empathRoute, match }: Props) => JSX.Element' is not assignable to parameter of type 'ComponentType<RouteComponentProps<any, StaticContext, unknown>>'.
export default withRouter(EmpathRoutes);
