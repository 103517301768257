import React from 'react';

import PageHeader from '../../components/header/PageHeader';
import Details from '../../components/user/Details';

const EmpathUserDetails = () => (
  <div className="page-container">
    <PageHeader title="Empath Admin" />

    <Details basePath="/empath/users" />
  </div>
);

export default EmpathUserDetails;
