import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';

import { useForm } from 'react-hook-form';
import { AbsoluteLoader } from '../../components/loader';
import PageHeader from '../../components/header/PageHeader';
import { getCustomer } from '../../graphql/queries';
import { updateCustomer } from '../../graphql/mutations';
import Tabs from '../../components/tabs/Tabs';
import EmpathTable from '../../components/tables/EmpathTable';
import { Card } from '../../components/cards';
import { searchDataSet, sortDataSet, executeAsync, getBaseUrl } from '../../shared/helper';
import { maskPhone } from '../../components/inputs/utils';
import { RadioGroup, Checkbox } from '../../components/inputs';
import { errorToast, successToast } from '../../shared/toast';
import { Button } from '../../components/buttons';
import { featureFlagGroups, effectualGroups } from '../../shared/groupsHelper';
import { useUser } from '../../context/UserContext';

const CustomerDetails = () => {
  const [loading, setLoading] = useState(true);
  const [customerTypeLoading, setCustomerTypeLoading] = useState(true);
  const [customer, setCustomer] = useState({ name: '', products: [] });
  const { handleSubmit, errors, control, reset } = useForm({
    defaultValues: { customerType: featureFlagGroups.supportOnly.name },
  });
  // @ts-expect-error TS(2339): Property 'userInRoles' does not exist on type 'unknown'.
  const { userInRoles } = useUser();
  const canEdit = () => userInRoles([effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName]);
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        // @ts-expect-error TS(2339): Property 'data' does not exist on type 'GraphQLRes... Remove this comment to see the full error message
        data: {
          getCustomer: customerDetails,
          getCustomer: { products, featureFlags, isActive },
        },
      } = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));

      customerDetails.products = JSON.parse(products);
      setCustomer(customerDetails);
      let customerType;

      if (featureFlags) {
        if (featureFlags.vmc) {
          customerType = featureFlagGroups.vmc.name;
        } else if (featureFlags.mcm) {
          customerType = featureFlagGroups.mcm.name;
        } else if (featureFlags.resaleOnly) {
          customerType = featureFlagGroups.resaleOnly.name;
        } else {
          customerType = featureFlagGroups.supportOnly.name;
        }
      } else {
        customerType = featureFlagGroups.supportOnly.name;
      }
      // @ts-expect-error TS(2345): Argument of type '{ customerType: string; isCustom... Remove this comment to see the full error message
      reset({ customerType, isCustomerInactive: !isActive });
      setLoading(false);
      setCustomerTypeLoading(false);
    })();
  }, [customerId, reset]);

  const setActiveTab = (key: any) => {
    history.push(`/empath${getBaseUrl(customerId)}/${key}`);
  };

  const DetailTable = () => (
    <div className="flex flex-col block m-1 text-gray-900 bg-white rounded-sm sm:overflow-x-auto">
      <div className="flex px-1 py-2 leading-5 bg-gray-100">
        <span className="mr-4 font-bold">Customer: </span>
        <span>{customer.name}</span>
      </div>
      <div className="flex px-1 py-2 leading-5 bg-white">
        <span className="mr-4 font-bold">Customer ID: </span>
        <span>{customerId}</span>
      </div>
      <div className="flex px-1 py-2 leading-5 bg-gray-100">
        <span className="mr-4 font-bold">Customer Phone: </span>
        <span>{maskPhone((customer as any).phone)}</span>
      </div>
    </div>
  );

  const loadData = useCallback(
    async (options: any) =>
      executeAsync(() => {
        const { sort, search } = options;
        let items = customer.products.map(x => x);

        if (sort) {
          items = sortDataSet(items, sort);
        }

        if (search) {
          const searchOptions = {
            searchString: search,
            keys: ['product'],
          };

          items = searchDataSet(items, searchOptions);
        }

        return {
          items: items ?? [],
        };
      }),
    [customer.products]
  );

  const onCustomerTypeSubmit = async ({ customerType, isCustomerInactive }: any) => {
    try {
      setCustomerTypeLoading(true);
      const featureFlags = {};

      if (customerType === featureFlagGroups.supportOnly.name) {
        (featureFlags as any).mcm = false;
        (featureFlags as any).supportOnly = true;
        (featureFlags as any).vmc = false;
        (featureFlags as any).resaleOnly = false;
      } else if (customerType === featureFlagGroups.vmc.name) {
        (featureFlags as any).mcm = false;
        (featureFlags as any).supportOnly = false;
        (featureFlags as any).vmc = true;
        (featureFlags as any).resaleOnly = false;
      } else if (customerType === featureFlagGroups.resaleOnly.name) {
        (featureFlags as any).mcm = false;
        (featureFlags as any).supportOnly = false;
        (featureFlags as any).vmc = false;
        (featureFlags as any).resaleOnly = true;
      } else {
        (featureFlags as any).mcm = true;
        (featureFlags as any).supportOnly = false;
        (featureFlags as any).vmc = false;
        (featureFlags as any).resaleOnly = false;
      }
      const input = { id: customerId, featureFlags, isActive: !isCustomerInactive };

      await API.graphql(
        graphqlOperation(updateCustomer, {
          input,
        })
      );
      successToast('Customer type updated');
      // @ts-expect-error TS(2345): Argument of type '{ customerType: any; isCustomerI... Remove this comment to see the full error message
      reset({ customerType, isCustomerInactive });
    } catch (err) {
      console.error('Error updating customer', err);
      errorToast('Error updating customer');
    }
    setCustomerTypeLoading(false);
  };

  return loading ? (
    <AbsoluteLoader />
  ) : (
    <div className="page-container">
      <PageHeader title={`${customer.name} Details`} />

      <Tabs
        tabs={[
          { title: 'Users', key: 'users' },
          { title: 'Customer Details', key: '', active: true },
          { title: 'View Team', key: 'my-team' },
        ]}
        tabSelected={setActiveTab}
      />

      <Card title="Customer Detail" component={<DetailTable />} noMinHeight />
      <CustomerType
        customerTypeLoading={customerTypeLoading}
        control={control}
        errors={errors}
        handleSubmit={handleSubmit}
        onCustomerTypeSubmit={onCustomerTypeSubmit}
        canEdit={canEdit}
      />

      <div className="pt-4 hidden">
        <EmpathTable
          title="Effectual Products"
          loadData={loadData}
          columns={[
            {
              header: 'Product Name',
              accessor: 'product',
            },
            {
              header: 'Date Added',
              accessor: 'added',
              cell: function AddedCell(cellInfo: any) {
                return DateTime.fromISO(cellInfo.value).toFormat('LL/dd/yyyy');
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

type CustomerTypeProps = {
  control: any;
  handleSubmit: (...args: any[]) => any;
  onCustomerTypeSubmit: (...args: any[]) => any;
  errors?: any;
  customerTypeLoading?: boolean;
  canEdit?: (...args: any[]) => any;
};

const CustomerType = ({
  control,
  errors,
  handleSubmit,
  onCustomerTypeSubmit,
  customerTypeLoading,
  canEdit,
}: CustomerTypeProps) => (
  <div className="pt-4">
    <form onSubmit={handleSubmit(onCustomerTypeSubmit)}>
      <Card
        title="Empath Access Type"
        noMinHeight
        component={
          <div className="flex flex-col block text-gray-900 bg-white rounded-sm sm:overflow-x-auto">
            <RadioGroup
              nameValues={[
                { text: featureFlagGroups.mcm.text, value: featureFlagGroups.mcm.name },
                { text: featureFlagGroups.vmc.text, value: featureFlagGroups.vmc.name },
                { text: featureFlagGroups.supportOnly.text, value: featureFlagGroups.supportOnly.name },
                { text: featureFlagGroups.resaleOnly.text, value: featureFlagGroups.resaleOnly.name },
              ]}
              field="customerType"
              control={control}
              errors={errors}
              classNamesText="text-lg mb-3"
              classNamesRadioContainer="lg:flex-col lg:items-start"
              // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              disabled={!canEdit()}
            />
          </div>
        }
      />
      <div className="pt-4">
        <Card
          title="Empath Customer Status"
          noMinHeight
          component={
            <div className="flex flex-col text-gray-900 bg-white rounded-sm sm:overflow-x-auto">
              <Checkbox text="Make this customer inactive" field="isCustomerInactive" control={control} />
            </div>
          }
        />
      </div>
      {/* @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
      {canEdit() && (
        <div className="pt-4 flex flex-row-reverse">
          <Button
            type="submit"
            title="Save"
            buttonStyle="solid"
            classNames="bg-purple-700 text-white hover:bg-purple-500 lg:w-auto px-4 py-1"
            loading={customerTypeLoading}
          />
        </div>
      )}
    </form>
  </div>
);

export default CustomerDetails;
