import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClouds } from '@fortawesome/pro-duotone-svg-icons';
import { API, graphqlOperation } from 'aws-amplify';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';

import { getMonitoringMetrics } from '../../graphql/queries';
import { roundTimeToNearestMinutes, formatTime, formatTimeWithTimeZone } from '../../shared/dateHelpers';
import { useUser } from '../../context/UserContext';

import Card from './Card';
import LineChart, { yAxisFormat } from '../charts/LineChart';

type Props = {
  customerId: string;
  detailLink?: (...args: any[]) => any;
  classNames?: string;
};

const EC2CPUCard = ({ customerId, detailLink, classNames }: Props) => {
  const [eC2MetricsChartOptions, setEC2MetricsChartOptions] = useState();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // @ts-expect-error TS(2339): Property 'activeUser' does not exist on type 'unknown'.
  const { activeUser } = useUser();

  const getNameTag = (tags: any) => {
    if (!tags || !tags.length) {
      return '';
    }
    const [tag] = tags.filter((t: any) => t.Key === 'Name');

    return (tag && tag.Value) || '';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endTime = roundTimeToNearestMinutes(10);
        const startTime = DateTime.fromJSDate(endTime).minus({ hour: 6 }).toJSDate();
        const result = await API.graphql(
          graphqlOperation(getMonitoringMetrics, {
            params: { customerId },
            query: {
              startDate: startTime,
              endDate: endTime,
              service: 'ec2',
              metrics: "'aws_ec2_cpuutilization_average'",
              workload: null,
            },
          })
        );
        const eC2Metrics =
          (result as any)?.data?.getMonitoringMetrics?.items?.map((recievedMetric: any) => {
            const tags = JSON.parse(recievedMetric.tags);
            recievedMetric.name = getNameTag(tags);
            return recievedMetric;
          }) ?? [];
        const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        setEC2MetricsChartOptions({
          // @ts-expect-error TS(2345): Argument of type '{ yAxis: { labels: { formatter: ... Remove this comment to see the full error message
          yAxis: {
            labels: {
              formatter: (params: any) => {
                const { value } = params;
                return yAxisFormat.percent(value);
              },
            },
            max: 100,
          },
          xAxis: {
            type: 'datetime',
            labels: {
              format: '{value:%l:%M%p}',
              formatter: (params: any) => {
                const { isFirst, value } = params;
                if (isFirst) {
                  return formatTimeWithTimeZone(DateTime.fromMillis(value), activeUser.timeZone);
                }
                return formatTime(DateTime.fromMillis(value), activeUser.timeZone);
              },
            },
          },
          chart: {
            height: viewWidth >= 2560 ? 350 : 250,
          },
          tooltip: {
            valueDecimals: 2,
          },
          plotOptions: {
            series: {
              boostThreshold: 1,
            },
          },
          series: [
            ...eC2Metrics.map((metricData: any) => ({
              name: metricData.name || metricData.id || '',
              data: metricData.metrics.map((newMetric: any) => {
                const timeAsUTC = newMetric.timestamp.split(/[^0-9]/);
                return {
                  y: Number(newMetric.value),
                  // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
                  x: new Date(...timeAsUTC).getTime(),
                };
              }),
            })),
          ],
          cSVFileName: 'EC2 Metric Data.csv',
          errorLoadingData: !!(result as any).errors,
        });
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (error) {
        console.error('Error loading EC2 metric data: ', error);
        setError('Error Loading Data');
        setLoading(false);
      }
    };
    fetchData();
  }, [activeUser.timeZone, customerId]);

  return (
    <Card
      icon={<FontAwesomeIcon icon={faClouds} fixedWidth />}
      title="EC2 CPU"
      // @ts-expect-error TS(2322): Type '((...args: any[]) => any) | null' is not ass... Remove this comment to see the full error message
      viewDetailsCallback={detailLink || null}
      classNames={classNames}
      loading={loading}
      errorMessage={error}
      component={<LineChart chartOptions={eC2MetricsChartOptions} />}
    />
  );
};

export default EC2CPUCard;
