import React, { createContext, useContext, useMemo } from 'react';
import { ISiteSettings } from '../models';

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const SiteContext = createContext();

type SiteProviderProps = {
  children: React.ReactElement;
  servicesStatus: ISiteSettings | null;
};

const SiteProvider = ({ children, servicesStatus }: SiteProviderProps) => {
  const status = useMemo(() => ({ servicesStatus: servicesStatus || {} }), []);

  return <SiteContext.Provider value={status}>{children}</SiteContext.Provider>;
};

const useSite = () => useContext(SiteContext);

export default SiteContext;

export { SiteProvider, useSite };
