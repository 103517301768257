import React, { useState } from 'react';

type Props = {
  tabs: any[];
  tabSelected: (...args: any[]) => any;
};

export default function Tabs({ tabs, tabSelected }: Props) {
  const [tabCollection, setTabCollection] = useState(tabs);

  const setActiveTab = (title: any) => {
    tabSelected(title);
    setTabCollection(
      tabCollection.map(tab => (tab.title === title ? { ...tab, active: true } : { ...tab, active: false }))
    );
  };

  return (
    <div className="flex">
      {tabCollection.map(tab => (
        <div key={tab.key ?? tab.title} className="pr-8">
          <button
            type="button"
            onClick={() => setActiveTab(tab.key ?? tab.title)}
            className={`mb-4 text-base uppercase text-cool-gray-900 focus:ring-0 transform transition-opacity border-transparent ${
              tab.active ? 'border-solid border-b-4 border-orange' : ''
            } hover:border-solid border-b-4 hover:border-orange`}
          >
            {tab.title}
          </button>
        </div>
      ))}
    </div>
  );
}
