import React from 'react';

import PageHeader from '../../components/header/PageHeader';

const SecurityDashboard = () => (
  <div className="page-container">
    <div className="m-1">
      <PageHeader title="Security" />
    </div>

    <div className="relative overflow-hidden bg-white py-16">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-indigo-600">Security</span>

            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              AWS Inspector Replacing Qualys
            </span>
          </h1>

          <p className="mt-8 text-md leading-8 text-gray-500">
            Effectual continually improves services to provide the best possible experience for our customers. Part of
            this process includes regular evaluation of the tools we use to support our service offerings.
          </p>

          <p className="mt-8 text-md leading-8 text-gray-500">
            Our managed cloud service currently features vulnerability assessment capabilities powered by Qualys. After
            careful evaluation of alternative solutions, we have determined that the native AWS Inspector tool will
            provide a better experience for our customers. We are developing an implementation plan to ensure a seamless
            transition and eliminate any disruption to normal operations.{' '}
          </p>
        </div>

        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-base text-gray-500">
          <h2>Why are we making this change?</h2>

          <p>
            This change will facilitate our ability to meet customer needs and proactively maintain the security of
            their AWS environments through improved transparency, visibility, communication, and escalation. And, in
            addition to being a native AWS tool, AWS Inspector offers a range of benefits for our customers, including:
          </p>

          <ul>
            <li>
              API integration that allows scan results to be directly ingested by our monitoring system, giving us the
              ability to automatically generate remediation tickets and reduce lead times
            </li>
            <li>Improved inspection and reporting for platform-level security issues</li>
            <li>
              Increased scanning capabilities with support for Lambda functions, container registries, and serverless
              instances
            </li>
            <li>
              Seamless integration with other native AWS services, providing a seamless experience for customers using
              multiple AWS products
            </li>
          </ul>
          <h2>Will you have any disruption to your solution during this switch?</h2>
          <p>Effectual does not anticipate any significant impacts or interruptions to current customer workloads.</p>

          <h2>When will we make this change?</h2>
          <p>
            We plan on transitioning from Qualys to AWS Inspector in mid-to-late December. We will notify you once we
            have finalized our internal implementation plan and identified an exact date for the cutover.
          </p>
          <p>
            If you have any questions at all, please contact our support engineers by opening a case through your Empath
            portal, or call us at 877-470-5260.
          </p>
          <p>Thank you.</p>
          <p>Effectual Operations</p>
        </div>
      </div>
    </div>
  </div>
);

export default SecurityDashboard;
