import React from 'react';
import { useController } from 'react-hook-form';

import { twCascade } from '@mariusmarais/tailwind-cascade';

import { getErrorMessage } from './utils';
import TimePicker from './TimePicker';

type Props = {
  label?: string;
  field: string;
  formErrors?: any;
  errorMessage?: string;
  onSubmit?: (...args: any[]) => any;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  purple?: boolean;
  tooltip?: React.ReactNode;
  defaultValue?: string;
  control: any;
  rules?: any;
};

const FormTimePicker = ({
  label,
  field,
  formErrors,
  errorMessage,
  onSubmit,
  tooltip,
  containerClassName = '',
  inputClassName = '',
  labelClassName = '',
  disabled,
  readOnly,
  autoFocus,
  purple,
  control,
  defaultValue,
  rules,
}: Props) => {
  const {
    field: { ref, onChange, value },
  } = useController({
    name: field,
    control,
    rules,
    defaultValue: defaultValue || null,
  });
  const error = errorMessage || getErrorMessage(formErrors, field, label);
  const labelColor = purple ? 'text-gray-200' : 'text-blue-900';

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && onSubmit && !disabled) {
      onSubmit();
    }
  };

  return (
    <div className={containerClassName}>
      <div className="flex flex-row items-center">
        <label
          htmlFor={field}
          className={twCascade(
            'flex',
            'text-base',
            'mb-1',
            labelColor,
            error ? 'text-red-700' : 'labelColor',
            labelClassName
          )}
        >
          {label}
          {rules?.required && <span className="text-orange">*</span>}
        </label>
        {tooltip && <div className="mb-1">{tooltip}</div>}
      </div>
      <TimePicker
        // @ts-expect-error TS(2322): Type '{ defaultValue: string | undefined; utc: tru... Remove this comment to see the full error message
        defaultValue={defaultValue}
        utc
        date={value}
        id={field}
        name={field}
        ref={e => (ref.current = e)}
        disabled={disabled}
        readOnly={readOnly}
        error={error}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputClassName={inputClassName}
        style={{
          '--ring-color': purple ? 'white' : 'transparent', // Not supported in this version of tailwind
        }}
        // eslint-disable-next-line
          autoFocus={autoFocus}
      />
      <div className={`${error ? '' : 'invisible'} min-h-5 text-xs text-red-700`}>{error}</div>
    </div>
  );
};

FormTimePicker.displayName = 'FormDatePicker';

export default FormTimePicker;
