import { API, graphqlOperation } from 'aws-amplify';
import { getCustomer } from '../graphql/queries';

export const effectualGroups = {
  effectualAdmin: {
    groupName: 'effectualAdmin',
    description: 'Effectual Administrator',
  },
  effectualSDM: {
    groupName: 'effectualSDM',
    description: 'Effectual Service Delivery Manager',
  },
  effectualAccounting: {
    groupName: 'effectualAccounting',
    description: 'Effectual Accounting',
  },
  effectualObserver: {
    groupName: 'effectualObserver',
    description: 'Effectual Observer',
  },
};

export const customerGroups = {
  customerAdmin: {
    groupName: 'customerAdmin',
    description: 'Administrator',
  },
  customerSupport: {
    groupName: 'customerSupport',
    description: 'Support',
  },
  customerAccounting: {
    groupName: 'customerAccounting',
    description: 'Accounting',
  },
  customerObserver: {
    groupName: 'customerObserver',
    description: 'Observer',
  },
  customerTechnical: {
    groupName: 'customerTechnical',
    description: 'Technical',
  },
  customerHelpDesk: {
    groupName: 'customerHelpDesk',
    description: 'Help Desk',
  },
};

export const customerRoles = [
  { role: 'Administrator', description: 'Can view all features and can add users' },
  { role: 'Support', description: 'Can view all features but cannot view admin settings' },
  { role: 'Accounting', description: 'Can view nothing' },
  { role: 'Observer', description: 'Can view all features but they are read only. No admin' },
  { role: 'Technical', description: 'All but administration' },
  { role: 'Help Desk', description: 'Can view support data only ' },
];

export const allGroups = { ...effectualGroups, ...customerGroups };

export const groupsForImpersonation = [
  effectualGroups.effectualAdmin.groupName,
  effectualGroups.effectualSDM.groupName,
];

export const featureFlagGroups = {
  supportOnly: { text: 'Support Only', name: 'supportOnly' },
  mcm: { text: 'MCM Customer', name: 'mcm' },
  vmc: { text: 'VMC Customer', name: 'vmc' },
  resaleOnly: { text: 'Resale Only', name: 'resaleOnly' },
};

export const customerIds = {
  empath: 'empath',
};

export const reports = {
  ticketsReport: 'ticketsReport',
  userListReport: 'userListReport',
  myCloudReport: 'myCloudReport',
  customerListReport: 'customerListReport',
};

export const getFeatureAcess = async (customerId: any) => {
  try {
    const {
      // @ts-expect-error TS(2339): Property 'data' does not exist on type 'GraphQLRes... Remove this comment to see the full error message
      data: {
        getCustomer: { featureFlags },
      },
    } = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));

    const featureAccessList = Object.keys(featureFlags).filter(feature => featureFlags[feature] === true);

    return featureAccessList.length ? featureAccessList : [featureFlagGroups.supportOnly.name];
  } catch (error) {
    return [featureFlagGroups.supportOnly.name];
  }
};

export const servicesGroups = { serviceNow: { name: 'serviceNow' } };
