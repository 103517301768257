import React, { useState, useEffect, useCallback, useRef, forwardRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClouds } from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import { searchDataSet, sortDataSet, executeAsync } from '../../shared/helper';
import ToolTip from '../../components/tooltip/Tooltip';
import { getMonitoringFilters, getMonitoringMetrics, getMonitorStatusSite24x7 } from '../../graphql/queries';
import {
  roundTimeToNearestMinutes,
  formatDateShortAndTime,
  formatTime,
  toUtcIso,
  formatMonthDate,
  formatMonthDateAndTime,
  convertToAnotherTimeZone,
} from '../../shared/dateHelpers';
import { EmpathTable, exportCSVReport } from '../../components/tables';
import TagsToolTip from '../../components/tooltip/TagsToolTip';
import Card from '../../components/cards/Card';
import LineChart, { yAxisFormat } from '../../components/charts/LineChart';
import PageHeader from '../../components/header/PageHeader';
import { Button } from '../../components/buttons';
import { AbsoluteLoader } from '../../components/loader';
import { errorToast } from '../../shared/toast';
import { Select, DatePicker } from '../../components/inputs';
import { useUser } from '../../context/UserContext';
import { customerIds, reports } from '../../shared/groupsHelper';
import Tabs from '../../components/tabs/Tabs';
import Modal from '../../components/modal/Modal';
import Monitoring from './Monitoring';

const MyCloudDashboard = () => {
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();

  const location = useLocation();

  const useMountEffect = (func: any) => useEffect(func, []);

  const ALL_WORKLOADS = 'All Workloads';
  const WORKLOADS = 'workloads';
  const ALL_ENVIRONMENTS = 'All Environments';
  const ENVIRONMENTS = 'environments';
  const ALL_REGIONS = 'All Regions';
  const REGIONS = 'regions';
  const SERVICES = 'services';
  const TIMEDIFF = 30;
  const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  // @ts-expect-error TS(2339): Property 'isEffectualUser' does not exist on type 'unknown'.
  const { activeUser, isEffectualUser } = useUser();
  const [monitoringMetricsCSVParams, setMonitoringMetricsCSVParams] = useState({});
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [showMonitoringModal, setShowMonitoringModal] = useState(false);

  const TIME_RANGES = {
    fifteenMin: { value: 15, relativeTime: 'fifteenMin', text: 'Last 15 Minutes' },
    thirtyMin: { value: 30, relativeTime: 'thirtyMin', text: 'Last 30 Minutes' },
    oneHr: { value: 1, relativeTime: 'oneHr', text: 'Last 1 Hour' },
    threeHrs: { value: 3, relativeTime: 'threeHrs', text: 'Last 3 Hours' },
    sixHrs: { value: 6, relativeTime: 'sixHrs', text: 'Last 6 Hours' },
    twelveHrs: { value: 12, relativeTime: 'twelveHrs', text: 'Last 12 Hours' },
    twentyFourHrs: { value: 24, relativeTime: 'twentyFourHrs', text: 'Last 24 Hours' },
    twoDays: { value: 2, relativeTime: 'twoDays', text: 'Last 2 Days' },
    sevenDays: { value: 7, relativeTime: 'sevenDays', text: 'Last 7 Days' },
  };
  const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
  const TWO_DAYS = 2;
  const SEVEN_DAYS = 7;

  const DatePickerInput = forwardRef((params, ref) => {
    // @ts-expect-error TS(2339): Property 'value' does not exist on type '{}'.
    const { value, onClick } = params;

    return (
      <button
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
        type="submit"
        className="mr-4 inline-flex w-full mb-2 sm:w-auto sm:mb-0 items-center space-between px-2 py-0.5 bg-white border border-gray-300 truncate"
        onClick={onClick}
      >
        {value}
        <FontAwesomeIcon icon={faCalendarAlt} className="ml-3 text-purple-500" fixedWidth />
      </button>
    );
  });

  DatePickerInput.displayName = 'DatePickerInput';

  const isInitialLoad = useRef(true);

  const [tableData, setTableData] = useState();
  const [chartOptions, setChartOptions] = useState();

  const endingDate = roundTimeToNearestMinutes(10, convertToAnotherTimeZone(new Date(), activeUser.timeZone));
  const [startDate, setStartDate] = useState(DateTime.local().startOf('day').toJSDate());
  const [appliedStartingDate, setAppliedStartingDate] = useState({});
  const [endDate, setEndDate] = useState(endingDate);
  const [appliedEndingDate, setAppliedEndingDate] = useState({});
  const [filters, setFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [workloadDropdown, setWorkloadDropdown] = useState([]);
  const [showFilters, setShowFilters] = useState(
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) >= 1024
  );
  const [chartName, setChartName] = useState('');
  const [selectedWorkload, setSelectedWorkload] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [serviceDropdown, setServiceDropdown] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState([]);
  const [metricDropdown, setMetricDropdown] = useState([]);
  const [environmentDropdown, setEnvironmentDropdown] = useState([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState({});
  const [regionDropdown, setRegionDropdown] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [metricsChartOptions, setMetricsChartOptions] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [noData, setNoData] = useState(false);
  const [activeTab, setActiveTab] = useState('Cloud Metrics');
  const [monitorStatusData, setmonitorStatusData] = useState([]);
  const [monitorID, setMonitorID] = useState('');
  const [monitorStatus, setStatus] = useState('');
  const [downReason, setDownReason] = useState('');

  const updateActiveTab = (tab: any) => {
    setActiveTab(tab);
  };

  const columns = {
    rdsInstance: [
      {
        header: 'DB Identifier',
        accessor: 'id',
      },
      { header: 'Database Type', accessor: 'type' },
      { header: 'Instance Type', accessor: 'size' },
      {
        header: 'State',
        accessor: 'state',
      },
      {
        header: 'Tags',
        accessor: 'tags',
        isSortable: false,
        cell: function TagsCell(cellInfo: any) {
          if (!cellInfo?.value?.props?.tags) {
            return 'No Tags';
          }

          return (
            <ToolTip
              header="Tags"
              content={cellInfo.value.props.tags.map((t: any) => (
                <p className="py-1 text-xs" key={t.Key}>
                  <b className="pr-1">{t.Key}</b>
                  {t.Value}
                </p>
              ))}
            >
              <div className="text-indigo-600 hover:text-indigo-900">Tags</div>
            </ToolTip>
          );
        },
      },
    ],
    rdsCluster: [
      {
        header: 'DB Identifier',
        accessor: 'id',
      },
      { header: 'Database Type', accessor: 'type' },
      {
        header: 'State',
        accessor: 'state',
      },
      {
        header: 'Tags',
        accessor: 'tags',
        isSortable: false,
        cell: function TagsCell(cellInfo: any) {
          if (!cellInfo?.value?.props?.tags) {
            return 'No Tags';
          }

          return (
            <ToolTip
              header="Tags"
              content={cellInfo.value.props.tags.map((t: any) => (
                <p className="py-1 text-xs" key={t.Key}>
                  <b className="pr-1">{t.Key}</b>
                  {t.Value}
                </p>
              ))}
            >
              <div className="text-indigo-600 hover:text-indigo-900">Tags</div>
            </ToolTip>
          );
        },
      },
    ],
    ec2: [
      {
        header: 'Instance ID',
        accessor: 'id',
      },
      { header: 'Name', accessor: 'name' },
      { header: 'Instance Type', accessor: 'type' },
      {
        header: 'State',
        accessor: 'state',
      },
      {
        header: 'Tags',
        accessor: 'tags',
        isSortable: false,
        cell: function TagsCell(cellInfo: any) {
          if (!cellInfo?.value?.props?.tags) {
            return 'No Tags';
          }

          return (
            <ToolTip
              header="Tags"
              content={cellInfo.value.props.tags.map((t: any) => (
                <p className="py-1 text-xs" key={t.Key}>
                  <b className="pr-1">{t.Key}</b>
                  {t.Value}
                </p>
              ))}
            >
              <div className="text-indigo-600 hover:text-indigo-900">Tags</div>
            </ToolTip>
          );
        },
      },
    ],
    elb: [
      {
        header: 'Instance ID',
        accessor: 'id',
      },
      { header: 'Name', accessor: 'name' },
      { header: 'Instance Type', accessor: 'type' },
      {
        header: 'State',
        accessor: 'state',
      },
      {
        header: 'Tags',
        accessor: 'tags',
        isSortable: false,
        cell: function TagsCell(cellInfo: any) {
          if (!cellInfo?.value?.props?.tags) {
            return 'No Tags';
          }

          return (
            <ToolTip
              header="Tags"
              content={cellInfo.value.props.tags.map((t: any) => (
                <p className="py-1 text-xs" key={t.Key}>
                  <b className="pr-1">{t.Key}</b>
                  {t.Value}
                </p>
              ))}
            >
              <div className="text-indigo-600 hover:text-indigo-900">Tags</div>
            </ToolTip>
          );
        },
      },
    ],
    ecsCluster: [
      {
        header: 'Instance ID',
        accessor: 'id',
      },
      {
        header: 'State',
        accessor: 'state',
      },
      {
        header: 'Tags',
        accessor: 'tags',
        isSortable: false,
        cell: function TagsCell(cellInfo: any) {
          if (!cellInfo?.value?.props?.tags) {
            return 'No Tags';
          }

          return (
            <ToolTip
              header="Tags"
              content={cellInfo.value.props.tags.map((t: any) => (
                <p className="py-1 text-xs" key={t.Key}>
                  <b className="pr-1">{t.Key}</b>
                  {t.Value}
                </p>
              ))}
            >
              <div className="text-indigo-600 hover:text-indigo-900">Tags</div>
            </ToolTip>
          );
        },
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const onMonitorStatusClick = async (ID: any, statusValue: any, reason: any) => {
    setMonitorID(ID);
    setStatus(statusValue);
    setDownReason(reason);
    setShowMonitoringModal(false);
    updateActiveTab('Resource Status');
  };
  const loadMonitors = async (status: any) => {
    try {
      setLoading(true);
      const monitorSatatusCallParams = { params: { customerId } };

      const monitorStatusAllData = await API.graphql(
        graphqlOperation(getMonitorStatusSite24x7, monitorSatatusCallParams)
      );

      if ((monitorStatusAllData as any)?.data?.getMonitorStatusSite24x7?.data) {
        let data = JSON.parse((monitorStatusAllData as any).data.getMonitorStatusSite24x7.data);

        data = data.filter((column: any) => column.status === status && column.monitor_type !== 'AMAZON');
        setmonitorStatusData(data);
      }

      setShowMonitoringModal(true);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      console.error('Error load monitors: ', error);

      setError('Error Loading Data');
      setLoading(false);
    }
  };

  const loadData = useCallback(
    async (options: any) =>
      executeAsync(() => {
        if (!tableData) {
          return { items: [] };
        }
        const { sort, search } = options;
        let items = (tableData as any).map((m: any) => m);

        if (sort) {
          items = sortDataSet(items, sort);
        }

        if (search) {
          const searchOptions = {
            searchString: search,
            keys: (appliedFilters as any).columns[(appliedFilters as any).service.id]
              .filter((column: any) => column.accessor !== 'tags')
              .map((column: any) => column.accessor),
          };

          items = searchDataSet(items, searchOptions);
        }

        return {
          items: items ?? [],
        };
      }),
    [tableData, appliedFilters]
  );

  const initializeComponent = async () => {
    try {
      setAppliedStartingDate(startDate);
      setAppliedEndingDate(endDate);

      const resourcesByWorkload = await API.graphql(
        graphqlOperation(getMonitoringFilters, {
          params: { customerId },
          query: {
            startDate: toUtcIso(startDate, activeUser.timeZone),
            endDate: toUtcIso(endDate, activeUser.timeZone),
          },
        })
      );

      const monitoringFilters = (resourcesByWorkload as any)?.data?.getMonitoringFilters?.items ?? [];

      if (!Object.keys(monitoringFilters).length || !monitoringFilters?.services?.length) {
        if (isInitialLoad.current) {
          isInitialLoad.current = false;
        }

        setNoData(true);

        const noDataWorkloads = [{ value: ALL_WORKLOADS, label: ALL_WORKLOADS }];
        const noDataEnvironments = [{ value: ALL_ENVIRONMENTS, label: ALL_ENVIRONMENTS }];
        const noDataRegions = [{ value: ALL_REGIONS, label: ALL_REGIONS }];

        // @ts-expect-error TS(2345): Argument of type '{ value: string; label: string; ... Remove this comment to see the full error message
        setWorkloadDropdown(noDataWorkloads);
        setSelectedWorkload(noDataWorkloads[0]);
        // @ts-expect-error TS(2345): Argument of type '{ value: string; label: string; ... Remove this comment to see the full error message
        setEnvironmentDropdown(noDataEnvironments);
        setSelectedEnvironment(noDataEnvironments[0]);
        // @ts-expect-error TS(2345): Argument of type '{ value: string; label: string; ... Remove this comment to see the full error message
        setRegionDropdown(noDataRegions);
        setSelectedRegion(noDataRegions[0]);
        setServiceDropdown([]);
        setSelectedService({});
        setMetricDropdown([]);
        // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
        setSelectedMetric({});
        buildEmpty();

        return;
      }

      setFilters(monitoringFilters);
      setNoData(false);

      let filtersToApplied;

      if (isInitialLoad.current) {
        filtersToApplied = firstTimeSetupFilters(monitoringFilters);
        isInitialLoad.current = false;
      } else {
        filtersToApplied = setupFilters(monitoringFilters);
      }
      setAppliedFilters(filtersToApplied);
      updateGraphUI(filtersToApplied);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      const noDataWorkloads = [{ value: ALL_WORKLOADS, label: ALL_WORKLOADS }];
      const noDataEnvironments = [{ value: ALL_ENVIRONMENTS, label: ALL_ENVIRONMENTS }];
      const noDataRegions = [{ value: ALL_REGIONS, label: ALL_REGIONS }];

      // @ts-expect-error TS(2345): Argument of type '{ value: string; label: string; ... Remove this comment to see the full error message
      setWorkloadDropdown(noDataWorkloads);
      setSelectedWorkload(noDataWorkloads[0]);
      // @ts-expect-error TS(2345): Argument of type '{ value: string; label: string; ... Remove this comment to see the full error message
      setEnvironmentDropdown(noDataEnvironments);
      setSelectedEnvironment(noDataEnvironments[0]);
      // @ts-expect-error TS(2345): Argument of type '{ value: string; label: string; ... Remove this comment to see the full error message
      setRegionDropdown(noDataRegions);
      setSelectedRegion(noDataRegions[0]);
      setNoData(true);

      console.error('Error initializing component: ', error);
      isInitialLoad.current = false;
      setLoading(false);
      setError('Error Fetching Monitoring Data');
    }
  };

  const firstTimeSetupFilters = (monitoringFilters: any) => {
    // workload
    const workloadDropdownList = monitoringFilters.workloads.map((x: any) => ({
      value: x.id,
      label: x.id,
    }));

    setWorkloadDropdown(workloadDropdownList);
    setSelectedWorkload(workloadDropdownList[0]);

    // environment
    const environmentDropdownList = monitoringFilters.environments.map((x: any) => ({
      value: x.id,
      label: x.id,
    }));

    setEnvironmentDropdown(environmentDropdownList);
    setSelectedEnvironment(environmentDropdownList[0]);

    // region
    const regionDropdownList = monitoringFilters.regions.map((x: any) => ({
      value: x.id,
      label: x.id,
    }));

    setRegionDropdown(regionDropdownList);
    setSelectedRegion(regionDropdownList[0]);

    // services
    const serviceDropdownList = monitoringFilters.services.map((x: any) => ({
      value: x.id,
      label: x.name,
    }));

    setServiceDropdown(serviceDropdownList);
    setSelectedService(serviceDropdownList[0]);

    // metrics
    const metricDropdownList = monitoringFilters.services[0].metrics.map((x: any) => ({
      value: x.ids,
      label: x.name,
    }));

    setMetricDropdown(metricDropdownList);
    setSelectedMetric(metricDropdownList[0]);

    return {
      workload: ALL_WORKLOADS,
      environment: ALL_ENVIRONMENTS,
      region: ALL_REGIONS,
      service: monitoringFilters.services[0],
      metricItem: monitoringFilters.services[0].metrics[0],
      columns,
    };
  };

  const setupFilters = (monitoringFilters: any) => {
    // workload
    const workloadDropdownList = monitoringFilters.workloads.map((x: any) => ({
      value: x.id,
      label: x.id,
    }));
    const selectedWrkld = workloadDropdownList.filter((wl: any) => wl.value === (selectedWorkload as any).value);
    const workload = selectedWrkld.length ? selectedWrkld[0] : workloadDropdownList[0];

    setWorkloadDropdown(workloadDropdownList);
    setSelectedWorkload(workload);

    // environment
    const environmentDropdownList = monitoringFilters.environments.map((x: any) => ({
      value: x.id,
      label: x.id,
    }));
    const selectedEnvrnmnt = environmentDropdownList.filter((e: any) => e.value === (selectedEnvironment as any).value);
    const environment = selectedEnvrnmnt.length ? selectedEnvrnmnt[0] : environmentDropdownList[0];

    setEnvironmentDropdown(environmentDropdownList);
    setSelectedEnvironment(environment);

    // region
    const regionDropdownList = monitoringFilters.regions.map((x: any) => ({
      value: x.id,
      label: x.id,
    }));
    const selectedRgn = regionDropdownList.filter((r: any) => r.value === (selectedRegion as any).value);
    const region = selectedRgn.length ? selectedRgn[0] : regionDropdownList[0];

    setRegionDropdown(regionDropdownList);
    setSelectedRegion(region);

    // services
    const service = monitoringFilters.services.find((srv: any) => srv.name === (selectedService as any).label);
    const selectedSrvc = service || monitoringFilters.services[0];
    const serviceDropdownList = monitoringFilters.services.map((x: any) => ({
      value: x.id,
      label: x.name,
    }));
    const serviceInDropdown = serviceDropdownList.find((srv: any) => srv.value === selectedSrvc.id);

    setServiceDropdown(serviceDropdownList);
    setSelectedService(serviceInDropdown);

    // metrics
    const metric = selectedSrvc.metrics.find((x: any) => x.name === (selectedMetric as any).label);
    const selectedMtc = metric || selectedSrvc.metrics[0];
    const metricDropdownList = selectedSrvc.metrics.map((x: any) => ({
      value: x.ids,
      label: x.name,
    }));
    const metricInDropdown = metricDropdownList.find((m: any) => m.label === selectedMtc.name);

    setMetricDropdown(metricDropdownList);
    setSelectedMetric(metricInDropdown);

    return {
      workload: workload.value,
      environment: environment.value,
      region: region.value,
      service: selectedSrvc,
      metricItem: selectedMtc,
      columns,
    };
  };

  useMountEffect(() => {
    initializeComponent();
    // @ts-expect-error TS(2339): Property 'detail' does not exist on type '{}'.
    if (location?.state?.detail === 'openMonitorModal') loadMonitors(location?.state?.status);
    // @ts-expect-error TS(2339): Property 'detail' does not exist on type '{}'.
    else if (location?.state?.detail === 'MonitorsTab') setActiveTab('Resource Status');
  });

  const updateGraphUI = async (data: any) => {
    setLoading(true);
    setError('');
    const { workload, service, metricItem, environment, region } = data;
    const chartTitle = `${service.name} ${metricItem.name}`;

    setChartName(chartTitle);
    const currentViewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / DAY_IN_MILLISECONDS);

    const lineChartOptions = {
      title: chartTitle,
      data: {
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%l:%M%p}',
            formatter: (params: any) => {
              const { isFirst, value } = params;

              if (isFirst) {
                return formatDateShortAndTime(DateTime.fromMillis(value), activeUser.timeZone);
              }

              if (diffDays >= TWO_DAYS && diffDays < SEVEN_DAYS) {
                return formatMonthDateAndTime(DateTime.fromMillis(value), activeUser.timeZone);
              }

              if (diffDays >= SEVEN_DAYS) {
                return formatMonthDate(DateTime.fromMillis(value), activeUser.timeZone);
              }

              return formatTime(DateTime.fromMillis(value), activeUser.timeZone);
            },
          },
        },
        tooltip: {
          valueDecimals: 2,
        },
        chart: {
          zoomType: 'x',
          resetZoomButton: {
            theme: {
              fill: 'white',
              stroke: '#463490',
              'stroke-width': 2,
              style: {
                color: '#463490',
                'text-transform': 'uppercase',
              },
              r: 1,
              states: {
                hover: {
                  fill: '#463490',
                  style: {
                    color: 'white',
                  },
                },
              },
            },
          },
          height: currentViewWidth >= 2560 ? 450 : 350,
        },
        legend: {
          aling: 'center',
          verticalAlign: 'bottom',
        },

        cSVFileName: `${service.name} ${metricItem.name}.csv`,
      },
    };

    try {
      const monitoringMetricsCallParams = {
        params: { customerId },
        query: {
          startDate: toUtcIso(startDate, activeUser.timeZone),
          endDate: toUtcIso(endDate, activeUser.timeZone),
          workload: workload === ALL_WORKLOADS ? null : workload,
          environment: environment === ALL_ENVIRONMENTS ? null : environment,
          region: region === ALL_REGIONS ? null : region,
          service: service.id,
          metrics: metricItem.ids.map((x: any) => `'${x}'`).join(','),
        },
      };

      setMonitoringMetricsCSVParams(monitoringMetricsCallParams);
      const metricsDataReceived = await API.graphql(
        graphqlOperation(getMonitoringMetrics, monitoringMetricsCallParams)
      );

      const metricsData =
        (metricsDataReceived as any)?.data?.getMonitoringMetrics?.items?.map((recievedMetric: any) => {
          const tags = JSON.parse(recievedMetric.tags);

          recievedMetric.tags = tags?.length ? <TagsToolTip tags={tags} /> : 'No Tags';
          recievedMetric.name = getNameTag(tags);
          return recievedMetric;
        }) ?? [];

      setTableData(metricsData);

      let yAxisLabel = '';
      let yAxisMax = null;

      if (metricItem.ids.some((x: any) => x.toLowerCase().indexOf('cpu') > 0)) {
        yAxisLabel = 'percent';
        yAxisMax = 100;
      } else {
        yAxisLabel = 'bytes';
      }

      // @ts-expect-error TS(2551): Property 'yAxis' does not exist on type '{ xAxis: ... Remove this comment to see the full error message
      lineChartOptions.data.yAxis = {
        labels: {
          formatter: (params: any) => {
            const { value } = params;

            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type
            return yAxisFormat[yAxisLabel](value);
          },
        },
        max: yAxisMax,
      };
      (lineChartOptions.data as any).series = [
        ...metricsData.map((metricData: any) => ({
          name: metricData.name || metricData.id || '',
          data: metricData.metrics.map((newMetric: any) => {
            const timeAsUTC = newMetric.timestamp.split(/[^0-9]/);
            const one = 1;
            timeAsUTC[one] = (parseInt(timeAsUTC[one]) - one).toString();
            return {
              y: Number(newMetric.value),
              // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
              x: new Date(...timeAsUTC).getTime(),
            };
          }),
        })),
      ];
      (lineChartOptions.data as any).errorLoadingData = false;
      // @ts-expect-error TS(2345): Argument of type '{ title: string; data: { xAxis: ... Remove this comment to see the full error message
      setChartOptions(lineChartOptions);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      if (
        error &&
        (error as any).errors &&
        (error as any).errors.length > 0 &&
        (error as any).errors[0].message &&
        (error as any).errors[0].message.toLowerCase() === 'transformation too large'
      ) {
        errorToast('Error Loading Data: Please try reducing your date/time range and filter again.');
      }
      console.error('Error Loading Data', error);
      setError('Error Loading Data');
    }

    setLoading(false);
  };

  const buildEmpty = () => {
    const chartTitle = 'No Data';

    setChartName(chartTitle);
    const currentViewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    const lineChartOptions = {
      title: chartTitle,
      data: {
        chart: {
          height: currentViewWidth >= 2560 ? 450 : 350,
        },
        cSVFileName: 'metrics.csv',
        series: [],
      },
    };

    // @ts-expect-error TS(2345): Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
    setTableData([]);
    // @ts-expect-error TS(2345): Argument of type '{ title: string; data: { chart: ... Remove this comment to see the full error message
    setChartOptions(lineChartOptions);
    setLoading(false);
  };

  const generateMainDropdowns = (selectedOption: any) => {
    const environmentList: any = [];
    const workloadList: any = [];
    const regionList: any = [];

    selectedOption.forEach((element: any) => {
      if (element.environments) {
        const environmentDropdownList = element.environments.map((x: any) => ({
          value: x.id,
          label: x.id,
        }));

        environmentDropdownList.forEach((c: any) => {
          // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
          if (!environmentList.some(e => e.value === c.value)) {
            environmentList.push(c);
          }
        });
      }

      if (element.regions) {
        const regionDropdownList = element.regions.map((x: any) => ({
          value: x.id,
          label: x.id,
        }));

        regionDropdownList.forEach((c: any) => {
          // @ts-expect-error TS(7006): Parameter 'r' implicitly has an 'any' type.
          if (!regionList.some(r => r.value === c.value)) {
            regionList.push(c);
          }
        });
      }

      if (element.workloads) {
        const workloadDropdownList = element.workloads.map((x: any) => ({
          value: x.id,
          label: x.id,
        }));

        workloadDropdownList.forEach((c: any) => {
          // @ts-expect-error TS(7006): Parameter 'w' implicitly has an 'any' type.
          if (!workloadList.some(w => w.value === c.value)) {
            workloadList.push(c);
          }
        });
      }
    });

    if (environmentList.length > 0) {
      environmentList.unshift({ value: ALL_ENVIRONMENTS, label: ALL_ENVIRONMENTS });
      setEnvironmentDropdown(environmentList);
      // if (!environmentList.find(x => x.value === selectedEnvironment.value)) {
      //   setSelectedEnvironment(environmentList[0]);
      // }
    }
    if (workloadList.length > 0) {
      workloadList.unshift({ value: ALL_WORKLOADS, label: ALL_WORKLOADS });
      setWorkloadDropdown(workloadList);

      // if (!workloadList.find(x => x.value === selectedWorkload.value)) {
      //   setSelectedWorkload(workloadList[0]);
      // }
    }
    if (regionList.length > 0) {
      regionList.unshift({ value: ALL_REGIONS, label: ALL_REGIONS });
      setRegionDropdown(regionList);
      // if (!regionList.find(x => x.value === selectedRegion.value)) {
      //   setSelectedRegion(regionList[0]);
      // }
    }
  };

  const generateSubDropdowns = (selectedOption: any, type: any) => {
    const serviceList: any = [];
    let isAllRegionsID = false;

    selectedOption.forEach((option: any) => {
      if (option.id === ALL_REGIONS) isAllRegionsID = true;
      if (option.environments) {
        option.environments.forEach((x: any) => {
          serviceList.push(x.services);
        });
      }
      if (option.regions) {
        option.regions.forEach((x: any) => {
          serviceList.push(x.services);
        });
      }
      if (option.workloads) {
        option.workloads.forEach((x: any) => {
          serviceList.push(x.services);
        });
      }
    });

    const uniqueServices = [...new Set(serviceList.flat())];

    if (uniqueServices.length) {
      const services = uniqueServices.map(x => (filters as any).services.find((y: any) => y.id === x));
      const serviceDropdownList = services.map(x => ({ value: x.id, label: x.name }));

      // @ts-expect-error TS(2345): Argument of type '{ value: any; label: any; }[]' i... Remove this comment to see the full error message
      setServiceDropdown(serviceDropdownList);
      setSelectedService(serviceDropdownList[0]);

      const metricDropdownList = services[0].metrics.map((x: any) => ({
        value: x.ids,
        label: x.name,
      }));

      setMetricDropdown(metricDropdownList);
      setSelectedMetric(metricDropdownList[0]);

      // Below is needed for when there are no workloads or environments
    } else if (isAllRegionsID) {
      const serviceDropdownList = (filters as any).services.map((x: any) => ({
        value: x.id,
        label: x.name,
      }));

      setServiceDropdown(serviceDropdownList);
      setSelectedService(serviceDropdownList[0]);

      const metricDropdownList = (filters as any).services[0].metrics.map((x: any) => ({
        value: x.ids,
        label: x.name,
      }));

      setMetricDropdown(metricDropdownList);
      setSelectedMetric(metricDropdownList[0]);
    } else if (type === REGIONS && filters.length > 0) {
      const allWorkloads = (filters as any).workloads.find((x: any) => x.id === ALL_WORKLOADS);
      const serviceDropdowns: any = [];

      selectedOption.forEach((option: any) => {
        const workloadRegion = allWorkloads.regions.find((region: any) => region.id === option.id);
        const serviceDropdownList = workloadRegion.services.map((serv: any) => {
          const service = (filters as any).services.find((x: any) => x.id === serv);
          return { value: service.id, label: service.name };
        });
        serviceDropdowns.push(serviceDropdownList);
        serviceDropdownList.forEach((c: any) => {
          // @ts-expect-error TS(7006): Parameter 'w' implicitly has an 'any' type.
          if (!serviceDropdowns.some(w => w.value === c.value)) {
            serviceDropdowns.push(c);
          }
        });
      });

      setServiceDropdown(serviceDropdowns);
      setSelectedService(serviceDropdowns[0]);

      const metricDropdownList = (filters as any).services
        .find((serv: any) => serv.id === serviceDropdowns[0].value)
        .metrics.map((x: any) => ({
          value: x.ids,
          label: x.name,
        }));

      setMetricDropdown(metricDropdownList);
      setSelectedMetric(metricDropdownList[0]);
    }
  };

  const updateDropdowns = (value: any, type: any) => {
    if (type === WORKLOADS) {
      const workloadData: any = [];

      value.forEach((element: any) => {
        const data = (filters as any).workloads.find((x: any) => x.id === element.value);
        workloadData.push(data);
      });
      generateMainDropdowns(workloadData);
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      generateSubDropdowns(workloadData);
    } else if (type === ENVIRONMENTS) {
      const environmentData: any = [];

      value.forEach((element: any) => {
        const data = (filters as any).environments.find((x: any) => x.id === element.value);
        environmentData.push(data);
      });
      generateMainDropdowns(environmentData);
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      generateSubDropdowns(environmentData);
    } else if (type === REGIONS && filters.length > 0) {
      const regionData: any = [];

      value.forEach((element: any) => {
        const data = (filters as any).regions.find((x: any) => x.id === element.value);
        regionData.push(data);
      });

      generateMainDropdowns(regionData);
      generateSubDropdowns(regionData, type);
    } else if (type === SERVICES) {
      const metricDropdownList = (filters as any).services
        .find((x: any) => x.id === value.value)
        .metrics.map((x: any) => ({
          value: x.ids,
          label: x.name,
        }));

      setMetricDropdown(metricDropdownList);
      setSelectedMetric(metricDropdownList[0]);
    }
  };

  const onWorkloadChange = (dropdownSelection: any) => {
    setSelectedWorkload(dropdownSelection);
    if (!noData) {
      updateDropdowns(dropdownSelection, WORKLOADS);
    }
  };
  const onServiceChange = (dropdownSelection: any) => {
    setSelectedService(dropdownSelection);
    updateDropdowns(dropdownSelection, SERVICES);
  };
  const onEnvironmentChange = (dropdownSelection: any) => {
    setSelectedEnvironment(dropdownSelection);
    updateDropdowns(dropdownSelection, ENVIRONMENTS);
  };
  const onRegionChange = (dropdownSelection: any) => {
    setSelectedRegion(dropdownSelection);
    updateDropdowns(dropdownSelection, REGIONS);
  };

  const onStartDateChange = (date: any) => {
    if (date >= endDate) {
      const updateEndDate = new Date(date);

      updateEndDate.setMinutes(updateEndDate.getMinutes() + TIMEDIFF);
      setEndDate(updateEndDate);
    }
    setStartDate(date);
  };

  const onEndDateChange = (date: any) => {
    if (date <= startDate) {
      const updateStartDate = new Date(date);

      updateStartDate.setMinutes(updateStartDate.getMinutes() - TIMEDIFF);
      setStartDate(updateStartDate);
    }
    setEndDate(date);
  };

  const onRelativeTimeRangeChange = (range: any) => {
    setEndDate(convertToAnotherTimeZone(new Date(), activeUser.timeZone));
    const updateStartDate = convertToAnotherTimeZone(new Date(), activeUser.timeZone);

    if (range.relativeTime === TIME_RANGES.fifteenMin.relativeTime)
      updateStartDate.setMinutes(updateStartDate.getMinutes() - TIME_RANGES.fifteenMin.value);
    else if (range.relativeTime === TIME_RANGES.thirtyMin.relativeTime)
      updateStartDate.setMinutes(updateStartDate.getMinutes() - TIME_RANGES.thirtyMin.value);
    else if (range.relativeTime === TIME_RANGES.oneHr.relativeTime)
      updateStartDate.setHours(updateStartDate.getHours() - TIME_RANGES.oneHr.value);
    else if (range.relativeTime === TIME_RANGES.threeHrs.relativeTime)
      updateStartDate.setHours(updateStartDate.getHours() - TIME_RANGES.threeHrs.value);
    else if (range.relativeTime === TIME_RANGES.sixHrs.relativeTime)
      updateStartDate.setHours(updateStartDate.getHours() - TIME_RANGES.sixHrs.value);
    else if (range.relativeTime === TIME_RANGES.twelveHrs.relativeTime)
      updateStartDate.setHours(updateStartDate.getHours() - TIME_RANGES.twelveHrs.value);
    else if (range.relativeTime === TIME_RANGES.twentyFourHrs.relativeTime)
      updateStartDate.setHours(updateStartDate.getHours() - TIME_RANGES.twentyFourHrs.value);
    else if (range.relativeTime === TIME_RANGES.twoDays.relativeTime)
      updateStartDate.setDate(updateStartDate.getDate() - TIME_RANGES.twoDays.value);
    else if (range.relativeTime === TIME_RANGES.sevenDays.relativeTime)
      updateStartDate.setDate(updateStartDate.getDate() - TIME_RANGES.sevenDays.value);

    setStartDate(updateStartDate);
  };

  const onFilterClick = async () => {
    if (
      // @ts-expect-error TS(2551): Property 'value' does not exist on type 'never[]'.... Remove this comment to see the full error message
      (selectedMetric.value === undefined || (selectedService as any).value === undefined) &&
      (appliedStartingDate !== startDate || appliedEndingDate !== endDate || noData === true)
    ) {
      setLoading(true);
      await initializeComponent();

      return;
    }

    let filterWorkload = '';
    let filterRegion = '';
    let filterEnvironment = '';

    if ((selectedWorkload as any).length === 0) {
      const noDataWorkloads = [{ value: ALL_WORKLOADS, label: ALL_WORKLOADS }];

      // eslint-disable-next-line @typescript-eslint/await-thenable
      await setSelectedWorkload(noDataWorkloads[0]);
      filterWorkload = noDataWorkloads[0].value;
    } else {
      filterWorkload =
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (selectedWorkload as any).value === undefined ? selectedWorkload[0].value : (selectedWorkload as any).value;
    }

    if ((selectedRegion as any).length === 0) {
      const noDataRegions = [{ value: ALL_REGIONS, label: ALL_REGIONS }];

      // eslint-disable-next-line @typescript-eslint/await-thenable
      await setSelectedRegion(noDataRegions[0]);
      filterRegion = noDataRegions[0].value;
    } else {
      filterRegion =
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (selectedRegion as any).value === undefined ? selectedRegion[0].value : (selectedRegion as any).value;
    }

    if ((selectedEnvironment as any).length === 0) {
      const noDataEnvironments = [{ value: ALL_ENVIRONMENTS, label: ALL_ENVIRONMENTS }];

      // eslint-disable-next-line @typescript-eslint/await-thenable
      await setSelectedEnvironment(noDataEnvironments[0]);
      filterEnvironment = noDataEnvironments[0].value;
    } else {
      filterEnvironment =
        (selectedEnvironment as any).value === undefined
          ? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            selectedEnvironment[0].value
          : (selectedEnvironment as any).value;
    }

    if ((selectedWorkload as any).length > 1) {
      filterWorkload = '';
      let isWorkExist = false;

      (selectedWorkload as any).forEach((val: any) => {
        if (val.value === ALL_WORKLOADS) {
          isWorkExist = true;
        }
        filterWorkload += `${val.value},`;
      });

      if (!isWorkExist) filterWorkload = filterWorkload.substring(0, filterWorkload.length - 1);
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
      else filterWorkload = null;
    }
    if ((selectedRegion as any).length > 1) {
      filterRegion = '';
      let isRegExist = false;

      (selectedRegion as any).forEach((val: any) => {
        if (val.value === ALL_REGIONS) {
          isRegExist = true;
        }
        filterRegion += `${val.value},`;
      });

      if (!isRegExist) filterRegion = filterRegion.substring(0, filterRegion.length - 1);
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
      else filterRegion = null;
    }
    if ((selectedEnvironment as any).length > 1) {
      filterEnvironment = '';
      let isEnvExist = false;

      (selectedEnvironment as any).forEach((val: any) => {
        if (val.value === ALL_ENVIRONMENTS) {
          isEnvExist = true;
        }
        filterEnvironment += `${val.value},`;
      });

      if (!isEnvExist) filterEnvironment = filterEnvironment.substring(0, filterEnvironment.length - 1);
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
      else filterEnvironment = null;
    }

    const newFilter = {
      workload: filterWorkload,
      service: { id: (selectedService as any).value, name: (selectedService as any).label },
      // @ts-expect-error TS(2551): Property 'value' does not exist on type 'never[]'.... Remove this comment to see the full error message
      metricItem: { ids: selectedMetric.value, name: (selectedMetric as any).label },
      environment: filterEnvironment,
      region: filterRegion,
      columns,
    };

    setAppliedFilters(newFilter);
    updateGraphUI(newFilter);
  };

  const initializeMetricsOptionsDropdown = (generateCSVFunction: any) => {
    // @ts-expect-error TS(2345): Argument of type '{ text: string; downloadCSV: any... Remove this comment to see the full error message
    setMetricsChartOptions([
      {
        text: 'Download CSV',
        downloadCSV: generateCSVFunction,
      },
    ]);
  };

  const getNameTag = (tags: any) => {
    if (!tags || !tags.length) {
      return '';
    }
    const [tag] = tags.filter((t: any) => t.Key === 'Name');

    return (tag && tag.Value) || '';
  };

  const optionsDropdown = [
    {
      text: 'Export CSV',
      action: async () => {
        const cId = isEffectualUser ? customerIds.empath : customerId;
        const reportName = reports.myCloudReport;

        setIsOptionsLoading(true);

        await exportCSVReport(activeUser, cId, reportName, monitoringMetricsCSVParams);
        setIsOptionsLoading(false);
      },
    },
  ];

  const cancel = () => {
    setShowMonitoringModal(false);
  };

  return (
    <div className="page-container">
      <PageHeader title="My Cloud" />
      {activeTab === 'Cloud Metrics' && (
        <Tabs
          tabs={[{ title: 'Cloud Metrics', active: true }, { title: 'Resource Status' }]}
          tabSelected={updateActiveTab}
        />
      )}
      {activeTab === 'Resource Status' && (
        <Tabs
          tabs={[{ title: 'Cloud Metrics' }, { title: 'Resource Status', active: true }]}
          tabSelected={updateActiveTab}
        />
      )}
      {activeTab === 'Cloud Metrics' && (
        <>
          {!isInitialLoad.current && (
            <div className="flex flex-col justify-end w-full mb-3 xl:flex-row">
              <div className="flex items-center justify-end w-full text-purple-500 xl:hidden">
                <button
                  className="px-2 py-1 mb-2 rounded-sm focus:ring-0"
                  type="button"
                  onClick={() => setShowFilters(prevState => !prevState)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    className="mr-1 text-purple-500"
                    style={{
                      // @ts-expect-error TS(2322): Type '{ '--ring-color': string; }' is not assignab... Remove this comment to see the full error message
                      '--ring-color': 'transparent',
                    }}
                  />{' '}
                  Filters
                </button>
              </div>

              {/* @ts-expect-error TS(2559): Type '{ children: false | Element; }' has no properties in common with type 'IntrinsicAttributes & AnimatePresenceProps'. */}
              <AnimatePresence>
                {showFilters && (
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={{
                      initial: {
                        opacity: viewWidth >= 640 ? 1 : 0,
                        y: viewWidth >= 640 ? 0 : -100,
                        scaleY: viewWidth >= 640 ? 1 : 0,
                      },
                      in: {
                        opacity: 1,
                        scaleY: 1,
                        y: 0,
                        x: 0,
                      },
                      out: {
                        opacity: viewWidth >= 640 ? 1 : 0,
                        y: viewWidth >= 640 ? 0 : -100,
                        scaleY: viewWidth >= 640 ? 1 : 0,
                      },
                    }}
                    transition={{ duration: 0.25, type: 'tween' }}
                    className="flex flex-col justify-end w-full xl:flex-row"
                  >
                    <div className="w-full">
                      <div className="flex flex-col xl:flex-row flex-nowrap justify-end">
                        <div className="w-full xl:w-48 mb-2 sm:mb-2 mr-0 sm:mr-4 flex-shrink">
                          <Select
                            options={workloadDropdown}
                            // @ts-expect-error TS(2322): Type '{ options: never[]; onChange: Dispatch<SetStateAction<never[]>>;
                            onChange={onWorkloadChange}
                            isMulti
                            isClearable={false}
                            value={selectedWorkload}
                            disabled={loading}
                          />
                        </div>

                        <div className="w-full xl:w-48 mb-4 sm:mb-2 mr-0 sm:mr-4 flex-shrink">
                          <Select
                            options={environmentDropdown}
                            // @ts-expect-error TS(2322): Type '{ options: never[]; onChange: Dispatch<SetStateAction<never[]>>;
                            onChange={onEnvironmentChange}
                            isMulti
                            isClearable={false}
                            value={selectedEnvironment}
                            disabled={loading}
                          />
                        </div>

                        <div className="w-full xl:w-48 mb-2 sm:mb-2 mr-0 sm:mr-4 flex-shrink">
                          <Select
                            options={regionDropdown}
                            // @ts-expect-error TS(2322): Type '{ options: never[]; onChange: Dispatch<SetStateAction<never[]>>;
                            onChange={onRegionChange}
                            isMulti
                            isClearable={false}
                            value={selectedRegion}
                            disabled={loading}
                          />
                        </div>
                        {!!serviceDropdown.length && (
                          <div className="w-full xl:w-48 mb-2 mr-0 sm:mr-4 sm:mb-2 flex-shrink">
                            <Select
                              options={serviceDropdown}
                              // @ts-expect-error TS(2322): Type '{ options: never[]; onChange: Dispatch<SetStateAction<never[]>>;
                              onChange={onServiceChange}
                              value={selectedService}
                              disabled={loading}
                            />
                          </div>
                        )}

                        {!!metricDropdown.length && (
                          <div className="w-full xl:w-48 mb-2 mr-0 sm:mr-4 sm:mb-2 flex-shrink">
                            <Select
                              options={metricDropdown}
                              // @ts-expect-error TS(2322): Type '{ options: never[]; onChange: Dispatch<SetStateAction<never[]>>;
                              onChange={setSelectedMetric}
                              value={selectedMetric}
                              disabled={loading}
                            />
                          </div>
                        )}

                        <div className="w-full xl:w-48 mb-2 sm:mb-2 mr-0 sm:mr-4 flex-shrink">
                          <ToolTip
                            header=""
                            showOnClick
                            content={
                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <p className="text-purple-700">Absolute Time Range</p>

                                  <p>
                                    <b>From :</b>
                                  </p>

                                  <DatePicker
                                    disabled={loading}
                                    displayFormat="D, t"
                                    showTimeSelect
                                    date={startDate}
                                    onChange={(date: any) => {
                                      onStartDateChange(date);
                                    }}
                                    inputClassName="sm:w-48 mb-2 sm:mb-0 mr-0 sm:mr-4 sm:mb-2"
                                    // @ts-expect-error TS(2322): Type '{ date: Date; disabled: boolean; displayFormat: string;
                                    utc
                                  />

                                  <p>
                                    <b>To :</b>
                                  </p>

                                  <DatePicker
                                    date={endDate}
                                    disabled={loading}
                                    displayFormat="D, t"
                                    showTimeSelect
                                    onChange={(date: any) => {
                                      onEndDateChange(date);
                                    }}
                                    inputClassName="sm:w-48 mb-2 sm:mb-0 mr-0 sm:mr-4 sm:mb-2"
                                    // @ts-expect-error TS(2322): Type '{ date: Date; disabled: boolean; displayFormat: string;
                                    utc
                                  />
                                </div>

                                <div className="border-transparent border-l-2 border-indigo-600">
                                  <p className="px-2 text-purple-700">Relative Time Ranges</p>

                                  <div>
                                    {Object.keys(TIME_RANGES).map(range => {
                                      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                                      const timeRange = TIME_RANGES[range];
                                      return (
                                        <button
                                          key={timeRange.text}
                                          type="button"
                                          onClick={() => onRelativeTimeRangeChange(timeRange)}
                                          className="px-2 text-base text-cool-gray-900 focus:ring-0 transform transition-opacity border-transparent hover:border-solid border-l-4 hover:border-orange"
                                        >
                                          <p className="font-bold">{timeRange.text}</p>
                                        </button>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            }
                            disabled={loading}
                            maxWidth="450px"
                            containerClassName="border-none"
                          >
                            <div
                              className={`text-sm h-7 pt-1 border truncate rounded-sm pl-1 bg-white ${
                                loading ? 'text-gray-400 bg-gray-100' : 'text-purple-700'
                              }`}
                            >
                              <label>
                                {startDate.toLocaleString([], {
                                  month: '2-digit',
                                  day: '2-digit',
                                  year: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                                {' - '}
                                {endDate.toLocaleString([], {
                                  month: '2-digit',
                                  day: '2-digit',
                                  year: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                                <FontAwesomeIcon icon={faCalendarAlt} fixedWidth />
                              </label>
                            </div>
                          </ToolTip>
                        </div>

                        <div className="mb-2 sm:mb-0 mr-0 sm:mr-4 flex-shrink">
                          <Button onClick={onFilterClick} type="button" title="FILTER" loading={loading} />
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}

          {isInitialLoad.current ? (
            <AbsoluteLoader />
          ) : (
            <div className="my-2">
              <Card
                // @ts-expect-error TS(2322): Type '{ chartOptions: undefined; getGenerateCSVFunction:
                className="min-h-100 "
                icon={<FontAwesomeIcon icon={faClouds} fixedWidth />}
                title={chartName}
                optionsDropdown={noData || error ? [] : metricsChartOptions}
                loading={loading}
                errorMessage={error}
                component={
                  <LineChart
                    chartOptions={chartOptions && (chartOptions as any).data}
                    // @ts-expect-error TS(2322): Type '{ chartOptions: undefined; getGenerateCSVFunction:
                    getGenerateCSVFunction={initializeMetricsOptionsDropdown}
                    startDate={startDate}
                    endDate={endDate}
                  />
                }
              />

              <div>
                <EmpathTable
                  title="Resources"
                  icon={<FontAwesomeIcon icon={faClouds} fixedWidth />}
                  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  columns={(!loading && columns[(appliedFilters as any)?.service?.id]) || []}
                  loadData={loadData}
                  viewPortHeight={29}
                  sort={[{ field: 'name', sort: 'asce' }]}
                  initializing={loading}
                  errorMessage={error}
                  containerClassName="mt-6"
                  optionsDropdown={optionsDropdown}
                  isOptionsLoading={isOptionsLoading}
                />
              </div>
            </div>
          )}
        </>
      )}
      {activeTab === 'Resource Status' && (
        <Monitoring modalMonitorId={monitorID} modalStatusValue={monitorStatus} modalDownReason={downReason} />
      )}

      <Modal
        doShow={showMonitoringModal}
        cancel={cancel}
        showXButton
        title={
          <span>
            Resource <span className="text-orange">Status</span>
          </span>
        }
      >
        <div className="grid grid-cols-3 gap-4 text-white">
          {monitorStatusData.map(
            monitor =>
              ((monitor as any).status === 0 && (
                <div className="col-span-3 bg-red-500 p-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div>{(monitor as any).name}</div>

                    <div>{(monitor as any).status_description}</div>

                    <div>
                      <Button
                        buttonStyle="text"
                        type="button"
                        title="View Details"
                        onClick={() =>
                          onMonitorStatusClick(
                            (monitor as any).monitor_id,
                            (monitor as any).status,
                            (monitor as any).down_reason
                          )
                        }
                        classNames="text-white"
                      />
                    </div>
                  </div>
                </div>
              )) ||
              ((monitor as any).status === 3 && (
                <div className="col-span-3 bg-yellow-300 p-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div>{(monitor as any).name}</div>

                    <div>{(monitor as any).status_description}</div>

                    <div>
                      <Button
                        buttonStyle="text"
                        type="button"
                        title="View Details"
                        onClick={() =>
                          onMonitorStatusClick(
                            (monitor as any).monitor_id,
                            (monitor as any).status,
                            (monitor as any).down_reason
                          )
                        }
                        classNames="text-white"
                      />
                    </div>
                  </div>
                </div>
              )) ||
              ((monitor as any).status === 2 && (
                <div className="col-span-3 bg-yellow-500 p-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div>{(monitor as any).name}</div>

                    <div>{(monitor as any).status_description}</div>

                    <div>
                      <Button
                        buttonStyle="text"
                        type="button"
                        title="View Details"
                        onClick={() =>
                          onMonitorStatusClick(
                            (monitor as any).monitor_id,
                            (monitor as any).status,
                            (monitor as any).down_reason
                          )
                        }
                        classNames="text-white"
                      />
                    </div>
                  </div>
                </div>
              )) ||
              ((monitor as any).status === 1 && (
                <div className="col-span-3 bg-emerald-600 p-2">
                  <div className="grid grid-cols-3 gap-4">
                    <div>{(monitor as any).name}</div>
                    <div>{(monitor as any).status_description}</div>
                    <div>
                      <Button
                        buttonStyle="text"
                        type="button"
                        title="View Details"
                        onClick={() =>
                          onMonitorStatusClick(
                            (monitor as any).monitor_id,
                            (monitor as any).status,
                            (monitor as any).down_reason
                          )
                        }
                        classNames="text-white"
                      />
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MyCloudDashboard;
