import React from 'react';
// @ts-expect-error TS(2307): Cannot find module '../../assets/unableToLoadPageG... Remove this comment to see the full error message
import { ReactComponent as UnableToLoadPageGraphic } from '../../assets/unableToLoadPageGraphic.svg';

const UnableToLoadPage = () => (
  <div className="flex flex-col mt-40">
    <div>
      <p className="text-orange text-center text-xl px-3 lg:text-3xl uppercase">Unable to load page</p>
    </div>
    <div className="mt-3">
      <p className="text-purple-500 text-center lg:text-xl lg:mr-3">
        We can't seem to load your data, refresh the page to try again
      </p>
    </div>
    <div className="mt-5">
      <UnableToLoadPageGraphic className="mx-auto" />
    </div>
  </div>
);

export default UnableToLoadPage;
