import { API, graphqlOperation } from 'aws-amplify';
import { createTicketComment } from '../../graphql/mutations';

const INCIDENT = 'Incident';
const CHANGEREQUEST = 'ChangeRequest';
const SUPPORTREQUEST = 'Support Request';
const YES = 'yes';
const APPROVE = 'approve';
const DENY = 'deny';

const generateApproveDenyComment = (impersonation: any, user: any, action: any) => {
  let comment = '';

  if (impersonation && impersonation.user) {
    comment = `${user.firstName} ${user.lastName} has requested to ${
      action === YES ? APPROVE : DENY
    } this change request on behalf of ${impersonation.user.firstName} ${impersonation.user.lastName} (${
      impersonation.user.email
    }).`;
  } else if (impersonation) {
    comment += `${user.firstName} ${user.lastName} has requested to ${
      action === YES ? APPROVE : DENY
    } this change request on behalf of ${impersonation.company}.`;
  } else {
    comment += `Customer (${user.firstName} ${user.lastName} (${user.email})) has requested to ${
      action === YES ? APPROVE : DENY
    } this change request.`;
  }

  return comment;
};

const createComment = async (customerId: any, ticketId: any, comment: any) => {
  const params = {
    params: { customerId, ticketId },
    body: {
      data: JSON.stringify({
        sys_id: ticketId,
        id: ticketId,
        comments: comment,
      }),
    },
  };

  return API.graphql(graphqlOperation(createTicketComment, params));
};

const ticketIsClosed = (ticket: any) => {
  if (ticket.type === INCIDENT && ['6', '7', '8'].includes(ticket.data.state)) {
    return true;
  }

  if (ticket.type === CHANGEREQUEST && ['3', '4'].includes(ticket.data.state)) {
    return true;
  }

  if (ticket.type === SUPPORTREQUEST && ['3', '6', '7'].includes(ticket.data.state)) {
    return true;
  }

  return false;
};

export { createComment, generateApproveDenyComment, ticketIsClosed };
