import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';

import { listEmpathUsers } from '../../graphql/queries';
import { useUser } from '../../context/UserContext';
import { Button } from '../buttons';
import {
  groupsForImpersonation,
  featureFlagGroups,
  customerIds,
  effectualGroups,
  reports,
} from '../../shared/groupsHelper';
import { searchDataSet, sortDataSet, executeAsync } from '../../shared/helper';
import { EmpathTable, exportCSVReport } from '.';

type Props = {
  customer?: any;
  showActions?: boolean;
  nameIsLink?: boolean;
  hideIcon?: boolean;
};

const UsersTable = ({ customer, showActions = false, nameIsLink = false, hideIcon = false }: Props) => {
  const [loading, setLoading] = useState(true);
  const [tableError, setTableError] = useState('');
  const [tableData, setTableData] = useState([]);
  const [listEmpathUserCSVParams, setListEmpathUserCSVParams] = useState({});
  const { url } = useRouteMatch();
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  // @ts-expect-error TS(2339): Property 'setImpersonation' does not exist on type 'unknown'.
  const { isImpersonating, impersonation, isEffectualUser, userInRoles, setImpersonation, activeUser } = useUser();
  const history = useHistory();

  useEffect(() => {
    const getCustomerUsers = async () => {
      try {
        const listEmpathUsersCallParams = {
          query: { isImpersonating: true, customerId: customer.id },
        };
        setListEmpathUserCSVParams(listEmpathUsersCallParams);
        const response = await API.graphql(graphqlOperation(listEmpathUsers, listEmpathUsersCallParams));
        setTableData((response as any).data.listEmpathUsers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users: ', error);
        setTableError('Error Loading Users');
        setLoading(false);
      }
    };
    const getEmpathUsers = async () => {
      try {
        const listEmpathUsersCallParams = {
          query: { isImpersonating: false },
        };
        setListEmpathUserCSVParams(listEmpathUsersCallParams);
        const response = await API.graphql(graphqlOperation(listEmpathUsers, listEmpathUsersCallParams));
        setTableData((response as any).data.listEmpathUsers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users: ', error);
        setTableError('Error Loading Users');
        setLoading(false);
      }
    };
    if (customer) {
      getCustomerUsers();
    } else {
      getEmpathUsers();
    }
  }, [impersonation, isImpersonating, customer]);

  const showTableActions = () => {
    if (isEffectualUser && userInRoles([effectualGroups.effectualObserver.groupName])) {
      return false;
    }

    return true;
  };

  const impersonateUser = async (userToImpersonate: any) => {
    if (!userInRoles(groupsForImpersonation)) {
      return;
    }

    const usersRes = await API.graphql(
      graphqlOperation(listEmpathUsers, {
        query: { isImpersonating: true, customerId: customer.id },
      })
    );

    const { featureFlags } = customer;
    const featureAccessList = featureFlags
      ? Object.keys(featureFlags).filter(feature => featureFlags[feature] === true)
      : [featureFlagGroups.supportOnly.name];
    const users = (usersRes as any).data.listEmpathUsers.map((user: any) => ({
      ...user,
      featureFlags: featureAccessList,
    }));

    userToImpersonate.featureFlags = featureAccessList;

    setImpersonation({
      cid: customer.id,
      company: customer.name,
      groups: userToImpersonate.groups,
      users,
      user: userToImpersonate,
      cognitoUser: {
        sub: userToImpersonate.sub,
        username: userToImpersonate.sub,
      },
    });

    history.push('/');
  };

  const columns = [
    {
      header: 'Name',
      accessor: 'firstName',
      cell: function NameCell(cellInfo: any) {
        if (nameIsLink) {
          return (
            <Button
              type="button"
              buttonStyle="text"
              title={`${cellInfo.row.original.firstName} ${cellInfo.row.original.lastName}`}
              onClick={() => {
                history.push(`${url}/details/${cellInfo.row.original.id}`);
              }}
            />
          );
        }

        return <span>{`${cellInfo.row.original.firstName} ${cellInfo.row.original.lastName}`}</span>;
      },
    },
    { header: 'Email', accessor: 'email' },
    { header: 'Role', accessor: 'groups' },
  ];

  if (showActions) {
    columns.push({
      header: '',
      accessor: 'actions',
      cell: function ActionCell(cellInfo) {
        return (
          <div className="flex">
            <Button
              title="Edit"
              buttonStyle="text"
              onClick={() => {
                history.push(`${url}/details/${cellInfo.row.original.id}`);
              }}
            />
            {isEffectualUser && customer ? (
              <Button
                title="Impersonate"
                buttonStyle="text"
                classNames="ml-4"
                onClick={() => impersonateUser(cellInfo.row.original)}
              />
            ) : null}
          </div>
        );
      },
    });
  }

  const loadData = useCallback(
    async (options: any) =>
      executeAsync(() => {
        const { sort, search } = options;
        let items = tableData.map(m => m);

        if (sort) {
          items = sortDataSet(items, sort);
        }

        if (search) {
          const searchOptions = {
            searchString: search,
            keys: ['name', 'email', 'groups'],
          };

          items = searchDataSet(items, searchOptions);
        }

        return {
          items: items ?? [],
        };
      }),
    [tableData]
  );

  const optionsDropdown = [
    {
      text: 'Export CSV',
      action: async () => {
        const cId = isEffectualUser ? customerIds.empath : customer.id;
        const reportName = reports.userListReport;

        setIsOptionsLoading(true);
        await exportCSVReport(activeUser, cId, reportName, listEmpathUserCSVParams);
        setIsOptionsLoading(false);
      },
    },
  ];

  return (
    <EmpathTable
      title="Users"
      icon={!hideIcon ? <FontAwesomeIcon icon={faUsers} fixedWidth /> : null}
      columns={columns}
      viewPortHeight={50}
      loadData={loadData}
      initializing={loading}
      errorMessage={tableError}
      sort={[{ field: 'firstName', sort: 'asce' }]}
      callsToAction={
        showTableActions() && [
          {
            label: 'Add User',
            action: () => {
              history.push('users/details');
            },
          },
        ]
      }
      optionsDropdown={optionsDropdown}
      isOptionsLoading={isOptionsLoading}
    />
  );
};

export default UsersTable;
