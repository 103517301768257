import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import { GraphQLResult } from '@aws-amplify/api';

import { userBySub, usersByEmail, getSiteSettings } from './graphql/queries';
import BaseRoutes from './routes/BaseRoutes';
import { UserProvider } from './context/UserContext';
import { SiteProvider } from './context/SiteContext';
import { SplashLoader } from './components/loader';
import { updateUser, updateCognitoUser } from './graphql/mutations';
import ErrorBoundary from './components/errors/ErrorBoundary';
import Unavailable from './pages/errors/Unavailable';
import { customerIds, getFeatureAcess } from './shared/groupsHelper';

// eslint-disable-next-line import/no-unresolved
import './styles/output.css';
import { ISiteSettings } from './models';

interface IDynamoUser {
  id: string;
  identityId: string;
  groups: string[];
  customerId: string;
  featureFlags: string[];
}

const App = () => {
  const empathImpersonationKey = 'Empath';
  const empathLastActivity = 'LastActivity';
  const oneMinute = 60000;
  const threeSeconds = 3000;
  const fiveSeconds = 5000;
  const logoutTime = 7200000; // 2 hrs
  const useMountEffect = (func: () => void) => useEffect(func, []);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [cognitoUser, setCognitoUser] = useState();
  const [user, setUser] = useState<null | IDynamoUser>(null);
  const [servicesStatus, setServicesStatus] = useState<null | ISiteSettings>(null);
  const [groups, setGroups] = useState<string[]>([]);
  const [impersonation, setImpersonation] = useState();
  const [userNavigationHistoryStack, setUserNavigationHistoryStack] = useState({
    currentPage: window.location.pathname,
    lastVisitedPage: '',
  });

  let lastActivity: number;
  let lastTimerCheck = Date.now();
  let lastActivityWrite = Date.now();
  let inactivityTimer: number | undefined | NodeJS.Timeout;

  const checkInactivity = () => {
    const lastAcitivity = localStorage.getItem(empathLastActivity) || Date.now();
    const time = Number(lastAcitivity);

    if (lastAcitivity && !Number.isNaN(time)) {
      const now = Date.now();

      if (now - time > logoutTime) {
        doLogout();

        return true;
      }
    }
  };

  const writeActivityToStorage = (activityDate: number) => {
    localStorage.setItem(empathLastActivity, activityDate.toString());
    lastActivityWrite = activityDate;
  };

  const initInactivityTimer = () => {
    const initTime = Date.now();

    lastActivity = initTime;
    writeActivityToStorage(initTime);
    inactivityTimer = setInterval(() => {
      const now = Date.now();
      const diff = now - lastTimerCheck;

      if (diff > fiveSeconds) {
        checkInactivity();
      }
      if (now - lastActivity > logoutTime) {
        doLogout();
      }
      lastTimerCheck = now;
    }, threeSeconds);
    document.addEventListener('mousedown', writeActivity);
    document.addEventListener('keydown', writeActivity);
  };

  const removeInactivityTimer = () => {
    document.removeEventListener('mousedown', writeActivity);
    document.removeEventListener('keydown', writeActivity);
  };

  const writeActivity = () => {
    const now = Date.now();
    const diff = now - lastActivityWrite;

    if (diff > oneMinute) {
      writeActivityToStorage(now);
    }
    lastActivity = now;
  };

  const doLogout = () => {
    localStorage.removeItem(empathLastActivity);
    clearInterval(inactivityTimer);
    window.location.replace('/logout');
  };

  const initialize = () => {
    (async () => {
      try {
        const cogUser = await Auth.currentAuthenticatedUser();
        const creds = await Auth.currentUserCredentials();

        if (cogUser?.username && window.location.pathname !== '/logout') {
          if (checkInactivity()) {
            return;
          }
          initInactivityTimer();
        }
        let isSsoUser = false;

        if (cogUser.attributes.identities && JSON.parse(cogUser.attributes.identities)[0].providerType === 'SAML') {
          isSsoUser = true;
        }

        if (
          !isSsoUser &&
          cogUser &&
          cogUser.preferredMFA === 'NOMFA' &&
          !cogUser.attributes['custom:skip_MFA'] &&
          window.location.pathname.toLowerCase() !== '/login'
        ) {
          window.location.href = '/login';

          return;
        }

        if (!cogUser.attributes?.email) {
          return cogUser;
        }

        const {
          signInUserSession: {
            idToken: { payload },
          },
        } = cogUser;

        try {
          let dynamoUser;

          if (isSsoUser) {
            const response = (await API.graphql(
              graphqlOperation(usersByEmail, { email: cogUser.attributes.email.toLowerCase() })
            )) as GraphQLResult<{ usersByEmail: { items: IDynamoUser[] } }>;
            dynamoUser = response?.data?.usersByEmail.items[0];

            if (!dynamoUser) {
              throw new Error('Dynamo record is undefined');
            }

            // identityId is empty on first login
            if (!dynamoUser.identityId) {
              const params = {
                id: dynamoUser.id,
                identityId: creds.identityId,
              };

              await API.graphql(graphqlOperation(updateUser, { input: params }));

              await API.graphql(
                graphqlOperation(updateCognitoUser, {
                  params: { username: btoa(cogUser.username.toLowerCase()) },
                  body: { newgroup: dynamoUser.groups[0] },
                })
              );
            }

            setGroups(dynamoUser.groups || []);
          } else {
            const response = (await API.graphql(
              graphqlOperation(userBySub, { sub: cogUser.attributes.sub })
            )) as GraphQLResult<{ userBySub: { items: IDynamoUser[] } }>;

            dynamoUser = response?.data?.userBySub.items[0];

            if (!dynamoUser) {
              throw new Error('Dynamo record is undefined');
            }

            // identityId is empty on first login
            if (!dynamoUser.identityId) {
              const params = {
                id: dynamoUser.id,
                identityId: creds.identityId,
              };

              await API.graphql(graphqlOperation(updateUser, { input: params }));
            }

            setGroups(payload['cognito:groups'] || []);
          }

          setCognitoUser(await Auth.currentAuthenticatedUser({ bypassCache: true }));

          const { customerId } = dynamoUser;

          if (customerId.toLowerCase() !== customerIds.empath) {
            dynamoUser.featureFlags = await getFeatureAcess(customerId);
          }
          setUser(dynamoUser);

          const impersonationKey = localStorage.getItem(empathImpersonationKey);
          if (impersonationKey !== null) {
            setImpersonation(JSON.parse(impersonationKey));
          }

          setIsLoading(false);
        } catch (e) {
          console.error('Error fetching user', e);
          setError('There was an error fetching your profile information.');
        }
      } catch (_e) {
        // Swallow error, not logged in
        setIsLoading(false);
      }

      // Fetch SiteSettings
      try {
        const servicesStatusRes = (await API.graphql(
          graphqlOperation(getSiteSettings, { serviceName: 'serviceNow' })
        )) as GraphQLResult<{ getSiteSettings: ISiteSettings }>;

        const status = servicesStatusRes.data?.getSiteSettings;

        setServicesStatus(status || null);
      } catch (e) {
        console.error(e);
        setServicesStatus(null);
      }
    })();
  };

  const setUserImpersonation = (impersonationData?: React.SetStateAction<undefined>) => {
    if (impersonationData) {
      localStorage.setItem(empathImpersonationKey, JSON.stringify(impersonationData));
    } else {
      localStorage.removeItem(empathImpersonationKey);
    }

    setImpersonation(impersonationData);
  };

  Hub.listen('auth', data => {
    switch (data.payload.event) {
      case 'signOut':
        removeInactivityTimer();
        setUserImpersonation();
        break;
      case 'cognitoHostedUI': {
        initialize();
        break;
      }
      default:
        break;
    }
  });

  window.addEventListener('storage', e => {
    if (e.key === null) {
      return;
    }

    if (e.key.startsWith('CognitoIdentityServiceProvider') && e.newValue === null) {
      window.location.href = window.location.origin;
    }
  });

  useMountEffect(initialize);

  return isLoading ? (
    <SplashLoader errorMessage={error} />
  ) : (
    <ErrorBoundary fallbackComponent={Unavailable}>
      <SiteProvider servicesStatus={servicesStatus}>
        <UserProvider
          cognitoUser={cognitoUser}
          groups={groups}
          user={user}
          setUser={setUser}
          impersonation={impersonation}
          setImpersonation={setUserImpersonation}
          setCognitoUser={setCognitoUser}
          setUserNavigationHistoryStack={setUserNavigationHistoryStack}
          userNavigationHistoryStack={userNavigationHistoryStack}
        >
          <Router>
            <div className="App font-display">
              <ToastContainer position="bottom-right" draggable={false} autoClose={4000} hideProgressBar />
              <BaseRoutes />
            </div>
          </Router>
        </UserProvider>
      </SiteProvider>
    </ErrorBoundary>
  );
};

export default App;
