import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';

import { getCustomer } from '../../graphql/queries';
import PageHeader from '../../components/header/PageHeader';
import UsersTable from '../../components/tables/UsersTable';
import Tabs from '../../components/tabs/Tabs';
import { getBaseUrl } from '../../shared/helper';
import { effectualGroups } from '../../shared/groupsHelper';
import { useUser } from '../../context/UserContext';

const EmpathUserList = () => {
  const [customer, setCustomer] = useState({});
  // @ts-expect-error TS(2339): Property 'userInRoles' does not exist on type 'unknown'.
  const { userInRoles } = useUser();
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  const history = useHistory();

  useEffect(() => {
    const get = async () => {
      try {
        const response = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));
        setCustomer((response as any).data.getCustomer);
      } catch (err) {
        console.error('Error fetching customer: ', err);
      }
    };
    get();
  }, [customerId]);

  const showActions = () =>
    userInRoles([effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName]);

  const setActiveTab = (key: any) => {
    history.push(`/empath${getBaseUrl(customerId)}/${key}`);
  };

  return (
    <div className="page-container">
      <PageHeader title={`${(customer as any)?.name} Admin`} />

      <Tabs
        tabs={[
          { title: 'Users', active: true, key: 'users' },
          { title: 'Customer Details', key: '' },
          { title: 'View Team', key: 'my-team' },
        ]}
        tabSelected={setActiveTab}
      />

      <div>
        {(customer as any)?.id && <UsersTable customer={customer} showActions={showActions()} nameIsLink hideIcon />}
      </div>
    </div>
  );
};

export default EmpathUserList;
