import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import IntroTour from '../../components/tour/IntroTour';
import CloudSupportEscalationPlanLink from '../../components/cloudSupportEscalationPlanLink/CloudSupportEscalationPlanLink';
import { updateUser } from '../../graphql/mutations';
import { useUser } from '../../context/UserContext';
import { useSite } from '../../context/SiteContext';
import PageHeader from '../../components/header/PageHeader';
import { IncidentTable, ChangeRequestTable, CaseTable } from '../../components/tables';
import {
  featureFlagGroups,
  customerIds,
  getFeatureAcess,
  servicesGroups,
  effectualGroups,
} from '../../shared/groupsHelper';
import Tabs from '../../components/tabs/Tabs';
import DashboardLayout from '../../components/cards/DashboardLayout';

const CustomerDashboard = () => {
  // @ts-expect-error TS(2339): Property 'impersonation' does not exist on type 'unknown'.
  const { activeUser, setActiveUser, impersonation, customerHasFeature, userInRoles } = useUser();
  // @ts-expect-error TS(2339): Property 'servicesStatus' does not exist on type 'unknown'.
  const { servicesStatus } = useSite();
  const [activeTab, setActiveTab] = useState('Support Requests');
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  const changeRequestTable = useRef(null);
  const incidentTable = useRef(null);
  const caseTable = useRef(null);
  const history = useHistory();
  const [tableMessage, setTableMessage] = useState('');
  const [showTour, setShowTour] = useState(!activeUser.onboarded);

  const doIncidentClick = useCallback(
    (data: any) => {
      history.push(`/customers/${data.customerId}/support/incidents`);
      history.push(`/customers/${data.customerId}/support/incidents/${data.id}`);
    },
    [history]
  );

  const doCaseClick = useCallback(
    (data: any) => {
      history.push(`/customers/${data.customerId}/support/cases`);
      history.push(`/customers/${data.customerId}/support/cases/${data.id}`);
    },
    [history]
  );

  useEffect(() => {
    const { isUnderMaintenance, serviceName } = servicesStatus;

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (isUnderMaintenance && serviceName === servicesGroups[serviceName].name) {
      setTableMessage('Service is unavailable');
    }
  }, [servicesStatus]);

  const finishTour = async () => {
    if (impersonation) {
      setShowTour(false);

      return;
    }

    const oldUser = { ...activeUser, onboarded: true };

    const input = {
      id: oldUser.id,
      email: oldUser.email,
      createdAt: oldUser.createdAt,
      updatedAt: oldUser.updatedAt,
      onboarded: oldUser.onboarded,
      sub: oldUser.sub,
      customerIds: oldUser.customerIds,
      serviceNowId: oldUser.serviceNowId,
      firstName: oldUser.firstName,
      lastName: oldUser.lastName,
      timeZone: oldUser.timeZone,
      jobTitle: oldUser.jobTitle,
      phoneNumber: oldUser.phoneNumber,
      officeLocation: oldUser.officeLocation,
    };

    try {
      const userRes = await API.graphql(graphqlOperation(updateUser, { input }));

      if (customerId.toLowerCase() !== customerIds.empath) {
        (userRes as any).data.updateUser.featureFlags = await getFeatureAcess(customerId);
      }
      setActiveUser((userRes as any).data.updateUser);
      setShowTour(false);
    } catch (err) {
      console.error('Error updating user', err);
    }
  };

  if (!activeUser.id) {
    return <div />;
  }

  const updateActiveTab = (tab: any) => {
    setActiveTab(tab);
  };

  const doChangeRequestClick = (data: any) => {
    history.push(`/customers/${data.customerId}/support/change-requests`);
    history.push(`/customers/${data.customerId}/support/change-requests/${data.id}`);
  };

  const ticketSubmitted = () => {
    if (activeTab === 'Incidents') {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      incidentTable.current.reloadData();
    } else if (activeTab === 'Support Requests') {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      caseTable.current.reloadData();
    } else {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      changeRequestTable.current.reloadData();
    }
  };

  return (
    <div className="page-container">
      <PageHeader title="Welcome!" onSubmitted={ticketSubmitted} />

      <IntroTour doShow={showTour} cancel={finishTour} action={finishTour} />

      {(customerHasFeature([featureFlagGroups.mcm.name]) ||
        customerHasFeature([featureFlagGroups.vmc.name]) ||
        customerHasFeature([featureFlagGroups.resaleOnly.name])) && <DashboardLayout />}
      {(userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualSDM.groupName,
        effectualGroups.effectualObserver.groupName,
      ]) ||
        !customerHasFeature([featureFlagGroups.resaleOnly.name])) && (
        <div>
          <Tabs
            tabs={[{ title: 'Support Requests', active: true }, { title: 'Incidents' }, { title: 'Change Requests' }]}
            tabSelected={updateActiveTab}
          />
          {activeTab === 'Support Requests' && (
            <CaseTable ref={caseTable} customerId={customerId} onCaseClick={doCaseClick} tableMessage={tableMessage} />
          )}
          {activeTab === 'Incidents' && (
            <IncidentTable
              ref={incidentTable}
              customerId={customerId}
              onIncidentClick={doIncidentClick}
              tableMessage={tableMessage}
            />
          )}
          {activeTab === 'Change Requests' && (
            <ChangeRequestTable
              ref={changeRequestTable}
              customerId={customerId}
              onChangeRequestClick={doChangeRequestClick}
              showApproveColumn
              tableMessage={tableMessage}
            />
          )}

          <CloudSupportEscalationPlanLink />
        </div>
      )}
    </div>
  );
};

export default CustomerDashboard;
