/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import { SplashLoader } from '../../components/loader';
import { useUser } from '../../context/UserContext';

const Logout = () => {
  const [error, setError] = useState('');
  // @ts-expect-error TS(2339): Property 'clearUserInfo' does not exist on type 'unknown'.
  const { clearUserInfo } = useUser();
  const [done, setDone] = useState(false);

  const useMountEffect = (func: any) => useEffect(func, []);

  useMountEffect(() => {
    const logout = async () => {
      try {
        await Auth.currentAuthenticatedUser();

        try {
          setError('');
          localStorage.removeItem('EmpathTimer');
          clearUserInfo();
          await Auth.signOut();

          setDone(true);
        } catch (error) {
          setError('Error Completing Logout');
          console.error('Error completing logout: ', error);
        }
      } catch (_e) {
        setDone(true);
      }
    };

    logout();
  });

  return done ? <Redirect to="/login" /> : <SplashLoader errorMessage={error} />;
};

export default Logout;
