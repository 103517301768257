import React from 'react';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import Loader from './Loader';
import ComponentDataLoader from './ComponentDataLoader';

type Props = {
  className?: string;
  showText?: boolean;
  smallLoader?: boolean;
};

const AbsoluteLoader = ({ className, showText = true, smallLoader }: Props) => (
  <div
    className={twCascade('absolute', 'm-auto', 'text-purple-700', 'top-0', 'right-0', 'left-0', 'bottom-0', className)}
  >
    <div className="flex flex-col h-full justify-center items-center">
      {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
      {!smallLoader && <Loader height="100" width="100" />}
      {smallLoader && <ComponentDataLoader />}

      {showText && <p className="pt-2">Loading</p>}
    </div>
  </div>
);

export default AbsoluteLoader;
