import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useUser } from '../../context/UserContext';

const ImpersonationBanner = () => {
  // @ts-expect-error TS(2339): Property 'setImpersonation' does not exist on type 'unknown'.
  const { impersonation, setImpersonation } = useUser();

  const history = useHistory();

  return (
    <div className="flex justify-between px-4 py-2 text-white sm:px-10 lg:px-14 bg-banner-red">
      <div>
        <h5 className="inline pr-4 sm:pr-6 lg:pr-8">
          <b>Impersonating:</b> {impersonation.company}
        </h5>
        {impersonation?.users?.length > 0 && (
          <span>
            <select
              defaultValue={impersonation?.user?.id ? impersonation.user.id : ''}
              // eslint-disable-next-line @typescript-eslint/require-await
              onChange={async e => {
                const impersonatedUser = impersonation.users.filter((user: any) => user.id === e.target.value)[0];

                setImpersonation({
                  ...impersonation,
                  user: impersonatedUser,
                  cognitoUser: {
                    sub: impersonatedUser.sub,
                    username: impersonatedUser.sub,
                  },
                  groups: impersonatedUser.groups,
                });
              }}
              className="pl-2 pr-8 my-2 leading-tight text-purple-700 border border-solid rounded sm:my-0 focus:ring-0"
            >
              <option value="" disabled>
                Select User
              </option>
              {impersonation.users.map((user: any) => (
                <option key={user.id} value={user.id}>
                  {`${user.firstName} ${user.lastName}`}
                </option>
              ))}
            </select>
          </span>
        )}
      </div>

      <button
        onClick={() => {
          setImpersonation(null);
          history.push('/empath/customers');
        }}
        type="button"
        className="hidden px-2 mx-4 bg-white rounded md:block sm:mx-2 lg:mx-0 text-banner-red focus:ring-0 sm:justify-self-center"
      >
        Exit Impersonation
      </button>

      <button
        aria-label="Close Impersonation Banner"
        type="button"
        className="block md:hidden focus:ring-0"
        onClick={() => {
          setImpersonation(null);
          history.push('/customers');
        }}
      >
        <FontAwesomeIcon icon={faTimesCircle} className="text-2xl text-white" />
      </button>
    </div>
  );
};

export default ImpersonationBanner;
