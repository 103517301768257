import React from 'react';
import { Link } from 'react-router-dom';
// @ts-expect-error TS(2307): Cannot find module '../../assets/404Graphic.svg' o... Remove this comment to see the full error message
import { ReactComponent as PageNoutFoundGraphic } from '../../assets/404Graphic.svg';

const PageNotFound = () => (
  <div className="flex flex-col bg-b-200 h-screen">
    <div className="mt-40">
      <p className="text-orange text-center text-xl px-3 lg:text-3xl uppercase">
        We can't seem to find what you are looking for...
      </p>
    </div>

    <div className="flex flex-col lg:flex-row lg:justify-center mt-3 px-3">
      <p className="text-purple-500 text-center lg:text-xl lg:mr-3 underline">
        <Link to="/">Return to your home dashboard </Link>
      </p>

      <p className="text-orange hidden lg:inline lg:text-xl lg:mr-3">||</p>

      <p className="text-purple-500 text-center lg:text-xl underline">
        <a href="mailto: support@empath.io?subject=Empath Error Reported from User">Report an issue</a>
      </p>
    </div>

    <div className="mt-5">
      <PageNoutFoundGraphic className="mx-auto" />
    </div>
  </div>
);

export default PageNotFound;
