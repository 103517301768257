import React from 'react';
import { useUser } from '../context/UserContext';
import ImpersonationBanner from '../components/impersonationBanner/ImpersonationBanner';

import Header from '../components/header/Header';
import LeftNav from '../components/leftNav/LeftNav';

type Props = {
  children: React.ReactElement;
  display?: boolean;
};

const Layout = ({ display = true, children }: Props) => {
  // @ts-expect-error TS(2339): Property 'impersonation' does not exist on type 'unknown'.
  const { isImpersonating, impersonation } = useUser();

  const getMainKey = () => {
    // change key on impersonated user select to force remount of component
    if (impersonation && impersonation.user) {
      return `main-${impersonation.user.email}`;
    }

    if (impersonation) {
      return `main-${impersonation.company}`;
    }

    return `main-no-impersonation`;
  };

  return (
    <div className="flex flex-col max-h-screen min-h-screen overflow-hidden bg-gray-100">
      {display && <Header />}

      <div className="flex flex-row flex-1">
        {display && <LeftNav />}
        <div className="flex flex-col flex-1 focus:ring-0">
          {isImpersonating && <ImpersonationBanner />}
          <div className="relative flex flex-1">
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-grow overflow-scroll">
              <main className="z-0 w-full m-4 my-0 sm:my-2 sm:px-6 lg:px-8">
                <div className="flex flex-col flex-1 pb-6" key={getMainKey()}>
                  {children}
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
