import React, { useEffect, useState } from 'react';
import { Switch, withRouter, useLocation, useHistory, generatePath } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { API, graphqlOperation } from 'aws-amplify';

import { useUser } from '../context/UserContext';
import PrivateRoute from './PrivateRoute';
import Layout from '../pages/Layout';
import CustomerDashboard from '../pages/home/CustomerDashboard';
import SecurityDashboard from '../pages/security/SecurityDashboard';
import MyCloudDashboard from '../pages/mycloud/MyCloudDashboard';
import UserDetails from '../pages/admin/UserDetails';
import { customerGroups, effectualGroups, allGroups, featureFlagGroups } from '../shared/groupsHelper';
import { getCustomer } from '../graphql/queries';
import UserList from '../pages/admin/UserList';
import PageNotFound from '../pages/errors/PageNotFound';
import Sso from '../pages/admin/Sso';
import Unauthorized from '../pages/errors/Unauthorized';
import { AbsoluteLoader } from '../components/loader';
import Settings from '../pages/settings/Settings';
import MyTeam from '../pages/myTeam/MyTeam';
import Cases from '../pages/support/Cases';
import Case from '../pages/support/Case';
import Incident from '../pages/support/Incident';
import Incidents from '../pages/support/Incidents';
import ChangeRequest from '../pages/support/ChangeRequest';
import ChangeRequests from '../pages/support/ChangeRequests';

type Props = {
  customerRoute: string;
  match: any;
};

const CustomerRoutes = ({ customerRoute, match }: Props) => {
  const location = useLocation();
  const history = useHistory();
  // @ts-expect-error TS(2339): Property 'impersonation' does not exist on type 'unknown'
  const { impersonation } = useUser();
  const [loading, setLoading] = useState(true);
  const [customerId, setCustomerId] = useState();
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Redirect if impersonating and customer id in path doesn't match the impersonated customer's customerId
  useEffect(() => {
    if (!match || !match.params) {
      return;
    }

    const pathCustomerId = match.params.customerId;

    setCustomerId(pathCustomerId);

    if (pathCustomerId && impersonation && impersonation.cid && impersonation.cid !== pathCustomerId) {
      const redirectPath = generatePath(match.path, {
        ...match.params,
        customerId: impersonation.cid,
      });

      history.replace(redirectPath);
    }
  }, [impersonation, history, match]);

  useEffect(() => {
    (async () => {
      if (customerId) {
        try {
          const customerRes = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));
          setIsAuthorized(
            !!(customerRes as any).data.getCustomer && (customerRes as any).data.getCustomer.id === customerId
          );
          setLoading(false);
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, [customerId]);

  const RenderRoutes = () => {
    if (loading === true) {
      return <AbsoluteLoader key="loader" />;
    }

    if (!isAuthorized) {
      return <Unauthorized key="unauthorized" />;
    }

    return [
      // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
      <PrivateRoute key="settings" path={`${customerRoute}/settings`} animate>
        <Settings />
      </PrivateRoute>,

      <PrivateRoute
        key="0"
        // @ts-expect-error TS(2322): Type '{ children: Element; key: string; path: stri... Remove this comment to see the full error message
        path={`${customerRoute}/`}
        exact
        animate
        // @ts-expect-error TS(7053): Type '{ children: Element; key: string; path: stri... Remove this comment to see the full error message
        requiredRoles={Object.keys(allGroups).map(group => allGroups[group].groupName)}
      >
        <CustomerDashboard />
      </PrivateRoute>,

      // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
      <PrivateRoute key="cases" path={`${customerRoute}/support/cases`} animate exact>
        <Cases />
      </PrivateRoute>,

      // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
      <PrivateRoute key="incidents" path={`${customerRoute}/support/incidents`} animate exact>
        <Incidents />
      </PrivateRoute>,

      // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
      <PrivateRoute key="changerequests" path={`${customerRoute}/support/change-requests`} animate exact>
        <ChangeRequests />
      </PrivateRoute>,

      // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
      <PrivateRoute key="case" path={`${customerRoute}/support/cases/:incidentId`} animate>
        <Case />
      </PrivateRoute>,

      // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
      <PrivateRoute key="incident" path={`${customerRoute}/support/incidents/:incidentId`} animate>
        <Incident />
      </PrivateRoute>,

      // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
      <PrivateRoute key="changerequest" path={`${customerRoute}/support/change-requests/:incidentId`} animate>
        <ChangeRequest />
      </PrivateRoute>,

      <PrivateRoute
        key="2"
        // @ts-expect-error TS(2322): Type '{ children: Element; key: string; path: stri... Remove this comment to see the full error message
        path={`${customerRoute}/security`}
        exact
        animate
        requiredRoles={[
          effectualGroups.effectualAdmin.groupName,
          effectualGroups.effectualSDM.groupName,
          effectualGroups.effectualObserver.groupName,
          customerGroups.customerAdmin.groupName,
          customerGroups.customerSupport.groupName,
          customerGroups.customerObserver.groupName,
          customerGroups.customerTechnical.groupName,
        ]}
        requiredFeatureFlags={[featureFlagGroups.mcm.name, featureFlagGroups.vmc.name]}
      >
        <SecurityDashboard />
      </PrivateRoute>,

      <PrivateRoute
        key="4"
        // @ts-expect-error TS(2322): Type '{ children: Element; key: string; path: stri... Remove this comment to see the full error message
        path={`${customerRoute}/mycloud`}
        exact
        animate
        requiredRoles={[
          effectualGroups.effectualAdmin.groupName,
          effectualGroups.effectualSDM.groupName,
          effectualGroups.effectualObserver.groupName,
          customerGroups.customerAdmin.groupName,
          customerGroups.customerSupport.groupName,
          customerGroups.customerObserver.groupName,
          customerGroups.customerTechnical.groupName,
        ]}
        requiredFeatureFlags={[featureFlagGroups.mcm.name, featureFlagGroups.vmc.name]}
      >
        <MyCloudDashboard />
      </PrivateRoute>,

      <PrivateRoute
        key="myteam"
        // @ts-expect-error TS(2322): Type '{ children: Element; key: string; path: stri... Remove this comment to see the full error message
        path={`${customerRoute}/my-team`}
        exact
        animate
        requiredRoles={[
          effectualGroups.effectualAdmin.groupName,
          effectualGroups.effectualSDM.groupName,
          effectualGroups.effectualAccounting.groupName,
          effectualGroups.effectualObserver.groupName,
          customerGroups.customerAccounting.groupName,
          customerGroups.customerAdmin.groupName,
          customerGroups.customerObserver.groupName,
          customerGroups.customerSupport.groupName,
          customerGroups.customerTechnical.groupName,
        ]}
      >
        <MyTeam />
      </PrivateRoute>,

      <PrivateRoute
        key="6"
        // @ts-expect-error TS(2322): Type '{ children: Element; key: string; path: stri... Remove this comment to see the full error message
        path={`${customerRoute}/admin/users`}
        exact
        animate
        requiredRoles={[
          effectualGroups.effectualAdmin.groupName,
          customerGroups.customerAdmin.groupName,
          effectualGroups.effectualAccounting.groupName,
          effectualGroups.effectualSDM.groupName,
          effectualGroups.effectualObserver.groupName,
        ]}
      >
        <UserList />
      </PrivateRoute>,

      <PrivateRoute
        key="7"
        // @ts-expect-error TS(2322): Type '{ children: Element; key: string; path: stri... Remove this comment to see the full error message
        path={`${customerRoute}/admin/users/details/:userId?`}
        exact
        animate
        requiredRoles={[
          effectualGroups.effectualAdmin.groupName,
          customerGroups.customerAdmin.groupName,
          effectualGroups.effectualAccounting.groupName,
          effectualGroups.effectualSDM.groupName,
          effectualGroups.effectualObserver.groupName,
        ]}
      >
        <UserDetails />
      </PrivateRoute>,

      <PrivateRoute
        key="8"
        // @ts-expect-error TS(2322): Type '{ children: Element; key: string; path: stri... Remove this comment to see the full error message
        path={`${customerRoute}/admin/sso`}
        exact
        animate
        requiredRoles={[
          effectualGroups.effectualAdmin.groupName,
          effectualGroups.effectualSDM.groupName,
          effectualGroups.effectualObserver.groupName,
          customerGroups.customerAdmin.groupName,
        ]}
      >
        <Sso />
      </PrivateRoute>,

      // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
      <PrivateRoute key="99" path="*" animate>
        <PageNotFound />
      </PrivateRoute>,
    ];
  };

  return (
    <Layout>
      {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          {RenderRoutes()}
        </Switch>
      </AnimatePresence>
    </Layout>
  );
};
// @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message Argument of type '({ empathRoute, match }: Props) => JSX.Element' is not assignable to parameter of type 'ComponentType<RouteComponentProps<any, StaticContext, unknown>>'.
export default withRouter(CustomerRoutes);
