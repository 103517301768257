import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { getCustomer, listUsers, getUser, getMyTeam } from '../../graphql/queries';
import { addTeamMember, updateTeamMember } from '../../graphql/mutations';
import { Button } from '../../components/buttons';
import { TextInput, FormSelect } from '../../components/inputs';
import Checkbox from '../../components/inputs/Checkbox';
import { errorToast, successToast } from '../../shared/toast';
import ScaleLoader from '../../components/loader/Loader';
import { getBaseUrl } from '../../shared/helper';
import { effectualGroups } from '../../shared/groupsHelper';

const TeamMemberDetails = () => {
  const [title, setTitle] = useState('');
  const [memberNameDropdownOptions, setMemberNameDropdownOptions] = useState([]);
  const [placeholderName, setPlaceholderName] = useState('');
  const [isTeamMemberPrimary, setIsTeamMemberPrimary] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const dataStates = {
    LOADING: 'loading',
    LOADED: 'loaded',
    NO_DATA: 'no_data',
  };

  const [dataState, setDataState] = useState(dataStates.LOADING);
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { teamMemberId, customerId } = useParams();

  const { handleSubmit, control, errors, reset } = useForm();

  const useMountEffect = (func: any) => useEffect(func, []);

  const initializeComponent = () => {
    (async () => {
      try {
        const {
          // @ts-expect-error TS(2339): Property 'data' does not exist on type 'GraphQLRes... Remove this comment to see the full error message
          data: {
            getCustomer: { name },
          },
        } = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));
        setTitle(name);
        const resMyTeam = await API.graphql(graphqlOperation(getMyTeam, { customerId }));
        const { myTeam } = (resMyTeam as any).data.getMyTeam;
        if (teamMemberId) {
          const { role, phoneNumber, isPrimary } = myTeam.find((member: any) => member.teamMemberId === teamMemberId);
          const resGetUser = await API.graphql(graphqlOperation(getUser, { id: teamMemberId }));
          const fullName = `${(resGetUser as any).data.getUser.firstName} ${(resGetUser as any).data.getUser.lastName}`;
          setDataState(dataStates.LOADED);
          setPlaceholderName(fullName);
          setIsTeamMemberPrimary(isPrimary);
          reset({ memberRole: role, isPrimary, includePhone: !!phoneNumber });
          return;
        }
        const currentTeamIdList = myTeam.map((member: any) => member.teamMemberId);
        const options = await getDropdownOptions(currentTeamIdList);
        // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
        setMemberNameDropdownOptions(options);
        setDataState(dataStates.LOADED);
        // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (error) {
        // Record does not exist yet
        if (error && (error as any).errors && (error as any).errors[0].errorType === '404') {
          // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
          const options = await getDropdownOptions();
          // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
          setMemberNameDropdownOptions(options);
          setDataState(dataStates.LOADED);
        } else {
          setDataState(dataStates.LOADED);
          // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          setError(error);
        }
      }
    })();
  };

  const getDropdownOptions = async (currentTeamIdList: any) => {
    const effectualGroupNames = Object.keys(effectualGroups).map(effectualGroupName => ({
      groups: { contains: effectualGroupName },
    }));

    const filter = {
      or: effectualGroupNames,
    };
    let nextToken = null;
    const userList = [];

    try {
      do {
        const params = { filter };

        if (nextToken !== null) {
          (params as any).nextToken = nextToken;
        }
        const userListResponse = await API.graphql(graphqlOperation(listUsers, params));

        userList.push(...(userListResponse as any).data.listUsers.items);
        ({ nextToken } = (userListResponse as any).data.listUsers);
      } while (nextToken);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      console.error(error);
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      setError(error);
    }
    const dropdownOptionsData = [];

    if (currentTeamIdList) {
      for (const user of userList) {
        if (!currentTeamIdList.includes(user.id)) {
          user.label = `${user.firstName} ${user.lastName}`;
          user.value = user.id;
          dropdownOptionsData.push(user);
        }
      }

      return dropdownOptionsData;
    }
    for (const user of userList) {
      user.label = `${user.firstName} ${user.lastName}`;
      user.value = user.id;
      dropdownOptionsData.push(user);
    }

    return dropdownOptionsData;
  };

  useMountEffect(initializeComponent);

  const onSubmit = async (data: any) => {
    const {
      isPrimary,
      includePhone,
      memberRole,
      memberName: { id: newTeamMemberId },
    } = data;
    const body = {
      isPrimary,
      includePhone,
      role: memberRole,
    };

    try {
      if (teamMemberId) {
        await API.graphql(graphqlOperation(updateTeamMember, { customerId, teamMemberId, body }));

        successToast('Team Member updated');
        history.push(`/empath${getBaseUrl(customerId)}/my-team`);

        return;
      }

      (body as any).teamMemberId = newTeamMemberId;

      await API.graphql(graphqlOperation(addTeamMember, { customerId, body }));
      successToast('Member added to My Team');
      history.push(`/empath${getBaseUrl(customerId)}/my-team`);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      console.error(error);
      errorToast('Error adding Team Member');
    }
  };

  return (
    <>
      {dataState === dataStates.LOADING && (
        <div className="flex flex-col items-center pt-20">
          {/* @ts-expect-error TS(2322): Type '{ height: string; width: string; color: string; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
          <ScaleLoader height="100" width="100" color="text-purple-700" />
        </div>
      )}
      {dataState === dataStates.LOADED && error && (
        <div className="flex flex-col items-center pt-20">Something went wrong please try again</div>
      )}
      {dataState === dataStates.LOADED && !error && (
        <div className="mt-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mb-4 mx-1">
              <div className="flex flex-col md:flex-row justify-between mt-8 mb-4">
                <h2 className="text-xl md:text-2xl leading-6 uppercase text-purple-700 font-title ">{title}</h2>
              </div>
            </div>

            <div className="bg-offwhite shadow rounded-lg py-3 px-5">
              <h4 className="text-2xl font-normal font-title text-b-700 capitalize">
                {teamMemberId ? 'Edit' : 'Add'} Team Member
              </h4>

              <div className="pt-5">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <div className=" w-full sm:w-1/2 lg:w-1/4">
                    <FormSelect
                      control={control}
                      label="Member Name"
                      field="memberName"
                      options={memberNameDropdownOptions}
                      disabled={!!teamMemberId}
                      isSearchable
                      placeholder={placeholderName}
                      formErrors={errors}
                      rules={teamMemberId ? {} : { required: true }}
                    />

                    <TextInput
                      control={control}
                      label="Role"
                      field="memberRole"
                      formErrors={errors}
                      rules={{ required: true, maxLength: 30 }}
                    />

                    <Checkbox text="Include Phone Number" field="includePhone" control={control} />

                    <Checkbox
                      disabled={isTeamMemberPrimary}
                      text="Primary Contact"
                      field="isPrimary"
                      control={control}
                    />
                  </div>

                  <div className="flex flex-row-reverse">
                    <Button title="Save" loadingTitle="Saving" type="submit" />

                    <Button
                      title="Cancel"
                      onClick={() => history.push(`/empath${getBaseUrl(customerId)}/my-team`)}
                      buttonStyle="text"
                      classNames="mr-6"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamMemberDetails;
