import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import Card from './Card';
import { getMonitorStatusSite24x7 } from '../../graphql/queries';

type Props = {
  customerId: string;
  viewDetailsCallback?: (...args: any[]) => any;
};

const ResourceStatusMonitorCard = ({ customerId, viewDetailsCallback }: Props) => {
  const useMountEffect = (func: any) => useEffect(func, []);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [monitoringData, setMonitoringData] = useState([]);
  const [upMonitoringClass, setUpMonitoringclass] = useState('');
  const [upMonitoringPElement, setUpMonitoringPElement] = useState(0);
  const [downMonitoringClass, setDownMonitoringclass] = useState('');
  const [downMonitoringPElement, setDownMonitoringPElement] = useState(0);
  const [troubleMonitoringClass, setTroubleMonitoringclass] = useState('');
  const [troubleMonitoringPElement, setTroubleMonitoringPElement] = useState(0);
  const [criticalMonitoringClass, setCriticalMonitoringclass] = useState('');
  const [criticalMonitoringPElement, setCriticalMonitoringPElement] = useState(0);
  const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const cardheight = viewWidth >= 2560 ? 350 : 250;
  const history = useHistory();

  const loadInitialData = async () => {
    try {
      const monitorSatatusCallParams = { params: { customerId } };

      const monitorStatusData = await API.graphql(graphqlOperation(getMonitorStatusSite24x7, monitorSatatusCallParams));

      setMonitoringData((monitorStatusData as any)?.data?.getMonitorStatusSite24x7);

      const statusValue = (monitorStatusData as any)?.data?.getMonitorStatusSite24x7;

      // Up Value Settings
      if (statusValue.upValue > 0) {
        setUpMonitoringclass('rounded-full min-w-32 bg-emerald-600 sm:p-3 border-2 border-emerald-600');
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setUpMonitoringPElement('text-2xl');
      } else if (statusValue.upValue === 0) {
        setUpMonitoringclass('rounded-full min-w-32 sm:p-3 border-2 border-emerald-600');
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setUpMonitoringPElement('text-emerald-600 text-2xl');
      }

      // Down Value Settings
      if (statusValue.downValue > 0) {
        setDownMonitoringclass('rounded-full min-w-32 relative w-10 bg-red-500 sm:p-3 border-2 border-red-500');
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setDownMonitoringPElement('text-2xl');
      } else if (statusValue.downValue === 0) {
        setDownMonitoringclass('rounded-full min-w-32 relative w-10 sm:p-3 border-2 border-red-500');
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setDownMonitoringPElement('text-red-500 text-2xl');
      }

      // Trouble Value Settings
      if (statusValue.troubleValue > 0) {
        setTroubleMonitoringclass(
          'rounded-full min-w-32 relative w-10 bg-yellow-500 sm:p-3 border-2 border-yellow-500'
        );
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setTroubleMonitoringPElement('text-2xl');
      } else if (statusValue.troubleValue === 0) {
        setTroubleMonitoringclass('rounded-full min-w-32 relative w-10 sm:p-3 border-2 border-yellow-500');
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setTroubleMonitoringPElement('text-yellow-500 text-2xl');
      }

      // critical Value Settings
      if (statusValue.criticalValue > 0) {
        setCriticalMonitoringclass(
          'rounded-full min-w-32 relative w-10 bg-yellow-300 sm:p-3 border-2 border-yellow-300'
        );
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setCriticalMonitoringPElement('text-2xl');
      } else if (statusValue.criticalValue === 0) {
        setCriticalMonitoringclass('rounded-full min-w-32 relative w-10 sm:p-3 border-2 border-yellow-300');
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setCriticalMonitoringPElement('text-yellow-300 text-2xl');
      }

      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      console.error('Error initializing security dashboard: ', error);

      setError('Error Loading Data');
      setLoading(false);
    }
  };

  useMountEffect(() => {
    loadInitialData();
  });

  const onStatusClick = (statusValue: any) => {
    history.push({ pathname: `${customerId}/mycloud`, state: { detail: 'openMonitorModal', status: statusValue } });
  };

  return (
    <Card
      icon={<FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />}
      title="Resource Status Monitor"
      classNames="w-full"
      viewDetailsCallback={viewDetailsCallback}
      loading={loading}
      errorMessage={error}
      component={
        (monitoringData as any)?.upValue > 0 ||
        (monitoringData as any)?.downValue > 0 ||
        (monitoringData as any)?.troubleValue > 0 ||
        (monitoringData as any)?.criticalValue > 0 ? (
          <div style={{ height: cardheight }} className="flex flex-col items-center pt-10 ">
            <div className="grid grid-cols-2 gap-6">
              <div className="relative sm:max-w-xl sm:mx-auto">
                <button type="button" className={upMonitoringClass} onClick={() => onStatusClick(1)}>
                  {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
                  <span className={upMonitoringPElement}>{(monitoringData as any)?.upValue}</span>
                </button>
                <div className="py-2 flex items-center justify-center flex-1 font-bold">Up</div>
              </div>
              <div className="relative sm:max-w-xl sm:mx-auto">
                <button type="button" className={troubleMonitoringClass} onClick={() => onStatusClick(2)}>
                  {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
                  <span className={troubleMonitoringPElement}>{(monitoringData as any)?.troubleValue}</span>
                </button>
                <div className="py-2 flex items-center justify-center flex-1 font-bold">Trouble</div>
              </div>
              <div className="relative sm:max-w-xl sm:mx-auto">
                <button type="button" className={criticalMonitoringClass} onClick={() => onStatusClick(3)}>
                  {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
                  <span className={criticalMonitoringPElement}>{(monitoringData as any)?.criticalValue}</span>
                </button>
                <div className="py-2 flex items-center justify-center flex-1 font-bold">Critical</div>
              </div>
              <div className="relative sm:max-w-xl sm:mx-auto">
                <button type="button" className={downMonitoringClass} onClick={() => onStatusClick(0)}>
                  {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
                  <span className={downMonitoringPElement}>{(monitoringData as any)?.downValue}</span>
                </button>
                <div className="py-2 flex items-center justify-center flex-1 font-bold">Down</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center flex-1 py-16 ">
            <p className="text-center text-gray-500">No Data Found</p>
          </div>
        )
      }
    />
  );
};

export default ResourceStatusMonitorCard;
