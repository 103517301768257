import React from 'react';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import Loader from './Loader';
import ComponentDataLoader from './ComponentDataLoader';

type Props = {
  className?: string;
  showText?: boolean;
  smallLoader?: boolean;
};

const FlexLoader = ({ className, showText = true, smallLoader }: Props) => (
  <div
    className={twCascade('flex', 'flex-col', 'flex-1', 'justify-center', 'items-center', 'text-purple-700', className)}
  >
    {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
    {!smallLoader && <Loader height="100" width="100" />}
    {smallLoader && <ComponentDataLoader />}

    {showText && <p className="pt-2">Loading</p>}
  </div>
);

export default FlexLoader;
