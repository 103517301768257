import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import AvatarEditor from 'react-avatar-editor';
import Pica from 'pica';

import Modal from '../modal/Modal';
import { useUser } from '../../context/UserContext';
import { getEmpathUser } from '../../graphql/queries';
import {
  addEmpathUser,
  updateEmpathUser,
  adminResetPassword,
  deleteEmpathUser,
  deleteCognitoUser,
} from '../../graphql/mutations';
import { effectualGroups, customerGroups, customerIds, customerRoles } from '../../shared/groupsHelper';
import { errorToast, successToast, infoToast } from '../../shared/toast';
import { TextInput, MaskInput, Checkbox, FormSelect } from '../inputs';
import { Button } from '../buttons';
import { timeZones } from '../inputs/utils';
import Tooltip from '../tooltip/Tooltip';
import SupportToolTip from '../../pages/support/SupportToolTip';

type Props = {
  customer?: any;
  basePath: string;
};

const Details = ({ customer, basePath }: Props) => {
  const [showEmailChangeModal, setShowEmailChangeModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string | null>(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const [profilePhotoScale, setProfilePhotoScale] = useState(1);
  const [showSsoModal, setShowSsoModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [disableSSo, setDisableSso] = useState(false);
  const [saving, setSaving] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  const profilePhotoUpload = useRef(null);
  const profilePhotoEditor = useRef(null);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, setValue, getValues, reset, unregister, control } = useForm();
  // @ts-expect-error TS(2339): Property 'userId' does not exist on type 'unknown'.
  const { userId } = useParams();
  // @ts-expect-error TS(2339): Property 'activeUser' does not exist on type 'unknown'.
  const { activeUser, activeUserGroups, setActiveUser, impersonation, isEffectualUser, isImpersonating, userInRoles } =
    useUser();
  const history = useHistory();

  const pica = Pica();

  const availableGroups = (() => {
    if (!customer) {
      return effectualGroups;
    }

    return customerGroups;
  })();

  useEffect(() => {
    (async () => {
      if (activeUser.groups && activeUser.groups.length > 0) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        activeUser.role = availableGroups[activeUser.groups[0]]?.groupName;
      }
      const rolesList = Object.keys(availableGroups).map(key => ({
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        label: availableGroups[key].description,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        value: availableGroups[key].groupName,
      }));
      // @ts-expect-error TS(2345): Argument of type '{ label: any; value: any; }[]' i... Remove this comment to see the full error message
      setRoleOptions(rolesList);
      if (!customer && !userId) {
        reset({ role: rolesList.find(role => role.value === effectualGroups.effectualObserver.groupName) });
      }
      if (userId) {
        if (
          (!impersonation && userId === activeUser.id) ||
          (isEffectualUser &&
            !userInRoles([effectualGroups.effectualAdmin.groupName, effectualGroups.effectualSDM.groupName]))
        ) {
          setReadOnly(true);
        }
        try {
          if (customer && customer.metadataUrl) {
            if (!userId) {
              setValue('isSsoUser', true);
            }
          } else {
            unregister('isSsoUser');
            setDisableSso(true);
          }
          const userRes = await API.graphql(graphqlOperation(getEmpathUser, { params: { userId } }));
          const empathUser = (userRes as any).data.getEmpathUser;
          if (!empathUser.timeZone) {
            empathUser.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          }
          if (empathUser.groups && empathUser.groups.length > 0) {
            empathUser.role = {
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              value: availableGroups[empathUser.groups[0]]?.groupName,
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              label: availableGroups[empathUser.groups[0]]?.description,
            };
          }
          setSelectedUser(empathUser);
          reset({ ...empathUser, timeZone: timeZones().find(timeZone => timeZone.value === empathUser.timeZone) });
          if (empathUser.profilePhotoUrl) {
            const url = await Storage.get(empathUser.profilePhotoUrl);
            setProfilePhotoUrl(url);
          }
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, [
    activeUser,
    activeUserGroups,
    reset,
    setValue,
    unregister,
    userId,
    impersonation,
    customer,
    isEffectualUser,
    availableGroups,
    userInRoles,
  ]);

  const createUser = async (data: any) => {
    const options = {
      body: data,
    };

    if (customer?.id) {
      (options as any).query = {
        customerId: customer.id,
      };
    }

    (options as any).query = { customerId: customer?.id ?? 'EMPATH' };

    const response = await API.graphql(graphqlOperation(addEmpathUser, options));

    const newEmpathUser = (response as any).data.addEmpathUser;

    let photoUrl: string | null = profilePhotoUrl;

    if (profilePhoto !== null) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      const canvas = profilePhotoEditor.current.getImage();
      const offScreenCanvas = document.createElement('canvas');

      offScreenCanvas.width = 150;
      offScreenCanvas.height = 150;
      const picaCanvas = await pica.resize(canvas, offScreenCanvas);
      const image = await pica.toBlob(picaCanvas, 'image/jpg', 0.9);

      Storage.put(`${newEmpathUser.id}.jpg`, image, { contentType: 'image/jpg' });
      photoUrl = picaCanvas.toDataURL('image/jpg', 0.9);

      setProfilePhotoUrl(photoUrl);
      setProfilePhoto(null);

      data.profilePhotoUrl = `${newEmpathUser.id}.jpg`;

      // Has to be done again because we didn't know the id before
      await API.graphql(
        graphqlOperation(updateEmpathUser, { params: { username: btoa(newEmpathUser.sub) }, body: data })
      );
    }

    if (newEmpathUser.groups && newEmpathUser.groups.length > 0) {
      newEmpathUser.role = {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        value: availableGroups[newEmpathUser.groups[0]]?.groupName,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        label: availableGroups[newEmpathUser.groups[0]]?.description,
      };
    }
    history.push(`details/${newEmpathUser.id}`);
  };

  const saveUser = async (data: any) => {
    let photoUrl = profilePhotoUrl;

    if (profilePhoto !== null) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      const canvas = profilePhotoEditor.current.getImage();
      const offScreenCanvas = document.createElement('canvas');

      offScreenCanvas.width = 150;
      offScreenCanvas.height = 150;
      const picaCanvas = await pica.resize(canvas, offScreenCanvas);
      const image = await pica.toBlob(picaCanvas, 'image/jpg', 0.9);

      Storage.put(`${userId}.jpg`, image, { contentType: 'image/jpg' });
      photoUrl = picaCanvas.toDataURL('image/jpg', 0.9);

      setProfilePhotoUrl(photoUrl);
      setProfilePhoto(null);

      data.profilePhotoUrl = `${userId}.jpg`;
    }

    if (data.isSsoUser && (selectedUser as any).isSsoUser) {
      data.email = (selectedUser as any).email;
    }

    if ((selectedUser as any).email !== data.email) {
      data.notifyEmailChanged = true;
    }

    if ((selectedUser as any).role !== data.role) {
      data.dashboardSettings = null;
    }

    const updatedUser = await API.graphql(
      graphqlOperation(updateEmpathUser, { params: { username: btoa((selectedUser as any).sub) }, body: data })
    );

    const userSelect = (updatedUser as any).data.updateEmpathUser;

    if (!(selectedUser as any).isSsoUser && data.isSsoUser) {
      await API.graphql(graphqlOperation(deleteCognitoUser, { params: { username: btoa((selectedUser as any).sub) } }));
    }

    if (userSelect.groups && userSelect.groups.length > 0) {
      userSelect.role = {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        value: availableGroups[userSelect.groups[0]]?.groupName,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        label: availableGroups[userSelect.groups[0]]?.description,
      };
    }

    userSelect.timeZone = timeZones().find(timeZone => timeZone.value === userSelect.timeZone);

    setSelectedUser(userSelect);

    if (userSelect.id === activeUser.id) {
      setActiveUser({
        ...activeUser,
        firstName: userSelect.firstName,
        lastName: userSelect.lastName,
        email: userSelect.email,
        timeZone: userSelect.timeZone,
        jobTitle: userSelect.jobTitle,
        phoneNumber: userSelect.phoneNumber,
        officeLocation: userSelect.officeLocation,
        groups: userSelect.groups,
        role: userSelect.role,
        profilePhotoUrl: userSelect.profilePhotoUrl,
      });
    }

    setSaving(false);

    successToast('User updated');

    reset(userSelect);
  };

  const deleteUser = async () => {
    try {
      setSaving(true);

      const params = { params: { username: btoa((selectedUser as any).sub) } };

      if ((selectedUser as any).isSsoUser) {
        (params as any).body = { email: (selectedUser as any).email };
      }

      await API.graphql(graphqlOperation(deleteEmpathUser, params));
      successToast('User successfully deleted!');
      setSaving(false);
      history.push(basePath);
    } catch (err) {
      setSaving(false);
      console.error(err);
      errorToast(
        <div>
          <p>
            Error deleting user {(selectedUser as any).firstName} {activeUser.lastName}
          </p>
          <p>Please contact support.</p>
        </div>
      );
    }
  };

  const emailChangeConfirmation = async (accept: any) => {
    if (accept) {
      setSaving(true);
      try {
        const changes = getValues();

        changes.email = changes.email.toLowerCase();
        await saveUser(changes);
      } catch (err) {
        errorToast(`Error updating email: ${(err as any).message || (err as any).errors[0].message}`);
        setValue('email', (selectedUser as any).email);
        console.error('Error updating email', err);
      }
    } else {
      setValue('email', (selectedUser as any).email.toLowerCase());
      infoToast(`Email has been set back to ${(selectedUser as any).email}`);
    }
    setShowEmailChangeModal(!showEmailChangeModal);
  };

  const onSubmit = async (data: any) => {
    data.timeZone = data.timeZone.value || '';
    data.role = data.role.value || '';

    if ((selectedUser as any).id === activeUser.id && getValues('email') !== activeUser.email) {
      errorToast(`Go to My Settings to update your email`);

      return;
    }

    if (!(selectedUser as any).id) {
      try {
        setSaving(true);
        await createUser(data);
        successToast('User created!');
        history.push(basePath);
      } catch (err) {
        if ((err as any).errors) {
          for (const error of (err as any).errors) {
            errorToast(error.message);
          }
        }

        console.error('Error creating user', err);
      } finally {
        setSaving(false);
      }

      return;
    }

    if (getValues('email') !== (selectedUser as any).email) {
      setShowEmailChangeModal(true);

      return;
    }

    try {
      setSaving(true);
      await saveUser(data);
    } catch (err) {
      errorToast('Error saving user');
      console.error('Error saving user', err);
    } finally {
      setSaving(false);
    }
  };

  const uploadClick = (e: any) => {
    setProfilePhoto(e.target.files[0]);
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
    setProfilePhotoUrl();
  };

  const handleScale = (e: any) => {
    setProfilePhotoScale(parseFloat(e.target.value));
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const resetPassword = async () => {
    if ((selectedUser as any).isSsoUser) {
      return;
    }
    setShowPasswordResetModal(true);
  };

  const doResetPassword = async () => {
    try {
      setSaving(true);
      await API.graphql(
        graphqlOperation(adminResetPassword, { params: { username: btoa((selectedUser as any).sub) } })
      );

      successToast('Password reset email sent');
      setShowPasswordResetModal(false);
      setSaving(false);
    } catch (err) {
      console.error(err);
      errorToast(`Error resetting password: ${(err as any).message || (err as any).errors[0].message}`);
      setSaving(false);
    }
  };

  const cancelResetPassword = () => {
    setShowPasswordResetModal(false);
  };

  const checkEnabled = (e: any) => {
    if (!disableSSo) {
      return true;
    }

    e.preventDefault();

    if (!(selectedUser as any).id) {
      setShowSsoModal(true);
    }
  };

  const matchPartOfLabel = (candidate: any, input: any) => {
    if (input) {
      const re = new RegExp(input, 'gi');

      return candidate.label.match(re);
    }

    return true;
  };

  const rolesToolTip = (
    <div>
      {customerRoles.map(x => (
        <div key={x.description}>
          <p className="font-bold">{x.role}: </p>
          {x.description}
        </div>
      ))}
    </div>
  );

  const showRoleToolTip = () => {
    if ((selectedUser as any)?.customerId?.toLowerCase() === customerIds.empath) {
      return null;
    }
    if (!customer) {
      return null;
    }

    return <SupportToolTip>{rolesToolTip}</SupportToolTip>;
  };

  return (
    <>
      <Modal
        doShow={showPasswordResetModal}
        title={
          <span>
            Please <span className="text-orange">confirm</span>
          </span>
        }
        cancelText="No"
        cancel={cancelResetPassword}
        actionText="Yes"
        action={doResetPassword}
        actionLoading={saving}
        // @ts-expect-error TS(2322): Type '{ children: Element; doShow: boolean; title:... Remove this comment to see the full error message
        actionColor="red"
      >
        <div className="p-1">
          <p>Do you want to reset this user's password?</p>
        </div>
      </Modal>

      <Modal
        doShow={showEmailChangeModal}
        title={
          <span>
            Please <span className="text-orange">confirm</span>
          </span>
        }
        cancelText="No"
        cancel={async () => {
          await emailChangeConfirmation(false);
        }}
        actionText="Yes"
        action={async () => {
          await emailChangeConfirmation(true);
        }}
        actionLoading={saving}
      >
        <p>Changing a user’s email will change a user’s login.</p>
        <p>An email will be sent to {getValues('email')} to notify them.</p>
        <p>Are you sure?</p>
      </Modal>

      <Modal
        doShow={showSsoModal}
        title={
          <span>
            Enable <span className="text-orange">SSO</span>
          </span>
        }
        actionText="Ok"
        action={() => setShowSsoModal(false)}
        cancel={() => setShowSsoModal(false)}
        hideCancel
      >
        <p>You must enable SSO sign on first</p>
      </Modal>

      <Modal
        doShow={showDeleteUserModal}
        title={
          <span>
            Delete <span className="text-orange">User</span>
          </span>
        }
        actionText="Delete User"
        actionButtonClass="btn-red"
        actionButtonsContainerClass="justify-evenly"
        cancel={() => {
          setShowDeleteUserModal(false);
        }}
        action={deleteUser}
        actionLoading={saving}
      >
        <p className="m-5 text-center">Do you want to permanently delete this user?</p>
      </Modal>

      {((userId && (selectedUser as any).id) || !userId) && (
        <div className="flex-1 my-4 focus:ring-0">
          <div className="px-4 py-4 rounded-lg shadow bg-offwhite sm:py-5 sm:px-8">
            <h4 className="mb-4 text-2xl font-normal text-purple-700 font-title">Personal Information</h4>

            <form onSubmit={handleSubmit(onSubmit)} className="px-2">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5">
                <div className="grid grid-cols-1 col-span-2 md:grid-cols-2 gap-x-8 xl:gap-x-16">
                  <TextInput
                    label="First Name"
                    field="firstName"
                    control={control}
                    rules={{ required: true }}
                    formErrors={errors}
                    disabled={readOnly}
                  />

                  <TextInput
                    label="Last Name"
                    field="lastName"
                    control={control}
                    rules={{ required: true }}
                    formErrors={errors}
                    disabled={readOnly}
                  />

                  <Tooltip
                    containerClassName={isImpersonating ? 'border-orange' : 'hidden'}
                    content="You cannot edit an email address while impersonating"
                  >
                    <TextInput
                      type="email"
                      label="Email Address"
                      field="email"
                      disabled={
                        (selectedUser as any).isSsoUser || userId === activeUser.id || isImpersonating || readOnly
                      }
                      control={control}
                      rules={{
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Invalid Email',
                        },
                      }}
                      formErrors={errors}
                    />
                  </Tooltip>

                  <FormSelect
                    label="Time Zone"
                    field="timeZone"
                    control={control}
                    formErrors={errors}
                    options={timeZones()}
                    filterOption={matchPartOfLabel}
                    disabled={readOnly}
                  />

                  <FormSelect
                    label="Role"
                    field="role"
                    control={control}
                    formErrors={errors}
                    options={roleOptions}
                    disabled={activeUser.id === (selectedUser as any).id || readOnly}
                    rules={{ required: true }}
                    tooltip={showRoleToolTip()}
                  />

                  <Checkbox
                    label="SSO Login"
                    text="User will login with SSO"
                    field="isSsoUser"
                    control={control}
                    disabled={!!(selectedUser as any).id || readOnly}
                    onClick={checkEnabled}
                  />

                  <TextInput
                    label="Job Title"
                    field="jobTitle"
                    control={control}
                    formErrors={errors}
                    disabled={readOnly}
                  />

                  <Checkbox
                    label="Approve Change Requests"
                    text="User can approve change requests"
                    field="canApproveChangeRequest"
                    disabled={readOnly}
                    control={control}
                  />

                  <TextInput
                    label="Office Location"
                    field="officeLocation"
                    control={control}
                    formErrors={errors}
                    disabled={readOnly}
                  />

                  <MaskInput
                    // @ts-expect-error TS(2322): Type '{ type: string; label: string; field: string... Remove this comment to see the full error message
                    type="tel"
                    label="Phone Number"
                    field="phoneNumber"
                    rules={{ minLength: 10, maxLength: 10 }}
                    formErrors={errors}
                    control={control}
                    options={{ phone: true, phoneRegionCode: 'US' }}
                    setValue={setValue}
                    disabled={readOnly}
                  />

                  <MaskInput
                    // @ts-expect-error TS(2322): Type '{ type: string; label: string; field: string... Remove this comment to see the full error message
                    type="tel"
                    label="Additional Phone Number"
                    field="phoneNumber2"
                    rules={{ minLength: 10, maxLength: 10 }}
                    formErrors={errors}
                    control={control}
                    options={{ phone: true, phoneRegionCode: 'US' }}
                    setValue={setValue}
                    disabled={readOnly}
                  />
                </div>

                <div className="lg:ml-3 xl:ml-10">
                  <div className="mb-1 text-blue-900">Profile Photo</div>

                  <input hidden type="file" accept="image/*" onChange={e => uploadClick(e)} ref={profilePhotoUpload} />

                  {profilePhoto && (
                    <div className="flex flex-col items-center w-48 ml-4">
                      <AvatarEditor
                        image={profilePhoto}
                        width={175}
                        height={175}
                        scale={profilePhotoScale}
                        ref={profilePhotoEditor}
                      />

                      <input
                        className="w-full mt-2"
                        name="scale"
                        type="range"
                        onChange={handleScale}
                        min={1}
                        max={2}
                        step={0.01}
                        defaultValue={1}
                      />
                    </div>
                  )}

                  {profilePhotoUrl && <img className="object-fill w-48 h-48" src={profilePhotoUrl} alt="Profile" />}

                  {!readOnly && (
                    <Button
                      title="Upload New Photo"
                      // @ts-expect-error TS(2531): Object is possibly 'null'.
                      onClick={() => profilePhotoUpload.current.click()}
                      buttonStyle="text"
                    />
                  )}
                </div>
              </div>
              {userId && activeUser.id !== userId && !readOnly && (
                <Button
                  title="Reset Password"
                  onClick={resetPassword}
                  buttonStyle="text"
                  classNames={`mt-5 text-sm text-blue-900 ring-0 ${
                    (selectedUser as any).isSsoUser ? 'no-underline cursor-not-allowed' : ''
                  }`}
                  disabled={(selectedUser as any).isSsoUser}
                />
              )}
              <span className="flex flex-col w-full mt-5 sm:flex-row sm:mt-4">
                {userId && !readOnly && (
                  <div className="justify-start hidden w-full mt-5 sm:flex sm:mt-4 sm:flex-row">
                    <Button
                      title="Delete"
                      // eslint-disable-next-line @typescript-eslint/require-await
                      onClick={async () => {
                        setShowDeleteUserModal(true);
                      }}
                      classNames="w-full sm:w-auto"
                      gradient="red"
                    />
                  </div>
                )}

                {!readOnly && (
                  <div className="flex flex-col-reverse justify-end w-full mt-5 sm:flex-row sm:mt-4">
                    <Button
                      title="Cancel"
                      onClick={() => {
                        history.push(basePath);
                      }}
                      buttonStyle="text"
                      classNames="mr-0 mt-6 sm:mt-0 sm:mr-6"
                    />

                    <Button
                      title={userId ? 'Save' : 'Add User'}
                      loadingTitle={userId ? 'Saving' : 'Creating'}
                      loading={saving}
                      type="submit"
                    />
                  </div>
                )}
              </span>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Details;
