import React from 'react';

import EmpathTable from './EmpathTable';
import ErrorBoundary from '../errors/ErrorBoundary';
import ComponentError from '../errors/ComponentError';

const EmpathTableWithErrorBoundry = (props: any) => (
  <ErrorBoundary
    fallbackClassNames="flex h-full justify-center items-center text-center min-h-40"
    fallbackComponent={ComponentError}
  >
    <EmpathTable {...props} />
  </ErrorBoundary>
);

export default EmpathTableWithErrorBoundry;
