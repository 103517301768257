const awsmobile = {
  "aws_project_region": "us-west-2",
  "aws_cognito_identity_pool_id": "us-west-2:46d001ed-2bdc-46ec-b0fd-77371337b0d9",
  "aws_cognito_region": "us-west-2",
  "aws_user_pools_id": "us-west-2_0NhYSJGpG",
  "aws_user_pools_web_client_id": "2rdp9g9n1i10u5fauhaj2is5f3",
  "oauth": {
    "domain": "empath-auth-production.auth.us-west-2.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "responseType": "code",
    "redirectSignIn": "https://www.empath.io/login",
    "redirectSignOut": "https://www.empath.io/logout"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": "https://axk36dvgirht5lylt2pfzknana.appsync-api.us-west-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-west-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_mobile_analytics_app_id": "a73d5c11426c41548d4ab212eb103a14",
  "aws_mobile_analytics_app_region": "us-west-2",
  "aws_user_files_s3_bucket": "empath-production-us-west-2-storage",
  "aws_user_files_s3_bucket_region": "us-west-2",
  "cookieStorage": {
    "domain": ".empath.io",
    "secure": true
  }
}; 

export default awsmobile;