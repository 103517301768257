import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// @ts-expect-error TS(2307): Cannot find module '../../assets/logo.svg' or its ... Remove this comment to see the full error message
import Logo from '../../assets/logo.svg';
// @ts-expect-error TS(2307): Cannot find module '../../assets/triangleOverlayCr... Remove this comment to see the full error message
import BG from '../../assets/triangleOverlayCropped.png';
import LoginForm from './forms/LoginForm';
import CompletePasswordForm from './forms/CompletePasswordForm';
import CompleteMfaSetupForm from './forms/CompleteMfaSetupForm';
import MfaForm from './forms/MfaForm';

export default function Login() {
  const history = useHistory();

  const [user, setUser] = useState({});

  const RenderForm = () => {
    if ((user as any).username && (user as any).challengeName === 'NEW_PASSWORD_REQUIRED') {
      return <CompletePasswordForm setUser={setUser} user={user} />;
    }

    if ((user as any).username && (user as any).challengeName === 'MFA_SETUP') {
      return <CompleteMfaSetupForm setUser={setUser} user={user} />;
    }

    if ((user as any).username && (user as any).challengeName === 'SOFTWARE_TOKEN_MFA') {
      return <MfaForm setUser={setUser} user={user} />;
    }

    if ((user as any).username && !(user as any).challengeName) {
      // Logged in, wait for reload

      return <div />;
    }

    return <LoginForm setUser={setUser} showPasswordReset={() => history.push('/resetpassword')} />;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-800 via-purple-700 to-purple-500">
      <div
        className="flex flex-col items-center content-center justify-center w-screen min-h-screen"
        style={{ backgroundImage: `url(${BG})`, backgroundPosition: 'bottom center', backgroundRepeat: 'repeat-x' }}
      >
        <div className="flex flex-col w-4/5 md:w-3/12">
          <img className="w-full m-0" src={Logo} alt="Empath logo" />

          <div className="w-full">
            <RenderForm />
          </div>
        </div>
      </div>
    </div>
  );
}
