import React from 'react';
import { useController } from 'react-hook-form';
import { twCascade } from '@mariusmarais/tailwind-cascade';

type Props = {
  label?: string;
  text?: string;
  purple?: boolean;
  disabled?: boolean;
  field: string;
  onClick?: (...args: any[]) => any;
  defaultValue?: string;
  control: any;
  rules?: any;
};

const Checkbox = ({ label, text, purple, disabled = false, field, onClick, control, rules, defaultValue }: Props) => {
  const {
    field: { ref, onChange, value },
  } = useController({
    name: field,
    control,
    rules,
    defaultValue: defaultValue || false,
  });
  const labelColor = purple ? 'text-gray-200' : 'text-blue-900';
  const textColor = purple ? 'text-gray-200' : 'text-purple-700';

  return (
    <div className="pb-5">
      <div className="flex flex-row items-center">
        <label htmlFor={field} className={twCascade('flex', 'text-base', 'mb-1', labelColor)}>
          {label}
        </label>
      </div>
      <button onClick={onClick} type="button" className="cursor-default">
        <label htmlFor={field} className={twCascade('w-full', 'leading-tight', textColor)}>
          <input
            id={field}
            type="checkbox"
            name={field}
            ref={ref}
            disabled={disabled}
            className="mx-2 border border-gray-300 rounded ring-0 ring-offset-0 focus:ring-0 focus:ring-offset-0"
            onChange={e => onChange(e.target.checked)}
            value={value || defaultValue || false}
            checked={value || defaultValue || false}
          />
          {text}
        </label>
      </button>
    </div>
  );
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
