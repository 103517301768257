import React from 'react';
import Tippy from '@tippyjs/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

type Props = {
  children: React.ReactNode;
  maxWidth?: number;
};

export default function SupportToolTip({ children, maxWidth = 350 }: Props) {
  return (
    <Tippy
      maxWidth={`${maxWidth}px`}
      interactive
      interactiveBorder={0}
      delay={[100, null]}
      content={
        <div className="overflow-hidden shadow bg-white border-solid border-orange border-l-8">
          <div className="pt-2 pb-1 px-5">{children}</div>
        </div>
      }
      appendTo={document.body}
    >
      <div className="ml-2 text-blue-900">
        <FontAwesomeIcon size="sm" icon={faInfoCircle} />
      </div>
    </Tippy>
  );
}
