// @ts-expect-error TS(7016): Could not find a declaration file for module 'stri... Remove this comment to see the full error message
import StringMask from 'string-mask';
import { getTimeZones } from '@vvo/tzdb';
import { isValidTimeZone } from '../../shared/dateHelpers';

const getErrorMessage = (errors: any, field: any, label: any) => {
  if (!errors) {
    return '';
  }

  const err = errors[field];

  if (!err) {
    return '';
  }
  if (err.message) {
    return err.message;
  }
  if (err.type === 'required') {
    return `${label} is required`;
  }
  if (err.type === 'maxLength') {
    return `${label} is too long`;
  }
  if (err.type === 'minLength') {
    return `${label} is too short`;
  }

  return '';
};

const isValidEmail = (email: any) => {
  const result = { valid: false, error: '' };

  try {
    if (!email) {
      result.error = 'Email Address is required';

      return result;
    }

    // Covers 99.99% of emails in theory... https://emailregex.com/
    // eslint-disable-next-line no-useless-escape
    const expression =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email && !expression.test(String(email))) {
      result.error = 'Invalid email format';

      return result;
    }

    result.valid = true;

    return result;
  } catch (error) {
    console.error('Error validating email address: ', error);
    result.error = 'Could not validate email';

    return result;
  }
};

const timeZones = () => {
  const allTimeZones = getTimeZones();

  const tzList = [];

  for (const tz of allTimeZones) {
    const { name, rawFormat } = tz;

    if (isValidTimeZone(name)) {
      tzList.push({ value: name, label: rawFormat });
    }
  }

  return tzList.sort((a, b) => (a.label > b.label ? 1 : -1));
};

const maskPhone = (phoneNumber: any) => {
  const formatter = new StringMask('000-000-0000');

  return formatter.apply(phoneNumber);
};

const isEmptyOrWhiteSpace = (string: any) => string === null || string === undefined || string.match(/^\s*$/) !== null;

export { getErrorMessage, isValidEmail, isEmptyOrWhiteSpace, timeZones, maskPhone };
