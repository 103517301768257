import React, { useEffect } from 'react';
import { Switch, Route, Redirect, withRouter, useHistory } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import CustomerRoutes from './CustomerRoutes';
import EmpathRoutes from './EmpathRoutes';
import { useUser } from '../context/UserContext';
import PrivateRoute from './PrivateRoute';
import Layout from '../pages/Layout';
import { getBaseUrl } from '../shared/helper';
import PageNotFound from '../pages/errors/PageNotFound';
import Unauthorized from '../pages/errors/Unauthorized';
import Login from '../pages/user/Login';
import Logout from '../pages/user/Logout';
import ResetPassword from '../pages/user/ResetPassword';
import Settings from '../pages/settings/Settings';

const customerRoute = '/customers/:customerId';

const Routes = () => {
  const {
    // @ts-expect-error TS(2339): Property 'activeCognitoUser' does not exist on type 'unknown'
    activeCognitoUser,
    // @ts-expect-error TS(2339): Property 'activeCognitoUser' does not exist on type 'unknown'
    isImpersonating,
    // @ts-expect-error TS(2339): Property 'activeCognitoUser' does not exist on type 'unknown'
    activeUserGroups,
    // @ts-expect-error TS(2339): Property 'activeCognitoUser' does not exist on type 'unknown'
    activeUser,
    // @ts-expect-error TS(2339): Property 'activeCognitoUser' does not exist on type 'unknown'
    isEffectualUser,
    // @ts-expect-error TS(2339): Property 'activeCognitoUser' does not exist on type 'unknown'
    userNavigationHistoryStack,
    // @ts-expect-error TS(2339): Property 'activeCognitoUser' does not exist on type 'unknown'
    setUserNavigationHistoryStack,
  } = useUser();
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location: any) => {
      setUserNavigationHistoryStack({
        currentPage: location.pathname,
        lastVisitedPage: userNavigationHistoryStack.currentPage,
      });
    });

    return () => {
      unlisten();
    };
  }, [history, setUserNavigationHistoryStack, userNavigationHistoryStack.currentPage]);

  const getHomePath = () => {
    if (!activeUser) {
      return <Redirect to="/login" />;
    }

    if (isEffectualUser) {
      return <Redirect to="/empath" />;
    }

    return <Redirect to={getBaseUrl(activeUser.customerId)} />;
  };

  if (activeCognitoUser && !isImpersonating && !(activeUserGroups && activeUserGroups.length)) {
    return (
      <Layout>
        <Unauthorized />
      </Layout>
    );
  }

  return (
    <Switch>
      <Route exact path="/login">
        {!activeCognitoUser ||
        (!activeCognitoUser?.attributes?.identities && activeCognitoUser?.preferredMFA === 'NOMFA') ? (
          <Login />
        ) : (
          getHomePath()
        )}
      </Route>

      <Route exact path="/resetpassword">
        {!activeCognitoUser ||
        (!activeCognitoUser?.attributes?.identities && activeCognitoUser?.preferredMFA === 'NOMFA') ? (
          <ResetPassword />
        ) : (
          getHomePath()
        )}
      </Route>

      <Route exact path="/logout">
        <Logout />
      </Route>

      {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
      <PrivateRoute path="/settings" animate noUnauthorized>
        <Layout>
          {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
          <AnimatePresence exitBeforeEnter>
            <Settings />
          </AnimatePresence>
        </Layout>
      </PrivateRoute>

      {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
      <PrivateRoute path={customerRoute} noUnauthorized>
        {/* @ts-expect-error TS(2322): Type '{ customerRoute: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Pick<RouteComponentProps<any, StaticContext, unknown>, never>, any, any>> & Readonly<...>'. */}
        <CustomerRoutes customerRoute={customerRoute} />
      </PrivateRoute>

      {isEffectualUser && (
        // @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
        <PrivateRoute path="/empath" noUnauthorized>
          {/* @ts-expect-error TS(2322): Type '{ customerRoute: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Pick<RouteComponentProps<any, StaticContext, unknown>, never>, any, any>> & Readonly<...>'. */}
          <EmpathRoutes empathRoute={`/empath${customerRoute}`} />
        </PrivateRoute>
      )}

      <Route exact path="/">
        {getHomePath()}
      </Route>

      <Route path="*">
        {activeCognitoUser ? (
          <Layout>
            <PageNotFound />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )}
      </Route>
    </Switch>
  );
};

export default withRouter(Routes);
