import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';

import PageHeader from '../../components/header/PageHeader';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { getBaseUrl } from '../../shared/helper';
import Details from '../../components/user/Details';
import { getCustomer } from '../../graphql/queries';

const UserDetails = () => {
  const [customer, setCustomer] = useState({ name: '' });
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { userId, customerId } = useParams();

  const basePath = `${getBaseUrl(customerId)}/admin/users`;

  useEffect(() => {
    (async () => {
      try {
        const customerRes = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));
        setCustomer((customerRes as any).data.getCustomer);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [customerId]);

  const Crumbs = () => {
    const crumbs = [
      {
        text: 'Users',
        path: `/customers/${customerId}/admin/users`,
      },
      {
        text: `${userId ? 'Edit User' : 'Add User'}`,
      },
    ];

    return <Breadcrumbs crumbs={crumbs} />;
  };

  return (
    <div className="page-container">
      <PageHeader title={`${customer.name} Admin`} containerClassName="sm:mb-6" />

      <Crumbs />

      {(customer as any)?.id && <Details customer={customer} basePath={basePath} />}
    </div>
  );
};

export default UserDetails;
