import React from 'react';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { useController } from 'react-hook-form';
import { getErrorMessage } from './utils';

const createOption = (data: any) => {
  if (React.isValidElement(data)) {
    return data;
  }
  if (data !== null && typeof data === 'object') {
    return (
      <option key={data?.value || data.label} value={data?.value} disabled={data.disabled === true}>
        {data.label}
      </option>
    );
  }

  return <option key={data}>{data}</option>;
};

type SelectInputProps = {
  label?: string;
  field: string;
  errorMessage?: string;
  formErrors?: any;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  defaultValue?: string;
  placeholder?: string;
  options?: (
    | React.ReactElement
    | {
        disabled?: boolean;
        label: string;
        value?: string;
      }
    | string
  )[];
  tooltip?: React.ReactNode;
  control: any;
  rules?: any;
};

const SelectInput = ({
  label,
  field,
  formErrors,
  errorMessage,
  containerClassName,
  inputClassName,
  labelClassName,
  disabled,
  defaultValue = '',
  placeholder = '- Select an option -',
  options,
  tooltip,
  control,
  rules,
}: SelectInputProps) => {
  const {
    field: { ref, onChange, value },
  } = useController({
    name: field,
    control,
    rules,
    defaultValue: defaultValue || '',
  });

  const error = errorMessage || getErrorMessage(formErrors, field, label);

  return (
    <div className={containerClassName}>
      <div className="flex flex-row items-center">
        <label
          htmlFor={field}
          className={twCascade(
            'flex',
            'text-base',
            'mb-1',
            'text-blue-900',
            error ? 'text-red-700' : '',
            labelClassName
          )}
        >
          {label}
          {rules?.required && <span className="text-banner-red">*</span>}
        </label>

        {tooltip && <div className="mb-1">{tooltip}</div>}
      </div>

      <select
        onChange={onChange}
        value={value || defaultValue}
        id={field}
        name={field}
        ref={ref}
        disabled={disabled}
        className={twCascade(
          'w-full',
          'leading-tight',
          'text-purple-700',
          'border',
          'border-b-300',
          'rounded',
          error ? 'border-red-700' : '',
          'focus:ring-0',
          // 'focus:border-purple-700',
          inputClassName
        )}
      >
        <option disabled value="">
          {placeholder}
        </option>

        {options && options.map(createOption)}
      </select>
      <div className={`${error ? '' : 'invisible'} min-h-5 text-xs text-red-700`}>{error}</div>
    </div>
  );
};

SelectInput.displayName = 'SelectInput';

export default SelectInput;
