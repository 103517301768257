import React from 'react';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faInfoCircle, faBan } from '@fortawesome/pro-regular-svg-icons';

const error = (message: any) => {
  toast(
    <div className="toast-body">
      <div className="flex items-center pb-1">
        <div className="w-5 mr-2 text-red-700">
          <FontAwesomeIcon icon={faBan} fixedWidth />
        </div>
        <p className="font-bold text-gray-700 uppercase">Error</p>
      </div>
      <div className="pl-2 ml-5 text-gray-500">{message}</div>
    </div>,
    { className: 'border-red-700', autoClose: false }
  );
};

const success = (message: any) => {
  toast(
    <div className="toast-body">
      <div className="flex items-center pb-1">
        <div className="w-5 mr-2 text-emerald-600">
          <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
        </div>
        <p className="font-bold text-gray-700 uppercase">Success</p>
      </div>
      <div className="pl-2 ml-5 text-gray-500">{message}</div>
    </div>,
    { className: 'border-emerald-600' }
  );
};

const info = (message: any) => {
  toast(
    <div className="toast-body">
      <div className="flex items-center pb-1">
        <div className="w-5 mr-2 text-purple-700">
          <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
        </div>
        <p className="font-bold text-gray-500 uppercase">Info</p>
      </div>
      <div className="pl-2 ml-5 text-gray-500">{message}</div>
    </div>,
    { className: 'border-purple-500' }
  );
};

export { error as errorToast, success as successToast, info as infoToast };
