import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { TextInputNoControl } from '../../../components/inputs';
import { Button } from '../../../components/buttons';

type Props = {
  setUser: (...args: any[]) => any;
  user: any;
};

const CompletePasswordForm = ({ setUser, user }: Props) => {
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({ email: '', password: '', username: '', verificationCode: '' });

  const onChange = (e: any) => {
    const newState = { ...form, [e.target.name]: e.target.value };

    setForm(newState);
  };

  const newPassword = async () => {
    if (!form.password) {
      // @ts-expect-error TS(2345): Argument of type '"You must enter a password"' is ... Remove this comment to see the full error message
      setPasswordErrorMessage('You must enter a password');

      return;
    }
    if (form.password.length < 8) {
      // @ts-expect-error TS(2345): Argument of type '"Password must be at least 8 cha... Remove this comment to see the full error message
      setPasswordErrorMessage('Password must be at least 8 characters');

      return;
    }
    // @ts-expect-error TS(2345): Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
    setPasswordErrorMessage('');
    try {
      setSaving(true);

      await Auth.completeNewPassword(user, form.password);

      const updatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

      if (!updatedUser.attributes?.['custom:skip_MFA'] && updatedUser.preferredMFA === 'NOMFA') {
        updatedUser.challengeName = 'MFA_SETUP';
      }

      setSaving(false);
      setUser(updatedUser);

      if (!updatedUser.challengeName) {
        window.location.href = '/';
      }
    } catch (err) {
      setGeneralErrorMessage((err as any).message);
      setSaving(false);
    }
  };

  return (
    <div className="flex flex-col my-8 xl:w-3/4 xl:mx-auto">
      <p className="w-auto pt-2 pb-4 mb-2 text-xl text-gray-200 font-body">Please Create a New Password.</p>

      <TextInputNoControl
        label="Password"
        field="password"
        type="password"
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | undefined'.
        errorMessage={passwordErrorMessage}
        onSubmit={newPassword}
        onChange={onChange}
        purple
        autoFocus
      />

      {generalErrorMessage && (
        <div className="text-sm font-bold text-red-700 font-body align-center">{generalErrorMessage}</div>
      )}

      <Button
        title="Confirm Password"
        buttonStyle="solid"
        type="button"
        classNames="my-4 h-8 text-purple-700 hover:bg-gray-200"
        onClick={newPassword}
        loading={saving}
        loadingTitle="Saving Password"
      />
    </div>
  );
};

export default CompletePasswordForm;
