import React, { useState } from 'react';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisHAlt } from '@fortawesome/pro-light-svg-icons';

import { Button } from '../buttons';
import { FlexLoader } from '../loader';
import ErrorBoundary from '../errors/ErrorBoundary';
import ComponentError from '../errors/ComponentError';

type Props = {
  icon?: any;
  title: string;
  component?: any;
  loading?: boolean;
  errorMessage?: string;
  viewDetailsCallback?: (...args: any[]) => any;
  optionsDropdown?: any[];
  classNames?: string;
  noMinHeight?: boolean;
};

export default function Card({
  icon,
  title,
  component,
  loading,
  errorMessage,
  viewDetailsCallback,
  optionsDropdown,
  classNames,
  noMinHeight = false,
}: Props) {
  const [showOptions, setShowOptions] = useState(false);
  const handleOptions = () => setShowOptions(!showOptions);

  const renderData = () => {
    if (errorMessage) {
      return (
        <div className="flex items-center justify-center flex-1 py-16 ">
          <p className="text-center text-red-700">{errorMessage}</p>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="flex flex-1 my-16">
          <FlexLoader showText={false} smallLoader />
        </div>
      );
    }

    return component;
  };

  return (
    <div className={twCascade('flex', 'flex-col', 'bg-offwhite', 'shadow', 'rounded-lg', classNames)}>
      {/* @ts-expect-error TS(17004): Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
      <ErrorBoundary
        fallbackClassNames="flex h-full justify-center items-center text-center"
        fallbackComponent={ComponentError}
      >
        <div className="relative flex flex-row items-center mx-5 mt-3 mb-0">
          {icon && <div className="flex-shrink-0 text-lg text-purple-500">{icon}</div>}

          <div className={twCascade(icon && 'ml-4', 'flex-1')}>
            <h4 className="text-xl font-normal text-purple-700 break-words font-title">{title}</h4>
          </div>

          <div className="flex flex-row justify-end">
            {optionsDropdown && !!optionsDropdown.length && (
              <>
                {showOptions && (
                  <div
                    className="absolute z-50 origin-top-right bg-white rounded-md shadow-lg md:right-0 md:top-0 md:-mt-4 md:-mr-4 w-36"
                    onMouseLeave={handleOptions}
                  >
                    <div className="p-4 text-xs rounded-sm ring-1 ring-black ring-opacity-5">
                      {optionsDropdown &&
                        optionsDropdown.map((m, index) => (
                          <button key={index} type="button" onClick={m.downloadCSV}>
                            {m.text}
                          </button>
                        ))}
                    </div>
                  </div>
                )}

                <div className="text-purple-700">
                  <FontAwesomeIcon icon={faEllipsisHAlt} className="text-2xl" onMouseEnter={handleOptions} />
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={twCascade(
            'flex',
            'flex-col',
            'flex-1',
            'p-4',
            'rounded-lg',
            !noMinHeight && 'min-h-72',
            !noMinHeight && '4xl:min-h-96'
          )}
        >
          {renderData()}
        </div>

        {viewDetailsCallback && (
          <div className="flex flex-row justify-end px-5 py-1 mb-2">
            <Button title="View Details" buttonStyle="text" onClick={viewDetailsCallback} classNames="font-bold" />
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
}
