import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { TextInput } from '../../../components/inputs';
import { Button } from '../../../components/buttons';

type Props = {
  onBackToLoginClicked: (...args: any[]) => any;
};

const PasswordResetForm = ({ onBackToLoginClicked }: Props) => {
  const [notification, setNotification] = useState();
  const [resetting, setResetting] = useState(false);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, getValues, errors, clearErrors, watch, control } = useForm({
    defaultValues: {
      email: '',
      verificationCode: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const history = useHistory();

  const resetPassword = async () => {
    try {
      clearErrors();
      setResetting(true);
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setNotification(null);

      await Auth.forgotPasswordSubmit(getValues('email'), getValues('verificationCode'), getValues('newPassword'));

      // Signin, then signout globally to end all other sessions, then sign back in again.
      await Auth.signIn(getValues('email'), getValues('newPassword'));
      await Auth.signOut({ global: true });
      const res = await Auth.signIn(getValues('email'), getValues('newPassword'));

      setResetting(false);

      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      history.push({ pathname: '/login', cognitoUser: res });
    } catch (err) {
      setNotification({
        // @ts-expect-error TS(2345): Argument of type '{ isError: boolean; message: any... Remove this comment to see the full error message
        isError: true,
        message: (err as any).message,
      });

      setResetting(false);
    }
  };

  return (
    <div className="my-8">
      <form onSubmit={handleSubmit(resetPassword)}>
        <TextInput
          label="Email Address"
          field="email"
          control={control}
          rules={{ required: true }}
          formErrors={errors}
          purple
        />

        <TextInput
          label="Verification Code"
          field="verificationCode"
          control={control}
          rules={{ required: true }}
          formErrors={errors}
          purple
        />

        <TextInput
          label="New Password"
          field="newPassword"
          control={control}
          rules={{
            required: true,
            minLength: 8,
            validate: (value: any) => value === watch('confirmPassword') || 'Passwords do not match',
          }}
          formErrors={errors}
          type="password"
          purple
        />

        <TextInput
          label="Confirm Password"
          field="confirmPassword"
          control={control}
          rules={{
            required: true,
            minLength: 8,
            validate: (value: any) => value === watch('newPassword') || 'Passwords do not match',
          }}
          formErrors={errors}
          type="password"
          purple
        />

        {notification && (
          <div
            className={`text-sm font-bold ${
              (notification as any).isError ? 'text-red-700' : 'text-gray-200'
            } font-body align-center`}
          >
            {(notification as any).message}
          </div>
        )}

        <Button
          title="Submit New Password"
          buttonStyle="solid"
          type="submit"
          classNames="my-4"
          loading={resetting}
          loadingTitle="Submitting New Password"
        />
      </form>

      <Button
        title="BACK TO LOGIN"
        onClick={onBackToLoginClicked}
        type="submit"
        buttonStyle="text"
        classNames="text-white text-left hover:text-gray-200"
      />
    </div>
  );
};

export default PasswordResetForm;
