import { API, graphqlOperation } from 'aws-amplify';
import { generateCSVReport } from '../../graphql/queries';
import { errorToast, successToast } from '../../shared/toast';
import { isValidTimeZone } from '../../shared/dateHelpers';

const STATUS_ACCEPTED = 202;

export const exportCSVReport = async (activeUser: any, customerId: any, reportName: any, csvParams: any) => {
  const { email, firstName, lastName, timeZone } = activeUser;

  const tz = isValidTimeZone(activeUser.timeZone) ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  const body = {
    email,
    customerId,
    givenName: `${firstName} ${lastName}`,
    reportName,
    timeZone: tz,
    data: JSON.stringify(csvParams),
  };

  try {
    const response = await API.graphql(
      graphqlOperation(generateCSVReport, {
        body,
      })
    );

    if ((response as any)?.data?.generateCSVReport?.statusCode === STATUS_ACCEPTED) {
      successToast(
        'Your request was submitted, we will process the request and you will receive an email to download your CSV.'
      );
    } else {
      errorToast('Error submitting your request');
    }
  } catch (error) {
    console.error(error);
    errorToast('Error submitting your request');
  }
};
