import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';

import { getCustomer } from '../../graphql/queries';
import PageHeader from '../../components/header/PageHeader';
import UsersTable from '../../components/tables/UsersTable';
import Tabs from '../../components/tabs/Tabs';
import { getBaseUrl } from '../../shared/helper';

const UserList = () => {
  const [customer, setCustomer] = useState({});
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId } = useParams();
  const history = useHistory();

  useEffect(() => {
    const get = async () => {
      try {
        const response = await API.graphql(graphqlOperation(getCustomer, { id: customerId }));
        setCustomer((response as any).data.getCustomer);
      } catch (err) {
        console.error('Error fetching customer: ', err);
      }
    };
    get();
  }, [customerId]);

  const setActiveTab = () => {
    history.push(`${getBaseUrl(customerId)}/admin/sso`);
  };

  return (
    <div className="page-container">
      <PageHeader title="Admin" containerClassName="sm:mb-6" />

      <div>
        <Tabs tabs={[{ title: 'User Admin', active: true }, { title: 'SSO Settings' }]} tabSelected={setActiveTab} />

        {(customer as any)?.id && <UsersTable customer={customer} nameIsLink />}
      </div>
    </div>
  );
};

export default UserList;
