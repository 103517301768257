/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'luxo... Remove this comment to see the full error message
import { DateTime } from 'luxon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FormSelect, TextInput, TextAreaInput } from '../inputs';
import Modal from './Modal';
import SupportToolTip from '../../pages/support/SupportToolTip';
import { createTicket } from '../../graphql/mutations';
import { useUser } from '../../context/UserContext';
import { errorToast, successToast } from '../../shared/toast';
import { formatDateShortAndTime } from '../../shared/dateHelpers';
import { MAX_FILE_SIZE } from '../../shared/constants';

type Props = {
  doShow: boolean;
  cancel: (...args: any[]) => any;
  className?: string;
  onSubmitted?: (...args: any[]) => any;
};

const SupportTicketModal = ({ doShow, cancel, className, onSubmitted }: Props) => {
  const params = useParams();
  const { handleSubmit, errors, reset, control, getValues, setValue, watch } = useForm({
    defaultValues: {
      category: '',
      subCategory: '',
      type: 'Support Request',
      priority: '',
      issue: '',
      requestedDate: undefined,
      requestedTime: undefined,
    },
  });
  // @ts-expect-error TS(2339): Property 'impersonation' does not exist on type 'unknown'.
  const { activeUser, user, impersonation } = useUser();
  const [saving, setSaving] = useState(false);
  const [step, setStep] = useState('1');
  const category = watch('category');
  const [subCategories, setSubCategories] = useState();
  const [subCategoryLabel, setSubCategoryLabel] = useState();
  const [data, setData] = useState({});
  const addAttachment = useRef(null);
  const [newAttachments, setNewAttachments] = useState([]);
  const [actionText, setActionText] = useState('Next');
  const [otherActionText, setOtherActionText] = useState();

  useEffect(() => {
    if (getValues('subCategory')) {
      setValue('subCategory', '');
    }
    if (category) {
      setSubCategories((category as any).subCategories);
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setSubCategoryLabel(`${(category as any).label} Type`);
    } else {
      setSubCategories(undefined);
    }
  }, [category, getValues, setValue]);

  useEffect(() => {
    switch (step) {
      case '1':
        setActionText('Next');
        setOtherActionText(undefined);
        break;
      case '2':
        setActionText('Submit');
        // @ts-expect-error TS(2345): Argument of type '"Back"' is not assignable to par... Remove this comment to see the full error message
        setOtherActionText('Back');
        break;
      default:
        break;
    }
  }, [step]);

  const handleAction = async (newData: any) => {
    if (newData.category && !newData.subCategory) {
      delete (data as any).subCategory;
    }
    const combinedData = { ...data, ...newData };

    setData(combinedData);
    if (step === '1') {
      setStep('2');
      reset(combinedData);
    } else if (step === '2') {
      setSaving(true);
      // @ts-expect-error TS(2339): Property 'userId' does not exist on type 'unknown'.
      const { customerId } = params;
      const files = [];

      if (newAttachments && newAttachments.length > 0) {
        const credentials = await Auth.currentUserCredentials();

        for (const item of newAttachments) {
          const upload = await Storage.put(`${(item as any).fileName}`, (item as any).file, {
            contentType: (item as any).file.type,
            level: 'private',
          });

          files.push({
            fileName: (item as any).fileName,
            key: `private/${credentials.identityId}/${upload.key}`,
          });
        }
      }

      let commentPrefix = `${user.firstName} ${user.lastName} `;

      if (impersonation && impersonation.user) {
        commentPrefix += `submitted on behalf of ${impersonation.user.firstName} ${impersonation.user.lastName} (${impersonation.user.email}):`;
      } else if (impersonation) {
        commentPrefix += `submitted on behalf of ${impersonation.company}:`;
      } else {
        commentPrefix += `(${activeUser.email}) said:`;
      }

      let requestDateTime;

      if (combinedData.requestedDate) {
        const hour = combinedData.requestedTime.getHours();
        const minute = combinedData.requestedTime.getMinutes();

        requestDateTime = formatDateShortAndTime(
          DateTime.fromJSDate(combinedData.requestedDate, activeUser.timeZone).set({ hour, minute }),
          activeUser.timeZone
        );
      }

      const serviceNow = {
        active: 'true',
        short_description: combinedData.subject,
        description: combinedData.issue,
        comments: requestDateTime
          ? `${requestDateTime}\n${commentPrefix}\n${combinedData.issue}`
          : `${commentPrefix}\n${combinedData.issue}`,
        impact: combinedData.priority.impact,
        urgency: combinedData.priority.urgency,
        u_case_category:
          (combinedData.subCategory && combinedData.subCategory.category) || combinedData.category.category,
        u_case_subcategory:
          (combinedData.subCategory && combinedData.subCategory.subCategory) || combinedData.category.subCategory,
        attachments: files,
        start_date: requestDateTime,
        end_date: requestDateTime,
      };

      const body = {
        data: JSON.stringify(serviceNow),
      };

      (body as any).serviceNowId = activeUser.serviceNowId;
      (body as any).email = activeUser.email;

      try {
        await API.graphql(
          graphqlOperation(createTicket, {
            body,
            params: { customerId },
          })
        );

        successToast('Thank you for submitting a support ticket');
        setSaving(false);
        if (onSubmitted) {
          onSubmitted();
        }
        clearForm();
      } catch (err) {
        errorToast('Error saving support ticket');
        setSaving(false);
        console.error('Error saving support ticket', err);
      }
    }
  };

  const handleOtherAction = () => {
    const combinedData = { ...data, ...getValues() };

    setData(combinedData);

    if (step === '2') {
      setStep('1');
      reset(data);
    }
  };

  const categories = [
    {
      label: 'Service Request',
      value: 'AWS Managed Environment',
      category: 'AWS Managed Environment',
      description: 'Support requests for AWS environments managed by Effectual.',
      subCategories: [
        {
          label: 'General Question',
          value: 'General Question',
          subCategory: 'Unknown / Other',
          description: 'TBD',
        },
        {
          label: 'Performance Issues',
          value: 'Performance Issues',
          subCategory: 'Performance Issue',
          description: 'TBD',
        },
        {
          label: 'Feature / Capability Issue',
          value: 'Feature / Capability Issue',
          subCategory: 'Feature / Capability Issue',
          description: 'TBD',
        },
        {
          label: 'Access Issue',
          value: 'Access Issue',
          subCategory: 'Access Issue',
          description: 'TBD',
        },
        {
          label: 'Network Issue',
          value: 'Network Issue',
          subCategory: 'Network Issue',
          description: 'TBD',
        },
      ],
    },
    {
      label: 'Security and Compliance',
      value: 'Security and Compliance',
      category: 'Security and Compliance',
      description: 'Support request associated with security and/or compliance issues.',
    },
    {
      label: 'General Question',
      value: 'Other',
      category: 'Inquiry / Help',
      subCategory: 'Unknown / Other',
      description: 'TBD',
    },
    {
      label: 'Billing',
      value: 'Billing',
      category: 'Finance & Legal',
      subCategory: 'Unknown / Other',
      description: 'TBD',
    },
  ];

  const priorities = [
    {
      label: 'Planning',
      value: 'Planning',
      impact: 3,
      urgency: 3,
      description: 'Scheduling routine maintenance, advice on a potential configuration change, etc.',
    },
    { label: 'Low', value: 'Low', impact: 3, urgency: 2, description: 'A non-business impacting issue' },
    {
      label: 'Medium',
      value: 'Medium',
      impact: 2,
      urgency: 2,
      description: 'Non-critical system performance issue',
    },
    { label: 'High', value: 'High', impact: 1, urgency: 2, description: 'Production system(s) severely impaired' },
    { label: 'Critical', value: 'Critical', impact: 1, urgency: 1, description: 'Production system(s) unavailable' },
  ];

  const internalCancel = () => {
    cancel();
    clearForm();
  };

  const clearForm = () => {
    setStep('1');
    setSubCategories(undefined);
    setNewAttachments([]);
    reset({ category: '' });
    setData({});
  };

  const priorityToolTip = (
    <ul className="text-xs text-purple-900">
      {priorities &&
        priorities.map(x => (
          <li className="pb-2" key={x.label}>
            <span className="font-bold">{x.label}:</span> {x.description}
          </li>
        ))}
    </ul>
  );

  const categoryToolTip = (
    <div>
      <div>
        <p className="font-bold">Service Request:</p>A request which requires a response from Effectual to resolve an
        incident or issue.
      </div>
      <div>
        <p className="font-bold">Security and Compliance:</p>
        Any requests specifically regarding the security and or compliance of your infrastructure.
      </div>
      <div>
        <p className="font-bold">General Question:</p>A general inquiry about your account or environment
      </div>
      <div>
        <p className="font-bold">Billing Question:</p>A question about your spend or invoice.
      </div>
    </div>
  );

  const step1 = () => (
    <div className="mt-3 text-left sm:mt-0 sm:mx-4">
      <p className="my-4 text-sm text-gray-700 sm:text-base">How can we help you?</p>
      <FormSelect
        control={control}
        // @ts-expect-error TS(2322): Type '{ control: Control<{ category: string; subCa... Remove this comment to see the full error message
        className="w-1/2"
        label="Category"
        field="category"
        placeholder="Select an option"
        rules={{ required: true }}
        formErrors={errors}
        options={categories}
        tooltip={<SupportToolTip maxWidth={600}>{categoryToolTip}</SupportToolTip>}
      />

      {subCategories && (
        <FormSelect
          control={control}
          className="w-1/2"
          // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
          label={subCategoryLabel}
          field="subCategory"
          placeholder="Select an option"
          rules={{ required: true }}
          formErrors={errors}
          options={subCategories}
        />
      )}
    </div>
  );

  const step2 = () => (
    <div className="mt-3 text-left sm:mt-0 sm:mx-4" style={{ maxHeight: '58vh' }}>
      <p className="my-4 text-sm text-gray-700 sm:text-base">Confirm your support request details:</p>
      <p className="my-4 text-sm text-gray-700 sm:text-base">
        {(data as any).category.label}
        {(data as any).subCategory ? `: ${(data as any).subCategory.label}` : ''}
      </p>
      <TextInput
        control={control}
        // @ts-expect-error TS(2322): Type '{ control: Control<{ category: string; subCategory:
        className="w-1/2"
        label="Subject"
        field="subject"
        rules={{ required: true, maxLength: 160 }}
        formErrors={errors}
      />
      <FormSelect
        control={control}
        // @ts-expect-error TS(2322): Type '{ control: Control<{ category: string; subCategory:
        className="w-1/2"
        label="Priority"
        field="priority"
        placeholder="Select an option"
        rules={{ required: true }}
        formErrors={errors}
        options={priorities}
        tooltip={<SupportToolTip>{priorityToolTip}</SupportToolTip>}
      />
      <TextAreaInput
        control={control}
        // @ts-expect-error TS(2322): Type '{ control: Control<{ category: string; subCategory:
        className="w-full"
        label="Description"
        field="issue"
        rows="5"
        rules={{ required: true, maxLength: 5000 }}
        formErrors={errors}
      />
      {newAttachments &&
        newAttachments.map(attachment => (
          <div className="flex text-xs" key={(attachment as any).fileName}>
            {(attachment as any).fileName}
            <button
              aria-label="Remove Attachment"
              type="button"
              className="focus:ring-0"
              onClick={() => {
                // @ts-expect-error TS(2339): Property 'fileName' does not exist on type 'never'... Remove this comment to see the full error message
                removeAttachmentClick(attachment.fileName);
              }}
            >
              <div className="ml-1 text-red-700">
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </button>
          </div>
        ))}
      <input
        hidden
        multiple
        type="file"
        accept="image/png, image/jpeg, .pdf, .doc, .docx, .xlsx"
        onChange={e => {
          if (e.target.files === null) {
            return;
          }

          const fileList = Array.from(e.target.files);

          if (fileList.filter((file: File) => file.size > MAX_FILE_SIZE).length > 0) {
            errorToast('Attachments must be less than 10MB');
            return;
          }
          addAttachmentClick(e);
        }}
        ref={addAttachment}
      />
      <button
        type="button"
        className="flex mb-2 text-xs underline focus:ring-0"
        // @ts-expect-error TS(18047): 'addAttachment.current' is possibly 'null'.
        onClick={() => addAttachment.current.click()}
      >
        Add New Attachment
      </button>
    </div>
  );

  const addAttachmentClick = (e: ChangeEvent<HTMLInputElement>) => {
    const newItems = [];

    if (e.target.files === null) {
      return;
    }

    for (const file of e.target.files) {
      if (!newAttachments.some(x => (x as any).fileName === file.name)) {
        newItems.push({ fileName: file.name, file });
      }
    }
    if (newItems.length > 0) {
      // @ts-expect-error TS(2304): Cannot find name 'setNewAttachments'.
      setNewAttachments([...newAttachments, ...newItems]);
    }
    // @ts-expect-error: Reseting value to null.
    e.target.value = null;
  };

  const removeAttachmentClick = (fileName: any) => {
    setNewAttachments(newAttachments.filter(x => (x as any).fileName !== fileName));
  };

  return (
    <Modal
      className={className}
      doShow={doShow}
      cancel={internalCancel}
      actionLoading={saving}
      title={
        <div className="flex flex-col flex-wrap items-start justify-between w-full py-2 md:py-0 sm:flex-row md:items-center">
          <span className="pr-2 ">
            Create a new <span className="text-lg text-orange sm:text-xl">support request</span>
          </span>
          <span>
            Phone Support: <span className="text-sm text-orange sm:text-base md:text-xl">877-470-5260</span>
          </span>
        </div>
      }
      actionText={actionText}
      action={() => handleSubmit(handleAction)()}
      otherActionText={otherActionText}
      otherAction={() => handleOtherAction()}
    >
      <form onSubmit={handleSubmit(handleAction)}>
        {step === '1' && step1()}
        {step === '2' && step2()}
      </form>
    </Modal>
  );
};

SupportTicketModal.displayName = 'SupportTicketModal';

export default SupportTicketModal;
