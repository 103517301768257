import React from 'react';

import PageHeader from '../../components/header/PageHeader';
import UsersTable from '../../components/tables/UsersTable';

const EmpathAdminUserList = () => (
  <div className="page-container">
    <PageHeader title="Empath Admin" />

    <div>
      <UsersTable showActions nameIsLink hideIcon />
    </div>
  </div>
);

export default EmpathAdminUserList;
