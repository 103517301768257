import React, { useState } from 'react';

import Modal from '../modal/Modal';

// @ts-expect-error TS(2307): Cannot find module '../../assets/onboardingtour/st... Remove this comment to see the full error message
import TourIMG1 from '../../assets/onboardingtour/step-1.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/onboardingtour/st... Remove this comment to see the full error message
import TourIMG2 from '../../assets/onboardingtour/step-2.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/onboardingtour/st... Remove this comment to see the full error message
import TourIMG3 from '../../assets/onboardingtour/step-3.png';
// @ts-expect-error TS(2307): Cannot find module '../../assets/onboardingtour/st... Remove this comment to see the full error message
import TourIMG4 from '../../assets/onboardingtour/step-4.png';

type Props = {
  doShow: boolean;
  cancel: (...args: any[]) => any;
  action: (...args: any[]) => any;
};

const Tour = ({ doShow, cancel, action }: Props) => {
  const totalSteps = 4;
  const [step, setStep] = useState(1);
  const handleStepNext = () => setStep(step + 1);
  const handleStepBack = () => setStep(step - 1);

  const actionClick = () => {
    if (step === totalSteps) {
      return action;
    }

    return handleStepNext;
  };

  const actionText = () => {
    if (step === totalSteps) {
      return 'Finish';
    }

    return 'Next';
  };

  const otherActionClick = () => {
    if (step > 1) {
      return handleStepBack;
    }
  };

  const otherActionText = () => {
    if (step > 1) {
      return 'Back';
    }
  };

  function StepOne() {
    return (
      <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left">
        <p>Join us for a quick tour</p>

        <div className="border-solid border-l-16 border-purple-500 shadow-md mt-4">
          <div className="px-4 py-1 bg-purple-500 text-orange">
            <h6>Submit a Ticket</h6>
          </div>
          <div className="px-8 py-2 flex">
            <div className="flex-initial w-3/5 pr-12">
              <p className="py-4">Need Assistance?</p>
              <p className="text-sm leading-4">
                We are here to help! Send us a request by opening a ticket under the support section of our left hand
                menu.
              </p>
            </div>
            <div className="flex-initial px-8 py-4">
              <img src={TourIMG1} alt="" className="h-32" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function StepTwo() {
    return (
      <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left">
        <div className="border-solid border-l-16 border-purple-500 shadow-md mt-4">
          <div className="px-4 py-1 bg-purple-500 text-orange">
            <h6>Send Feedback</h6>
          </div>
          <div className="px-8 py-2 flex">
            <div className="flex-initial w-3/5 pr-12">
              <p className="py-4">We want to hear from you!</p>
              <p className="text-sm leading-4">
                Let us know how we can improve! The send feedback link is in the left hand menu.
              </p>
            </div>
            <div className="flex-initial px-8 py-4">
              <img src={TourIMG2} alt="" className="h-32" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function StepThree() {
    return (
      <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left">
        <div className="border-solid border-l-16 border-purple-500 shadow-md mt-4">
          <div className="px-4 py-1 bg-purple-500 text-orange">
            <h6>View Your Cloud Metrics</h6>
          </div>
          <div className="px-8 py-2 flex">
            <div className="flex-initial w-3/5 pr-12">
              <p className="py-4">Get an overview of your cloud performance</p>
              <p className="text-sm leading-4">
                By clicking on the “My Cloud” link in the menu, you will be able to view your resources and see metrics
                while filtering by categories such as network traffic, cpu capacity and more.
              </p>
            </div>
            <div className="flex-initial px-8 py-4">
              <img src={TourIMG3} alt="" className="h-32" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function StepFour() {
    return (
      <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left">
        <div className="border-solid border-l-16 border-purple-500 shadow-md mt-4">
          <div className="px-4 py-1 bg-purple-500 text-orange">
            <h6>Send us a Support Ticket</h6>
          </div>
          <div className="px-8 py-2 flex">
            <div className="flex-initial w-3/5 pr-12">
              <p className="py-4">Conveniently view and submit support tickets</p>
              <p className="text-sm leading-4">
                Click on the support section to view your open tickets and create a new ticket. From you support
                dashboard you can view ticket status and view ticket details including sending replies.
              </p>
            </div>
            <div className="flex-initial px-8 py-4">
              <img src={TourIMG4} alt="" className="h-32" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      doShow={doShow}
      cancel={cancel}
      cancelText="Skip"
      action={actionClick()}
      actionText={actionText()}
      otherAction={otherActionClick()}
      otherActionText={otherActionText()}
      showXButton={false}
      title={
        <span>
          Welcome to <span className="text-orange">Empath!</span>
        </span>
      }
    >
      <div className="px-4 py-8">
        {step === 1 && <StepOne />}
        {step === 2 && <StepTwo />}
        {step === 3 && <StepThree />}
        {step === 4 && <StepFour />}
      </div>
    </Modal>
  );
};

export default Tour;
