import React, { useState, useRef, useEffect, useCallback, ChangeEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import gql from 'graphql-tag';

import { useUser } from '../../context/UserContext';
import { useSite } from '../../context/SiteContext';
import { customerGroups, effectualGroups, servicesGroups } from '../../shared/groupsHelper';
import { getTicketComments, getTicketAttachment, getTicket } from '../../graphql/queries';
import { createTicketComment } from '../../graphql/mutations';
import { errorToast } from '../../shared/toast';
import { ComponentDataLoader } from '../../components/loader';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { Button } from '../../components/buttons';
import PageHeader from '../../components/header/PageHeader';
import Tabs from '../../components/tabs/Tabs';
import CloudSupportEscalationPlanLink from '../../components/cloudSupportEscalationPlanLink/CloudSupportEscalationPlanLink';
import { getBaseUrl } from '../../shared/helper';
import { formatDateShortAndTime, formatDateMedAndTime } from '../../shared/dateHelpers';
import { ticketIsClosed } from './helpers';
import ScheduleMaintenance from '../errors/ScheduleMaintenance';
import { MAX_FILE_SIZE } from '../../shared/constants';

const Incident = () => {
  const PRIORITIES = {
    1: { 1: 'Critical', 2: 'High' },
    2: { 2: 'Medium' },
    3: { 2: 'Low', 3: 'Planning' },
  };

  const [comments, setComments] = useState([]);
  const [commentsLoaded, setCommentsLoaded] = useState(false);
  const [commentLoadError, setCommentLoadError] = useState('');
  const [attachments, setAttachments] = useState();
  const [newAttachments, setNewAttachments] = useState([]);
  const [sendingComment, setSendingComment] = useState(false);
  const [requestingClose, setRequestingClose] = useState(false);
  const [incident, setIncident] = useState({});
  const [supportRequest, setSupportRequest] = useState({});
  const [changeRequest, setChangeRequest] = useState({});
  const [showAttachments, setShowAttachments] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [showComments, setShowComments] = useState(true);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const [pageError, setPageError] = useState(false);

  const useMountEffect = (func: any) => useEffect(func, []);
  const commentContainerEnd = useRef(null);
  // @ts-expect-error TS(2339): Property 'impersonation' does not exist on type 'unknown'.
  const { user, activeUser, userInRoles, impersonation } = useUser();
  // @ts-expect-error TS(2339): Property 'impersonation' does not exist on type 'unknown'.
  const { servicesStatus } = useSite();
  const { handleSubmit, errors, reset, control } = useForm({ defaultValues: { reply: '' } });
  const addAttachment = useRef(null);
  // @ts-expect-error TS(2339): Property 'customerId' does not exist on type 'unknown'.
  const { customerId, incidentId } = useParams();
  const history = useHistory();
  const cmpUser = 'cmp_webservice';

  const listNotifications = /* GraphQL */ `
    query ListNotifications($sub: String, $filter: NotificationFilterInput, $nextToken: String) {
      listNotifications(sub: $sub, filter: $filter, nextToken: $nextToken) {
        items {
          id
        }
        nextToken
      }
    }
  `;

  useMountEffect(() => {
    const update = async () => {
      let results: any = [];
      let nextToken = null;
      let mutations = '';

      do {
        // @ts-expect-error TS(7022): 'res' implicitly has type 'any' because it does no... Remove this comment to see the full error message
        const res = await API.graphql(
          graphqlOperation(listNotifications, {
            sub: activeUser.sub,
            filter: { key: { eq: incidentId } },
            nextToken,
          })
        );

        results = results.concat(res.data.listNotifications.items);

        ({ nextToken } = res.data.listNotifications);
      } while (nextToken);

      if (results.length) {
        // @ts-expect-error TS(7006): Parameter 'result' implicitly has an 'any' type.
        results.forEach((result, index) => {
          mutations += `notification${index}: updateNotification(input: { isRead: true, id: "${result.id}", sub: "${activeUser.sub}" }) { id, sub, message, when, isRead, customerId, type, key, createdAt, updatedAt }`;
        });

        const notificationsMutation = gql`mutation { ${mutations} }`;

        try {
          await API.graphql(graphqlOperation(notificationsMutation));
        } catch (e) {
          console.error('Error updating notifications', e);
        }
      }
    };

    update();
  });

  const loadInitialData = () => {
    (async () => {
      try {
        const res = await API.graphql(graphqlOperation(getTicket, { params: { ticketId: incidentId, customerId } }));
        if ((res as any)?.data?.getTicket) {
          (res as any).data.getTicket.data = JSON.parse((res as any).data.getTicket.data);
        }
        setSupportRequest((res as any)?.data?.getTicket?.linkedSupportRequest);
        setChangeRequest((res as any)?.data?.getTicket?.changeRequest);
        if ((res as any)?.data?.getTicket?.data) {
          const { impact, urgency } = (res as any).data.getTicket.data;
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          (res as any).data.getTicket.data.priority = PRIORITIES?.[impact]?.[urgency] || '';
          if ((res as any).data.getTicket.data.category === 'AWS Managed Environment') {
            (res as any).data.getTicket.data.category = 'Service Request';
          }
        }

        setIncident((res as any)?.data?.getTicket);
        if ((res as any)?.data?.getTicket?.attachments) {
          setAttachments(JSON.parse((res as any).data.getTicket.attachments));
        }
        await getComments();
        window.addEventListener('resize', doResize);
        doResize();
      } catch (error) {
        console.error(error);
        setPageError(true);
      }
    })();
  };

  const doResize = () => {
    const { innerWidth: width } = window;

    if (width >= 768) {
      setShowAttachments(true);
    }
  };

  useMountEffect(loadInitialData);

  const allowedToEdit = () => {
    if (
      userInRoles([
        effectualGroups.effectualAdmin.groupName,
        effectualGroups.effectualAccounting.groupName,
        customerGroups.customerAdmin.groupName,
        customerGroups.customerSupport.groupName,
        customerGroups.customerAccounting.groupName,
        customerGroups.customerTechnical.groupName,
      ])
    ) {
      return true;
    }

    return false;
  };

  const createComment = async (comment: any, fileAttachments: any) => {
    const files = [];

    if (fileAttachments && fileAttachments.length > 0) {
      const credentials = await Auth.currentUserCredentials();

      for (const item of fileAttachments) {
        const upload = await Storage.put(`${(incident as any).id}/${item.fileName}`, item.file, {
          contentType: item.file.type,
          level: 'private',
        });

        files.push({
          fileName: item.fileName,
          key: `private/${credentials.identityId}/${upload.key}`,
        });
      }
    }
    const params = {
      params: { customerId, ticketId: incidentId },
      body: {
        data: JSON.stringify({
          sys_id: (incident as any).id,
          id: (incident as any).id,
          comments: comment,
          attachments: files,
        }),
      },
    };
    const response = await API.graphql(graphqlOperation(createTicketComment, params));
    const commentsNow = [...comments, ...(response as any).data.createTicketComment.items];

    commentsNow.sort((a, b) => (a.createdOn < b.createdOn ? -1 : 1));

    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setComments(commentsNow);
    setCommentsLoaded(true);
    setNewAttachments([]);
    (incident as any).attachments = (response as any).data.createTicketComment.attachments
      ? JSON.stringify((response as any).data.createTicketComment.attachments)
      : undefined;
    setAttachments((response as any).data.createTicketComment.attachments);
    reset();
  };

  const scrollToBottom = useCallback((behavior: any) => {
    setTimeout(() => {
      if (commentContainerEnd.current) {
        (commentContainerEnd.current as any).scrollIntoView({ behavior });
      }
    }, 200);
  }, []);

  useEffect(() => {
    scrollToBottom('smooth');
  }, [comments, scrollToBottom]);

  useEffect(() => {
    const { isUnderMaintenance: serviceNowUnderMaintenance, serviceName } = servicesStatus;

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (serviceNowUnderMaintenance && serviceName === servicesGroups[serviceName].name) {
      setIsUnderMaintenance(true);
    }
  }, [servicesStatus]);

  const requestToCloseTicket = async () => {
    try {
      setRequestingClose(true);

      let comment = '';

      if (impersonation && impersonation.user) {
        comment = `${user.firstName} ${user.lastName} has requested to close this ticket on behalf of ${impersonation.user.firstName} ${impersonation.user.lastName} (${impersonation.user.email}).`;
      } else if (impersonation) {
        comment += `${user.firstName} ${user.lastName} has requested to close this ticket on behalf of ${impersonation.company}.`;
      } else {
        comment += `Customer (${activeUser.firstName} ${activeUser.lastName} (${activeUser.email})) has requested to close this ticket.`;
      }

      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      await createComment(comment);
    } catch (err) {
      console.error('Error creating comment', err);
      errorToast('Error Submitting Request');
    }

    setRequestingClose(false);
  };

  const onSubmit = async (data: any) => {
    try {
      setSendingComment(true);

      let ticketPrefix = `${user.firstName} ${user.lastName} `;

      if (impersonation && impersonation.user) {
        ticketPrefix += `submitted on behalf of ${impersonation.user.firstName} ${impersonation.user.lastName} (${impersonation.user.email}):`;
      } else if (impersonation) {
        ticketPrefix += `submitted on behalf of ${impersonation.company}:`;
      } else {
        ticketPrefix += `(${activeUser.email}) said:`;
      }

      await createComment(`${ticketPrefix}\n${data.reply}`, newAttachments);
    } catch (err) {
      console.error('Error creating comment', err);
      errorToast('Error Submitting Comment');
    }

    setSendingComment(false);
  };

  const getComments = async () => {
    let nextToken = null;
    const items = [];

    try {
      setCommentLoadError('');

      do {
        const params = { params: { customerId, ticketId: incidentId }, query: { limit: 100 } };

        if (nextToken !== null) {
          (params as any).nextToken = nextToken;
        }

        const response = await API.graphql(graphqlOperation(getTicketComments, params));

        items.push(...(response as any).data.getTicketComments.items);

        ({ nextToken } = (response as any).data.getTicketComments);
      } while (nextToken);
    } catch (error) {
      console.error('Error loading ticket comments: ', error);
      setCommentLoadError('Error loading comments');
    }

    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setComments(items.sort((a, b) => (a.createdOn < b.createdOn ? -1 : 1)));
    setCommentsLoaded(true);
  };

  const onAttachmentClick = async (id: any) => {
    try {
      const params = { params: { customerId, ticketId: incidentId, attachmentId: id } };
      const response = await API.graphql(graphqlOperation(getTicketAttachment, params));
      const link = document.createElement('a');
      const attachment = (response as any).data.getTicketAttachment;

      link.href = attachment.downloadUrl;
      link.download = attachment.fileName;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
      errorToast('Failed to download attachment');
    }
  };

  const addAttachmentClick = (e: ChangeEvent<HTMLInputElement>) => {
    const newItems = [];

    if (e.target.files === null) {
      return;
    }

    for (const file of e.target.files) {
      if (!newAttachments.some(x => (x as any).fileName === file.name)) {
        newItems.push({ fileName: file.name, file });
      }
    }
    if (newItems.length > 0) {
      // @ts-expect-error TS(2304): Cannot find name 'setNewAttachments'.
      setNewAttachments([...newAttachments, ...newItems]);
    }
    // @ts-expect-error: Reseting value to null.
    e.target.value = null;
  };

  const removeAttachmentClick = (fileName: any) => {
    setNewAttachments(newAttachments.filter(x => (x as any).fileName !== fileName));
  };

  const renderComments = () => {
    let commentsContent;
    const regex = /([A-z\S\s]*)\s(\([\S]*\))\ssaid:\n([A-z0-9\s\S]*)/;

    const breakUpComment = (comment: any) => {
      let res = comment.comment.match(regex);
      let lines = comment.comment.split('\n');
      let reqDate = lines.splice(0, 1);

      if (comment.createdBy === cmpUser) {
        if (!res) {
          if (comment.comment.startsWith('received from')) {
            return {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              name: reqDate[0].charAt(0).toUpperCase() + reqDate[0].slice(1),
              comment: lines.splice(1).join('\n'),
            }; // In this case reqDate will contains received from email
          }

          if (comment.comment.indexOf('requested to close')) {
            const newtext = lines.join('\n');

            if (Date.parse(reqDate)) {
              res = newtext.split('\n').splice(0, 1);
              res = res[0].split(' ('); // This will remove Email ID

              return {
                name: res[0],
                comment: newtext.split('\n').splice(1).join('\n'),
                reqestDate: reqDate,
              };
            }
            res = reqDate[0].split(' ('); // This will remove Email ID

            return { name: res[0], comment: newtext };
          }

          return {};
        }

        lines = res[1].split('\n');
        reqDate = lines.splice(0, 1);

        if (Date.parse(reqDate)) return { name: lines, comment: res[3], reqestDate: reqDate };

        return { name: res[1], comment: res[3] };
      }

      return { name: comment.createdBy, comment: comment.comment };
    };

    if (commentLoadError) {
      commentsContent = <p className="p-4 text-error-red">There was an error loading comments for this ticket.</p>;
    } else if (!commentsLoaded) {
      commentsContent = <ComponentDataLoader className="m-auto" />;
    } else if (comments && comments.length > 0) {
      commentsContent = comments.map((comment, index) => (
        <div key={(comment as any).id}>
          {index === comments.length - 1 && <div ref={commentContainerEnd} />}
          <div
            className="flex flex-col pl-2 mb-4 text-sm text-purple-700 border-purple-700 shadow border-l-6"
            key={(comment as any).id}
          >
            <div className="flex flex-col md:flex-row" style={{ whiteSpace: 'break-spaces' }}>
              {breakUpComment(comment).name && <p className="font-bold">{breakUpComment(comment).name}</p>}
              <p className="hidden mx-1 font-bold md:block">|</p>
              <p className="font-bold">{formatDateMedAndTime((comment as any).createdOn, activeUser.timeZone)}</p>
            </div>
            {breakUpComment(comment).reqestDate && (
              <p>
                <b>Requested Date:</b> {breakUpComment(comment).reqestDate}
              </p>
            )}
            <p style={{ whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
              {`${breakUpComment(comment).comment}`}
            </p>
          </div>
          {index === comments.length - 1 && <div className="lg:pt-1" />}
        </div>
      ));
    } else {
      commentsContent = <p className="p-4">No comments have been added to this ticket.</p>;
    }

    return <div className="flex flex-col p-2 lg:overflow-y-scroll lg:max-h-50s lg:pb-8">{commentsContent}</div>;
  };

  const setActiveTab = (key: any) => {
    history.push(key);
  };

  if (pageError) {
    // Bubble up to ErrorBoundary
    throw new Error('Error in the Incident page');
  }

  if (isUnderMaintenance === true) {
    return <ScheduleMaintenance />;
  }
  if (!(incident as any).data) {
    return null;
  }

  return (
    <div className="page-container">
      <PageHeader title="Support" buttonLabel="Need Help" />
      <Tabs
        tabs={[
          { title: 'Support Requests', key: `${getBaseUrl(customerId)}/support/cases` },
          { title: 'Incidents', key: `${getBaseUrl(customerId)}/support/incidents`, active: true },
          { title: 'Change Requests', key: `${getBaseUrl(customerId)}/support/change-requests` },
        ]}
        tabSelected={setActiveTab}
      />
      <div className="flex flex-col text-purple-700 lg:mr-2 lg:flex-row">
        <h2 className="text-xl lg:pb-4 md:text-2xl font-title lg:w-1/2">Incident Detail</h2>
        <p className="flex items-center pb-4 text-lg lg:justify-end lg:w-1/2 lg:text-xl">
          <span className="mr-1 font-bold">Phone Support:</span>877-470-5260
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col xl:flex-row">
          <div className="flex flex-col bg-white rounded-b shadow lg:mr-2 lg:rounded-none min-w-3/12 2xl:w-1/3 3xl:w-1/4 4xl:w-1/5">
            <div className="flex bg-purple-700 xl:hidden">
              <div className="flex flex-col flex-grow py-3 pl-4 font-sans font-bold text-white">
                <p className="">{(incident as any).data.short_description}</p>
                <p className="">#{(incident as any).data.number}</p>
              </div>
              <div className="flex items-center justify-center w-8 text-2xl text-orange xl:hidden">
                <button aria-label="Show Details" type="button" onClick={() => setShowDetails(!showDetails)}>
                  <FontAwesomeIcon icon={showDetails ? faCaretDown : faCaretUp} />
                </button>
              </div>
            </div>

            {showDetails && (
              <div className="flex flex-col px-2 pb-4">
                <p className="hidden mt-2 text-sm text-purple-900 lg:block">
                  <span className="mr-1 text-lg font-bold">{(incident as any).subject}</span>
                </p>
                <p className="hidden mb-2 text-lg lg:block">#{(incident as any).number}</p>
                <p className="my-2 text-sm">
                  <span className="mr-1 font-bold">Status:</span>
                  <span className="text-red-700">{(incident as any).state}</span>
                </p>
                <p className="my-2 text-sm">
                  <span className="mr-1 font-bold">Opened:</span>
                  {formatDateShortAndTime((incident as any).data.opened_at, activeUser.timeZone)}
                </p>
                <p className="my-2 text-sm">
                  <span className="mr-1 font-bold">Updated:</span>
                  {formatDateShortAndTime((incident as any).data.sys_updated_on, activeUser.timeZone)}
                </p>
                <p className="my-2 mr-1 text-sm">
                  <span className="font-bold">Category:</span>
                  <span className="capitalize"> {(incident as any).data.category}</span>
                </p>
                <p className="my-2 mr-1 text-sm">
                  <span className="font-bold">Priority:</span> {(incident as any).data.priority}
                </p>

                {attachments && (
                  <div className="my-2 text-sm">
                    <button
                      className="flex font-bold"
                      type="button"
                      onClick={() => setShowAttachments(!showAttachments)}
                    >
                      Attachments
                      <span className="ml-1 text-base text-orange">
                        {!showAttachments && <FontAwesomeIcon icon={faCaretUp} />}
                        {showAttachments && <FontAwesomeIcon icon={faCaretDown} />}
                      </span>
                    </button>
                    {showAttachments &&
                      (attachments as any).map((attachment: any) => (
                        <div key={attachment.id}>
                          <button
                            type="button"
                            className="ml-2 text-purple-500 underline focus:ring-0"
                            onClick={async () => {
                              await onAttachmentClick(attachment.id);
                            }}
                          >
                            {attachment.fileName}
                          </button>
                        </div>
                      ))}
                  </div>
                )}
                <div className="flex flex-row my-2 text-sm">
                  <p className="font-bold">Support Request:</p>
                  {supportRequest && (
                    <button
                      type="button"
                      className="ml-2 text-purple-500 underline focus:ring-0"
                      onClick={() => {
                        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        history.push(`/customers/${customerId}/support/cases/${supportRequest[0].id}`);
                      }}
                    >
                      {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                      {supportRequest[0].number}
                    </button>
                  )}
                </div>
                <div className="flex flex-row my-2 text-sm">
                  <p className="font-bold">Change Request:</p>
                  {changeRequest && (
                    <button
                      type="button"
                      className="ml-2 text-purple-500 underline focus:ring-0"
                      onClick={() => {
                        // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                        history.push(`/customers/${customerId}/support/change-requests/${changeRequest.id}`);
                      }}
                    >
                      {(changeRequest as any).number}
                    </button>
                  )}
                </div>
                <div className="flex flex-row my-2">
                  <p className="mr-1 text-sm">
                    <span className="font-bold">Assigned To:</span> {(incident as any).assignedToName || ''}
                  </p>
                </div>
              </div>
            )}
            {!ticketIsClosed(incident) && allowedToEdit() && (
              <div className="flex flex-col justify-end flex-grow">
                <div className="flex justify-center px-1 pb-4">
                  <Button
                    classNames="mt-2 w-full xl:w-auto"
                    textClassNames="text-xs 2xl:text-base"
                    onClick={requestToCloseTicket}
                    loading={requestingClose}
                    title="Request to Close Ticket"
                  />
                </div>
                <div className="flex justify-center">
                  <CloudSupportEscalationPlanLink />
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col bg-white rounded-b shadow lg:mr-2 lg:rounded-none lg:flex-grow">
            <div className="flex bg-purple-700 xl:hidden">
              <div className="flex flex-col flex-grow px-4 py-3 font-sans font-bold text-white">
                <p className="">Incident Detail</p>
                <p className="">#{(incident as any).data.number}</p>
              </div>
              <div className="flex items-center justify-center w-8 text-2xl text-orange xl:hidden">
                <button aria-label="Show Comments" type="button" onClick={() => setShowComments(!showComments)}>
                  <FontAwesomeIcon icon={showComments ? faCaretDown : faCaretUp} />
                </button>
              </div>
            </div>
            {showComments && renderComments()}
            {showComments && (
              <div className="flex flex-col px-4 pb-4">
                {ticketIsClosed(incident) && (
                  <p className="text-sm font-bold text-center text-blue-900">
                    It's been a pleasure working with you, this ticket has been resolved
                  </p>
                )}
                {!ticketIsClosed(incident) && allowedToEdit() && (
                  <div className="flex flex-col">
                    <TextAreaInput
                      control={control}
                      inputClassName="bg-gray-100 "
                      label="Reply"
                      field="reply"
                      rows="5"
                      rules={{ required: true, maxLength: 5000 }}
                      formErrors={errors}
                    />
                    {newAttachments &&
                      newAttachments.map(attachment => (
                        <div className="flex text-sm" key={(attachment as any).fileName}>
                          {(attachment as any).fileName}
                          <button
                            aria-label="Remove Attachment"
                            type="button"
                            className="focus:ring-0"
                            onClick={() => {
                              // @ts-expect-error TS(2339): Property 'fileName' does not exist on type 'never'... Remove this comment to see the full error message
                              removeAttachmentClick(attachment.fileName);
                            }}
                          >
                            <div className="ml-1 text-red-700">
                              <FontAwesomeIcon icon={faTimes} />
                            </div>
                          </button>
                        </div>
                      ))}

                    <input
                      hidden
                      multiple
                      type="file"
                      accept="image/png, image/jpeg, .pdf, .doc, .docx, .xlsx"
                      onChange={e => {
                        if (e.target.files === null) {
                          return;
                        }

                        const fileList = Array.from(e.target.files);

                        if (fileList.filter((file: File) => file.size > MAX_FILE_SIZE).length > 0) {
                          errorToast('Attachments must be less than 10MB');
                          return;
                        }
                        addAttachmentClick(e);
                      }}
                      ref={addAttachment}
                    />

                    {allowedToEdit() && (
                      <div className="flex mb-4">
                        <Button
                          title="Add New Attachment"
                          // @ts-expect-error TS(2322): Type '{ title: string; className: string; buttonStyle: "text"; onClick: () => any;
                          className="text-sm text-purple-500"
                          buttonStyle="text"
                          // @ts-expect-error TS(18047): 'addAttachment.current' is possibly 'null'.
                          onClick={() => addAttachment.current.click()}
                        />
                      </div>
                    )}

                    {allowedToEdit() && (
                      <div className="flex flex-row justify-end">
                        <Button
                          type="submit"
                          title="Send Reply"
                          buttonStyle="solid"
                          classNames="bg-purple-500 text-white hover:bg-purple-600 lg:w-auto px-4 py-1"
                          loading={sendingComment}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Incident;
