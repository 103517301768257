/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

// These are here because of the overlay needing to be clickable and a div

import React, { useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { twCascade } from '@mariusmarais/tailwind-cascade';
import { Button } from '../buttons';

type Props = {
  doShow?: boolean;
  title: React.ReactNode;
  cancel?: (...args: any[]) => any;
  cancelText?: string;
  actionText?: string;
  action?: (...args: any[]) => any;
  actionLoading?: boolean;
  actionButtonClass?: string;
  actionButtonsContainerClass?: string;
  actionDisabled?: boolean;
  otherActionText?: string;
  otherAction?: (...args: any[]) => any;
  otherActionLoading?: boolean;
  otherActionDisabled?: boolean;
  children?: React.ReactNode[] | React.ReactNode;
  canExit?: boolean;
  className?: string;
  showXButton?: boolean;
  hideCancel?: boolean;
  widthOverrides?: string;
};

const Modal = ({
  doShow,
  title,
  cancelText = 'Cancel',
  cancel,
  actionText = 'Ok',
  action,
  actionButtonClass,
  actionButtonsContainerClass = 'justify-end',
  actionLoading,
  actionDisabled,
  otherActionText,
  otherAction,
  otherActionLoading,
  otherActionDisabled,
  children,
  canExit = true,
  className,
  showXButton = true,
  hideCancel = false,
  widthOverrides,
}: Props) => {
  const actionButtonRef = useRef();

  useEffect(() => {
    const onKeyup = (e: any) => {
      const evt = e || window.event;
      let isEscape = false;

      if ('key' in evt) {
        isEscape = evt.key === 'Escape' || evt.key === 'Esc';
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape && doShow && canExit) {
        // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        cancel();
      }
    };

    window.addEventListener('keyup', onKeyup);

    return () => {
      window.removeEventListener('keyup', onKeyup);
    };
  }, [cancel, doShow, canExit]);

  useEffect(() => {
    if (!actionButtonRef.current) {
      return;
    }
    if (actionLoading) {
      (actionButtonRef.current as any).setAttribute('disabled', 'disabled');
    } else {
      (actionButtonRef.current as any).removeAttribute('disabled');
    }
  }, [actionLoading]);

  return (
    <div
      className={twCascade(
        'z-40',
        'transition-opacity',
        'duration-300',
        'ease-in-out',
        doShow ? '' : 'opacity-0 pointer-events-none ',
        'fixed',
        'w-full',
        'h-full',
        'top-0',
        'left-0',
        'flex',
        'items-center',
        'justify-center',
        className
      )}
    >
      <div
        className="absolute z-50 w-full h-full bg-gray-900 opacity-50 focus:ring-0"
        // @ts-expect-error TS(2322): Type '((...args: any[]) => any) | null | undefined... Remove this comment to see the full error message
        onClick={canExit ? cancel : null}
      />
      <div
        className={twCascade(
          'z-50',
          'w-full',
          'mx-auto',
          'bg-white',
          'rounded',
          'shadow-lg',
          widthOverrides || 'min-w-11/12 max-h-11/12 max-w-11/12 sm:min-w-1/4 md:max-w-256'
        )}
      >
        <div className="flex justify-between py-1 pl-4 pr-6 text-white bg-purple-500 sm:pl-6">
          <div className="w-full text-base sm:text-lg md:text-xl font-title">{title}</div>
          {canExit && showXButton && (
            <button
              aria-label="cancel"
              type="button"
              className="z-50 ml-4 -mr-2 cursor-pointer focus:ring-0"
              onClick={cancel}
            >
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </button>
          )}
        </div>

        <div className="flex flex-col overflow-y-auto text-left">
          <div className="flex flex-col px-4 py-4 overflow-y-auto md:px-6" style={{ maxHeight: '70vh' }}>
            {children}
          </div>

          <div
            className={`flex justify-end w-full mt-5 p-6 pt-2 sm:mt-4 sm:flex sm:flex-row ${actionButtonsContainerClass}`}
          >
            {!hideCancel && (actionText || otherActionText) && (
              <Button buttonStyle="text" onClick={cancel} title={cancelText} />
            )}

            {otherAction && otherActionText && (
              <Button
                title={otherActionText}
                classNames="md:ml-6 ml-2"
                onClick={otherAction}
                loading={otherActionLoading}
                disabled={!!otherActionDisabled}
              />
            )}

            {action && actionText && (
              <Button
                ref={actionButtonRef}
                title={actionText}
                onClick={action}
                classNames={`md:ml-4 ml-2 ${actionButtonClass}`}
                loading={actionLoading}
                disabled={!!actionDisabled}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
