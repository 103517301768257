import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';

type Props = {
  crumbs?: any[];
};

const Breadcrumbs = ({ crumbs }: Props) => (
  <div className="pt-2">
    {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
    {crumbs.map((part, index) => (
      <p key={index} className="inline pr-2 text-lg font-normal orange text-purple-800">
        {part.click && (
          <Link
            to={part.path}
            onClick={part.click}
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            className={`mr-2 text-purple-800 ${index < crumbs.length ? 'hover:text-purple-600' : ''}`}
          >
            {part.text}
          </Link>
        )}
        {!part.click && part.path && (
          <Link
            to={part.path}
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            className={`mr-2 text-purple-800 ${index < crumbs.length ? 'hover:text-purple-600' : ''}`}
          >
            {part.text}
          </Link>
        )}
        {!part.path && !part.click && part.text}
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        {index < crumbs.length - 1 && <FontAwesomeIcon icon={faCaretRight} className="text-orange" />}
      </p>
    ))}
  </div>
);

export default Breadcrumbs;
