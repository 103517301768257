import React from 'react';
import { useController } from 'react-hook-form';
import { twCascade } from '@mariusmarais/tailwind-cascade';

import { getErrorMessage } from './utils';

type Props = {
  field: string;
  nameValues: {
    text: string;
    value: string;
  }[];
  control: any;
  rules?: any;
  defaultValue?: string;
  purple?: boolean;
  title?: string;
  errors?: any;
  classNamesText?: string;
  classNamesRadioContainer?: string;
  disabled?: boolean;
};

const RadioGroup = ({
  field,
  nameValues,
  control,
  rules,
  defaultValue,
  purple,
  title,
  errors,
  classNamesText,
  classNamesRadioContainer,
  disabled,
}: Props) => {
  const labelColor = purple ? 'text-gray-200' : 'text-blue-900';
  const textColor = purple ? 'text-gray-200' : 'text-purple-700';

  const {
    field: { ref, onChange, value },
    // @ts-expect-error TS(2345): Argument of type '{ name: string; control: any; ru... Remove this comment to see the full error message
  } = useController({ name: field, control, rules, value: defaultValue });

  return (
    <div className="flex flex-col text-sm lg:pt-2">
      <div className="pb-2 font-bold">
        {title}
        {rules?.required && <span className="text-banner-red">*</span>}
      </div>
      <div className={twCascade('flex', 'flex-col', 'lg:items-center', 'lg:flex-row', classNamesRadioContainer)}>
        {nameValues.map(option => (
          <label
            htmlFor={`radio_${field}_${option.value}`}
            key={option.text}
            className={twCascade(
              'flex',
              'mb-1',
              'leading-tight',
              'items-center',
              textColor,
              labelColor,
              classNamesText
            )}
          >
            <input
              id={`radio_${field}_${option.value}`}
              type="radio"
              name={field}
              ref={ref}
              value={option.value}
              className="mx-2 focus:ring-0"
              style={{
                // @ts-expect-error TS(2322): Type '{ '--ring-color': string; }' is not assignab... Remove this comment to see the full error message
                '--ring-color': purple ? 'white' : 'transparent', // Not supported in this version of tailwind
              }}
              onChange={e => onChange(e.target.value)}
              checked={option.value === value}
              disabled={disabled}
            />
            {option.text}
          </label>
        ))}
      </div>
      <div
        className={twCascade(
          'text-xs',
          'text-red-700',
          'min-h-5',
          // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
          getErrorMessage(errors, field) ? 'block' : 'invisible'
        )}
      >
        {getErrorMessage(errors, field, 'This field')}
      </div>
    </div>
  );
};

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
